import { useState } from 'react';
import PropTypes from 'prop-types';
import { Body } from './Typography';
import Icon from './Icon';

const Accordion = props => {
    const [open, setOpen] = useState(props.initOpen);

    const toggle = () => {
        setOpen(prev => !prev);
        if (props.handleToggleChange) props.handleToggleChange(!open);
    };

    return (
        <div className={`Accordion Accordion--${props.type} ${props.padded ? 'Accordion--padded' : ''} ${props.className}`}>
            <div className={`Accordion__header ${props.headerFlex ? 'flex' : ''}`}>
                {props.headerContent}
                <div className='Accordion__toggle spacing__left' onClick={toggle}>
                    <Body>{props.closeTitle && open ? props.closeTitle : props.title}</Body>
                    <Icon name={open ? props.iconClose : props.iconOpen} />
                </div>

            </div>
            {open && props.children}
        </div>
    );
};

export default Accordion;

Accordion.propTypes = {
    // title of the accordion
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.array]),
    // title of accordion on close (optional)
    closeTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.array]),

    headerContent: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    // style of the accordion
    type: PropTypes.oneOf(['default', 'userDetails', 'advancedDetails']),

    // whether or not it intializes open
    initOpen: PropTypes.bool,
    children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),

    // whether or not the accordion is padded below
    padded: PropTypes.bool,

    iconOpen: PropTypes.string,
    iconClose: PropTypes.string,
    className: PropTypes.string,
    handleToggleChange: PropTypes.func,
    headerFlex: PropTypes.bool
};

Accordion.defaultProps = {
    type: 'default',
    initOpen: false,
    padded: false,
    iconOpen: 'caret-down',
    iconClose: 'caret-up',
    className: '',
    headerFlex: false
};
