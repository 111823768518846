export const createCSV = (CSVData, CSVHeaders) => {
    const csvRows = [];
    csvRows.push(CSVHeaders.join(','));
    CSVData.forEach(CSVDataRow => {
        const row = [];
        CSVHeaders.forEach(CSVHeader => CSVDataRow[CSVHeader] ? row.push(CSVDataRow[CSVHeader]) : null);
        return row.length ? csvRows.push(row.join(',')) : null;
    });
    return csvRows.join('\n');
};
