import { useContext } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import _ from 'lodash';

import EventContentTable from './EventContentTable';

import { SnackbarContext } from '../../contexts/SnackbarContextProvider';

import { UPDATE_EVENT_CONTENT } from '../../queries';

import { EventContext } from '../pages/Event';
import { SnackbarDialogContext } from '../../contexts/SnackbarDialogContextProvider';
import { EventContentTabContext } from './EventContentTab';

const EventRelatedContent = ({ parentContent, createNew, event, setEvent }) => {
    const { id } = event;
    const { relatedContent, id: contentId } = parentContent;
    const { successSnackbar, loadingSnackbar } = useContext(SnackbarContext);
    const { snackbarDialog } = useContext(SnackbarDialogContext);
    const { initEvent } = useContext(EventContext) ?? {};
    const { refetchEventContent } = useContext(EventContentTabContext) ?? {};

    const [updateEventContent] = useMutation(UPDATE_EVENT_CONTENT);

    const handleUpdateEventContent = async (updatedEventContent, updatedEvent) => {
        loadingSnackbar({ text: 'Adding Related Content' });
        const variables = {
            id,
            contentId,
            data: {
                ...updatedEventContent
            }
        };

        try {
            await updateEventContent({ variables });
            await refetchEventContent();
            successSnackbar({ text: `Successfully updated ${updatedEventContent.name}` });
        } catch (error) {
            console.log(error);
            snackbarDialog({
                snackbarText: `Failed to update ${updatedEventContent.name}`,
                dialogTitle: 'Event Content Update Error',
                graphQLErrors: error?.graphQLErrors
            });
        }
    };

    const handleNewContent = (newContent) => {
        const updatedRelatedContent = [...newContent, ...(relatedContent ?? [])];
        const currentEventContent = {
            ...parentContent,
            relatedContent: updatedRelatedContent
        };
        const currentEvent = _.cloneDeep(initEvent ?? event);
        const parentId = createNew ? parentContent?.presentationId : parentContent?.id;
        const parentContentIndex = event.eventContent.findIndex(eventPres => eventPres.id === parentId || eventPres.presentationId === parentId);
        currentEvent.eventContent[parentContentIndex].relatedContent = updatedRelatedContent;
        createNew ? setEvent(currentEvent) : handleUpdateEventContent(currentEventContent, currentEvent);
    };

    return (
        <EventContentTable
            eventContent={relatedContent ?? []}
            event={event}
            setEvent={(updatedEvent) => setEvent(updatedEvent)}
            createNew={createNew}
            parentContent={parentContent}
            handleNewContent={(newContent) => handleNewContent(newContent)}
        />
    );
};

export default EventRelatedContent;

EventRelatedContent.defaultProps = {
    createNew: false
};

EventRelatedContent.propTypes = {
    parentContent: PropTypes.object,
    createNew: PropTypes.bool,
    event: PropTypes.object,
    setEvent: PropTypes.func
};
