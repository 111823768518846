import PropTypes from 'prop-types';
import Icon from './Icon';

const InformationMessage = ({ message }) => {
    return (
        <>

            <div style={{ display: 'flex', alignItems: 'center', borderLeftColor: '#F9A825', borderLeftStyle: 'solid', paddingLeft: '10px', borderLeftWidth: 'thick', backgroundColor: '#F6F7F9', height: '35px', width: '99%', lineHeight: '35px' }}>
                <div style={{ display: 'inline-block', verticalAlign: 'middle', lineHeight: 'normal' }}>
                    <Icon name='warning-round' color='warning' className='warning' />&nbsp;&nbsp;<span style={{ fontSize: '10pt' }}>{message}</span>
                </div>
            </div>
        </>
    );
};

export default InformationMessage;

InformationMessage.proptypes = {
    message: PropTypes.string

};
