import Proptypes from 'prop-types';
import { Body } from './Typography';
import Button from './Button';
import { useContext } from 'react';
import { DialogContext } from '../../contexts/DialogContextProvider';
import { DashboardContext } from './Dashboard';

const DashboardMenu = props => {
    const { makeDialog } = useContext(DialogContext);
    const { menuData, className } = useContext(DashboardContext) || {};
    // Clean up props vs. context when implementing event pages
    const { numberOfItems, actions, closeMenu } = menuData || props.menuData;

    return (
        !!numberOfItems &&
        <div className={`DashboardMenu flex ${className || props.className}`}>
            <Body number={1} padded className='DashboardMenu--count'>{numberOfItems}</Body>
            <div className='flex flex-grow padded__left padded__right'>
                <Body number={2}>items selected</Body>
                <div className='DashboardMenu--actions flex'>
                    {
                        actions.map(action => {
                            if (action.hide) return false;
                            return (
                                <Button
                                    key={action.icon}
                                    type='surface'
                                    text={action.text}
                                    icon={action.icon}
                                    disabled={action.disabled}
                                    onClick={action.dialog ? () => makeDialog({ dialog: action.dialog }) : action.onClick}
                                />
                            );
                        })
                    }
                </div>
            </div>
            <Button icon='close' padded className='DashboardMenu--cancel' color='on-surface' onClick={closeMenu} />
        </div>
    );
};

export default DashboardMenu;

DashboardMenu.defaultProps = {
    className: ''
};

DashboardMenu.propTypes = {
    className: Proptypes.string,
    menuData: Proptypes.object
};
