import PropTypes from 'prop-types';
import { Subtitle } from '../modules/Typography';
import EventContentTable from './EventContentTable';

const EventContentCreateNewEvent = ({ event, setEvent, handleNewContent, triggerNewContent }) => {
    return (
        <div className='EventContent'>
            <Subtitle number={4} faded block className='uppercase padded__bottom'>Add Content</Subtitle>
            <EventContentTable
                eventContent={event?.eventContent ?? []}
                event={event}
                setEvent={(updatedEvent) => setEvent(updatedEvent)}
                createNew
                handleNewContent={(newContent) => handleNewContent(newContent)}
                triggerNewContent={() => triggerNewContent()}
            />
        </div>
    );
};

export default EventContentCreateNewEvent;

EventContentCreateNewEvent.defaultProps = {
    event: {}
};

EventContentCreateNewEvent.propTypes = {
    event: PropTypes.object,
    triggerNewContent: PropTypes.func,
    handleNewContent: PropTypes.func,
    setEvent: PropTypes.func,
    errors: PropTypes.object
};
