import { createContext, useEffect, useContext, useState } from 'react';
import PropTypes from 'prop-types';
import FileProcessingPresentation from './FileProcessingPresentation';
import FileProcessingJob from './FileProcessingJob';
import { SnackbarContext } from '../../contexts/SnackbarContextProvider';
import TopAppBar from '../modules/TopAppBar';
import { Heading } from '../modules/Typography';
import Button from '../modules/Button';

export const FileProcessingDialogContext = createContext();

const FileProcessingDialog = ({ processingFiles, setProcessingFiles, presentation }) => {
    const { warningSnackbar } = useContext(SnackbarContext);
    const [handlePrimaryVideo, setHandlePrimaryVideo] = useState();
    const [selectedPresentation, setSelectedPresentation] = useState({ ...(presentation ?? {}) });
    const isVpp = !!selectedPresentation.vppPlayback;
    const { id: presentationId } = selectedPresentation;

    useEffect(() => {
        if (!processingFiles.length && !presentationId) {
            warningSnackbar({ text: 'Cannot process files due to presentation failure' });
            setProcessingFiles([]);
        }
        const videoFiles = processingFiles.filter(file => file.type === 'video');
        if (!!presentationId && isVpp && !selectedPresentation.vppPackageId && videoFiles.length > 1) setHandlePrimaryVideo(true);
    }, [processingFiles, presentationId]);

    return (
        <FileProcessingDialogContext.Provider value={{
            presentationId,
            selectedPresentation,
            setSelectedPresentation,
            processingFiles,
            setProcessingFiles,
            isVpp,
            handlePrimaryVideo,
            setHandlePrimaryVideo
        }}
        >
            <TopAppBar className='FileProcessing__TopAppBar flex' static>
                <Heading number={6}>{presentationId ? 'Run New Job' : 'Add to Presentation'}</Heading>
                <Button icon='close-round' type='surface' size='small' onClick={() => setProcessingFiles([])} />
            </TopAppBar>
            {presentationId
                ? <FileProcessingJob />
                : <FileProcessingPresentation />}

        </FileProcessingDialogContext.Provider>
    );
};

export default FileProcessingDialog;

FileProcessingDialog.propTypes = {
    processingFiles: PropTypes.array,
    setProcessingFiles: PropTypes.func,
    presentation: PropTypes.object
};
