import Icon from './Icon';
import { Body } from './Typography';
import PropTypes from 'prop-types';

const ChipSet = props => {
    const click = (e, chip) => {
        e.preventDefault();
        props.handleDelete(chip, e.target.id);
    };

    return (
        <div className='ChipSet'>
            {props.chips.map((chip, index) => (
                <div className={`ChipSet__chip ${props.editing ? 'ChipSet__chip--editing' : ''}`} key={chip.split(' ').join('')}>
                    <Body number={2} className='ChipSet__chip__text'>{chip}</Body>
                    {(props.editing && props.handleDelete) &&
                        <button id={props.id} className='ChipSet__chip__delete' onClick={(e) => click(e, chip)}>
                            <Icon name='close-round' className='ChipSet__chip__delete__icon' />
                        </button>}
                </div>
            ))}

        </div>
    );
};

export default ChipSet;

ChipSet.propTypes = {
    // chips to iterate over
    chips: PropTypes.arrayOf(PropTypes.string),

    // whether or not the chips are in editing mode
    editing: PropTypes.bool,

    // function to handle the deletion of a chip
    handleDelete: PropTypes.func,

    id: PropTypes.string
};

ChipSet.defaultProps = {
    chips: [],
    editing: false,
    id: ''
};
