import { createContext, useContext, useEffect, useState } from 'react';
import _ from 'lodash';
import { useLazyQuery, useMutation } from '@apollo/client';

import EventContentTableV2 from './EventContentTableV2';
import Button from '../modules/Button';

import EventAddContent from './EventAddContent';

import { subjectsFromUser, mergeAcl, authorized } from '../../utils/acl';

import { GET_EVENT_CONTENT_BY_ID, ADD_EVENT_CONTENT, UPDATE_EVENT } from '../../queries';

import { EventContext } from '../pages/Event';
import { SnackbarContext } from '../../contexts/SnackbarContextProvider';
import { DialogContext } from '../../contexts/DialogContextProvider';
import { UserContext } from '../../contexts/UserContextProvider';
import { SnackbarDialogContext } from '../../contexts/SnackbarDialogContextProvider';
import { EventTabsContext } from './EventTabs';
import SearchInput from '../modules/SearchInput';

export const EventContentTabContext = createContext();

const EventContentTab = props => {
    const { user } = useContext(UserContext);
    const {
        contentVariables,
        setContentVariables,
        contentTotal,
        setContentTotal
    } = useContext(EventTabsContext) ?? {};
    const { event, setInitEvent, initEvent, handleRefetchEvent, refetchContentTab, setRefetchContentTab } = useContext(EventContext) ?? {};
    const [eventContentById, { loading, refetch: refetchEventContent }] = useLazyQuery(GET_EVENT_CONTENT_BY_ID);
    const [customLoading, setCustomLoading] = useState(false);
    const { errorSnackbar, loadingSnackbar, closeSnackbar, successSnackbar } = useContext(SnackbarContext);
    const { snackbarDialog } = useContext(SnackbarDialogContext);
    const { makeDialog } = useContext(DialogContext);

    const [addEventContent] = useMutation(ADD_EVENT_CONTENT);
    const [updateEvent] = useMutation(UPDATE_EVENT);

    const eventContent = initEvent.eventContent ?? [];

    const handleGetContent = async (updatedParams, replaceContent = true, updatedEvent, refetching = false) => {
        if (!initEvent.contentCount && (!!updatedEvent && !updatedEvent?.contentCount)) return;
        setCustomLoading(true);
        const replaceParams = replaceContent
            ? {
                page: 1
            }
            : {};
        const variables = {
            id: event.id,
            extended: true,
            paramsInput: { ...(updatedParams ?? contentVariables), ...replaceParams }
        };
        if (!refetching) loadingSnackbar({ text: 'Fetching Event Content' });
        try {
            const { data } = await eventContentById({ variables });
            const { total, eventContent } = data?.eventContentById;
            setContentTotal(total);
            let updatedContent = replaceContent ? eventContent : [...(initEvent.eventContent ?? []), ...eventContent];
            if (!replaceContent) updatedContent = _.uniqBy(updatedContent, 'id');
            let currentEvent = null;
            if (!updatedEvent) currentEvent = await handleRefetchEvent();
            currentEvent = {
                ...(updatedEvent ?? currentEvent),
                eventContent: updatedContent
            };
            setInitEvent(currentEvent);
            setRefetchContentTab(false);
            if (!refetching) closeSnackbar();
            setCustomLoading(false);
            return currentEvent;
        } catch (error) {
            if (error.name !== 'AbortError') {
                setCustomLoading(false);
                console.log(error);
                errorSnackbar({ text: 'Failed to fetch event content' });
            }
        }
    };

    const refetchContent = async (refetching = true) => {
        const updatedParams = {
            ...contentVariables,
            limit: initEvent.eventContent?.length ?? contentVariables.limit,
            page: 1
        };
        await handleGetContent(updatedParams, true, null, refetching);
    };

    useEffect(() => {
        if (!refetchContentTab) return;
        refetchContent(false);
    }, [refetchContentTab]);

    const handleACLUpdate = async (newContent, updatedEvent) => {
        const newAcls = newContent.reduce((acc, content) => {
            return {
                acl: mergeAcl(acc.acl, content.acl),
                aclType: _.uniq([...acc.aclType, ...content.aclType])
            };
        }, { acl: event.acl, aclType: event.aclType });

        if (!authorized(newAcls.acl, ['A'], subjectsFromUser(user))) return;

        try {
            const eventVariables = {
                id: event.id,
                data: {
                    ...updatedEvent,
                    acl: newAcls.acl,
                    aclType: newAcls.aclType
                }
            };

            await updateEvent({ variables: eventVariables });
            setInitEvent({ ...initEvent, ...updatedEvent, aclType: newAcls.aclType, acl: newAcls.acl });
        } catch (error) {
            console.log(error);
            snackbarDialog({
                snackbarText: 'Error Updating Group Access',
                dialogTitle: 'Event Group Access Error',
                graphQLErrors: error?.graphQLErrors
            });
        }
    };

    const handleNewContent = async (newContent) => {
        const variables = {
            id: event.id,
            data: newContent
        };

        try {
            loadingSnackbar({ text: 'Adding Event Content' });
            const { data } = await addEventContent({ variables });
            const mergedContentValues = data.addEventContent.map(c => {
                const newC = newContent.find(x => x.presentationId === c.presentationId);
                return _.merge(c, newC);
            });

            const currentEventEvent = await handleRefetchEvent();

            const currentEvent = {
                ...currentEventEvent,
                eventContent: [...(mergedContentValues ?? []),
                    ...(eventContent)]
            };

            await handleACLUpdate(newContent, currentEvent);
            await handleGetContent(contentVariables, true, currentEvent, true);

            successSnackbar({ text: 'Successfully Added Content' });
        } catch (error) {
            console.log(error);
            snackbarDialog({
                snackbarText: 'Error Adding Content',
                dialogTitle: 'Event Adding Content Error',
                graphQLErrors: error?.graphQLErrors
            });
        }
    };

    const handleAddContent = () => {
        makeDialog({
            dialog: <EventAddContent
                contentDetails={event}
                handleNewContent={handleNewContent}
                event={event}
                setEvent={setInitEvent}
                currentContent={eventContent}
                handleRefetchEvent={handleRefetchEvent}
            />,
            disableCloseOnClick: true,
            size: 'xl',
            className: 'EventContent__Add--Dialog manual-overflow'
        });
    };

    const handleLoadMore = async () => {
        const { limit, page } = contentVariables;
        if (page * limit >= contentTotal || loading) return;
        const updatedParams = {
            ...contentVariables,
            page: page + 1
        };
        await handleGetContent(updatedParams, false);
        setContentVariables(updatedParams);
    };

    const handleSearch = async (value, customSearchParams) => {
        const { limit, offset } = customSearchParams ?? contentVariables;
        const updatedParams = { search: value, page: 1, limit, offset };

        if (!value) {
            updatedParams.orderBy = 'startAt';
            updatedParams.order = 'desc';
        }

        if (value.length >= 2 || !value) await handleGetContent(updatedParams);
        setContentVariables(updatedParams);
    };

    const handleSortContent = async (id, isSorted) => {
        let order = 'desc';
        if (!isSorted || isSorted === 'desc') order = 'asc';
        setInitEvent({ ...initEvent, eventContent: [] });
        const updatedParams = { ...contentVariables, orderBy: id, order, page: 1 };
        await handleGetContent(updatedParams);
        setContentVariables(updatedParams);
    };

    const handleLimitChange = async (value) => {
        const currentLength = initEvent.eventContent?.length;
        const page = currentLength > value && currentLength > value / 2 ? Math.ceil(currentLength / value) : 1;

        const updatedParams = {
            ...contentVariables,
            page,
            limit: value
        };

        if (currentLength < value * page) {
            await handleGetContent(updatedParams, false);
        }
        setContentVariables(updatedParams);
    };

    const paginationData = {
        lazyLoading: true,
        ...contentVariables,
        showCount: initEvent.eventContent?.length,
        totalCount: contentTotal,
        handleLimitChange
    };

    return (
        <EventContentTabContext.Provider value={{
            refetchEventContent,
            refetchContent,
            handleLoadMore,
            loading: customLoading ?? loading,
            paginationData,
            handleGetContent,
            handleSortContent,
            contentSort: contentVariables.orderBy
                ? [{
                    id: contentVariables.orderBy,
                    desc: contentVariables.order === 'desc'
                }]
                : []
        }}>

            <div className='EventContent'>
                <div className='EventContent__Header flex'>
                    <SearchInput
                        searchValue={contentVariables.search}
                        updateSearchValue={(value, customSearchParams) => handleSearch(value, customSearchParams)}
                        className='EventContent__Search flex-grow spacing__right'
                        placeholder='Search Event Content...'
                        searchParams={paginationData}
                        debounceSearch
                    />
                    <Button icon='plus-circle' className='spacing__left EventContent__Add--Trigger' onClick={handleAddContent}>Add Content</Button>
                </div>
                <EventContentTableV2 eventContent={eventContent} />
            </div>

        </EventContentTabContext.Provider>
    );
};

export default EventContentTab;
