import { useState } from 'react';
import { Helmet } from 'react-helmet';
import FileProcessing from '../fileProcessing/FileProcessing';
import TopNav from '../layout/TopNav';
import { SEARCH_FILES } from '../../queries';
import AsperaUpload from '../AsperaUpload/AsperaUpload';

const ContentDeliveryDashboard = props => {
    const [triggerRefetch, setTriggerRefetch] = useState(false);
    const [openUpload, setOpenUpload] = useState(false);

    const handlePostUpload = () => {
        setOpenUpload(false);
        setTriggerRefetch(true);
    };

    return (
        <>
            <Helmet><title>File Delivery</title></Helmet>
            <TopNav onClick={() => setOpenUpload(true)} btnText='Add File' heading='File Delivery' />
            <div className='content-wrapper'>
                <AsperaUpload handlePostUpload={handlePostUpload} handlePostCancel={() => setOpenUpload(false)} triggerDialog={openUpload}>
                    <FileProcessing
                        dashboard
                        gqlQuery={SEARCH_FILES}
                        triggerRefetch={triggerRefetch}
                        setTriggerRefetch={setTriggerRefetch}
                    />
                </AsperaUpload>
            </div>
        </>

    );
};

export default ContentDeliveryDashboard;
