import { createContext, useContext, useState } from 'react';
import { useMutation } from '@apollo/client';
import _ from 'lodash';

import EventDetails from './EventDetails';
import DialogFooter from '../modules/DialogFooter';
import TopAppBar from '../modules/TopAppBar';
import { Heading, Body } from '../modules/Typography';
import Accordion from '../modules/Accordion';
import List from '../modules/List';

import { SnackbarDialogContext } from '../../contexts/SnackbarDialogContextProvider';
import { SnackbarContext } from '../../contexts/SnackbarContextProvider';
import { DialogContext } from '../../contexts/DialogContextProvider';

import { BULK_UPDATE_EVENT_CONTENT, UPDATE_EVENT } from '../../queries';

import { dateValidation } from '../../utils/validation';

export const EventContentBulkEditsContext = createContext();

const EventContentBulkEdits = ({ event, parentDetails, handlePostConfirm, handlePostCancel, selectedContent }) => {
    const { closeDialog, bulkConfirmDialog } = useContext(DialogContext);
    const { snackbarDialog } = useContext(SnackbarDialogContext);
    const { successSnackbar } = useContext(SnackbarContext);
    const [bulkUpdateEventContent] = useMutation(BULK_UPDATE_EVENT_CONTENT);
    const [updatedEvent] = useMutation(UPDATE_EVENT);

    const initDetails = {
        timezone: '',
        approvalSourceId: '',
        removeDates: {
            startDate: false,
            endDate: false
        }
    };
    const [details, setDetails] = useState({ ...initDetails });
    const validDetails = _.pickBy(details, _.identity);
    const { hasErrors } = dateValidation({
        startAt: details.startAt,
        endAt: details.endAt,
        parentDates: parentDetails ?? event,
        isRange: true,
        checkDates: true,
        strictParentDates: false,
        selectedDateChange: selectedContent,
        timezone: details.timezone || event.timezone
    });

    const disabled = _.isEqual(initDetails, details) || _.isEmpty(validDetails) || !!hasErrors;
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const [tagAction, setTagAction] = useState('replace');

    const handleReset = () => {
        setDetails({ ...initDetails });
        setErrors({});
        setTagAction('replace');
        closeDialog();
    };

    const handleResponse = async (response, contentDetails) => {
        const errors = response.filter(res => !!res.error);
        const successLength = response.length - errors.length;

        if (successLength) {
            await handlePostConfirm(contentDetails, selectedContent);
            setLoading(false);
        }

        if (!errors.length) {
            closeDialog();
            handleReset();
            return successSnackbar({ text: 'Successfully Edited Content' });
        }

        bulkConfirmDialog({
            confirmAction: 'Updated',
            size: 'medium',
            results: response,
            type: 'Content',
            className: 'EventContent__BulkEdits--Confirm',
            successText: `Successful Content Edit${successLength > 1 ? 's' : null}`,
            handleConfirm: () => {
                closeDialog();
                handleReset();
            },
            setLoading: true,
            postConfirmLoading: true,
            disableCloseOnClick: true
        });
    };

    const handleEventDates = async (contentDetails) => {
        const { startAt, endAt } = contentDetails;
        const currentEvent = { ...event };

        const { startAt: eventStart, endAt: eventEnd } = currentEvent;
        if (!eventStart && !eventEnd) return;

        if (_.has(contentDetails, 'startAt') && !startAt && !!eventStart) currentEvent.startAt = null;
        if (_.has(contentDetails, 'endAt') && !endAt && !!eventEnd) currentEvent.endAt = null;
        if (!!eventStart && startAt < eventStart) currentEvent.startAt = startAt;
        if ((!!eventEnd && endAt > eventEnd)) currentEvent.endAt = endAt;

        if (currentEvent.startAt !== event.startAt || currentEvent.endAt !== event.endAt) {
            try {
                const variables = {
                    id: event.id,
                    data: {
                        ...currentEvent
                    }
                };
                await updatedEvent({ variables });
            } catch (error) {
                console.log(error);
                snackbarDialog({
                    snackbarText: 'Error Updating Event and Content Dates',
                    dialogTitle: 'Event Dates Error',
                    graphQLErrors: error?.graphQLErrors
                });
                return 'error';
            }
        }
    };

    const handleUpdate = async () => {
        setLoading(true);
        const contentDetails = _.pickBy({ ...details, tagAction }, _.identity);

        const { watermarkType, watermarkLocation, watermarkPersistence } = contentDetails;

        if (contentDetails.removeDates?.startDate) contentDetails.startAt = null;
        if (contentDetails.removeDates?.endDate) contentDetails.endAt = null;
        delete contentDetails.removeDates;

        if (watermarkType && watermarkLocation && !watermarkPersistence) contentDetails.watermarkPersistence = null;

        if (!contentDetails.tags?.length) delete contentDetails.tagAction;

        if (_.has(contentDetails, 'startAt') || _.has(contentDetails, 'endAt')) {
            const result = await handleEventDates(contentDetails);
            if (result === 'error') {
                delete contentDetails.startAt;
                delete contentDetails.endAt;
            }
        };

        if (!Object.keys(contentDetails).length) return setLoading(false);

        try {
            const { data } = await bulkUpdateEventContent({
                variables: {
                    id: event.id,
                    data: {
                        contentIds: [...(selectedContent.map(content => content.id))],
                        contentDetails
                    }
                }
            });

            handleResponse(data.response, contentDetails);
        } catch (error) {
            console.log(error);
            snackbarDialog({
                snackbarText: 'Error Updating Content',
                dialogTitle: 'Bulk Edits Error',
                graphQLErrors: error?.graphQLErrors
            });
            setLoading(false);
        }
    };

    const handleCancel = () => {
        handleReset();
        closeDialog();
        if (handlePostCancel) handlePostCancel();
    };

    const handleDetailsUpdate = async (value, field) => {
        const { type, id } = field;
        type === 'object' ? setDetails({ ...details, ...value }) : setDetails({ ...details, [id]: value });
    };

    return (
        <EventContentBulkEditsContext.Provider value={{
            selectedDateChange: selectedContent,
            selectedContent,
            tagAction,
            setTagAction
        }}>
            <TopAppBar>
                <Heading number={6} className='padded'>Bulk Edit</Heading>
            </TopAppBar>
            <div className='padded'>
                <Accordion
                    title='Show Content'
                    closeTitle='Hide Content'
                    type='advancedDetails'
                    headerFlex
                    headerContent={<Body number={1}>Edit the fields below fields to override data for the selected content</Body>}>
                    <div className='spacing__bottom'>
                        <List items={selectedContent.map(content => ({ name: content.name }))} className='BulkList' />
                    </div>
                </Accordion>
                <EventDetails
                    event={event}
                    details={details}
                    setDetails={(updatedContent) => setDetails({ ...updatedContent })}
                    errors={errors}
                    detailsType='content'
                    bulkEdit
                    parentDetails={event}
                    handleDetailsChange={(field, value) => handleDetailsUpdate(field, value)}
                    handleDetailsBlur={(field, value) => handleDetailsUpdate(field, value)}
                    bulkEditContent={selectedContent}
                />

            </div>
            <DialogFooter
                handleConfirm={handleUpdate}
                handleCancel={handleCancel}
                loading={loading}
                disabled={disabled}
            />
        </EventContentBulkEditsContext.Provider>
    );
};

export default EventContentBulkEdits;
