/* eslint-disable indent */

import { createContext, useMemo, useState, useContext } from 'react';
import { Helmet } from 'react-helmet';

import TopNav from '../layout/TopNav';
import { columnHelper } from '../modules/Table';
import Status from '../modules/Status';
import DateTimeCell from '../modules/TableCell/DateTimeCell';
import TextLink from '../modules/TextLink';
import EventDefaultCheckbox from '../modules/TableCell/EventDefaultCheckbox';
import EventCheckbox from '../modules/TableCell/EventCheckbox';
import CreateEventDialog from '../dialogs/event/CreateEventDialog';
import PopUpCard from '../modules/PopUpCard';
import { Body, Subtitle } from '../modules/Typography';
import Accordion from '../modules/Accordion';
import List from '../modules/List';
import Icon from '../modules/Icon';
import DialogFooter from '../modules/DialogFooter';

import { DialogContext } from '../../contexts/DialogContextProvider';

import {
    GET_EVENTS,
    DELETE_EVENT,
    COPY_EVENT,
    PUBLISH_MANY_EVENTS,
    UNPUBLISH_MANY_EVENTS,
    CKECK_EVENT_PUBLISH_PERMISSIONS
} from '../../queries';
import Dashboard from '../modules/Dashboard';
import { useMutation, useLazyQuery } from '@apollo/client';
import { SnackbarContext } from '../../contexts/SnackbarContextProvider';
import { checkPlural } from '../../utils/checkPlural';
import { CopyEventDialog } from '../event/CopyEventDialog';

export const EventsContext = createContext();

const EventsDashboard = () => {
    const { makeDialog, closeDialog } = useContext(DialogContext);
    const { successSnackbar, errorSnackbar } = useContext(SnackbarContext);

    const [selectedEvents, setSelectedEvents] = useState([]);
    const [defaultChecked, setDefaultChecked] = useState(false);
    const [tableData, setTableData] = useState([]);
    const [triggerRefetch, setTriggerRefetch] = useState(false);

    const [deleteEvents] = useMutation(DELETE_EVENT);
    const [copyEvents] = useMutation(COPY_EVENT);
    const [publishManyEvents] = useMutation(PUBLISH_MANY_EVENTS);
    const [unpublishManyEvents] = useMutation(UNPUBLISH_MANY_EVENTS);
    const [checkEventPublishPermissions] = useLazyQuery(CKECK_EVENT_PUBLISH_PERMISSIONS);

    const columns = useMemo(
        () => [
            columnHelper.display({
                header: EventDefaultCheckbox,
                id: 'checkbox',
                cell: EventCheckbox,
                enableSorting: false,
                meta: {
                    className: 'CheckboxCell'
                }
            }),
            columnHelper.accessor('status', {
                cell: ({ getValue }) => <Status value={getValue()?.split('_')?.join(' ') || '-'} />
            }),
            columnHelper.accessor('name', {
                header: 'Event Name',
                cell: ({ getValue, row }) => <TextLink to={`/event-planner/${row.original.id}`}>{getValue()}</TextLink>,
                size: 200
            }),
            columnHelper.accessor('types', {
                header: 'Event Types',
                cell: ({ getValue, row }) => <PopUpCard chips={getValue()} cardTitle={row.original.name} listTitle='Event Types' />,
                enableSorting: false,
                meta: {
                    className: 'overflow--visible'
                }
            }),
            columnHelper.accessor('approvalSourceId', {
                header: 'Source Id',
                cell: ({ getValue }) => getValue() || '-',
                enableSorting: false
            }),
            columnHelper.accessor('tags', {
                header: 'Event Tags',
                cell: ({ getValue, row }) => <PopUpCard chips={getValue()} cardTitle={row.original.name} listTitle='Event Tags' />,
                enableSorting: false,
                meta: {
                    className: 'overflow--visible'
                }
            }),
            columnHelper.accessor('startAt', {
                header: 'Start Date',
                cell: DateTimeCell
            }),
            columnHelper.accessor('endAt', {
                header: 'End Date',
                cell: DateTimeCell
            }),
            columnHelper.accessor('eventContent', {
                header: 'Content',
                cell: ({ getValue, row }) => <PopUpCard listItems={getValue()?.map(val => ({ ...val, path: `/presentation/${val.presentationId}` }))} cardTitle={row.original.name} listTitle='Content' />,
                enableSorting: false,
                meta: {
                    className: 'overflow--visible'
                }
            }),
            columnHelper.accessor('audienceCount', {
                header: 'Audience',
                cell: ({ getValue }) => getValue() || '0',
                enableSorting: false
            }),
            columnHelper.accessor('aclType', {
                header: 'Group Access',
                cell: ({ getValue, row }) => <PopUpCard chips={getValue()} cardTitle={row.original.name} listTitle='Group Access' />,
                enableSorting: false,
                meta: {
                    className: 'overflow--visible'
                }
            })
        ],
        []
    );

    const hasPublishPermissions = async (id) => {
        try {
            await checkEventPublishPermissions({ variables: { id } });
            return true;
        } catch (error) {
            if (error.graphQLErrors.some(graphQLError => graphQLError.extensions.code === 'FORBIDDEN')) {
                return false;
            }
            return false;
        }
    };

    const handleAction = async (action, events) => {
        if (!events.length) return closeDialog();

        const handleBulk = {
            delete: deleteEvents,
            copy: copyEvents,
            publish: publishManyEvents,
            unpublish: unpublishManyEvents
        };

        const eventIds = events.map(event => event.id);
        const checkEventsIsPlural = checkPlural('event', events);

        const snackbarText = {
            delete: {
                success: `Successfully deleted ${checkEventsIsPlural}`,
                error: `Error deleting ${checkEventsIsPlural}`
            },
            copy: {
                success: `Successfully duplicated ${checkEventsIsPlural}`,
                error: `Error duplicating ${checkEventsIsPlural}`
            },
            publish: {
                success: `Successfully published ${checkEventsIsPlural}`,
                error: `Error publishing ${checkEventsIsPlural}`
            },
            unpublish: {
                success: `Successfully unpublished ${checkEventsIsPlural}`,
                error: `Error unpublishing ${checkEventsIsPlural}`
            }
        };

        try {
            await handleBulk[action]({ variables: { eventIds } });
            successSnackbar({ text: snackbarText[action].success });
        } catch (error) {
            errorSnackbar({ text: snackbarText[action].error });
        }

        closeDialog();
        setSelectedEvents([]);
        setTriggerRefetch(true);
    };

    const actionDialog = async (action, confirmAction) => {
        let events = [...selectedEvents];
        let failedEvents = [];

        if (action === 'delete') {
            const checkEvents = events.reduce((acc, event) => {
                return !event.publishedAt
                    ? { ...acc, canDelete: [...acc.canDelete, event] }
                    : { ...acc, cannotDelete: [...acc.cannotDelete, event] };
            }, { canDelete: [], cannotDelete: [] });

            events = checkEvents.canDelete;
            failedEvents = checkEvents.cannotDelete;
        }

        if (action === 'publish') {
            const checkEvents = await events.reduce(async (accPromise, event) => {
                const acc = await accPromise;
                const canPublish = await hasPublishPermissions(event.id);
                return canPublish
                    ? { ...acc, canPublish: [...acc.canPublish, event] }
                    : { ...acc, cannotPublish: [...acc.cannotPublish, event] };
            }, Promise.resolve({ canPublish: [], cannotPublish: [] }));

            events = checkEvents.canPublish;
            failedEvents = checkEvents.cannotPublish;
        }

        if (action === 'unpublish') {
            const checkEvents = events.reduce((acc, event) => {
                return event.publishedAt
                    ? { ...acc, canUnpublish: [...acc.canUnpublish, event] }
                    : { ...acc, cannotUnpublish: [...acc.cannotUnpublish, event] };
            }, { canUnpublish: [], cannotUnpublish: [] });

            events = checkEvents.canUnpublish;
            failedEvents = checkEvents.cannotUnpublish;
        }

        const checkEventsIsPlural = checkPlural('event', events);
        const checkFailedEventsIsPlural = checkPlural('event', failedEvents);

        const warningText = {
            delete: 'Deleting a event will permanently remove it from the system and cannot be undone. Do you wish to continue?',
            copy: `Are you sure you want to duplicate ${checkEventsIsPlural}?`,
            publish: `Are you sure you want to publish ${checkEventsIsPlural}?`,
            unpublish: `Are you sure you want to unpublish ${checkEventsIsPlural}?`
        };

        const failedActionTest = {
            delete: <span>{failedEvents.length} <span className='Typography--heavy capitalize'>published</span> {checkFailedEventsIsPlural} cannot be {action}d.</span>,
            copy: <span>{failedEvents.length} {checkFailedEventsIsPlural} cannot be {action}.</span>,
            publish: <span>{failedEvents.length} {checkFailedEventsIsPlural} cannot be {action} due to permissions. Please contact your administrator for assistance.</span>,
            unpublish: <span>{failedEvents.length} <span className='Typography--heavy capitalize'>already unpublished</span> {checkFailedEventsIsPlural} cannot be {action}.</span>
        };

        makeDialog({
            title: `${action} ${checkEventsIsPlural}`,
            dialog: <>
                <div className='padded__left padded__right DialogEventsBulk__Content'>
                    {(warningText[action] && !!events.length) &&
                    <Subtitle number={5} className='spacing__bottom block DialogEventsBulk__Warning'>{warningText[action]}</Subtitle>}
                    {!!events.length &&
                    <Accordion
                        title={`Show ${checkEventsIsPlural}`}
                        closeTitle={`Hide ${checkEventsIsPlural}`}
                        type='advancedDetails'
                        headerContent={<Body number={1}>{events.length} {checkEventsIsPlural} to be {confirmAction}</Body>}>
                        <List items={events.map(pres => ({ name: pres.name }))} className='EventsList' />
                    </Accordion>}
                    {!!failedEvents.length &&
                    <Accordion
                        type='advancedDetails'
                        title={`Show ${checkFailedEventsIsPlural}`}
                        closeTitle={`Hide ${checkFailedEventsIsPlural}`}
                        headerContent={
                            <Body number={1}>
                                <Icon name='warning' color='warning' className='half-spacing__right block' />
                                {failedActionTest[action]}
                            </Body>
                        }>
                        <List items={failedEvents.map(pres => ({ name: pres.name }))} className='EventsList' />
                    </Accordion>}
                </div>
                <DialogFooter
                    setLoading
                    confirmText={events.length ? action : 'Ok'}
                    handleConfirm={() => handleAction(action, events)}
                />
            </>,
            size: 'medium',
            className: 'DialogEventsBulk',
            includeHeaderClose: true
        });
    };

    const dupDialog = async (action, confirmAction) => {
        const events = [...selectedEvents];

        makeDialog({
            title: 'Duplicate Event',
            dialog: <CopyEventDialog event={events[0]} />,
            size: 'xl'
        });
    };

    const menuData = {
        closeMenu: () => setSelectedEvents([]),
        numberOfItems: selectedEvents.length,
        actions: [
            {
                text: 'Publish',
                icon: 'check-circle',
                onClick: () => actionDialog('publish', 'published')
            },
            {
                text: 'Unpublish',
                icon: 'stop',
                onClick: () => actionDialog('unpublish', 'unpublished')
            },
            {
                text: 'Duplicate',
                icon: 'copy',
                onClick: () => dupDialog('copy', 'copied')
            },
            {
                text: 'Delete',
                icon: 'trash-can',
                onClick: () => actionDialog('delete', 'deleted')
            }
        ]
    };

    if (selectedEvents.length > 1) menuData.actions.splice(2, 1);

    const handleQuery = (data) => {
        setTableData(data?.allEvents?.events || []);
        setTriggerRefetch(false);
    };

    const dashboardData = {
        gqlQuery: GET_EVENTS,
        handleQuery,
        tableData,
        columns,
        triggerRefetch,
        menuData,
        searchPlaceholder: 'Search event, ID, title, content or file...',
        setTriggerRefetch
    };

    return (
        <EventsContext.Provider value={{
            selectedEvents,
            setSelectedEvents,
            events: tableData,
            defaultChecked,
            setDefaultChecked
        }}
        >
            <Helmet><title>Event Planner</title></Helmet>
            <TopNav
                heading='Events'
                btnText='Create Event'
                onClick={() => makeDialog({
                    dialog: <CreateEventDialog initCancel={false} refetch={() => setTriggerRefetch(true)} />,
                    className: 'CreateEventDialog manual-overflow',
                    disableCloseOnClick: true
                })}
            />
            <div className='content-wrapper'>
                <Dashboard
                    hasSideFilters
                    dashboardData={dashboardData}
                    className='Events'
                    type='Events'
                    menuData={menuData}
                />
            </div>
        </EventsContext.Provider>
    );
};

export default EventsDashboard;
