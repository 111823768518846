import { useRef, useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Button from './Button';

import { SnackbarContext } from '../../contexts/SnackbarContextProvider';
import IconButton from './IconButton';

const FileUploadButton = ({ buttonCopy, type, acceptedFiles, handleFile, className, disabled, showFile, value }) => {
    const uploadInput = useRef(null);
    const [file, setFile] = useState(undefined);
    const { warningSnackbar } = useContext(SnackbarContext);

    const uploadFile = (e) => {
        if (uploadInput.current.value) {
            e.preventDefault();
            const newFile = e.target.files[0];
            if (!newFile) return warningSnackbar({ text: 'Error Uploading File' });
            handleFile(newFile);
            setFile(newFile);
            uploadInput.current.value = '';
        }
    };

    const removeFile = () => {
        setFile(undefined);
        handleFile(undefined);
    };

    const triggerInput = (e) => {
        e.preventDefault();
        uploadInput.current.click();
    };

    useEffect(() => {
        if (!value) return;
        setFile(value);
    }, [value]);

    return (
        <>
            {!(showFile && file) &&
                <Button
                    type={type}
                    icon='plus-circle'
                    className={`UploadDropzone__Trigger ${className || ''}`}
                    disabled={disabled}
                    onClick={(e) => triggerInput(e)}>
                    {buttonCopy}
                </Button>}
            {(showFile && file) &&
                <div className='flex align-center'>
                    <span style={{
                        width: '400px',
                        textOverflow: 'ellipsis',
                        overflow: 'hidden'
                    }}>
                        {file.name}
                    </span>
                    <IconButton name='trash-can' onClick={removeFile} />
                </div>}
            <input
                type='file'
                className='sr-only'
                onChange={(e) => uploadFile(e)}
                accept={acceptedFiles}
                ref={uploadInput} />
        </>
    );
};

export default FileUploadButton;

FileUploadButton.defaultProps = {
    buttonCopy: 'Browse',
    disabled: false,
    showFile: false
};

FileUploadButton.proptypes = {
    buttonCopy: PropTypes.string,
    handleFile: PropTypes.func,
    acceptedFiles: PropTypes.string,
    disabled: PropTypes.bool,
    showFile: PropTypes.bool
};
