import { useContext } from 'react';
import Checkbox from '../Checkbox';

import { PresentationsContext } from '../../pages/PresentationsDashboard';
import { EventAddContentContext } from '../../event/EventAddContent';

const PresentationsDefaultCheckbox = props => {
    const { setSelectedPresentations, tableData, setDefaultChecked, defaultChecked } = useContext(PresentationsContext) ?? useContext(EventAddContentContext) ?? {};

    const handleDefaultCheck = ({ checked }) => {
        setDefaultChecked(checked);
        setSelectedPresentations(checked ? tableData : []);
    };

    return (
        <Checkbox
            handleCheck={handleDefaultCheck}
            checked={defaultChecked}
        />
    );
};

export default PresentationsDefaultCheckbox;
