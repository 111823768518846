import DialogFooter from '../modules/DialogFooter';
import { useContext, useEffect, useState } from 'react';
import { DialogContext } from '../../contexts/DialogContextProvider';
import { Body, Subtitle } from '../modules/Typography';
import TextField from '../modules/TextField';
import { DOES_EVENT_EXIST_BY_NAME, COPY_EVENT } from '../../queries';
import { useLazyQuery, useMutation } from '@apollo/client';
import InformationMessage from '../modules/InformationMessage';

export const CopyEventDialog = ({ event }) => {
    const { closeDialog } = useContext(DialogContext);
    const [checkbox1, setCheckbox1] = useState(true);
    const [checkbox2, setCheckbox2] = useState(true);
    const [checkbox3, setCheckbox3] = useState(true);
    const [dupEventName, setDupEventName] = useState('');
    const [errors, setErrors] = useState({ dupName: false });

    const [doesEventExistByName] = useLazyQuery(DOES_EVENT_EXIST_BY_NAME, { variables: { name } });
    const [copyEvent] = useMutation(COPY_EVENT);

    const reset = () => {
        closeDialog();
    };

    const cancel = () => {
        setCheckbox1(true);
        setCheckbox2(true);
        setCheckbox3(true);
        setDupEventName(`Copy of ${event.name}`);
        checkName(`Copy of ${event.name}`);
        closeDialog();
    };

    const handleDuplicateConfirm = async () => {
        const newEvent = await copyEvent({ variables: { eventIds: [event.id], content: checkbox1, audience: checkbox2, code: checkbox3, name: dupEventName } });
        const url = '/event-planner/' + newEvent.data.copyEvent[0].id;
        window.location.href = url;
        reset();
    };

    const checkName = async (name) => {
        const { data } = await doesEventExistByName({ variables: { name: name == null ? dupEventName : name } });
        setErrors({ dupName: data.doesEventExistByName });
        return data.doesEventExistByName;
    };

    const setName = (e) => {
        setDupEventName(e.target.value);
        setErrors({ dupName: false });
    };

    const handleBlur = (e) => {
        checkName();
    };

    useEffect(() => {
        setDupEventName(`Copy of ${event.name}`);
        setCheckbox1(true);
        setCheckbox2(true);
        setCheckbox3(true);
        checkName(`Copy of ${event.name}`).then({
            // nothing
        });
    }, [event]);

    return (
        <div className='padded__left padded__right DialogEventsBulk__Content'>
            <InformationMessage message={`${event.name} is being duplicated. Please assign new name to the event. You will be redirected to the duplicated event for further edits.`} />
            <br />
            <Subtitle number={4} className='spacing__bottom block DialogEventsBulk__Warning'>EVENT
                DETAILS</Subtitle>

            <TextField
                key='name'
                required
                className={'TextField__name width : \'2000\' required'}
                label='Event Name'
                error={errors.dupName || dupEventName == null || dupEventName.length === 0}
                errorMessage={getErrorMessage()}
                disabled={false}
                defaultValue={`Copy of ${event.name}`}
                value={dupEventName}
                onChange={e => {
                    setName(e);
                }}
                onBlur={e => {
                    handleBlur(e);
                }}
            />
            <p />
            <Body number={1}>Select the details from the existing event you want to include:</Body>
            <p />
            <input
                type='checkbox' name='contentCheckbox' checked={checkbox1}
                onChange={() => {
                    setCheckbox1(!checkbox1);
                }} /> Content<br />
            <input
                type='checkbox' name='audienceCheckbox' checked={checkbox2}
                onChange={() => {
                    setCheckbox2(!checkbox2);
                }} /> Audience <br />
            <input
                type='checkbox' name='codeCheckbox' checked={checkbox3}
                onChange={() => {
                    setCheckbox3(!checkbox3);
                }} /> Codes <br />
            <DialogFooter
                setLoading
                disabled={errors.dupName || dupEventName == null || dupEventName.length === 0}
                confirmText='Duplicate'
                confirmCancelText='Cancel'
                handleConfirm={() => handleDuplicateConfirm()}
                handleCancel={() => cancel()}
            />

        </div>
    );

    function getErrorMessage () {
        if (errors.dupName) {
            return 'Duplicate event name. Please enter a unique event name.';
        } else if (dupEventName == null || dupEventName.length === 0) {
            return 'Please enter a unique event name.';
        }
    }
};
