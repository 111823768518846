/* eslint-disable indent */
import { useMemo, useContext } from 'react';
import PropTypes from 'prop-types';
import Table, { columnHelper } from './Table';
import ContentAccordion from './ContentAccordion';
import { PublishingContext } from '../dialogs/PublishDialog';
import { Subtitle } from './Typography';
import _ from 'lodash';

const PublishingTable = ({ data, environment }) => {
    const { publishingScopes, publishingEnvironments, presentation } = useContext(PublishingContext);
    const { value: environmentStatus } = presentation.doc.publish.status[environment];

    const disabled = !publishingEnvironments.includes(environment);
    const filteredData = data.filter(val => publishingScopes.includes(val.scope));
    const title = `${environment} Changes ${!!filteredData.length && !disabled ? '(' + filteredData.length + ')' : ''}`;

    const getTypeFormat = (value) => {
        const type = typeof value;

        switch (type) {
            case 'string':
                return !value ? 'null' : value;
            case 'number':
                return `${value}`;
            case 'boolean':
                return `${value}`;
            case 'object': {
                return _.isEmpty(value) ? 'null' : <pre>{JSON.stringify(value, undefined, 1)}</pre>;
            }
            default:
                return false;
        }
    };

    const columns = useMemo(
        () => [
            columnHelper.accessor('scope', {
                enableSorting: false
            }),
            columnHelper.accessor('field', {
                enableSorting: false
            }),
            columnHelper.accessor('catalogValue', {
                header: 'Changed From',
                enableSorting: false,
                meta: {
                    className: 'catalogValue'
                },
                cell: ({ getValue }) => {
                    const value = getValue();
                    return value !== undefined ? getTypeFormat(value) : <span className='success'>Added</span>;
                }
            }),
            columnHelper.accessor('authoringValue', {
                header: 'Changed To',
                enableSorting: false,
                meta: {
                    className: 'authoringValue'
                },
                cell: ({ getValue }) => {
                    const value = getValue();
                    return value !== undefined ? getTypeFormat(value) : <span className='error'>Removed</span>;
                }
            })
            ],
        []
    );

    return (
        <ContentAccordion
            initOpen={!disabled}
            className={`PublishingTable ${disabled && 'disabled'}`}
            title={title}
        >
            <Table
                columns={columns}
                data={filteredData}
            />
            {!filteredData.length &&
                <>
                    {
                        environmentStatus === 'unpublished'
                        ? <div className='flex column align-center padded'>
                            <Subtitle className='padded'>This record is being published to <span className='bold uppercase'>{environment}</span> for the first time.</Subtitle>
                            <Subtitle className='padded'> All saved values will be published.</Subtitle>
                        </div>
                        : <div className='flex column align-center padded'>
                            <Subtitle className='padded'>No unpublished changes exist in <span className='bold uppercase'>{environment}</span>.</Subtitle>
                        </div>
                    }
                </>}
        </ContentAccordion>
    );
};

PublishingTable.propTypes = {
    data: PropTypes.array,
    environment: PropTypes.string
};

export default PublishingTable;
