import { NavLink } from 'react-router-dom';
import Icon from '../modules/Icon';
import Tooltip from '../modules/Tooltip';
import logo from '../../images/Logo.png';
import { useContext } from 'react';
import { UserContext } from '../../contexts/UserContextProvider';

const SideNav = props => {
    const { user } = useContext(UserContext);
    const links = [
        {
            id: 'home',
            to: '/',
            img: logo,
            imgClass: 'logo',
            tooltip: 'Debut Backstage',
            disabled: true
        },
        {
            id: 'request',
            to: '/request',
            icon: 'list-view',
            tooltip: 'request Search',
            roles: ['ROLE_REQUEST']
        },
        {
            id: 'ingest',
            to: '/ingest',
            icon: '\ue9fe', // 'file-text' does not work as a ligature on Safari, use unicode
            tooltip: 'File Delivery',
            roles: ['ROLE_CONTENT_INGEST']
        },
        {
            id: 'presentations',
            to: '/presentations',
            icon: 'film',
            tooltip: 'Presentation Search'
        },
        {
            id: 'eventPlanner',
            to: '/event-planner',
            icon: 'calendar',
            tooltip: 'Events Search',
            roles: ['ROLE_EVENT_PLANNER']
        }
    ];

    return (
        <div className='SideNav flex align-flex-start center'>
            <nav>
                {links.map(link => {
                    if (link.roles && !link.roles.every(role => user.authorities.includes(role))) return false;
                    return (
                        <Tooltip
                            TooltipCopy={link.tooltip}
                            key={link.id}
                            location='right'
                        >
                            <NavLink to={link.to} className={`nav-link ${link.disabled && 'disabled'}`}>
                                {link.img &&
                                <img src={link.img} className={link.imgClass} />}
                                {link.icon &&
                                <Icon name={link.icon} />}
                            </NavLink>
                        </Tooltip>
                    );
                })}
            </nav>
        </div>
    );
};

export default SideNav;
