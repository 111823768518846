import { useEffect, useRef, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import MenuSurfaceOption from './MenuSurfaceOption';
import TextField from './TextField';
import { Caption } from './Typography';
import Loading from './Loading';
import checkUserRole from '../../hooks/checkUserRole';

const MenuSurface = props => {
    const [open, setOpen] = useState(false);
    const menuRef = useRef(null);
    const dropdownRef = useRef(null);
    const [xFlip, setXFlip] = useState(false);
    const [yFlip, setYFlip] = useState(false);
    const [search, setSearch] = useState('');

    const openMenu = () => setOpen(true);
    const closeMenu = () => {
        setOpen(false);
        if (props.closeParentMenu) props.closeParentMenu();
    };

    useEffect(() => {
        if (!props.handleSearchChange) return;
        props.handleSearchChange(search);
    }, [search]);

    const handleMenuClick = () => {
        if (open) {
            closeMenu();
        } else {
            openMenu();
        }
    };

    // handle organic close
    const handleClick = useCallback((e) => {
        if (menuRef && !menuRef.current?.contains(e.target)) {
            setOpen(false);
        }
    }, [setOpen]);

    const handleKeyPress = (e) => {
        if (!props.handleKeyPress) return;
        if (e.key === 'Enter') {
            props.handleKeyPress(e.target.value);
            closeMenu();
        }
    };

    useEffect(() => {
        if (props.handleOpen) {
            props.handleOpen(open);
        }
        if (open) window.addEventListener('click', handleClick);
        return () => window.removeEventListener('click', handleClick);
    }, [open, handleClick]);

    // handle organic positioning
    useEffect(() => {
        const ref = dropdownRef ? dropdownRef.current : null;
        if (open && ref) {
            const { right, bottom } = ref.getBoundingClientRect();

            if (right > window.innerWidth) setXFlip(true);
            if (bottom > window.innerHeight) setYFlip(true);
        }

        const clearPositioning = () => {
            if (!open) {
                setXFlip(false);
                setYFlip(false);
            }
        };

        if (ref) ref.addEventListener('transitionend', clearPositioning);
        return () => {
            if (ref) ref.removeEventListener('transitionend', clearPositioning);
        };
    }, [open]);

    // filter search
    useEffect(() => {
        if (!open && !props.handleSearchChange) setSearch('');
        if (open && props.handleSearchChange) setSearch('');
    }, [open]);
    let options = props.options || [];
    if (props.searchable && search && options.length) {
        options = options
            .filter(option => typeof option.text === 'string')
            .filter(option => {
                return search
                    .trim()
                    .toLowerCase()
                    .split(' ')
                    .every(term => (option.text.toLowerCase().includes(term) || option.value?.toLowerCase().includes(term))
                    );
            });
    }

    return (
        <div
            className={[
                'MenuSurface',
                props.className,
                open ? 'MenuSurface--open' : 'MenuSurface--close',
                xFlip ? 'MenuSurface--x-flip' : null,
                yFlip ? 'MenuSurface--y-flip' : null
            ].filter(i => i).join(' ')}
            ref={menuRef}
        >
            <div className={`MenuSurface__button ${props.disabled ? 'MenuSurface__button--disabled' : ''}`} onClick={handleMenuClick}>
                {props.children}
            </div>

            {props.searchable && open && (
                <TextField
                    className='Select__searchable'
                    value={search}
                    icon='Search'
                    autofocus
                    onChange={e => setSearch(e.target.value)}
                    onKeyPress={handleKeyPress}
                    placeholder='Start typing to search...'
                />
            )}

            <ul
                className={[
                    'MenuSurface__menu',
                    props.isSubMenu ? 'MenuSurface__menu--submenu' : null,
                    props.className ? `${props.className}__MenuSurface__menu` : null,
                    props.createNew ? 'MenuSurface__menu--createNew' : null
                ].filter(i => i).join(' ')}
                ref={dropdownRef}
            >
                {
                    (props.createNew && props.createNewClick && !yFlip && (!props.createNewRole || checkUserRole(props.createNewRole))) &&
                        <MenuSurfaceOption
                            icon='plus'
                            text='Create New'
                            className='MenuSurfaceOption--createNew'
                            closeParentMenu={closeMenu}
                            onClick={() => props.createNewClick()} />
                }

                {
                    props.searchable && props.loading &&
                    <li className='padded MenuSurfaceOption--loading'>
                        <Loading />
                    </li>
                }

                {props.searchable && open && search && options.length === 0 && !props.loading && (
                    <>
                        <li className='padded'>
                            <Caption faded>No results found.</Caption>
                        </li>
                        {
                            props.addSearch &&
                            <MenuSurfaceOption
                                icon='plus'
                                text={`Add ${search}`}
                                onClick={() => props.addSearch(search)}
                                closeParentMenu={closeMenu}
                            />
                        }
                    </>
                )}
                {options.map((option, index) => (
                    <MenuSurfaceOption multiple={props.multiple} key={index} {...option} closeParentMenu={closeMenu} />
                ))}
                {
                    (props.createNew && props.createNewClick && yFlip) &&
                        <MenuSurfaceOption
                            icon='plus'
                            text='Create New'
                            className='MenuSurfaceOption--createNew MenuSurfaceOption--createNew--yFlip'
                            closeParentMenu={closeMenu}
                            onClick={() => props.createNewClick()} />
                }
            </ul>
        </div>
    );
};

export default MenuSurface;

MenuSurface.propTypes = {
    // all options for the drop down menu
    options: PropTypes.array,

    // className to passdown to tags
    className: PropTypes.string,

    // whether or not the menu provided is a submenu
    isSubMenu: PropTypes.bool,

    // function to close the entire menu
    closeParentMenu: PropTypes.func,

    // whether or not the menu surface is disabled
    disabled: PropTypes.bool,
    children: PropTypes.object,

    // whether or not the list is searchable by listening to window keypresses
    searchable: PropTypes.bool,

    // function to add search value if no match
    addSearch: PropTypes.func,
    // A function to return the search value for use in compenents up the tree
    handleSearchChange: PropTypes.func,

    // whether multiple values can be selected and display checkboxes
    multiple: PropTypes.bool,

    handleKeyPress: PropTypes.func,

    createNew: PropTypes.bool,
    createNewClick: PropTypes.func,

    // weather or not data for menu is loading
    loading: PropTypes.bool,

    // required role for creating new option
    createNewRole: PropTypes.string,

    // trigger event when open changes

    handleOpen: PropTypes.func
};

MenuSurface.defaultProps = {
    className: '',
    isSubMenu: false,
    disabled: false,
    searchable: false,
    multiple: false,
    createNew: false,
    loading: false
};
