import { gql } from '@apollo/client';
import { CODE_FIELDS, CODE_POLICY_FIELDS } from '../fragments/code';

export const GET_CODES = gql`
    ${CODE_FIELDS}
    query getCodes {
        getCodes {
            ...CodeFields
        }
    }
`;

export const CREATE_CODE_BATCH = gql`
    ${CODE_FIELDS}
    mutation createCodeBatch($createCodeBatch: CreateCodeBatch) {
        code: createCodeBatch(createCodeBatch: $createCodeBatch) {
            ...CodeFields
        }
    }
`;

export const GET_CODE_POLICIES = gql`
    ${CODE_POLICY_FIELDS}
    query getCodePolicies {
        getCodePolicies {
            ...CodePolicyFields
        }
    }
`;

export const GET_BATCH_CODES = gql`
    query getBatchCodes($id: ID) {
        getBatchCodes(id: $id) {
            id
            batchId
            code
        }
    }
`;

export const VALIDATE_CODE_BATCH = gql`
    mutation validateCodeBatch($codes: [String], $policy: PolicyInput) {
        validateCodeBatch(codes: $codes, policy: $policy) {
            existingCodes
            invalidCodes
            validCodes
        }
    }
`;
