import { gql } from '@apollo/client';

export const ADD_FILE_TO_PRESENTATION = gql`
	mutation addFileToPresentation($id: ID, $fileId: String) {
		addFileToPresentation(id:$id, fileId: $fileId) {
			result {
				fileId
        		presentationId
        		status
        		createdAt
        		updatedAt
        		createdBy
        		updatedBy
				addedOn
			}
		}
	}
`;

export const UPDATE_PRESENTATION_FILE = gql`
	mutation updatePresentationFile($id: ID, $presentationId: String, $status: String) {
		updatePresentationFile(id: $id, presentationId: $presentationId, status: $status) {
			fileId
			presentationId
			status
			createdAt
			updatedAt
			createdBy
			updatedBy
			addedOn
		}
	}
`;

export const DELETE_PRESENTATION_FILE = gql`
	mutation deletePresentationFile($id: ID, $presentationId: String) {
		deletePresentationFile(id: $id, presentationId: $presentationId) {
			message
		}
	}
`;
