import { gql } from '@apollo/client';
import { EVENT_CODE } from '../fragments/eventCode';

export const GET_EVENT_CODES_BY_ID = gql`
    ${EVENT_CODE}
    query eventCodesById($id: ID!) {
        eventCodes: eventCodesById(id: $id) {
            ...EventCode
        }
    }
`;

export const EVENT_CODES = gql`
    ${EVENT_CODE}
    query getEventCodes ($id: ID) {
        getEventCodes(id: $id) {
            ...EventCode
        }
    }
`;

export const EVENT_CREATE_CODES = gql`
    ${EVENT_CODE}
    mutation createEventCodes ($id: ID, $eventCodeIds:[ID]) {
        createEventCodes (id: $id, eventCodeIds: $eventCodeIds) {
            ...EventCode
        }
    }
`;

export const EVENT_DELETE_CODES = gql`
    mutation deleteEventCodes ($id: ID, $eventCodeIds:[ID]) {
        deleteEventCodes (id: $id, eventCodeIds: $eventCodeIds) {
            message
            error
        }
    }
`;
