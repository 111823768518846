import { Duration, DateTime } from 'luxon';

export const formatValues = (format, value, appConfig) => {
    const { imagick, rfc5646LanguageTags, hdrMetadatas } = appConfig;

    if (value === 'Not Found' || value === 'N/A') return value;

    switch (format) {
    case 'imagePreview': {
        const srcImage = value.startsWith('data:') ? value : `${imagick.baseUrl}${value}&w=280`;
        return <img className='spacing__top spacing__bottom' src={srcImage} width={200} />;
    }
    case 'framerate':
        return `${value} fps`;
    case 'time':
        return isNaN(value) ? value : Duration.fromMillis(Number(value) * 1000).toFormat('hh:mm:ss');
    case 'dateTime':
        return DateTime.fromMillis(Number(value)).toFormat('LL/dd/y h:mm a');
    case 'language': {
        const lang = rfc5646LanguageTags?.find(l => l.id === value)?.display;
        return lang ? `${value} | ${lang}` : value;
    }
    case 'hdrMetadata': {
        const hdrMetadataDisplay = hdrMetadatas?.find(hdrMetadata => hdrMetadata.value === value)?.display;
        return hdrMetadataDisplay || value;
    }
    default:
        return value;
    }
};
