import { gql } from '@apollo/client';

export const FILE_FIELDS = gql`
    fragment FileFields on File {
        id
        docId
        name
        status
        presentationStatus
        type
        createdAt
        updatedAt
        createdBy
        updatedBy
        location
        aclType
        acl {
            subject
            capabilities
        }
        lastProcessingHistoryJobInput {
            language
        }
    }
`;
