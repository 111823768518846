import { useContext, Fragment, useState, useEffect } from 'react';
import { DataContext } from '../../../contexts/DataContext';
import ChipSelect from '../ChipSelect';
import Select from '../Select';
import _ from 'lodash';
import { EventContentBulkEditsContext } from '../../event/EventContentBulkEdits';

const Tags = ({ selectedTags, handleChange }) => {
    const { appConfig } = useContext(DataContext);
    const tagOptions = appConfig.entitlementTags.map(val => ({ text: val.name || val, value: val.id || val }));
    const tagsAction = ['replace', 'add', 'remove'].map(val => ({ text: val, value: val }));

    const { selectedContent: bulkEditContent, tagAction, setTagAction } = useContext(EventContentBulkEditsContext) ?? {};

    const [currentTags, setCurrentTags] = useState([]);

    const [tags, setTags] = useState([...(selectedTags ?? [])]);

    useEffect(() => {
        setTags(selectedTags);
    }, [selectedTags]);

    const handleAction = (action) => {
        setTagAction(action);

        if (action === 'remove') {
            const allCurrentTags = bulkEditContent.reduce((acc, content) => {
                const tags = [...(content.tags ?? [])].map(tag => ({ text: tag, value: tag }));
                return _.uniqBy([...acc, ...tags], 'value');
            }, []);
            setCurrentTags(allCurrentTags);
        } else {
            setCurrentTags([]);
        }
    };

    return (
        <Fragment key='tags'>
            {!!bulkEditContent?.length &&
                <Select
                    label='Tags Action'
                    id='tagsAction'
                    options={tagsAction}
                    selectedValue={tagAction}
                    onChange={({ value }) => handleAction(value)}
                    className='Select__tagsAction width-33'
                />}
            <ChipSelect
                id='tags'
                label='Tags'
                chips={tags}
                options={currentTags.length && tagAction === 'remove' ? currentTags : tagOptions}
                className={`ChipSetSelect--tags ${bulkEditContent?.length ? 'width-66' : 'width-100'}`}
                handleChips={(updatedChips) => {
                    setTags(updatedChips);
                    handleChange(updatedChips);
                }}
                searchable
                sortOptions
            />
        </Fragment>
    );
};

export default Tags;
