import Loading from './Loading';

const LoadingOverlay = () => {
    return (
        <div className='Loading__Overlay'>
            <Loading />
        </div>
    );
};

export default LoadingOverlay;
