import { useContext } from 'react';
import { Helmet } from 'react-helmet';
import WidthContextProvider from '../contexts/WidthContextProvider';
import DialogContextProvider from '../contexts/DialogContextProvider';
import UserContextProvider, { UserContext } from '../contexts/UserContextProvider';
import SnackbarContextProvider from '../contexts/SnackbarContextProvider';
import DataContextProvider from '../contexts/DataContext';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import '../styles/main.scss';

import ContentDeliveryDashboard from './pages/ContentDeliveryDashboard';
import Layout from './layout/Layout';

import PropTypes from 'prop-types';
import File from './pages/File';
import Unauthorized from './pages/Unauthorized';
import Presentation from './pages/Presentation';
import PresentationsDashboard from './pages/PresentationsDashboard';
import EventsDashboard from './pages/EventsDashboard';
import Event from './pages/Event';
import checkUserRole from '../hooks/checkUserRole';
import SnackbarDialogContextProvider from '../contexts/SnackbarDialogContextProvider';
import FeatureFlagContextProvider from '../contexts/FeatureFlagContext';
import RequestDashboard from './pages/RequestDashboard';

const routes = [
    {
        path: '/event-planner/:id',
        element: <RequireRole role='ROLE_EVENT_PLANNER'><Event /></RequireRole>
    },
    {
        path: '/event-planner',
        element: <RequireRole role='ROLE_EVENT_PLANNER'><EventsDashboard /></RequireRole>
    },
    {
        path: '/request',
        element: <RequireRole role='ROLE_REQUEST'><RequestDashboard /></RequireRole>
    },
    {
        path: '/presentation/:presentationId/file/:fileId',
        element: <RequireRole role='ROLE_CONTENT_INGEST'><File /></RequireRole>
    },
    {
        path: '/presentation/:id',
        element: <Presentation />
    },
    {
        path: '/presentations',
        element: <PresentationsDashboard />
    },
    {
        path: '/ingest',
        element: <RequireRole role='ROLE_CONTENT_INGEST'><ContentDeliveryDashboard /> </RequireRole>
    },
    {
        path: '/unauthorized',
        element: <Unauthorized />
    },
    {
        path: '/',
        element: <DefaultLoginRoute />
    }
];

function DefaultLoginRoute () {
    const defalutLanding =
        checkUserRole('ROLE_REQUEST')
            ? '/request'
            : checkUserRole('ROLE_EVENT_PLANNER')
                ? '/event-planner'
                : checkUserRole('ROLE_CONTENT_INGEST')
                    ? '/ingest'
                    : checkUserRole('ROLE_DEBUT_BACKSTAGE')
                        ? '/presentations'
                        : '/unauthorized';

    return <Navigate to={defalutLanding} />;
};

function RequireRole ({ role, children }) {
    const { user } = useContext(UserContext);
    return user.authorities.includes(role) ? children : <Navigate to='/unauthorized' />;
}

function App () {
    return (
        <Router>
            <UserContextProvider>
                <DataContextProvider>
                    <FeatureFlagContextProvider>
                        <SnackbarDialogContextProvider>
                            <SnackbarContextProvider>
                                <DialogContextProvider>
                                    <Helmet>
                                        <body className='light' />
                                        <meta httpEquiv='Content-Security-Policy' content='upgrade-insecure-requests' />
                                    </Helmet>
                                    <Routes>
                                        <Route element={<WidthContextProvider />}>
                                            {routes.map((route, key) => {
                                                const { path, element, ...props } = route;
                                                return (
                                                    <Route key={key} element={<Layout path={path} {...props} exact />}>
                                                        <Route path={path} element={element} exact />
                                                    </Route>
                                                );
                                            })}
                                        </Route>
                                    </Routes>
                                </DialogContextProvider>
                            </SnackbarContextProvider>
                        </SnackbarDialogContextProvider>
                    </FeatureFlagContextProvider>
                </DataContextProvider>
            </UserContextProvider>
        </Router>
    );
}

export default App;

RequireRole.propTypes = {
    role: PropTypes.string,
    children: PropTypes.oneOfType([PropTypes.array, PropTypes.object])
};
