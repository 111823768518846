/* eslint-disable indent */

import { createContext, useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useLazyQuery, useMutation } from '@apollo/client';
import moment from 'moment-timezone';
import _ from 'lodash';

import DialogFooter from '../../modules/DialogFooter';
import { Heading, Body } from '../../modules/Typography';
import IconButton from '../../modules/IconButton';
import CreatePresentation from '../../modules/CreatePresentation';

import { DialogContext } from '../../../contexts/DialogContextProvider';
import { SnackbarContext } from '../../../contexts/SnackbarContextProvider';

import { eventConfig } from '../../../config/eventConfig';

import { CREATE_EVENT, CHECK_EVENT_NAME } from '../../../queries';
import EventDetails from '../../event/EventDetails';
import EventContentCreateNewEvent from '../../event/EventContentCreateNewEvent';

import { UserContext } from '../../../contexts/UserContextProvider';
import { hasACLAccess, mergeAcl } from '../../../utils/acl';
import UploadDropzone from '../../modules/UploadDropzone';
import EventAudienceInvite from '../../event/EventAudienceInvite';
import Steps from '../../modules/Steps';
import TopAppBar from '../../modules/TopAppBar';
import CodeBatches from '../../code/CodeBatches';
import useUpdateEventContent from '../../../hooks/event/useUpdateEventContent';
import checkUserRole from '../../../hooks/checkUserRole';

import addNewEventContent from '../../../hooks/event/addNewEventContent';
import { SnackbarDialogContext } from '../../../contexts/SnackbarDialogContextProvider';

export const CreateEventContext = createContext();

const CreateEventDialog = ({ refetch }) => {
    const { eventDetailsFields, eventInviteFields } = eventConfig;
    const createEventSteps = checkUserRole('ROLE_CODE') ? eventConfig.createEventSteps : eventConfig.createEventSteps.filter(s => s.id !== 'addCodes');
    const initEvent = {
        timezone: moment.tz.guess(),
        acl: [],
        aclType: []
    };

    const { user } = useContext(UserContext);
    const { closeDialog } = useContext(DialogContext);
    const { successSnackbar, loadingSnackbar, closeSnackbar } = useContext(SnackbarContext);
    const { snackbarDialog } = useContext(SnackbarDialogContext);
    const [createNewPresentation, setCreateNewPresentation] = useState(false);
    const [createdEvent, setCreatedEvent] = useState({ ...initEvent });
    const [eventDetails, setEventDetails] = useState({ ...initEvent });
    const [confirmClose, setConfirmClose] = useState(false);
    const [step, setStep] = useState({ ...createEventSteps[0] });
    const [errors, setErrors] = useState({});
    const [uploadedFile, setUploadedFile] = useState(null);
    const errorCount = Object.values(errors).filter(error => !!error).length;
    const userHasACLAccess = hasACLAccess(user, createdEvent.acl);

    const allRequired = eventDetailsFields.filter(s => s.required === true).every(s => !!createdEvent[s.id]) && createdEvent.aclType.length && createdEvent.types?.length;

    const [createEvent, { loading }] = useMutation(CREATE_EVENT);
    const [checkEventName, { loading: checkEventNameLoad }] = useLazyQuery(CHECK_EVENT_NAME);

    useEffect(() => setEventDetails(_.cloneDeep({ ...createdEvent })), [createdEvent]);

    const updateStep = (step) => {
        setErrors({});
        setUploadedFile(null);
        setStep(step);
    };

    const handleClose = async () => {
        setCreateNewPresentation(false);
        setConfirmClose(false);
        setStep({ ...createEventSteps[0] });
        setCreatedEvent({ ...initEvent });
        setEventDetails({ ...initEvent });
        setErrors({});
        closeDialog();
        // allow the db time for creation
        // may need to use pollInterval https://www.apollographql.com/docs/react/data/queries/#graphql-config-options-pollInterval
        const creationDelay = 1000;
        setTimeout(() => { refetch(); }, creationDelay);
    };

    const checkNameDuplicate = async (name) => {
        let duplicateName = false;

        if (step.id === 'eventDetails') {
            loadingSnackbar({ text: 'Checking for duplicate event name' });
            const { data } = await checkEventName({ variables: { name } });
            const checkEvents = data.checkEventName.events.some(event => (
                event.name.toLowerCase().trim() === name?.toLowerCase().trim()
            ));
            setErrors({ ...errors, duplicateName: checkEvents });
            duplicateName = checkEvents;
            closeSnackbar();
        }

        return duplicateName;
    };

    const handleDetailsBlur = async (value, field) => {
        const { relatedValues } = field;
        const { updatedDetails, updatedContent: eventContent } = useUpdateEventContent(createdEvent, field, value);

        if (relatedValues) {
            relatedValues.forEach(relatedValue => {
                const updateValue = value === relatedValue.value || value.includes(relatedValue.value);
                updatedDetails[relatedValue.id] = updateValue ? relatedValue.updatedValue : relatedValue.defaultValue;
            });
        }

        setCreatedEvent({ ...updatedDetails, eventContent: eventContent ?? createdEvent.eventContent });

        if (field.id === 'name') {
            checkNameDuplicate(value);
        }
    };

    const handleCreateEvent = async () => {
            const currEvent = { ...createdEvent };
            await currEvent.eventContent?.map(pres => delete pres.subRows);
            currEvent.eventAudience = currEvent.invites;
            currEvent.codes = currEvent.codes?.map(code => code.id);
            delete currEvent.invites;
            try {
                await createEvent({ variables: { createdEvent: currEvent } });
                successSnackbar({ text: `Successfully created event: ${createdEvent.name}` });
            } catch (error) {
                console.log(error);
                snackbarDialog({
                    snackbarText: 'Error Creating Event',
                    dialogTitle: 'Event Creation Error',
                    graphQLErrors: error?.graphQLErrors
                });
            }
            handleClose();
    };

    const validate = (value, step) => {
        if (value === null) return false;
        const { validation, allowZero = true } = step;
        switch (validation) {
            case 'number': {
                const number = Number(value);
                return !allowZero ? !number || value <= 0 : !number;
            }
            default:
                return false;
        }
    };

    const handleEventUpdate = (value, step) => {
        const { validation, id, required } = step;

        setEventDetails({ ...eventDetails, [id]: value });

        if (id === 'name') setErrors({ ...errors, duplicateName: false });

        const hasNoValue = value === null || value === undefined || value === '';
        if (required && errors[id] !== hasNoValue) {
            setErrors({ ...errors, [id]: hasNoValue });
        }

        if (validation) {
            const valid = validate(value, step);
            if (errors[id] !== valid) {
                setErrors({ ...errors, [id]: valid });
            }
        };
    };

    const handleNewContent = (newContent) => {
        const newAcls = newContent.reduce((acc, content) => {
            return {
                acl: mergeAcl(acc.acl, content.acl),
                aclType: _.uniq([...acc.aclType, ...content.aclType])
            };
        }, { acl: createdEvent.acl, aclType: createdEvent.aclType });

        setCreatedEvent({
            ...createdEvent,
            eventContent: [...newContent, ...(createdEvent.eventContent ?? [])],
            acl: newAcls.acl,
            aclType: newAcls.aclType
        });
    };

    const handlePostCreate = (content) => {
        const { updatedContent } = addNewEventContent(content, createdEvent);
        handleNewContent([updatedContent]);
        setCreateNewPresentation(false);
    };

    const handleCodes = (code) => setCreatedEvent({ ...createdEvent, codes: [...(createdEvent.codes ?? []), code] });

    const codeCardActions = {
            handleRemove: (code) => setCreatedEvent({ ...createdEvent, codes: createdEvent.codes.filter(c => c.id !== code) })
    };

    const inviteData = createdEvent?.invites?.find(invite => !!invite.invitation)?.invitation;
    const allInvitedRequired = inviteData ? eventInviteFields.filter(s => s.required === true).every(s => !!inviteData[s.id]) : true;
    const disableNext = Object.values(errors).some(val => !!val) || !allRequired || !userHasACLAccess || checkEventNameLoad || (step.id === 'addAudience' && (!allInvitedRequired || (!!inviteData?.sendAt && inviteData?.sendAt < Date.now())));

    const handleDropzoneFile = (file) => {
        const snackbarText = {
            addAudience: 'Fetching Audience Account Details',
            addCodes: 'Fetching Code Details'
        };
        loadingSnackbar({ text: snackbarText[step.id] });
        setUploadedFile(file);
    };

    return (
        <CreateEventContext.Provider value={{
            uploadedFile,
            setUploadedFile
        }}>
            <UploadDropzone acceptedFiles='.csv' handleFile={(file) => handleDropzoneFile(file)} disabled={!step?.uploadDropzone}>
                {
                    !confirmClose
                        ? <div className={`CreateEventDialog__Wrapper flex flex-start column ${createNewPresentation ? 'CreateEventDialog__Wrapper--NewPresentation' : null}`}>
                            <TopAppBar className='flex stretch-width CreateEventDialog__Header'>
                                <Heading number={6} className='padded'>Create New Event</Heading>
                                <IconButton name='close' onClick={() => setConfirmClose(true)} />
                            </TopAppBar>
                            <Steps
                                className='padded'
                                stepsData={createEventSteps}
                                handleCancel={() => setConfirmClose(true)}
                                handleFinalStep={handleCreateEvent}
                                finalStepConfirmCopy='Create'
                                handleCurrentStep={(step) => updateStep(step)}
                                disableNext={disableNext}
                                errorCount={errorCount}
                                loading={loading}
                                dialog
                            >
                                {
                                    {
                                        eventDetails: <EventDetails
                                            event={createdEvent}
                                            setEvent={(updatedEvent) => setCreatedEvent(updatedEvent)}
                                            details={eventDetails}
                                            setDetails={(updatedEvent) => setEventDetails(updatedEvent)}
                                            handleDetailsChange={(value, field) => handleEventUpdate(value, field)}
                                            handleDetailsBlur={(value, field) => handleDetailsBlur(value, field)}
                                            errors={{ ...errors }}
                                            createNew
                                        />,
                                        addContent: <EventContentCreateNewEvent
                                            event={createdEvent}
                                            setEvent={setCreatedEvent}
                                            handleNewContent={handleNewContent}
                                            triggerNewContent={() => setCreateNewPresentation(true)}
                                            errors={errors}
                                        />,
                                        addAudience: <EventAudienceInvite
                                            event={createdEvent}
                                            inviteOnly={false}
                                            newInvites={createdEvent?.invites || []}
                                            handleInvites={(updatedInvites) => setCreatedEvent({ ...createdEvent, invites: [...updatedInvites] })}
                                        />,
                                        addCodes: checkUserRole('ROLE_CODE') && <CodeBatches
                                            codes={createdEvent.codes || []}
                                            createFlow
                                            handleCodes={(codes) => handleCodes(codes)}
                                            cardActions={codeCardActions}
                                        />
                                    }[step.id]
                                }
                            </Steps>
                            {
                                createNewPresentation &&
                                <div className='padded stretch-width CreateEventDialog--NewPresentation flex-grow flex column'>
                                    <CreatePresentation
                                        handleBack={() => setCreateNewPresentation(false)}
                                        noHeader
                                        cancelCopy='Back'
                                        handlePostCreate={(_, presentation) => handlePostCreate(presentation)}
                                        removeCreateTypes={['series']}
                                    />
                                </div>
                            }
                        </div>
                        : <div className='CreateEventDialog--Cancel flex column'>
                            <div className='padded'>
                                <Heading number={6} block className='spacing__bottom'>Are you sure you want to exit?</Heading>
                                <Body faded block className='spacing__right'>The details you've entered for this new event will not be saved.</Body>
                            </div>
                            <DialogFooter
                                handleCancel={() => setConfirmClose(false)}
                                handleConfirm={handleClose}
                            />
                        </div>
                }
            </UploadDropzone>
        </CreateEventContext.Provider>
    );
};

export default CreateEventDialog;

CreateEventDialog.propTypes = {
    refetch: PropTypes.func
};
