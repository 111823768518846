import { gql } from '@apollo/client';

export const EVENT_CODE = gql`
    fragment EventCode on Code {
        id
        description
        policyExpiration
        createdAt
        codeCount
        enabled
    }
`;
