import { useContext } from 'react';
import ContentAccordion from '../modules/ContentAccordion';
import { PresentationContext } from '../pages/Presentation';
import ChipSetEditable from '../modules/ChipSetEditable';

const PresentationCredits = props => {
    const { presentationData, setPresentationData } = useContext(PresentationContext);
    const { doc } = useContext(PresentationContext);
    const { credits } = doc.metadata || {};
    const creditFields = ['Actor', 'Director', 'Producer', 'Writer', 'Composer'];

    const handleNewCredit = (credit, id) => {
        const newCredit = {
            name: credit,
            type: id.toUpperCase(),
            character: ''
        };
        const updatedCredits = credits?.length ? [...credits, newCredit] : [newCredit];
        setPresentationData({ ...presentationData, doc: { ...doc, metadata: { ...doc.metadata, credits: updatedCredits } } });
    };

    const handleDeleteCredit = (creditToDelete, id) => {
        const filteredCredit = credits.filter(credit => credit.name !== creditToDelete || credit.type !== id.toUpperCase());
        setPresentationData({ ...presentationData, doc: { ...doc, metadata: { ...doc.metadata, credits: filteredCredit } } });
    };

    return (
        <ContentAccordion title='Credits' className='Presentation__Credits'>
            {creditFields.map(creditField =>
                <ChipSetEditable
                    key={creditField}
                    id={creditField}
                    label={`${creditField}(s)`}
                    chips={credits?.filter(credit => credit.type === creditField.toUpperCase()).map(credit => credit.name)}
                    allowRemove
                    edit
                    handleNewChip={handleNewCredit}
                    handleDeleteChip={handleDeleteCredit}
                    className='spacing__bottom'
                />)}
        </ContentAccordion>
    );
};

export default PresentationCredits;
