import { createContext, useEffect, useContext, useState } from 'react';
import { useQuery } from '@apollo/client';
import _ from 'lodash';
// import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import makeCommitMessage from '../../utils/makeCommitMessage';

import { DialogContext } from '../../contexts/DialogContextProvider';

import TopNav from '../layout/TopNav';
import Loading from '../modules/Loading';
import ContentAccordion from '../modules/ContentAccordion';
import Icon from '../modules/Icon';
import { Body, Subtitle } from '../modules/Typography';
import PresentationHeader from '../presentation/PresentationHeader';
import PresentationFooter from '../presentation/PresentationFooter';
import PresentationMetadata from '../presentation/PresentationMetadata';
import SavePresentationDialog from '../dialogs/SavePresentationDialog';

import { GET_PRESENTATION_BY_ID } from '../../queries';
import PresentationInternalAccess from '../presentation/PresentationInternalAccess';
import FileProcessing from '../fileProcessing/FileProcessing';
import PresentationCredits from '../presentation/PresentationCredits';
import PresentationImages from '../presentation/PresentationImages';
import PresentationEpisodes from '../presentation/PresentationEpisodes';

import PresentationHistory from '../presentation/PresentationHistory';

import checkUserRole from '../../hooks/checkUserRole';
import Unauthorized from './Unauthorized';
import AsperaUpload from '../AsperaUpload/AsperaUpload';
import { isNum, isWholeNum } from '../../utils/validation';

export const PresentationContext = createContext();

const Presentation = props => {
    const { id } = useParams();
    const roleProducer = checkUserRole('ROLE_PRODUCER');
    const { data, loading: loadingPresentation, refetch: refetchPresentation, error } = useQuery(GET_PRESENTATION_BY_ID, { variables: { id } });

    const [presentationData, setPresentationData] = useState({});
    const [initPresentation, setInitPresentation] = useState({});
    const { doc = {}, type, history } = presentationData || {};
    const initDoc = initPresentation?.doc || {};
    const [errors, setErrors] = useState({});
    const [saveDisabled, setSaveDisabled] = useState(false);
    const { makeDialog } = useContext(DialogContext);

    const [openUpload, setOpenUpload] = useState(false);

    const updateDoc = (value, id, field = {}) => {
        const { path, validation, presentation } = field;
        const isNumericInput = validation?.includes('number') || validation?.includes('wholeNumber');
        let val = (isNumericInput && !isNaN(value) && value !== '') ? parseInt(value) : value;
        if (val === '') val = null;

        if (presentation) {
            setPresentationData({ ...presentationData, [id]: val });
        } else {
            const currPres = { ...presentationData };
            path ? currPres.doc.metadata[path][0][id] = val : currPres.doc.metadata[id] = val;
            setPresentationData(currPres);
        }

        validate(val, id, validation);
    };

    const checkError = (error, code) => {
        if (error?.graphQLErrors) {
            for (const graphError of error.graphQLErrors) {
                if (graphError.extensions.code === code) {
                    return true;
                }
            }
        }
        return false;
    };

    const validate = (val, id, validations) => {
        setErrors({
            ...errors,
            [id]: validations
                ?.some(type => {
                    if (type === 'required' && val == null) return true;
                    if (type === 'number' && !(isNum(val) || val == null)) return true;
                    if (type === 'wholeNumber' && !(isWholeNum(val) || val == null)) return true;
                    if (type === 'noSpaces' && (/\s/).test(val)) return true;
                    return false;
                })
        });
    };

    const addAutoPublishInformation = (message) => {
        const { autoPublishToLive, autoPublishToPreview } = presentationData;
        let currentMessage = message;
        if (message.includes('autoPublishToPreview')) {
            currentMessage = currentMessage.replace('autoPublishToPreview', autoPublishToPreview ? 'Auto-Publish On (Preview)' : 'Auto-Publish Off (Preview)');
        }

        if (message.includes('autoPublishToLive')) {
            currentMessage = currentMessage.replace('autoPublishToLive', autoPublishToLive ? 'Auto-Publish On (Live)' : 'Auto-Publish Off (Live)');
        }

        return currentMessage;
    };

    const handleDocUpdate = async () => {
        const { message, diff } = await makeCommitMessage(initPresentation, presentationData);
        const changedFields = addAutoPublishInformation(message);
        const currPres = { ...presentationData };
        const variables = {
            id: data.presentation.id,
            type: data.presentation.type.toLowerCase(),
            commitMessage: changedFields,
            diff,
            data: currPres
        };
        makeDialog({
            dialog:
    <>
        {(!!currPres.autoPublishToLive || !!currPres.autoPublishToPreview) &&
            <div className='flex flex-start Presentation__AutoPublish--Save'>
                <Icon size='large' name='warning' color='warning' className='half-spacing__right' />
                <Body>{`Auto-publish is enabled on this Presentation for the 
            ${currPres.autoPublishToPreview ? 'Preview' : ''} 
            ${!!currPres.autoPublishToPreview && !!currPres.autoPublishToLive ? '&' : ''} 
            ${currPres.autoPublishToLive ? 'Live' : ''} 
            environment(s)`}</Body>
            </div>}
        <Body block>Save any changes you have made and add your comments below</Body>
        <SavePresentationDialog
            key={Math.random()} size='medium'
            variables={variables}
            changedFields={changedFields}
            setInitPresentation={setInitPresentation}
            initDoc={initDoc}
        />
    </>,
            title: 'Save',
            size: 'large',
            padded: true
        });
    };

    useEffect(() => {
        setPresentationData(_.cloneDeep(initPresentation));
    }, [initPresentation]);

    useEffect(() => {
        if (loadingPresentation) return;
        setInitPresentation(_.cloneDeep(data?.presentation));
    }, [data, loadingPresentation]);

    if (checkError(error, 'FORBIDDEN')) return (<Unauthorized />);

    if (loadingPresentation || _.isEmpty(presentationData)) return (<Loading />);

    const handlePostUpload = () => {
        refetchPresentation();
        setOpenUpload(false);
    };

    return (
        <PresentationContext.Provider value={{
            presentationId: id,
            doc,
            initDoc,
            presentationData,
            setPresentationData,
            setInitPresentation,
            loadingPresentation,
            refetchPresentation,
            updateDoc,
            initPresentation,
            handleDocUpdate,
            saveDisabled,
            setSaveDisabled,
            setErrors,
            errors,
            history,
            openUpload,
            setOpenUpload
        }}
        >
            <Helmet><title>{presentationData.name}</title></Helmet>
            <TopNav heading={`${type} Details`} onClick={() => setOpenUpload(true)} btnText='Add File' />
            <div className='Presentation PageContent'>
                <AsperaUpload presentation={presentationData} handlePostUpload={() => handlePostUpload()} handlePostCancel={() => setOpenUpload(false)} triggerDialog={openUpload}>
                    <PresentationHeader />
                    {(doc && type) &&
                    <PresentationMetadata />}
                    {
                        (!doc) &&
                        <ContentAccordion title='Metadata'>
                            <Subtitle number={2} block heavy bottomPadded error>Failed to load metadata</Subtitle>
                        </ContentAccordion>
                    }
                    {type === 'series' && <PresentationEpisodes />}
                    {type === 'movie' && <PresentationCredits />}
                    <PresentationImages />
                    <ContentAccordion title='Files'>
                        <FileProcessing
                            refetch={refetchPresentation}
                            fileProcessingData={presentationData.files || []}
                            presentation={presentationData}
                        />
                    </ContentAccordion>
                    <PresentationInternalAccess />
                    <PresentationHistory />
                </AsperaUpload>
            </div>
            {
                roleProducer &&
                <PresentationFooter />
            }
        </PresentationContext.Provider>
    );
};

Presentation.propTypes = {

};

export default Presentation;
