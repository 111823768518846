import { useEffect, useState, useCallback } from 'react';
import { debounce } from 'lodash';

import { Caption } from './Typography';

const SearchInput = ({
    searchValue,
    updateSearchValue,
    debounceSearch,
    className,
    handleKeyDown,
    disabled,
    error,
    errorMessage,
    placeholder,
    searchParams
}) => {
    const [inputValue, setInputValue] = useState('');

    useEffect(() => {
        setInputValue(searchValue ?? '');
    }, [searchValue]);

    const debouncedHandleSearch = useCallback(debounce(updateSearchValue, 500), []);

    return (
        <div className={`${className ?? ''} TextField TextField__SearchInput`}>
            <div className={[
                'TextField__style-wrapper',
                disabled ? 'TextField__style-wrapper--disabled' : null,
                error ? 'TextField__style-wrapper--error' : null
            ].filter(i => i).join(' ')}
            >
                <input
                    type='text'
                    value={inputValue}
                    onChange={e => {
                        setInputValue(e.target.value);
                        debounceSearch ? debouncedHandleSearch(e.target.value, searchParams) : updateSearchValue(e.target.value);
                    }}
                    onKeyDown={handleKeyDown}
                    className='TextField__input'
                    placeholder={placeholder}
                />
            </div>
            {error && errorMessage && <Caption number={2} error className='TextField__error'>{errorMessage}</Caption>}
        </div>
    );
};

export default SearchInput;
