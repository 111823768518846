import PropTypes from 'prop-types';

const TopAppBar = props => {
    return (
        <header className={`TopAppBar ${props.className} ${props.static ? 'TopAppBar--static' : ''} ${props.padded ? 'padded__left padded__right' : ''}`} style={props.style}>
            {props.children}
        </header>
    );
};

export default TopAppBar;

TopAppBar.propTypes = {
    // className to pass down to tag
    className: PropTypes.string,

    // style to pass down to tag
    style: PropTypes.object,

    padded: PropTypes.bool,

    // whether or not the bar is static
    static: PropTypes.bool,
    children: PropTypes.oneOfType([PropTypes.object, PropTypes.array])

};

TopAppBar.defaultProps = {
    className: '',
    static: false,
    padded: true
};
