import getThumbnail from '../../../hooks/getThumbnail';
import Icon from '../Icon';
import TextLink from '../TextLink';
import { Caption } from '../Typography';

const PresentationDetailsCell = ({
    getValue,
    row,
    table
}) => {
    const value = getValue();
    const {
        id,
        presentationId,
        status,
        internalTitle,
        authorized
    } = row.original;

    const { linkTargetBlank } = table?.options?.state ?? {};

    const imageUrl = getThumbnail(row.original, 'images', 70);
    const liveStatus = status?.find(s => s.indexOf('live') !== -1)?.replace('live_', '');
    const previewStatus = status?.find(s => s.indexOf('preview') !== -1)?.replace('preview_', '');

    const icon = {
        dirty: {
            name: 'warning-round',
            color: 'warning'
        },
        unpublished: {
            name: 'stop',
            faded: true
        },
        published: {
            name: 'check-circle',
            color: 'success'
        }
    };
    return (
        <div className='flex PresentationDetailsCell align-flex-start'>
            {!!presentationId && <img src={imageUrl} alt='' />}
            <div className='PresentationDetailsCell__Content'>
                {authorized
                    ? <TextLink to={`/presentation/${presentationId ?? id}`} target={presentationId || linkTargetBlank ? '_blank' : '_self'}>{value}</TextLink>
                    : <Caption className='TextField'>{value}</Caption>}
                {(internalTitle) &&
                    <Caption faded>{internalTitle}</Caption>}
                <div className='flex flex-start'>
                    {previewStatus &&
                        <div className='flex'>
                            <Caption className='half-spacing__right'>Preview</Caption>
                            <Icon
                                name={icon[previewStatus].name} color={icon[previewStatus].color ?? 'default'}
                                faded={icon[previewStatus].faded ?? false} />
                        </div>}
                    {
                        liveStatus &&
                        <div className='flex'>
                            <Caption className='half-spacing__right spacing__left'>Live</Caption>
                            <Icon
                                name={icon[liveStatus].name} color={icon[liveStatus].color ?? 'default'}
                                faded={icon[liveStatus].faded ?? false} />
                        </div>
                    }
                </div>
            </div>
        </div>
    );
};

export default PresentationDetailsCell;
