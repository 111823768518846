import { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';

import { DataContext } from '../../contexts/DataContext';
import UploadDropzone from '../modules/UploadDropzone';
import { DialogContext } from '../../contexts/DialogContextProvider';
import AsperaUploadDialog from './AsperaUploadDialog';
import { customAvailableContentFormat } from '../../config/createPresentationConfig';

const AsperaUpload = ({ children, handlePostUpload, triggerDialog, newFiles, presentation, handlePostCancel }) => {
    const { asperaInstaller, setAsperaInstaller, asperaWeb, setAsperaWeb } = useContext(DataContext);
    const { makeDialog } = useContext(DialogContext);
    const defaultAcceptedFileTypes = ['mp4', 'mov', 'mxf', 'atmos.audio', 'atmos.metadata', 'atmos.dbmd', 'atmos', 'wav', 'vtt', 'tt', 'ttml', 'webvtt', 'xml', 'jpg', 'jpeg', 'png', 'pdf'];
    const acceptedFileTypes = customAvailableContentFormat[presentation?.type] ?? defaultAcceptedFileTypes;

    const handleUploadDialog = (files = []) => {
        makeDialog({
            dialog: <AsperaUploadDialog
                handlePostUpload={() => handlePostUpload()}
                initialFiles={files}
                acceptedFileTypes={acceptedFileTypes}
                presentation={presentation}
                handlePostCancel={() => handlePostCancel()}
            />,
            size: 'medium',
            className: 'manual-overflow AsperaUpload__Dialog',
            disableCloseOnClick: true
        });
    };

    const initAsperaConnect = () => {
        const AW4 = window.AW4;
        if (!asperaWeb) {
            const CONNECT_INSTALLER = '//d3gcli72yxqn2z.cloudfront.net/connect/v4';
            setAsperaWeb(new AW4.Connect({
                sdkLocation: CONNECT_INSTALLER,
                minVersion: '3.9.0',
                dragDropEnabled: true,
                connectMethod: 'extension'
            }));
            setAsperaInstaller(new AW4.ConnectInstaller({ sdkLocation: CONNECT_INSTALLER }));
        }
    };

    useEffect(() => {
        if (!triggerDialog && !newFiles.length) return;
        handleUploadDialog(newFiles);
    }, [triggerDialog, newFiles]);

    useEffect(() => {
        if (asperaWeb) return;
        initAsperaConnect();
    }, [asperaWeb]);

    useEffect(() => {
        if (!asperaWeb && !asperaInstaller) return;
        if (asperaWeb && asperaInstaller) {
            const AW4 = window.AW4;
            const statusEventListener = (eventType, data) => {
                if (eventType === AW4.Connect.EVENT.STATUS && data === AW4.Connect.STATUS.FAILED) {
                    asperaInstaller.showDownload();
                } else if (eventType === AW4.Connect.EVENT.STATUS && data === AW4.Connect.STATUS.OUTDATED) {
                    asperaInstaller.showUpdate();
                } else if (eventType === AW4.Connect.EVENT.STATUS && data === AW4.Connect.STATUS.RUNNING) {
                    asperaInstaller.connected();
                }
            };
            asperaWeb.initSession();
            asperaWeb.addEventListener(AW4.Connect.EVENT.STATUS, statusEventListener);
        }
    }, [asperaWeb, asperaInstaller]);
    return (
        <UploadDropzone multipleFiles acceptedFiles={acceptedFileTypes.join(',')} handleFile={handleUploadDialog} asperaWeb={asperaWeb} className='AsperaUpload' isSeries={presentation?.type === 'series'}>
            {children}
        </UploadDropzone>

    );
};

export default AsperaUpload;

AsperaUpload.defaultProps = {
    newFiles: [],
    triggerDialog: false
};

AsperaUpload.proptypes = {
    children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    handlePostUpload: PropTypes.func,
    handlePostCancel: PropTypes.func,
    triggerDialog: PropTypes.bool,
    newFiles: PropTypes.array
};
