import { useContext } from 'react';
import PropTypes from 'prop-types';
import Checkbox from '../Checkbox';
import { EventsContext } from '../../pages/EventsDashboard';

const EventCheckbox = ({ row }) => {
    const {
        selectedEvents,
        setSelectedEvents,
        events
    } = useContext(EventsContext);

    const value = row.original.id;
    return (
        <Checkbox
            handleCheck={({ checked }) => setSelectedEvents(checked ? [...selectedEvents, { ...events.find(data => data.id === value) }] : selectedEvents.filter(event => event.id !== value))}
            value={value}
            checked={selectedEvents.some(event => event.id === value)}
        />
    );
};

export default EventCheckbox;

EventCheckbox.propTypes = {
    row: PropTypes.object
};
