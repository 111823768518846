/* eslint-disable indent */
import { createContext, useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import { SnackbarContext } from '../../contexts/SnackbarContextProvider';
import DialogFooter from '../modules/DialogFooter';
import { DialogContext } from '../../contexts/DialogContextProvider';
import { Body, Subtitle } from '../modules/Typography';
import Checkbox from '../modules/Checkbox';
import PresentationAutoPublishCheckbox from '../presentation/PresentationAutoPublishCheckbox';
import { publishingConfig } from '../../config/publishingConfig';
import _ from 'lodash';
import PublishingTable from '../modules/PublishingTable';
import { PUBLISH_PRESENTATION } from '../../queries';
import { getObjectKeyPaths } from '../../utils/object';

export const PublishingContext = createContext();

const PublishingDialog = ({ publishingDiff, presentation, refetchPresentation }) => {
    const { name, type, documentId, doc } = presentation;
    const { status } = doc.publish;
    const { closeDialog } = useContext(DialogContext);
    const { errorSnackbar, successSnackbar } = useContext(SnackbarContext);
    const { environments, scopes, hiddenScopes } = publishingConfig[type];
    const [publishingEnvironments, setPublishingEnvironments] = useState(environments);
    const [publishingScopes, setPublishingScopes] = useState(scopes);

    const [publishPresentation, { loading }] = useMutation(PUBLISH_PRESENTATION, {
        variables: {
            documentId,
            publishData: {
                type,
                environments: publishingEnvironments,
                scopes: publishingScopes
            }
        }
    });

    const handlePublishing = async () => {
        try {
            await publishPresentation();
            refetchPresentation();
            closeDialog();
            successSnackbar({ text: `Published ${name}` });
        } catch (err) {
            console.log(err);
            closeDialog();
            errorSnackbar({ text: `Falied to publish ${name}` });
        }
    };

    const getData = (environment) => {
        return _.union(
            getObjectKeyPaths(publishingDiff[environment], 'catalogValue').map(val => val.replace('.catalogValue', '')),
            getObjectKeyPaths(publishingDiff[environment], 'authoringValue').map(val => val.replace('.authoringValue', ''))
        ).map(path => {
            const value = _.get(publishingDiff[environment], path);
            const pathVals = path.split('.');
            return {
                ...value,
                scope: pathVals[0],
                field: pathVals.slice(1).join(' > ')
            };
        });
    };

    const liveTableData = publishingDiff.live ? getData('live') : [];
    const previewTableData = publishingDiff.preview ? getData('preview') : [];

    const checkScope = (scope) => liveTableData.some(val => val.scope === scope) || previewTableData.some(val => val.scope === scope);

    useEffect(() => setPublishingScopes(publishingScopes.filter(scope => checkScope(scope))), []);

    useEffect(() => {
        const unpublished = publishingEnvironments.some(env => status[env].value === 'unpublished');

        if (!publishingScopes.length || unpublished) {
            return setPublishingScopes(scopes);
        }

        if (publishingScopes.includes('metadata') && !publishingScopes.includes('supplemental') && type === 'movie') {
            return setPublishingScopes([...publishingScopes, 'supplemental']);
        }
    }, [publishingScopes, publishingEnvironments]);

    return (
        <PublishingContext.Provider value={{
            publishingScopes,
            publishingEnvironments,
            presentation
        }}
        >
            <div className='padded PublishingDialog'>
                <Subtitle number={1} block className='spacing__bottom'>Environments</Subtitle>
                <div className='flex flex-start'>
                    <PresentationAutoPublishCheckbox
                        value={publishingEnvironments}
                        disabled={{
                            live: !publishingDiff.live && status.live.value !== 'unpublished',
                            preview: !publishingDiff.preview && status.preview.value !== 'unpublished'
                        }}
                        onChange={checked => setPublishingEnvironments(checked)}
                    />
                </div>
                <Body number={2} block className='spacing__bottom'>Scopes</Body>
                <div className='flex flex-wrap flex-start'>
                    {scopes.map(scope => {
                        if (hiddenScopes?.includes(scope)) return false;
                        return (
                            <Checkbox
                                className='spacing__right spacing__bottom'
                                key={scope}
                                label={scope}
                                value={scope}
                                inline
                                disabled={!checkScope(scope)}
                                handleCheck={({ value, checked }) =>
                                setPublishingScopes(checked
                                    ? [...publishingScopes, value]
                                    : publishingScopes.filter(scope => scope !== value))}
                                checked={publishingScopes.includes(scope)}
                            />
);
}
                    )}
                </div>
                <PublishingTable
                    title='Live Changes'
                    data={liveTableData}
                    environment='live'
                />
                <PublishingTable
                    title='Preview Changes'
                    data={previewTableData}
                    environment='preview'
                />
            </div>
            <DialogFooter
                warningMessage={!presentation.doc?.metadata?.runtime ? 'This presentation does not have playable content' : ''}
                handleCancel={closeDialog}
                handleConfirm={handlePublishing}
                confirmText='Publish'
                confirmCancelText='Back'
                loading={loading}
            />
        </PublishingContext.Provider>
    );
};

PublishingDialog.propTypes = {
    publishingDiff: PropTypes.object,
    presentation: PropTypes.object,
    refetchPresentation: PropTypes.func
};

export default PublishingDialog;
