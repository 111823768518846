import PropTypes from 'prop-types';
import Button from './Button';
import { createCSV } from '../../utils/createCSV';
import { useMemo, useRef, useState } from 'react';

const ButtonDownloadCSV = ({ buttonCopy, type, className, CSVData, CSVHeaders, CSVName, handlePostDownload }) => {
    const [downloading, setDownloading] = useState(false);
    const csvDownload = useRef();
    const downloadData = useMemo(() => createCSV(CSVData, CSVHeaders), [CSVData]);
    const downloadURL = useMemo(() => {
        const blob = new Blob([downloadData], { type: 'text/csv' });
        return window.URL.createObjectURL(blob);
    }, [downloadData]);

    const handleCSVDownload = async () => {
        setDownloading(true);
        await csvDownload.current.click();
        if (handlePostDownload) handlePostDownload();
        setDownloading(false);
    };

    return (
        <>
            <Button type={type} onClick={() => handleCSVDownload()} className={className} loading={downloading}>{buttonCopy}</Button>
            <a href={downloadURL} download={`${CSVName}.csv`} ref={csvDownload} className='visually-hidden' />
        </>
    );
};

ButtonDownloadCSV.defaultProps = {
    buttonCopy: 'Download CSV',
    type: 'primary',
    className: ''
};

ButtonDownloadCSV.proptypes = {
    className: PropTypes.string,
    buttonCopy: PropTypes.string,
    type: PropTypes.string,
    CSVData: PropTypes.array,
    CSVHeaders: PropTypes.array,
    CSVName: PropTypes.string,
    handlePostDownload: PropTypes.array
};

export default ButtonDownloadCSV;
