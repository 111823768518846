import { gql } from '@apollo/client';

const VIDEO_PROCESSING_DETAILS = gql`
    fragment VideoProcessingDetails on Details {
        resolution
        ColorSpace
        framerate
        Duration
    }
`;

const AUDIO_PROCESSING_DETAILS = gql`
    fragment AudioProcessingDetails on Details {
        Channels
        language
    }
`;

const SUBTITLE_PROCESSING_DETAILS = gql`
    fragment SubtitleProcessingDetails on Details {
        framerate
        language
        timestamp
    }
`;

const IMAGE_PROCESSING_DETAILS = gql`
fragment ImageProcessingDetails on Details {
    imagePreview
    resolution
}
`;

export const FILE_PROCESSING_DETAILS = gql`
    ${VIDEO_PROCESSING_DETAILS}
    ${AUDIO_PROCESSING_DETAILS}
    ${SUBTITLE_PROCESSING_DETAILS} 
    ${IMAGE_PROCESSING_DETAILS}
    fragment FileProcessingDetails on Details {
        ...VideoProcessingDetails @include(if: $isVideo)
        ...AudioProcessingDetails @include(if: $isAudio)
        ...SubtitleProcessingDetails @include(if: $isSubtitle)
        ...ImageProcessingDetails @include(if: $isImage)
    }
`;
