import { createContext, useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import EventAudienceTable from './EventAudienceTable';
import Form from '../modules/Form';
import { DialogContext } from '../../contexts/DialogContextProvider';
import { eventConfig } from '../../config/eventConfig';
import Steps from '../modules/Steps';
import _ from 'lodash';
import UploadDropzone from '../modules/UploadDropzone';
import TopAppBar from '../modules/TopAppBar';
import { Heading, Body } from '../modules/Typography';
import { useMutation } from '@apollo/client';
import { EVENT_ASSIGN_AUDIENCE } from '../../queries';
import { SnackbarContext } from '../../contexts/SnackbarContextProvider';
import { DataContext } from '../../contexts/DataContext';
import moment from 'moment-timezone';
import { SnackbarDialogContext } from '../../contexts/SnackbarDialogContextProvider';

export const EventAudienceInviteContext = createContext();

const EventAudienceInvite = ({ newInvites, currentAudience, handleInvites, inviteOnly, handleNewAudience, event, setEvent, inviteParsing, setInviteParsing }) => {
    const eventId = event?.id;
    const { eventInviteFields, audienceInviteSteps } = eventConfig;
    const { closeDialog } = useContext(DialogContext);
    const { appConfig } = useContext(DataContext);
    const { successSnackbar, loadingSnackbar } = useContext(SnackbarContext);
    const { snackbarDialog } = useContext(SnackbarDialogContext);

    const [activeStep, setActiveStep] = useState(audienceInviteSteps[0]);

    const [invites, setInvites] = useState([...(newInvites ?? [])]);
    const initInviteData = { timezone: moment.tz.guess(), ...appConfig.audienceInvite };
    const [inviteData, setInviteData] = useState(initInviteData);
    const allRequired = eventInviteFields.filter(s => s.required === true).every(s => !!inviteData[s.id]);

    const needsStep2 = ['new', 'expired', 'unsent'];
    const sendUserInvites = invites.filter(invite => needsStep2.some(val => val.toLowerCase() === invite.status.toLowerCase()));
    const removeInviteStep = audienceInviteSteps.filter(step => step.id !== 'invite');
    const currentSteps = !sendUserInvites.length ? removeInviteStep : audienceInviteSteps;
    const lastStep = activeStep.id === _.last(currentSteps).id;
    const firstStep = activeStep.id === _.first(currentSteps).id;

    const [uploadedFile, setUploadedFile] = useState(null);

    const [assignEventAudience, { loading: loadingAssignAudience }] = useMutation(EVENT_ASSIGN_AUDIENCE);

    const addInvitationData = (allInvites) => [...allInvites].map(invite => ({ ...invite, invitation: { ...inviteData } }));

    useEffect(() => {
        if (!newInvites) return;
        const invitesWithData = addInvitationData(newInvites);
        setInvites(_.uniqBy([...invitesWithData, ...invites], 'email'));
    }, [newInvites]);

    useEffect(() => {
        const currInvites = addInvitationData(invites);
        inviteOnly ? setInvites(currInvites) : handleInvites(currInvites);
    }, [inviteData]);

    const handleClose = () => {
        closeDialog();
        setInvites([]);
        setInviteData(initInviteData);
        setUploadedFile(null);
        setInviteParsing({});
    };

    const handleCancel = () => {
        if (firstStep) {
            if (inviteOnly) handleClose();
        } else {
            setActiveStep(activeStep - 1);
        }
    };

    const handleInviteError = (error) => {
        snackbarDialog({
            snackbarText: 'Error Adding Audience',
            dialogTitle: 'Event Adding Audience Error',
            graphQLErrors: error?.graphQLErrors
        });
    };

    const handleConfirm = async () => {
        if (!lastStep) {
            setUploadedFile(null);
            setActiveStep(activeStep + 1);
        }
        if (lastStep && inviteOnly) {
            const eventAudience = invites.reduce((acc, currInvite) => {
                const { email, provider, status, invitation } = currInvite;
                return [...acc, { email, provider: provider.toLowerCase(), status, invitation }];
            }, []);

            try {
                const { data } = await assignEventAudience({
                    variables: {
                        id: eventId,
                        eventAudience
                    }
                });

                const { message, error } = data?.assignEventAudience || {};
                if (!!error || !message) {
                    console.log(error);
                    handleInviteError(error);
                } else {
                    successSnackbar({ text: `Added ${invites.length} Audience Member(s)` });
                    await handleNewAudience(eventAudience);
                }
            } catch (error) {
                console.log(error);
                handleInviteError(error);
            }
            handleClose();
        };
    };

    const handleNewInvites = (updatedInvites) => {
        const invitesWithData = addInvitationData(updatedInvites);
        setInvites(invitesWithData);
        if (!inviteOnly) {
            handleInvites(invitesWithData);
        }
    };

    return (
        <EventAudienceInviteContext.Provider value={{
            setUploadedFile,
            uploadedFile
        }}>
            <UploadDropzone
                acceptedFiles='.csv'
                handleFile={(file) => {
                    loadingSnackbar({ text: 'Fetching Audience Account Details' });
                    setUploadedFile(file);
                }}
                disabled={!inviteOnly}>
                {
                    inviteOnly &&
                    <TopAppBar>
                        <Heading number={6}>Add Audience</Heading>
                    </TopAppBar>
                }
                <div className={`EventAudienceInvite__Wrapper EventAudienceInvite--${lastStep ? 'last' : activeStep.id}`}>
                    <Steps
                        stepsData={currentSteps}
                        type='secondary'
                        handleCurrentStep={(step) => setActiveStep(step)}
                        handleCancel={inviteOnly || !firstStep ? handleCancel : null}
                        cancelCopy={!inviteOnly ? 'Back' : 'Cancel'}
                        handleFinalStep={inviteOnly || (!lastStep && !!invites.length) ? handleConfirm : null}
                        finalStepConfirmCopy={!lastStep || !inviteOnly ? 'Next' : 'Invite'}
                        disableNext={!invites.length || (activeStep.id === 'invite' && (!allRequired || (!!inviteData.sendAt && inviteData.sendAt < Date.now())))}
                        dialog
                        loading={loadingAssignAudience}
                        footerCopy={activeStep.id === 'invite' ? `Invite Count: ${sendUserInvites.length}` : `Audience Count: ${invites.length}`}
                    >
                        {
                            {
                                addEmail: <EventAudienceTable
                                    createEventFlow
                                    audience={invites}
                                    currentAudience={currentAudience}
                                    handleAudience={(updatedInvites) => handleNewInvites(updatedInvites)}
                                    newFile={uploadedFile}
                                    event={event}
                                    inviteOnly={inviteOnly}
                                    uploadedInviteData={inviteParsing}
                                />,
                                invite: <Form
                                    formFields={eventInviteFields}
                                    formData={{ ...inviteData, timezone: event.timezone }}
                                    setFormData={(updatedInviteData) => setInviteData(updatedInviteData)}
                                />
                            }[activeStep.id]
                        }
                    </Steps>
                    {!inviteOnly && !sendUserInvites.length &&
                        <Body className='EventAudienceInvite__Count' rightPadded>{activeStep.id === 'invite' ? `Invite Count: ${sendUserInvites.length}` : `Audience Count: ${invites.length}`}</Body>}
                </div>
            </UploadDropzone>
        </EventAudienceInviteContext.Provider>
    );
};

export default EventAudienceInvite;

EventAudienceInvite.proptypes = {
    event: PropTypes.object,
    setEvent: PropTypes.func,
    newInvites: PropTypes.array,
    currentAudience: PropTypes.array,
    handleInvites: PropTypes.func,
    inviteOnly: PropTypes.bool,
    eventId: PropTypes.string,
    handleNewAudience: PropTypes.func,
    inviteParsing: PropTypes.object,
    setInviteParsing: PropTypes.func
};
