import PropTypes from 'prop-types';
import { Body } from './Typography';
import Icon from './Icon';

const Tooltip = ({ tooltipCopy, icon, children, className, location, disabled, color, iconColor, iconReverse, iconFaded }) => {
    return (
        <div className={`
            Tooltip__Wrapper 
            Tooltip__Wrapper--${color}
            ${className} 
            ${disabled ? 'Tooltip__Wrapper--disabled' : 'Tooltip__Wrapper--active'}
            `}>
            <div className={`Tooltip__Trigger Tooltip__Trigger--${color} ${iconReverse ? 'flex reverse' : ''}`}>
                {children}
                {icon &&
                <Icon name={icon} color={iconColor} faded={iconFaded} />}
            </div>

            {tooltipCopy && <Body className={`Tooltip Tooltip--${location}`} number={2}>{tooltipCopy}</Body>}
        </div>

    );
};

Tooltip.defaultProps = {
    className: '',
    location: 'bottom',
    disabled: false,
    color: 'default',
    iconReverse: false,
    iconFaded: false
};

Tooltip.propTypes = {
    children: PropTypes.oneOfType([PropTypes.object, PropTypes.array, PropTypes.string]),
    tooltipCopy: PropTypes.oneOfType([PropTypes.object, PropTypes.array, PropTypes.string]),
    icon: PropTypes.string,
    iconColor: PropTypes.string,
    iconReverse: PropTypes.bool,
    iconFaded: PropTypes.bool,
    className: PropTypes.string,
    location: PropTypes.oneOf(['top', 'bottom', 'left', 'right']),
    disabled: PropTypes.bool,
    color: PropTypes.oneOf(['custom', 'default'])
};

export default Tooltip;
