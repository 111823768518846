import { Fragment, useEffect, useMemo, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import {
    useReactTable,
    flexRender,
    getCoreRowModel,
    getExpandedRowModel,
    getSortedRowModel,
    createColumnHelper
} from '@tanstack/react-table';
import PaginationRange from './PaginationRange';
import Pagination from './Pagination';
import Loading from './Loading';
import useOnScreen from '../../hooks/useOnScreen';
import PaginationLazyLoading from './PaginationLazyLoading';

const Table = props => {
    const [expanded, setExpanded] = useState({});
    const [sorting, setSorting] = useState(props?.initialState?.sorting || []);
    const [defaultChecked, setDefaultChecked] = useState(false);
    const [isLazyLoading, setIsLazyLoading] = useState(false);

    const columns = useMemo(() => (props.columns), [props.columns]);
    const data = useMemo(() => (props.data), [props.data]);

    const tableRef = useRef(null);
    const tableRefData = tableRef?.current?.getBoundingClientRect();
    const tableHeight = tableRefData?.height;
    const loadMoreRootMargin = tableHeight ? tableHeight / 2 : 475;
    const loadMoreDataRef = useRef(null);

    const loadMoreOptions = {
        rootMargin: `${loadMoreRootMargin}px 0px ${loadMoreRootMargin}px 0px`
    };

    const loadMoreData = useOnScreen(loadMoreDataRef, tableRef, data, loadMoreOptions);

    const handleLoading = async () => {
        if (!loadMoreData) return setIsLazyLoading(false);
        setIsLazyLoading(true);
        await props.handleLazyLoad();
        setIsLazyLoading(false);
    };
    useEffect(() => {
        handleLoading();
    }, [loadMoreData]);

    useEffect(() => {
        if (!defaultChecked) return;
        if (!props.selectedItems.length || props.selectedItems.length !== data.length) {
            setDefaultChecked(false);
        }
    }, [props.selectedItems, data]);

    useEffect(() => {
        if (!props?.initialState?.sorting) return;
        setSorting(props?.initialState?.sorting);
    }, [props?.initialState?.sorting]);

    const table =
        useReactTable({
            columns,
            data,
            getCoreRowModel: getCoreRowModel(),
            getExpandedRowModel: getExpandedRowModel(),
            getSortedRowModel: getSortedRowModel(),
            state: {
                expanded,
                sorting,
                columnVisibility: props.columnVisibility,
                rowSelection: props.rowSelection,
                defaultChecked,
                selectedItems: props.selectedItems,
                currentItems: props.currentItems,
                mergeOnDefaultChecked: props.mergeOnDefaultChecked,
                linkTargetBlank: props?.initialState?.linkTargetBlank ?? false
            },
            setSelectedItems: props.setSelectedItems,
            onExpandedChange: setExpanded,
            onSortingChange: setSorting,
            onClickDefault: () => setDefaultChecked(!defaultChecked),
            getSubRows: row => row.subRows,
            columnResizeMode: 'onChange'
            // debugAll: true
        });

    const handleTableSort = (column) => {
        if (!column.getCanSort()) return;
        if (props.handleSort) return props.handleSort(column.id, column.getIsSorted());
        column.getToggleSortingHandler();
    };

    return (
        <>
            {(props.paginationData && !!data.length && !props.hidePaginationHeader && !props.handleLazyLoad) &&
                <div className={`Pagination Pagination__header flex space-between ${props.stickyHeader && 'sticky'}`}>
                    <PaginationRange paginationData={props.paginationData} type='header' />
                </div>}
            {
                (props.handleLazyLoad && props.paginationData) &&
                <PaginationLazyLoading className={`${props.stickyHeader && 'sticky'}`} paginationData={props.paginationData} />
            }
            <table ref={tableRef} className={`ReactTable ${props.className || ''} w-full`} style={{ minWidth: table.getTotalSize() }}>
                <thead className={`ReactTable__Header ${props.stickyHeader && 'sticky'}`}>
                    {table.getHeaderGroups().map((headerGroup) => {
                        return (
                            <tr key={`headergroup-${headerGroup.id}`} className='flex'>
                                {headerGroup.headers.map((header, idx) => {
                                    return (
                                        <th
                                            key={`header-${header.id}`}
                                            className={`Typography th-${idx} ${header.column.columnDef.meta?.className || ''} ${header.column.getCanSort() ? 'canSort' : 'sortDisabled'}`}
                                            style={{ width: header.getSize(), maxWidth: header.column.columnDef.maxSize }}
                                            colSpan={header.colSpan}
                                        >
                                            {header.isPlaceholder
                                                ? null
                                                : (
                                                    <div {...{ className: 'relative flex' }}>
                                                        <div onClick={() => handleTableSort(header.column)} className={`header-info flex flex-start ${header.column.getCanSort() ? 'pointer' : ''}`}>
                                                            {flexRender(
                                                                header.column.columnDef.header,
                                                                header.getContext()
                                                            )}
                                                            {{
                                                                asc: <span className='sort-direction'>&uarr;</span>,
                                                                desc: <span className='sort-direction'>&darr;</span>
                                                            }[header.column.getIsSorted()]}
                                                        </div>
                                                        <div
                                                            {...{
                                                                onMouseDown: header.getResizeHandler(),
                                                                onTouchStart: header.getResizeHandler(),
                                                                className: 'resizer'
                                                            }}
                                                        >
                                                            <div className='resizer__line' />
                                                        </div>
                                                    </div>
                                                )}
                                        </th>
                                    );
                                })}
                            </tr>
                        );
                    })}
                </thead>
                <tbody>
                    {table.getRowModel().rows.map((row, idx) => {
                        return (

                            <Fragment key={row.id}>
                                <tr
                                    key={`row-${row.id}`}
                                    id={`row-${row.id}`}
                                    onClick={() => props.handleRowClick ? props.handleRowClick(idx, row.original) : null}
                                    className={`flex pointer ${row.getIsSelected() && 'selected'}`}
                                >
                                    {row.getVisibleCells().map((cell, idx) => {
                                        return (
                                            <td
                                                key={`cell-${cell.id}`}
                                                className={`Typography Typography--subtitle-2 td-${idx} ${cell.column.columnDef.meta?.className}`}
                                                style={{
                                                    width: cell.column.getSize(),
                                                    maxWidth: cell.column.columnDef.maxSize,
                                                    minWidth: cell.column.columnDef.minSize
                                                }}
                                            >
                                                {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                            </td>
                                        );
                                    })}
                                </tr>
                                {
                                    row.subRows && row.subRows.map(subRow =>
                                        <Fragment key={subRow.id}>
                                            {
                                                !!expanded[subRow.id] &&
                                                <tr
                                                    className={`subRow subRow-${subRow.id}`}
                                                    id={`subRow-${subRow.id}`}
                                                    colSpan={row.getVisibleCells().length}>
                                                    <td>
                                                        {subRow.original.renderSubComponent(row, subRow, subRow.original.id)}
                                                    </td>
                                                </tr>
                                            }
                                        </Fragment>

                                    )
                                }
                            </Fragment>
                        );
                    })}
                </tbody>
                {(props.handleLazyLoad && !props.loading && !!data.length) &&
                    <tfoot className='ReactTable__Loading__MoreData'>
                        <tr>
                            <td ref={loadMoreDataRef} />
                        </tr>
                    </tfoot>}
            </table>
            {((!data.length && props.loading) || isLazyLoading) && <Loading />}
            {(props.paginationData && !!data.length && !props.handleLazyLoad) &&
                <Pagination
                    paginationData={props.paginationData}
                />}
        </>
    );
};

export default Table;

const columnHelper = createColumnHelper();
export { columnHelper };

Table.propTypes = {
    className: PropTypes.string,
    columns: PropTypes.array,
    data: PropTypes.array,
    paginationData: PropTypes.object,
    stickyHeader: PropTypes.bool,
    setSortColumn: PropTypes.func,
    setSortDirection: PropTypes.func,
    sortColumn: PropTypes.string,
    sortDirection: PropTypes.string,
    initialState: PropTypes.object,
    columnVisibility: PropTypes.object,
    rowSelection: PropTypes.object,
    handleRowClick: PropTypes.func,
    hidePaginationHeader: PropTypes.bool,
    handleSort: PropTypes.func,
    setSelectedItems: PropTypes.func,
    selectedItems: PropTypes.array,
    renderSubComponent: PropTypes.func,
    loading: PropTypes.bool,
    currentItems: PropTypes.array,
    mergeOnDefaultChecked: PropTypes.bool,
    handleLazyLoad: PropTypes.func
};

Table.defaultProps = {
    className: '',
    columns: [],
    data: [],
    stickyHeader: true,
    initialState: {},
    setSortDirection: null,
    columnVisibility: {},
    rowSelection: {},
    hidePaginationHeader: false,
    loading: false,
    mergeOnDefaultChecked: false
};
