const ErrorFallBack = (error) => {
    return (
        <div>
            Ooops! You've encountered this error:
            {error}
        </div>
    );
};

export default ErrorFallBack;
