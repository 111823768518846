import { gql } from '@apollo/client';

const VIDEO_DEFAULTS = gql`
    fragment VideoDefaults on JobConfig {
        fileType
        fileFormat
        jobType
        watermarkingMode
        language
        audioKind
        chunkSize
        clipmarkStart
        clipmarkEnd
        clipmarkThreshold
        multiRegion
        uploadTimeStamp
        proxis {
            dPlusLadder
            dPlusLadderDebug
            encoder
            xCoder
            watermarkingMode
            clipmarkStart
            clipmarkEnd
            clipmarkThreshold
        }
        videoType
    }
`;

const AUDIO_DEFAULTS = gql`
    fragment AudioDefaults on JobConfig {
        jobType
        fileFormat
        watermarkingMode
        language
        audioKind
        uploadTimeStamp
        multiRegion
        sourceFormat
        startTimecode
        end
        offset
        bitrate
    }
`;

const SUBTITLE_DEFAULTS = gql`
    fragment SubtitleDefaults on JobConfig {
        jobType
        fileFormat
        watermarkingMode
        language
        uploadTimeStamp
        multiRegion
    }
`;

const IMAGE_DEFAULTS = gql`
    fragment ImageDefaults on JobConfig {
        jobType
        fileType
        imageType
        serveAs
    }
`;

export const FILE_DEFAULTS = gql`
    ${VIDEO_DEFAULTS}
    ${AUDIO_DEFAULTS}
    ${SUBTITLE_DEFAULTS}
    ${IMAGE_DEFAULTS}
    fragment FileDefaults on JobConfig {
        ...VideoDefaults @include(if: $isVideo)
        ...AudioDefaults @include(if: $isAudio)
        ...SubtitleDefaults @include(if: $isSubtitle)
        ...ImageDefaults @include(if: $isImage)
    }
`;
