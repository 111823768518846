import { useMemo, useState } from 'react';
import PresentationDetailsCell from '../../components/modules/TableCell/PresentationDetailsCell';
import PresentationsCheckbox from '../../components/modules/TableCell/PresentationsCheckbox';
import PresentationsDefaultCheckbox from '../../components/modules/TableCell/PresentationsDefaultCheckbox';
import DateTimeCell from '../../components/modules/TableCell/DateTimeCell';
import Tooltip from '../../components/modules/Tooltip';
import PopUpCard from '../../components/modules/PopUpCard';
import { columnHelper } from '../../components/modules/Table';
import PresentationsEpisodeToggle from '../../components/modules/TableCell/PresentationsEpisodeToggle';

const usePresentationTable = ({ isDashboard, renderSubComponent }) => {
    const [triggerRefetch, setTriggerRefetch] = useState(false);
    const [tableData, setTableData] = useState();
    const [selectedPresentations, setSelectedPresentations] = useState([]);
    const [partialSuccess, setPartialSuccess] = useState(false);
    const [createNew, setCreateNew] = useState(false);
    const [defaultChecked, setDefaultChecked] = useState(false);

    const columns = useMemo(
        () => [
            columnHelper.display({
                id: 'episodesToggle',
                cell: PresentationsEpisodeToggle,
                size: 30,
                meta: {
                    className: 'ToggleCell'
                }
            }),
            columnHelper.display({
                header: isDashboard ? PresentationsDefaultCheckbox : '',
                id: 'checkbox',
                cell: PresentationsCheckbox,
                enableSorting: false,
                size: 50,
                meta: {
                    className: 'CheckboxCell'
                }
            }),
            columnHelper.accessor('name', {
                size: 350,
                meta: {
                    className: 'flex-grow'
                },
                cell: PresentationDetailsCell
            }),
            columnHelper.accessor('type', {
                enableSorting: false,
                meta: {
                    className: 'type'
                }
            }),
            // TODO: add this column once series presentation is sent to the UI
            // columnHelper.accessor('series', {
            //     enableSorting: false,
            //     header: 'Series',
            //     size: 250,
            //     cell: ({ row }) => {
            //         if (row.original.associations?.seriesPresentationId?.length) {
            //             console.log(row.original, 'row here');
            //         }
            //         return '-';
            //     }
            // }),
            columnHelper.accessor('aclType', {
                header: 'Group Access',
                cell: ({ getValue, row }) => <PopUpCard chips={getValue()} cardTitle={row.original.name} listTitle='Group Access' />,
                enableSorting: false,
                meta: {
                    className: 'overflow--visible'
                }
            }),
            columnHelper.accessor('createdAt', {
                header: 'Created',
                cell: ({ getValue, row }) => {
                    const { createdBy } = row.original;
                    const tooltipCopy = `Created By: ${createdBy}`;
                    return (
                        <Tooltip
                            tooltipCopy={tooltipCopy}
                            location='left'
                            color='custom'
                            className='half-spacing__bottom'
                        >
                            <DateTimeCell getValue={getValue} row={row} />

                        </Tooltip>
                    );
                },
                meta: {
                    className: 'overflow--visible'
                }
            }),
            columnHelper.accessor('updatedAt', {
                header: 'Updated',
                cell: ({ getValue, row }) => {
                    const { updatedBy } = row.original;
                    const tooltipCopy = `Updated By: ${updatedBy}`;
                    return (
                        <Tooltip
                            tooltipCopy={tooltipCopy}
                            location='left'
                            color='custom'
                            className='half-spacing__bottom'
                        >
                            <DateTimeCell getValue={getValue} row={row} />
                        </Tooltip>
                    );
                },
                meta: {
                    className: 'overflow--visible'
                }
            })
        ],
        []
    );

    const handleQuery = async (data) => {
        const tableData = [...data?.allPresentations?.presentations].map(presentation => {
            const currentPresentation = { ...presentation };
            if (presentation.type === 'series') {
                currentPresentation.subRows = [
                    {
                        id: 'episodes',
                        renderSubComponent: (row) => renderSubComponent(row)
                    }
                ];
            }
            return currentPresentation;
        });

        setTableData(tableData ?? []);
        if (selectedPresentations.length && !!tableData) {
            setSelectedPresentations([...selectedPresentations].map(selectedPresentation => tableData.find(pres => pres.id === selectedPresentation.id) ?? selectedPresentation));
        }
        setTriggerRefetch(false);
    };

    const handlePostCreate = (_, newPresentation) => {
        setPartialSuccess(!!newPresentation?.partialSuccess);
        setTriggerRefetch(true);
        setCreateNew(false);
    };

    return {
        columns,
        handleQuery,
        triggerRefetch,
        setTriggerRefetch,
        tableData,
        setTableData,
        selectedPresentations,
        setSelectedPresentations,
        handlePostCreate,
        partialSuccess,
        setPartialSuccess,
        createNew,
        setCreateNew,
        defaultChecked,
        setDefaultChecked,
        columnVisibility: isDashboard ? null : { episodesToggle: false }
    };
};

export default usePresentationTable;
