import { Body, Caption } from '../../modules/Typography';

const PresentationFieldLink = ({ value, label, link, disabled }) => {
    return (
        <div className='TextField PresentationField--Link__Wrapper'>
            <Caption block className='TextField__label' faded={disabled}>{label}</Caption>
            <div className='PresentationField--Link'>
                {
                    link && value
                        ? <a target='_blank' href={link} rel='noreferrer'>
                            {value}
                        </a>
                        : <Body className='DocumentID--copy'>{value}</Body>

                }

            </div>
        </div>
    );
};

export default PresentationFieldLink;
