import { gql } from '@apollo/client';
import { ASSOCIATED_PRESENTATIONS } from './associatedPresentation';
import { DOCUMENT_FIELDS, DOCUMENT_HISTORY_FIELDS } from './document';
import { PRESENTATION_FILE, PRESENTATION_FILE_DETAILS } from './presentationFile';

export const PRESENTATION_SIMPLIFIED = gql`
    ${ASSOCIATED_PRESENTATIONS}
    fragment PresentationSimplified on Presentation {
        id
        name
        status
        type
        aclType
        acl {
            subject
            capabilities
            deny
        }
        updatedAt
		updatedBy
		createdAt
		createdBy
		internalTitle
        authorized
        seasonNumber
        vppPlayback
        vppCatalogId
        vppPackageVersion
        vppPackageId
        documentId
        associations {
			seriesPresentationId
            seasonPresentationId
            seasonPresentationIds
            episodePresentationIds
		}
        associatedPresentations {
            ...AssociatedPresentations
        }
    }
`;

export const PRESENTATION_DETAILED = gql`
    ${ASSOCIATED_PRESENTATIONS}
	${DOCUMENT_FIELDS}
	${DOCUMENT_HISTORY_FIELDS}
	${PRESENTATION_FILE}
	${PRESENTATION_FILE_DETAILS}
    fragment PresentationDetailed on Presentation {
        id
		name
		documentId
		type
		updatedAt
		updatedBy
		createdAt
		createdBy
		episodeNumber
        seasonName
        seasonNumber
        seriesId
        aclType
        status
        internalTitle
        authorized
        autoPublishToLive
        autoPublishToPreview
        vppPlayback
        vppPackageId
        vppCatalogId
        vppPackageVersion
        acl {
			subject
			capabilities
			deny
		}
		associations {
			seriesPresentationId
            seasonPresentationId
            seasonPresentationIds
            episodePresentationIds
		}
        lastPublishVersion {
            live
            preview
        }
        partialSuccess {
            code
            message
        }
        associatedPresentations {
            ...AssociatedPresentations
        }
        files {
            ...PresentationFile
            details {
                ...PresentationFileDetails
            }
        }
        doc {
            ...DocumentFields
        }
        history {
            ...DocumentHistoryFields
        }
    }
`;
