import { useContext, useEffect, useState } from 'react';
import { DateTime } from 'luxon';
import DatePicker from 'react-datepicker';
import PropTypes from 'prop-types';
import { Caption } from './Typography';
import TextFieldLabel from './TextFieldLabel';
import Icon from './Icon';
import Checkbox from './Checkbox';
import { enGB } from 'date-fns/esm/locale';
import moment from 'moment-timezone';
import { utcToZonedTime } from 'date-fns-tz';
import TimezoneSelect from './FormFields/TimezoneSelect';
import { dateValidation } from '../../utils/validation';
import { EventContentBulkEditsContext } from '../event/EventContentBulkEdits';
import { convertTimestamp } from '../../utils/dateTimeUtils';

const CustomDatePicker = props => {
    const {
        onChange,
        isRange,
        startLabel,
        endLabel,
        showTime,
        initStart,
        initEnd,
        initRemoveDates,
        includePST,
        id,
        endId,
        orientation,
        parentDates,
        includeTimezone,
        allowNoTimezone,
        hideErrors,
        checkDates,
        strictParentDates,
        compareParentDates,
        dateErrors: initDateErrors,
        timezoneCheck,
        showRemoveDateCheckbox,
        disableRemoveStartDateCheckbox,
        disableRemoveEndDateCheckbox,
        bulkEdit
    } = props;

    const { selectedDateChange } = useContext(EventContentBulkEditsContext) ?? {};

    const [timezone, setTimezone] = useState(props.timezone ?? moment.tz.guess());

    const [dates, setDates] = useState({
        startDate: null,
        endDate: null
    });

    const { startDate, endDate } = dates;

    const [removeDates, setRemoveDates] = useState({
        startDate: false,
        endDate: false
    });

    const [errors, setErrors] = useState([]);

    const dateErrors = errors.filter(error => error.type === 'date');
    const endErrors = errors.filter(error => error.type === 'end');

    useEffect(() => {
        if (!initDateErrors?.length) return;
        setErrors([...initDateErrors]);
    }, [initDateErrors]);

    useEffect(() => {
        if (!props?.timezone && (!allowNoTimezone || props?.timezone !== '')) return;
        setTimezone(props.timezone);
    }, [props?.timezone]);

    useEffect(() => {
        setDates({
            startDate: initStart ? Number(initStart) : null,
            endDate: (initEnd && isRange) ? Number(initEnd) : null
        });
        if (!initStart && !initEnd) setErrors([]);
    }, [initStart, initEnd]);

    useEffect(() => {
        if (!initRemoveDates) return;
        setRemoveDates(initRemoveDates);
    }, [initRemoveDates]);

    const handleOnChange = (currentDates) => {
        const timezoneObj = includeTimezone
            ? { timezone: currentDates.timezone }
            : {};

        const { hasErrors, errors: currentErrors } = dateValidation({
            startAt: currentDates.startDate,
            endAt: currentDates.endDate,
            parentDates,
            isRange,
            checkDates,
            strictParentDates,
            selectedDateChange,
            compareParentDates,
            timezone: currentDates.timezone ?? timezone,
            parentTimezone: timezoneCheck,
            initStart: startDate ?? initStart
        });

        setErrors(hasErrors ? currentErrors : []);

        if (hasErrors && !bulkEdit) return;

        let onChangeEvent = { [id]: currentDates.startDate, ...timezoneObj };
        if (isRange) onChangeEvent = { ...onChangeEvent, [id]: currentDates.startDate ?? null, [endId]: currentDates.endDate ?? null };
        if (showRemoveDateCheckbox) onChangeEvent = { ...onChangeEvent, removeDates: currentDates.removeDates };
        onChange(onChangeEvent);
    };

    const handleDateChange = (fieldId, value) => {
        const updatedDates = { ...dates, [fieldId]: value ? convertTimestamp(value, timezone) : null };
        setDates(updatedDates);
        handleOnChange({ ...updatedDates, timezone, ...(showRemoveDateCheckbox ? { removeDates } : {}) });
    };

    const handleRemoveDateChange = (fieldId, value) => {
        const updatedDates = { ...dates, [fieldId]: '' };
        const updatedRemoveDates = { ...removeDates, [fieldId]: value.checked };
        setDates(updatedDates);
        setRemoveDates(updatedRemoveDates);
        handleOnChange({
            ...updatedDates,
            [fieldId]: null,
            timezone,
            removeDates: updatedRemoveDates
        });
    };

    const renderByTimezone = (date) => !timezone ? utcToZonedTime(date, moment.tz.guess()) : utcToZonedTime(date, timezone);

    const handleTimezone = (newTimezone) => {
        const currentStart = utcToZonedTime(new Date(startDate), timezone);
        const currentEnd = utcToZonedTime(new Date(endDate), timezone);
        const convertedStart = startDate ? convertTimestamp(currentStart, newTimezone) : null;
        const convertedEnd = endDate ? convertTimestamp(currentEnd, newTimezone) : null;

        const updatedDates = {
            startDate: convertedStart,
            endDate: convertedEnd
        };

        setDates(updatedDates);
        setTimezone(newTimezone);
        handleOnChange({ ...updatedDates, timezone: newTimezone });
    };

    const sharedProps = {
        timeInputLabel: 'Time:',
        dateFormat: showTime ? 'MM/dd/yyyy H:mm' : 'MM/dd/yyyy',
        showTimeSelect: showTime,
        timeIntervals: 5,
        timeFormat: 'p',
        locale: enGB,
        shouldCloseOnSelect: !showTime,
        showMonthDropdown: true,
        showYearDropdown: true
    };

    const getMinDate = (currentDate) => {
        const date = currentDate ?? Date.now();
        const momentDate = moment.tz(currentDate ? moment(currentDate) : moment(), timezone);
        const eventDate = new Date(date);
        eventDate.setHours(momentDate?.format('HH'));
        eventDate.setMinutes(momentDate?.format('mm'));
        eventDate.setDate(momentDate?.format('DD'));
        return eventDate;
    };

    const filterPassedTime = (time, date) => {
        const currentDate = getMinDate(date);
        const selectedDate = timezone ? time : getMinDate(time);
        return currentDate.getTime() < selectedDate.getTime();
    };

    const disableStartDate = removeDates.startDate;
    const disableEndDate = removeDates.endDate;

    return (
        <>
            {
                !!includeTimezone &&
                <TimezoneSelect
                    className={showRemoveDateCheckbox ? 'remove-dates' : ''}
                    timezone={timezone}
                    formField={includeTimezone}
                    handleChange={(newTimezone) => handleTimezone(newTimezone)}
                    allowBlank={allowNoTimezone}
                />
            }
            <div className={`DatePicker--wrapper ${showRemoveDateCheckbox ? 'remove-dates' : ''}`}>
                <div className={`flex align-flex-start ${orientation || ''}`}>
                    <div className='DatePicker DatePicker__Start'>
                        <div className='flex'>
                            <div className='DatePicker__Input flex flex-grow column'>
                                <TextFieldLabel label={startLabel} />
                                <DatePicker
                                    selected={startDate ? renderByTimezone(startDate) : ''}
                                    onChange={(date) => handleDateChange('startDate', date)}
                                    placeholderText={`Select ${startLabel}`}
                                    minDate={getMinDate()}
                                    filterTime={filterPassedTime}
                                    {...sharedProps}
                                    disabled={disableStartDate}
                                />
                                <Icon name='calendar' className='DatePicker__Icon' />
                            </div>
                            {
                                showRemoveDateCheckbox &&
                                    <Checkbox
                                        disabled={disableRemoveStartDateCheckbox}
                                        className='DatePicker__CheckBox'
                                        label='Remove Start Date'
                                        handleCheck={(date) => handleRemoveDateChange('startDate', date)}
                                        checked={removeDates.startDate}
                                    />
                            }
                        </div>
                        {(includePST && initStart) && <PSTCaption time={initStart} />}
                        {
                            !hideErrors &&
                            dateErrors.map(dateError => {
                                if (!dateError.render) return false;
                                return <Caption key={dateError.id} error className='block DatePicker__Caption'>{dateError.copy}</Caption>;
                            })
                        }
                    </div>

                    {
                        isRange &&
                        <div className='DatePicker DatePicker__End spacing__left'>
                            <div className='flex'>
                                <div className='DatePicker__Input flex flex-grow column'>
                                    <TextFieldLabel label={endLabel} />
                                    <DatePicker
                                        selected={endDate ? renderByTimezone(endDate) : ''}
                                        onChange={(date) => handleDateChange('endDate', date)}
                                        placeholderText={`Select ${endLabel}`}
                                        minDate={startDate ?? getMinDate()}
                                        filterTime={(time) => filterPassedTime(time, startDate)}
                                        disabled={disableEndDate}
                                        {...sharedProps}
                                    />
                                    <Icon name='calendar' className='DatePicker__Icon' />
                                </div>
                                {
                                    showRemoveDateCheckbox &&
                                        <Checkbox
                                            disabled={disableRemoveEndDateCheckbox}
                                            className='DatePicker__CheckBox'
                                            label='Remove End Date'
                                            handleCheck={(date) => handleRemoveDateChange('endDate', date)}
                                            checked={removeDates.endDate}
                                        />
                                }
                            </div>
                            {
                                (includePST && initEnd) && <PSTCaption time={initEnd} />
                            }
                            {
                                !hideErrors &&
                                endErrors.map(endError => {
                                    if (!endError.render) return false;
                                    return <Caption key={endError.id} error className='block DatePicker__Caption'>{endError.copy}</Caption>;
                                })
                            }
                        </div>
                    }
                </div>
            </div>
        </>
    );
};

export default CustomDatePicker;

const PSTCaption = ({ time }) => {
    return (
        <Caption
            className='block faded DatePicker__Caption'>
            {DateTime.fromMillis(Number(time)).setZone('America/Los_Angeles').toFormat('LL/dd/y h:mm a ZZZZ')}
        </Caption>
    );
};

PSTCaption.propTypes = {
    time: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

CustomDatePicker.defaultProps = {
    isRange: true,
    endLabel: 'End Date',
    startLabel: 'Start Date',
    showTime: false,
    timezone: null,
    includePST: false,
    id: 'startAt',
    endId: 'endAt',
    customRangeError: '',
    parentDates: {},
    hideErrors: false,
    allowNoTimezone: false,
    checkDates: false,
    strictParentDates: true,
    showRemoveDateCheckbox: false,
    disableRemoveStartDateCheckbox: false,
    disableRemoveEndDateCheckbox: false,
    bulkEdit: false
};

CustomDatePicker.propTypes = {
    onChange: PropTypes.func,
    isRange: PropTypes.bool,
    endLabel: PropTypes.string,
    startLabel: PropTypes.string,
    showTime: PropTypes.bool,
    timezone: PropTypes.string,
    initStart: PropTypes.number,
    initEnd: PropTypes.number,
    initRemoveDates: PropTypes.object,
    includePST: PropTypes.bool,
    id: PropTypes.string,
    endId: PropTypes.string,
    customRangeErrors: PropTypes.object,
    parentDates: PropTypes.object,
    includeTimezone: PropTypes.object,
    hideErrors: PropTypes.bool,
    allowNoTimezone: PropTypes.bool,
    checkDates: PropTypes.bool,
    strictParentDates: PropTypes.bool,
    showRemoveDateCheckbox: PropTypes.bool,
    disableRemoveStartDateCheckbox: PropTypes.bool,
    disableRemoveEndDateCheckbox: PropTypes.bool,
    bulkEdit: PropTypes.bool
};
