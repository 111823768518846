import _ from 'lodash';

const isObject = v => v && typeof v === 'object' && !Array.isArray(v);

const diff = [];

const getObjectDiff = (original, updated) => {
    return Object.keys(original).reduce((result, key) => {
        result = [];
        if (isObject(original[key]) && isObject(updated[key])) {
            getObjectDiff(original[key], updated[key]);
        } else if (!_.isEqual(original[key], updated[key])) {
            result.push(key);
        }
        result.map(id => {
            if (!diff.includes(id)) return diff.push(id);
            return id;
        });
        return result;
    }, Object.keys(updated));
};

const makeCommitMessage = (original, updated) => {
    diff.splice(0, diff.length);
    getObjectDiff(original, updated);
    return {
        diff,
        message: 'Changed Fields:\n' + diff.map((s) => '\u2022 ' + s).join('\n')
    };
};

export default makeCommitMessage;
