import { useEffect, useContext, useState } from 'react';
import PropTypes from 'prop-types';
import CodeCard from './CodeCard';
import FileUploadButton from '../modules/FileUploadButton';
import CreateNewCodeBatch from './CreateNewCodeBatch';

import { CreateEventContext } from '../dialogs/event/CreateEventDialog';
import { EventTabsContext } from '../event/EventTabs';
import { SnackbarContext } from '../../contexts/SnackbarContextProvider';
import { DialogContext } from '../../contexts/DialogContextProvider';

import { parseCSV } from '../../utils/parseCSV';

import { GET_CODES } from '../../queries';
import Select from '../modules/Select';
import { useLazyQuery } from '@apollo/client';
import checkUserRole from '../../hooks/checkUserRole';

const CodeBatches = ({ codes, createFlow, handleCodes, cardActions }) => {
    const { makeDialog } = useContext(DialogContext);
    const { warningSnackbar, loadingSnackbar, closeSnackbar } = useContext(SnackbarContext);
    const { uploadedFile, setUploadedFile } = useContext(CreateEventContext) ?? useContext(EventTabsContext) ?? {};
    const [createNewBatch, setCreateNewBatch] = useState(false);
    const initCodes = { codes: [], file: null };
    const [uploadedCodes, setUploadedCodes] = useState({ ...initCodes });
    const [allCodes, setAllCodes] = useState([]);
    const [codeErrorOption, setCodeErrorOption] = useState([]);
    const codeRole = checkUserRole('ROLE_CODE');

    const [getCodes, { loading: loadingCodes, error: codeError }] = useLazyQuery(GET_CODES);
    const handleGetCodes = async (isRetry = false) => {
        try {
            const { data, errors } = await getCodes();

            if (errors) {
                return isRetry
                    ? setCodeErrorOption([{ text: 'Code Batches failed to load. Try again later', value: 'error' }])
                    : setCodeErrorOption([{ text: 'Code Batches failed to load. Retrying...', value: 'loading' }]);
            }
            setCodeErrorOption([]);
            const filterCodes = data.getCodes?.filter(codeData => !codes.some(code => code.id === codeData.id));
            setAllCodes(filterCodes);
        } catch (error) {
            if (error.name !== 'AbortError') {
                console.log(error);
            }
        }
    };

    useEffect(() => {
        if (!!allCodes.length || !codeRole) return;
        handleGetCodes();
    }, []);

    const handleSelectOpen = (open) => {
        if (!open && !!codeError) return setCodeErrorOption([{ text: 'Code Batches failed to load. Retrying...', value: 'loading' }]);
        if (!open) return;
        if (open && !!codeError) handleGetCodes(true);
    };

    const resetCreate = () => {
        setUploadedCodes({ ...initCodes });
        setCreateNewBatch(false);
    };

    const sharedCreateProps = {
        handleCSV: (csv) => handleCSV(csv),
        createOnly: !createFlow,
        handleCreatedCodes: (codeBatch) => handleCodes(codeBatch),
        handleCreateCancel: () => resetCreate()
    };

    const codeBatchDialog = (newCodes, file) => {
        makeDialog({
            title: 'Create New Code Batch',
            dialog: <CreateNewCodeBatch
                {...sharedCreateProps}
                newCodes={newCodes}
                file={file}
            />,
            disableCloseOnClick: true,
            size: 'xl',
            className: 'Code__CreateDialog manual-overflow'
        });
    };

    const handleCodesData = async (newCodes, file) => {
        const codesArray = newCodes.map(newCode => newCode.codes);
        if (createFlow) {
            setUploadedCodes({ ...uploadedCodes, codes: [...codesArray], file });
            setCreateNewBatch(true);
        } else {
            codeBatchDialog(codesArray, file);
        }
        closeSnackbar();
    };

    const handleCSV = async (file) => {
        if (!file) {
            setUploadedCodes({ ...initCodes });
            return;
        }
        loadingSnackbar({ text: 'Fetching Code Details' });
        const csvData = await parseCSV(file, true);
        const checkCodesPresent = csvData.filter(data => !!data.codes);
        if (checkCodesPresent.length) {
            setUploadedCodes({ ...uploadedCodes, file });
            handleCodesData(csvData, file);
        } else {
            warningSnackbar({ text: 'No codes or codes header found in CSV' });
        }
        setUploadedFile(null);
    };

    useEffect(() => {
        if (!uploadedFile || !codeRole) return;
        handleCSV(uploadedFile);
    }, [uploadedFile]);

    const handleAddingCodes = (newCode) => {
        if (newCode === 'createNew') {
            createFlow ? setCreateNewBatch(true) : codeBatchDialog();
        } else {
            const code = allCodes.find(code => code.id === newCode);
            handleCodes(code);
        }
    };

    const codeOptions = [...allCodes].filter(allCode => !codes.some(code => code.id === allCode.id)).map(code => ({ text: code.description, value: code.id }));

    return (
        <div className={`Code__Batches flex stretch-width column flex-start ${createNewBatch ? 'Code__Batches--new' : ''}`}>
            {(codeRole && !createNewBatch) &&
                <div className='flex stretch-width'>
                    <Select
                        searchable
                        createNew
                        placeholder='Search Code Batches or Create New...'
                        className='Code__Select flex-grow spacing__right'
                        loading={loadingCodes}
                        options={[...codeErrorOption, ...codeOptions]}
                        onChange={({ value }) => handleAddingCodes(value)}
                        handleOpen={(open) => handleSelectOpen(open)}
                    />
                    <FileUploadButton acceptedFiles='.csv' handleFile={(file) => handleCSV(file)} />
                </div>}
            {
                (createFlow && createNewBatch) &&
                <CreateNewCodeBatch
                    {...sharedCreateProps}
                    newCodes={uploadedCodes.codes}
                    file={uploadedCodes.file}
                />
            }
            <div className='CodeCard__Wrapper stretch-width'>
                {codes?.map(code => <CodeCard key={code.id} code={code} linkBatches cardActions={cardActions} createFlow={createFlow} />)}
            </div>
        </div>
    );
};

export default CodeBatches;

CodeBatches.defaultProps = {
    createFlow: false,
    codes: []
};

CodeBatches.propTypes = {
    codes: PropTypes.array,
    createFlow: PropTypes.bool,
    handleCodes: PropTypes.func,
    cardActions: PropTypes.object
};
