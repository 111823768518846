const { useContext } = require('react');
const { UserContext } = require('../contexts/UserContextProvider');

const checkUserRole = (role) => {
    if (!role) return true;
    const { user } = useContext(UserContext);
    return user.authorities.includes(role);
};

export default checkUserRole;
