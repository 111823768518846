import PropTypes from 'prop-types';
import { Body } from './Typography';

const Status = ({ value, type, active, past, colorFamily }) => {
    return (
        <div className={`
                Status 
                Status--${type}
                Status--${colorFamily === 'default' ? value?.replace(' ', '').toLowerCase() : colorFamily + '--' + value?.replace(' ', '').toLowerCase()}
                ${active ? 'Status--currentStatus' : 'Status--notCurrentStatus'} 
                ${past ? 'Status--past' : ''}`}>
            <Body number={2} capitalize>{value?.toLowerCase()}</Body>
        </div>
    );
};

export default Status;

Status.defaultProps = {
    active: false,
    past: false,
    type: 'default',
    colorFamily: 'default'
};

Status.propTypes = {
    value: PropTypes.string,

    type: PropTypes.oneOf(['listItem', 'list', 'default']),
    // in a list of statuses is it the current
    active: PropTypes.bool,
    // in a list of statuses has it already happened
    past: PropTypes.bool,

    colorFamily: PropTypes.oneOf(['audience', 'default'])
};
