import PropTypes from 'prop-types';
import Button from '../modules/Button';
import UserMenu from './UserMenu';
import checkUserRole from '../../hooks/checkUserRole';

const TopNav = props => {
    return (
        <div className='TopNav flex space-between'>
            <h1>{props.heading}</h1>
            <div className='flex center'>
                {(props.onClick && (!props.btnRole || checkUserRole(props.btnRole))) && <Button className='Button' icon='plus' type='secondary' color='on-primary-dark' text={props.btnText} onClick={props.onClick} />}
                <UserMenu />
            </div>
        </div>
    );
};

export default TopNav;

TopNav.propTypes = {
    heading: PropTypes.string,
    onClick: PropTypes.func,
    btnText: PropTypes.string,
    btnRole: PropTypes.string
};
