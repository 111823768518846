import PropTypes from 'prop-types';
import checkUserRole from '../../hooks/checkUserRole';
import { Subtitle, Body } from '../modules/Typography';
import Switch from '../modules/Switch';

const PresentationAutoPublish = ({ presentation, setPresentation, isEpisode, createNewPresentation }) => {
    const roleAutoPublish = checkUserRole('ROLE_AUTO_PUBLISH');

    const customDisable = !createNewPresentation && presentation.type === 'series';

    const fields = [
        {
            id: 'autoPublishToPreview',
            label: 'Preview'
        },
        {
            id: 'autoPublishToLive',
            label: 'Live'
        }
    ];

    return (
        <div className={`Presentation__AutoPublish ${isEpisode ? 'Presentation__AutoPublish--Episode' : ''}`}>
            <Subtitle number={4} fadedHalf block className={`uppercase stretch-width ${!createNewPresentation ? 'half-spacing__bottom' : ''}`}>Auto-Publish{isEpisode ? ' Episodes' : ''}</Subtitle>
            {
                createNewPresentation &&
                <Body number={2} block fadedHalf className='stretch-width'>
                    {isEpisode
                        ? 'Select environment to automatically publish changes made to all newly created episodes above'
                        : 'Select environment to automatically publish changes made to this presentation'}
                </Body>
            }

            <div className='flex flex-start'>
                {
                    fields.map((field, idx) => (
                        <Switch
                            className={idx > 0 ? 'spacing__left' : ''}
                            key={field.id}
                            label={field.label}
                            disabled={!roleAutoPublish || customDisable}
                            checked={presentation[field.id]}
                            onChange={({ target }) => setPresentation({
                                ...presentation,
                                [field.id]: target.checked
                            })}
                        />
                    ))
                }
            </div>
        </div>
    );
};

export default PresentationAutoPublish;

PresentationAutoPublish.defaultProps = {
    isEpisode: false,
    createNewPresentation: false
};

PresentationAutoPublish.proptypes = {
    presentation: PropTypes.object,
    setPresentation: PropTypes.func,
    isEpisode: PropTypes.bool,
    createNewPresentation: PropTypes.bool
};
