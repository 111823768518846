import { useEffect, useCallback, useState } from 'react';
import { Body } from './Typography';
import PropTypes from 'prop-types';
import Icon from './Icon';
import IconButton from './IconButton';
import Button from './Button';
import Loading from './Loading';

const Snackbar = props => {
    const { open, handleClose, closeDelay, type } = props;
    const [closeTimeout, setCloseTimeout] = useState();

    const handleTimeout = useCallback(() => {
        if (type === 'loading') return;
        if (!closeDelay) {
            if (closeTimeout) clearInterval(closeTimeout);
            return;
        };

        if (open) {
            if (closeTimeout) clearTimeout(closeTimeout);
            const newTimeout = setTimeout(() => {
                handleClose();
            }, closeDelay);
            setCloseTimeout(newTimeout);
        } else if (!open && closeTimeout) {
            clearTimeout(closeTimeout);
            setCloseTimeout(null);
        }
    }, [open, handleClose, closeDelay, closeTimeout, type]);

    useEffect(handleTimeout, [open, type]);

    return (
        <div className={`Snackbar__wrapper Snackbar__wrapper--${props.verticalAlign} Snackbar__wrapper--${props.type} Snackbar__wrapper--${props.horizontalAlign} ${props.open ? 'Snackbar__wrapper--open' : ''} `}>
            <div className={`Snackbar  ${props.fullScreen ? 'Snackbar__wrapper--full-screen' : ''}`}>
                <div className={`Snackbar__color Snackbar__color--${props.type}`} />
                <div className='flex flex-start flex-grow'>
                    {props.type === 'success' && <Icon name='check-circle' className='Snackbar__icon Snackbar__icon--success' />}
                    {props.type === 'error' && <Icon name='warning-round' className='Snackbar__icon Snackbar__icon--error' />}
                    {props.type === 'info' && <Icon name='info' className='Snackbar__icon Snackbar__icon--info' />}
                    {props.type === 'warning' && <Icon name='warning' className='Snackbar__icon Snackbar__icon--warning' />}
                    <div className='Snackbar__text--wrapper'>
                        <Body number={2} className='Snackbar__text'>{props.text}</Body>
                        {props.type === 'loading' && <Loading />}
                    </div>
                </div>
                {props.handleButton && props.buttonText && <Button text={props.buttonText} className='Snackbar__button' color='surface' onClick={props.handleButton} />}
                <div className='Snackbar__close__wrapper'>
                    <IconButton onClick={props.handleClose} name='close' className='Snackbar__close' type='light' size='small' />
                </div>
            </div>
        </div>
    );
};

export default Snackbar;

Snackbar.propTypes = {
    // text to display in the snackbar
    text: PropTypes.string,

    // whether or not the snackbar is open
    open: PropTypes.bool.isRequired,
    fullScreen: PropTypes.bool.isRequired,

    // positioning of the snackbar
    verticalAlign: PropTypes.oneOf(['top', 'bottom']),
    horizontalAlign: PropTypes.oneOf(['left', 'center', 'right']),

    // type/color of the snackbar
    type: PropTypes.oneOf(['default', 'error', 'success', 'info', 'warning', 'loading']),

    // function to handle closing the snackbar
    handleClose: PropTypes.func,

    // function to handle the button click
    handleButton: PropTypes.func,

    // text for the button
    buttonText: PropTypes.string,

    // how long to wait before snackbar automatically closes
    closeDelay: PropTypes.number
};

Snackbar.defaultProps = {
    open: false,
    fullScreen: false,
    verticalAlign: 'bottom',
    horizontalAlign: 'center',
    type: 'default',
    closeDelay: 3000
};
