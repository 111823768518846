import { gql } from '@apollo/client';
import { FILE_PROCESSING_DETAILS } from '../fragments/fileProcessing';
import { FILE_DEFAULTS } from '../fragments/fileDefaults';

export const PROCESS_FILE = gql`
	mutation processFile($presentationId: String, $fileId: String, $jobConfig: JobConfigInput) {
		processFile(presentationId: $presentationId, fileId: $fileId, jobConfig: $jobConfig) {
			id
		}
	}
`;

export const GET_FILE_PROCESSING_INFO = gql`
	${FILE_PROCESSING_DETAILS}
	${FILE_DEFAULTS}
	query getFileProcessingInfo($id: ID!, $presentationId: String, $isVideo: Boolean!, $isSubtitle: Boolean!, $isAudio: Boolean!, $isImage: Boolean!) {
		fileProcessingInfo: getFileProcessingInfo(id: $id, presentationId: $presentationId, isVideo: $isVideo, isSubtitle: $isSubtitle, isAudio: $isAudio, isImage: $isImage) {
			published
			fileDetails {
				...FileProcessingDetails
			}
			fileDefaults {
				...FileDefaults
			}
		}
	}
`;

export const FILE_UPLOAD_PROCESS = gql`
	mutation fileUploadProcess($uploadFiles: JSON, $acl: [ACEInput], $aclType: [String], $presentationId: ID) {
		fileUploadProcess(uploadFiles: $uploadFiles, acl: $acl, aclType: $aclType, presentationId: $presentationId) {
			id
			name
			status
			type
			location
			error {
				message
				error
			}
			transferResponse
			assets
		}
	}
`;
