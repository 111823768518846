import { useContext } from 'react';

import { Body } from '../../modules/Typography';
import Icon from '../../modules/Icon';
import Accordion from '../../modules/Accordion';

import { FileProcessingJobContext } from '../FileProcessingJob';
import { FileProcessingDialogContext } from '../FileProcessingDialog';
import { fileNameFromPath } from '../../../utils/fileNameFromPath';

const JobFileList = props => {
    const { currentFile, jobConfig, filesToProcess, dolbyFiles } = useContext(FileProcessingJobContext);
    const { processingFiles } = useContext(FileProcessingDialogContext);
    const { id: currentId } = currentFile;
    return (
        <Accordion
            className='FileProcessing__Files--Accordion'
            initOpen={processingFiles.length <= 10 && processingFiles.length >= 1}
            title={
                <>
                    <span className='colorBase'>
                        <span className='bold'>Process File: </span>
                        {currentFile.name}
                    </span>
                    <span className='clickable'>{`Show Files (${processingFiles.length})`}</span>
                </>
            }
            closeTitle={
                <>
                    <span className='colorBase'>
                        <span className='bold'>Process File: </span>
                        <span className='FileProcessing__FileName'>{fileNameFromPath(currentFile.name)}</span>
                    </span>
                    <span className='clickable'>Hide Files</span>
                </>
            }
            type='advancedDetails'>
            <div className='FileProcessing__Files--wrapper padded'>
                <div className='FileProcessing__Files FileProcessing__Files--list flex column flex-start'>
                    {
                        filesToProcess.map((job, idx) => {
                            const jobFileConfig = job.jobConfig;
                            const current = job.id === currentId;
                            const dolbyFile = current ? jobConfig.dolbyVisionXml : jobFileConfig?.dolbyVisionXml;
                            return (
                                <div key={job.id} className={`FileProcessing__Files--list--file ${current && 'current'} flex`}>
                                    <Body number={2} disabled={job.disabled || (idx < filesToProcess.findIndex(file => file.id === currentFile.id))}>
                                        {fileNameFromPath(job.name)}
                                        {dolbyFile &&
                                        <span className='FileProcessing__Files--list--dolby flex align-baseline flex-start faded'>
                                            <Icon name='corner-down-right' className='corner-down-right' />
                                            {fileNameFromPath(dolbyFile)}
                                        </span>}
                                    </Body>
                                    {!!job.disabled && <span className='FileProcessing__Files--list--loading'>Uploading<Icon name='spinner' /></span>}
                                    {
                                        (!!jobFileConfig && !current && !job.disabled) &&
                                        <>
                                            {
                                                jobFileConfig.processingResult
                                                    ? <Icon name='check' className='success' />
                                                    : <Icon name='close' className='error' />
                                            }
                                        </>
                                    }
                                </div>
                            );
                        })
                    }
                    {
                        !!dolbyFiles.length && dolbyFiles.map((file) => {
                            if (filesToProcess.some(job => job.jobConfig?.dolbyVisionXml === file.location) || jobConfig?.dolbyVisionXml === file.location) return false;
                            return (
                                <Body number={2} className='faded FileProcessing__Files--list--file' key={Math.random()}>{file.name}</Body>
                            );
                        })
                    }
                </div>
            </div>
        </Accordion>
    );
};

export default JobFileList;
