import { useContext } from 'react';
import Box from '../modules/Box';
import { Heading } from '../modules/Typography';
import { FileContext } from '../pages/File';
import FileFields from './FileFields';
import getThumbnail from '../../hooks/getThumbnail';

const FileHeader = props => {
    const { currentFileConfig, renderDefault } = useContext(FileContext);
    const { file, fileHistory, activeFileHistory } = useContext(FileContext);
    const { name } = file;
    const { jobInput } = activeFileHistory;

    const getValue = (id) => file?.[id] || file?.details?.[id] || jobInput?.[id] || 'Not Found';

    const renderSection = (field) => {
        const { id, textField, type, parentField } = field;
        const value = !textField ? getValue(id) : getValue(textField.fieldId);

        const { parentId, parentValue } = parentField || {};
        if (parentField && getValue(parentId) !== parentValue) return;

        switch (type) {
        case 'image': {
            const imgSrc = getThumbnail(fileHistory?.[0]?.jobOutput, 'images', 150);
            return <img key={field.id ?? field.fieldId} src={imgSrc} className='spacing__right' />;
        }
        default:
            return renderDefault(field, value);
        }
    };

    return (
        <Box
            key={Math.random()}
            type='white'
            borderType='none'
            shadow
            elevated={2}
            padded={false}
            className='flex align-flex-start flex-start margin FileDetails FileDetails--header'
            style={{ height: 'auto' }}
        >
            <Heading number={5} capitalize>{name}</Heading>
            {
                currentFileConfig.header.details.map((value, i) =>
                    <FileFields
                        key={value.id}
                        value={value}
                        idx={i}
                        renderSection={renderSection}
                        getValue={getValue}
                    />
                )
            }
        </Box>
    );
};

export default FileHeader;
