import PropTypes from 'prop-types';
import { Caption, Body } from './Typography';
import MenuSurface from './MenuSurface';
import Icon from './Icon';
import { LoadingIcon } from './Loading';
import IconButton from './IconButton';
import TextLink from './TextLink';

const Select = props => {
    const selectedOption =
        props.createNew && props.selectedValue === 'createNew'
            ? { text: 'Create New', value: 'createNew' }
            : props.options.find(option => option.value === props.selectedValue) ||
        props.options.find(option => option.value?.indexOf(props.selectedValue) !== -1) ||
        {};
    return (
        <div className={[
            'Select',
            props.disabled || props.loading ? 'Select--disabled' : null,
            props.error ? 'Select--error' : null,
            `Select--${props.styleType}`,
            props.className
        ].filter(i => i).join(' ')}
        >

            {props?.label === 'Series'
                ? (
                    <label className='Select__label'>
                        <TextLink to={`/presentation/${props.selectedValue}`} target='_blank'>{name}
                            <Caption faded={props.disabled}>{props.label}{props.required && '*'}</Caption>
                        </TextLink>
                    </label>
                )
                : props.label && (
                    <label className='Select__label'>
                        <Caption faded={props.disabled}>{props.label}{props.required && '*'}</Caption>
                    </label>
                )}
            <div className='Select__wrapper'>
                {props.icon && <Icon name={props.icon} className='Select__icon' />}
                <MenuSurface
                    createNew={props.createNew}
                    createNewClick={() => props.onChange({ value: 'createNew' })}
                    createNewRole={props.createNewRole}
                    searchable={props.searchable}
                    addSearch={props.addSearch}
                    handleSearchChange={props.handleSearchChange}
                    className='Select__MenuSurface'
                    loading={props.loading}
                    options={props.options.map((option, index) => ({
                        ...option,
                        onClick: () => props.onChange({ value: option.value, index })
                    }))}
                    handleOpen={(open) => props.handleOpen ? props.handleOpen(open) : null}
                >
                    <div className='Select__bar'>
                        <Body className={`Select__bar__text ${props.loading ? 'Select__bar__text--loading' : ''}`} number={2}>
                            {props.loading ? <LoadingIcon /> : (selectedOption?.text || props.placeholder)}</Body>
                    </div>
                </MenuSurface>
                {props.handleClear && !!props.selectedValue && <IconButton name='close' onClick={props.handleClear} className='Select__Clear' />}
            </div>
            {props.error && props.errorMessage && <Caption number={2} error className='TextField__error block'>{props.errorMessage}</Caption>}
        </div>
    );
};

export default Select;

Select.propTypes = {
    // function to handle select change event
    onChange: PropTypes.func,

    // options in the select drop down
    options: PropTypes.array,

    // whether or not the select input is disabled
    disabled: PropTypes.bool,

    // item that has been selected
    selectedValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),

    // className to pass down to the tags
    className: PropTypes.string,

    // label to associate with the select menu
    label: PropTypes.string,

    // type of style the select is
    styleType: PropTypes.oneOf(['default', 'dark']),

    // icon to showcase left of the select menu
    icon: PropTypes.string,

    // whether or not the select field is searchable
    searchable: PropTypes.bool,
    // function to add search value if no match
    addSearch: PropTypes.func,

    // A function to return the search value for use in compenents up the tree
    handleSearchChange: PropTypes.func,

    placeholder: PropTypes.string,

    // weather or not data for select is loading
    loading: PropTypes.bool,

    // whether or not the field is invalid
    error: PropTypes.bool,

    // error message to display
    errorMessage: PropTypes.string,

    // include an option to create new value
    createNew: PropTypes.bool,

    // Required role to create new option
    createNewRole: PropTypes.string,

    // trigger event when select opens
    handleOpen: PropTypes.func,

    required: PropTypes.bool,

    handleClear: PropTypes.func
};

Select.defaultProps = {
    disabled: false,
    className: '',
    styleType: 'default',
    searchable: false,
    placeholder: '',
    loading: false,
    error: false,
    createNew: false,
    required: false
};
