import { useState } from 'react';
import PropTypes from 'prop-types';

import ChipSet from './ChipSet';
import TextField from './TextField';
import { Caption } from './Typography';

const ChipSetEditable = ({ chips, edit, allowRemove, className, id, label, handleNewChip, handleDeleteChip, handleChips, classNameChips = 'ChipSetEditable--chips' }) => {
    const [newChip, setNewChip] = useState('');
    const [error, setError] = useState(false);

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            const duplicate = chips.some(chip => chip.toLowerCase() === newChip.toLowerCase());
            setError(duplicate);
            if (!duplicate && !!newChip) {
                handleChipUpdate(newChip, e.target.id);
                setNewChip('');
            }
        }
    };

    const handleChipUpdate = (val) => {
        if (chips.some(chip => chip === val)) {
            return handleDeleteChip ? handleDeleteChip(val, id) : handleChips(chips.filter(chip => chip !== val));
        } else {
            return handleNewChip ? handleNewChip(val, id) : handleChips([...chips, val]);
        }
    };
    if (!chips?.length && !edit) return;
    return (
        <div className={`flex column flex-start flex-grow stretch-width ChipSetEditable--Wrapper ${className ? className + '--Wrapper' : ''}`}>
            {label &&
            <Caption className='TextField__label' block>{label}</Caption>}
            <div className={`ChipSetEditable ${className} flex-grow`}>
                <div className={classNameChips}>
                    <ChipSet chips={chips} editing={allowRemove} id={`Chips-${id.split('').join('')}`} handleDelete={(val, id) => handleChipUpdate(val, id)} />
                </div>
                {(edit && (handleChips || (handleNewChip && handleDeleteChip))) &&
                    <TextField
                        placeholder={`Press enter to add ${id}...`}
                        id={id}
                        onChange={(e) => {
                            setNewChip(e.target.value);
                            setError(false);
                        }}
                        error={error}
                        errorMessage={`${newChip} already exists`}
                        value={newChip}
                        onKeyPress={handleKeyPress}
                    />}
            </div>
        </div>
    );
};

export default ChipSetEditable;

ChipSetEditable.defaultProps = {
    edit: false,
    id: '',
    label: '',
    chips: [],
    handleDeleteChip: null,
    handleNewChip: null,
    allowRemove: false
};

ChipSetEditable.propTypes = {
    edit: PropTypes.bool,
    chips: PropTypes.array,
    handleDeleteChip: PropTypes.func,
    handleNewChip: PropTypes.func,
    className: PropTypes.string,
    id: PropTypes.string,
    label: PropTypes.string,
    allowRemove: PropTypes.bool
};
