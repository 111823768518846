import { useContext, useState, useMemo } from 'react';
import DialogFooter from '../modules/DialogFooter';
import TopAppBar from '../modules/TopAppBar';
import { Body, Caption, Heading } from '../modules/Typography';
import { FileProcessingDialogContext } from './FileProcessingDialog';
import Table, { columnHelper } from '../modules/Table';
import Status from '../modules/Status';
import FileCell from '../modules/TableCell/FileCell';
import { FileProcessingJobContext } from './FileProcessingJob';
import IconButton from '../modules/IconButton';

const FileProcessingVideoOrder = () => {
    const { setHandlePrimaryVideo, setProcessingFiles } = useContext(FileProcessingDialogContext);
    const { filesToProcess, setFilesToProcess, setCurrentFile } = useContext(FileProcessingJobContext);
    const videoFiles = useMemo(() => filesToProcess.filter(file => file.type === 'video'), [filesToProcess]);
    const [primaryVideo, setPrimaryVideo] = useState({ 0: true, id: videoFiles[0].id });
    const handlePrimary = () => {
        const videoIndex = filesToProcess.findIndex(file => file.id === primaryVideo.id);
        const updatedFiles = [...filesToProcess];
        updatedFiles.unshift(...updatedFiles.splice(videoIndex, 1));
        setFilesToProcess(updatedFiles);
        setCurrentFile(updatedFiles[0]);

        setHandlePrimaryVideo(false);
    };

    const handleRowClick = (id, original) => setPrimaryVideo({ [id]: true, id: original.id });

    const columns = useMemo(
        () => [
            columnHelper.accessor('status', {
                cell: ({ getValue }) => <Status value={getValue()} />,
                enableSorting: false
            }),
            columnHelper.accessor('name', {
                header: 'Filename',
                cell: FileCell,
                size: 350,
                meta: {
                    className: 'flex-grow-2'
                },
                enableSorting: false
            }),
            columnHelper.accessor('type', {
                enableSorting: false
            })
        ],
        [videoFiles]
    );

    return (
        <div className='FileProcessing__VideoOrder flex column'>
            <TopAppBar className='FileProcessing__TopAppBar flex' static>
                <Heading number={6}>Select Primary Video</Heading>
                <IconButton onClick={() => setProcessingFiles([])} name='close-round' />
            </TopAppBar>
            <div className='flex-grow stretch-width padded FileProcessing__VideoOrder--Table'>
                <Body block className='padded__bottom'>There are multiple videos selected. Please select the primary video for processing.</Body>
                <Caption className='block half-spacing__top'>*If the primary video has not been selected, please close this window and select the primary video prior to initiating processing</Caption>
                <Table
                    data={videoFiles}
                    columns={columns}
                    className='spacing__top'
                    handleRowClick={handleRowClick}
                    rowSelection={primaryVideo}
                />
            </div>
            <DialogFooter
                handleConfirm={handlePrimary}
                confirmText='Set Primary Video'
                disabled={!primaryVideo}
            />
        </div>
    );
};

export default FileProcessingVideoOrder;
