import { gql } from '@apollo/client';
import { EVENT_AUDIENCE_FIELDS } from '../fragments/eventAudience';

export const GET_EVENT_AUDIENCE_BY_ID = gql`
    ${EVENT_AUDIENCE_FIELDS}
    query eventAudienceById($id: ID!, $paramsInput: ParamsInput) {
        eventAudienceById(id: $id, paramsInput: $paramsInput) {
            eventAudience {
                ...EventAudienceFields
            }
            total
        }
    }
`;

export const GET_EVENT_AUDIENCE = gql`
    ${EVENT_AUDIENCE_FIELDS}
    query getEventAudience($id: ID) {
        getEventAudience(id: $id) {
            ...EventAudienceFields
        }
    }
`;

export const EVENT_ASSIGN_AUDIENCE = gql`
    mutation assignEventAudience($id: ID, $eventAudience: [EventAudienceInput]) {
        assignEventAudience(id: $id, eventAudience: $eventAudience) {
            message
            error
        }
    }
`;

export const EVENT_UNASSIGN_AUDIENCE = gql`
    mutation unassignEventAudience($id: ID, $eventAudience: [String]) {
        unassignEventAudience(id: $id, eventAudience: $eventAudience) {
            message
            error
        }
    }
`;

export const GET_EVENT_ACCOUNT_STATUS = gql`
    mutation getAccountStatus($accounts: [String]) {
        accounts: getAccountStatus(accounts: $accounts) {
            email
            status
        }
    }
`;

export const RESEND_INVITES = gql`
    ${EVENT_AUDIENCE_FIELDS}
    mutation resendInvites($id: ID, $emails: [String]) {
        resendInvites(id: $id, emails: $emails) {
            ...EventAudienceFields
        }
    }
`;
