import { gql } from '@apollo/client';

export const PRESENTATION_FILE = gql`
fragment PresentationFile on File {
        id
        name
        presentationStatus
        status
        type
        createdAt
        addedOn
        location
        lastProcessingHistoryJobInput {
            fileFormat
        }
    }
`;

export const PRESENTATION_FILE_DETAILS = gql`
fragment PresentationFileDetails on Details {
        language
    }
`;
