import PropTypes from 'prop-types';
import { Subtitle } from '../modules/Typography';
import FileJson from './FileJson';

const FileFields = ({ value, idx, renderSection, section, getValue }) => {
    const { parentId, parentValue } = value.parentField || {};
    if (value.parentField && getValue(parentId) !== parentValue) return;

    return (
        <div
            key={value.id}
            className={
                ` ${value.id && value.type !== 'json' ? 'FileDetails--' + value.id : ''}
                ${value.type === 'json' ? 'FileDetails--json' : 'FileDetails--section'}
                ${value.columns ? 'FileDetails--columns' : ''}`
            }
        >
            {value.title && <Subtitle number={4} fadedHalf block>{value.title}</Subtitle>}
            {value.fields &&
            <div className={` flex flex-start FileDetails--fields  ${value.columns ? 'FileDetails--columns' + value.columns : 'column'}`}>
                {value.fields.map(field => renderSection(field, section))}
            </div>}
            {value.type === 'json' && <FileJson config={value} />}
        </div>
    );
};

export default FileFields;

FileFields.propTypes = {
    value: PropTypes.object,
    idx: PropTypes.number,
    renderSection: PropTypes.func,
    section: PropTypes.string,
    getValue: PropTypes.func
};
