import { gql } from '@apollo/client';

export const GET_DIFF = gql`
    query getDiff($documentId: String, $type: String) {
        diff: getDiff(documentId: $documentId, type: $type) {
            live
            preview
        }
    }
`;

export const PUBLISH_PRESENTATION = gql`
    mutation publishPresentation($documentId: String, $publishData: PublishData) {
        publishPresentation(documentId: $documentId, publishData: $publishData) {
            results {
                environment
                status
            }
            publish
        }
    }
`;

export const UNPUBLISH_PRESENTATION = gql`
    mutation unpublishPresentation($documentId: String, $publishData: PublishData) {
        unpublishPresentation(documentId: $documentId, publishData: $publishData) {
            results {
                environment
                status
            }
            publish
        }
    }
`;
