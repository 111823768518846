import Icon from './Icon';
import PropTypes from 'prop-types';
import { Caption } from './Typography';
import Button from './Button';
import TextFieldLabel from './TextFieldLabel';

const FormWrapper = ({ onSubmit, children }) => onSubmit
    ? (
        <form
            className='TextField__form'
            onSubmit={(e) => {
                e.preventDefault();
                onSubmit(e.target.querySelector('input').value);
            }}
        >
            {children}
        </form>
    )
    : (
        children
    );

const extendsNumbericInputProps = (props) => {
    const excludedForNumericInputs = {};
    const removeKeyCode = [8, 46];
    excludedForNumericInputs.number = ['e', 'E', '+', '-', ','];
    excludedForNumericInputs.wholeNumber = [...excludedForNumericInputs.number, '.'];
    return props.type === 'number' || props.type === 'wholeNumber'
        ? {
            type: 'number',
            onKeyPress: (e) => (
                excludedForNumericInputs[props.type].includes(e.key) ||
                (e.target.value.length >= props.maxLength && !removeKeyCode.includes(e.key))
            ) && e.preventDefault()
        }
        : null;
};

const TextField = props => {
    return (
        <div className={`TextField ${props.className} ${props.padded ? 'TextField--padded' : ''}`}>
            {props.label &&
                <TextFieldLabel
                    className={props.className}
                    id={props.id}
                    label={props.label}
                    disabled={props.disabled}
                    required={props.required}
                />}

            <div className={[
                'TextField__style-wrapper',
                `TextField__style-wrapper--${props.styleType}`,
                props.disabled ? 'TextField__style-wrapper--disabled' : null,
                props.error && !props.hideErrorStyles ? 'TextField__style-wrapper--error' : null
            ].filter(i => i).join(' ')}
            >
                {props.icon && <Icon className='TextField__icon' name={props.icon} />}

                <FormWrapper onSubmit={props.onSubmit}>
                    <input
                        type={props.type}
                        id={props.id}
                        defaultValue={props.defaultValue}
                        className='TextField__input'
                        placeholder={props.placeholder}
                        onChange={props.onChange}
                        onKeyPress={props.onKeyPress}
                        value={props.value}
                        disabled={props.disabled}
                        autoFocus={props.autofocus}
                        onClick={props.onClick}
                        maxLength={props.maxLength}
                        onBlur={props.onBlur}
                        {...extendsNumbericInputProps(props)}
                    />
                </FormWrapper>

                {props.clear && <Button icon='close' onClick={props.onClear} className='flex center align-center Button--clear' />}

            </div>
            {props.error && props.errorMessage && <Caption number={2} error className='TextField__error'>{props.errorMessage}</Caption>}
        </div>
    );
};

export default TextField;

FormWrapper.propTypes = {
    onSubmit: PropTypes.func,
    children: PropTypes.object,
    className: PropTypes.string,
    disabled: PropTypes.bool
};

TextField.propTypes = {
    // tag id to attach to the form field
    id: PropTypes.string,

    // icon name to display inside the form field
    icon: PropTypes.string,

    // className to pass to the tags
    className: PropTypes.string,

    // placeholder text to place in the form
    placeholder: PropTypes.string,

    // whether or not the field is invalid
    error: PropTypes.bool,

    // error message to display
    errorMessage: PropTypes.string,

    // function to submit single textfield form
    onSubmit: PropTypes.func,

    // function to handl the form field value changeg
    onChange: PropTypes.func,

    // function to handle a keypress on the input field
    onKeyPress: PropTypes.func,

    // function to handle a click on the input field
    onClick: PropTypes.func,

    // function to handle when the input field is blurred
    onBlur: PropTypes.func,

    // value of the text field
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),

    // type of the input field
    type: PropTypes.string,

    // label to add to the textfield
    label: PropTypes.string,

    // whether or not the textfield is disabled
    disabled: PropTypes.bool,

    // whether or not the field should autofocus
    autofocus: PropTypes.bool,

    // style of the textfield
    styleType: PropTypes.oneOf(['default', 'dark', 'underline']),

    // whether or not the textfield has padding below
    padded: PropTypes.bool,

    // default value to pass to input
    defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),

    maxLength: PropTypes.string,
    // adds a button to clear field
    clear: PropTypes.bool,
    // function to handle clearing of field
    onClear: PropTypes.func,

    required: PropTypes.bool,

    hideErrorStyles: PropTypes.bool
};

TextField.defaultProps = {
    className: '',
    error: false,
    type: 'text',
    disabled: false,
    autofocus: false,
    styleType: 'default',
    padded: true,
    clear: false,
    required: false,
    hideErrorStyles: false
};
