import { useMemo, useContext, useEffect } from 'react';
import { PresentationContext } from '../pages/Presentation';
import ContentAccordion from '../modules/ContentAccordion';
import Table, { columnHelper } from '../modules/Table';
import Accordion from '../modules/Accordion';
import { DataContext } from '../../contexts/DataContext';
import InternalAccessCell from '../modules/TableCell/InternalAccessCell';
import { authorized, subjectsFromUser } from '../../utils/acl';
import Button from '../modules/Button';
import { UserContext } from '../../contexts/UserContextProvider';
import MenuSurface from '../modules/MenuSurface';
import { Body } from '../modules/Typography';
import ACLChips from '../modules/ACLChips';
import checkUserRole from '../../hooks/checkUserRole';

const PresentationInternalAccess = props => {
    const { appConfig } = useContext(DataContext);
    const { ACLSubjects } = appConfig;
    const { user } = useContext(UserContext);
    const { presentationData, setPresentationData, loadingPresentation, setSaveDisabled } = useContext(PresentationContext);
    const disabled = !authorized(presentationData.acl, ['A'], subjectsFromUser(user));
    const presentationAclTypes = presentationData.aclType || [];
    const presentationAcls = presentationData.acl || [];

    useEffect(() => {
        setSaveDisabled(!presentationAcls.length);
    }, [presentationAcls]);

    const tableData = presentationAcls.map(type => {
        return {
            subject: type.subject,
            read: type.capabilities.includes('R'),
            update: type.capabilities.includes('U'),
            delete: type.capabilities.includes('D'),
            publish: type.capabilities.includes('P'),
            entitle: type.capabilities.includes('E'),
            access: type.capabilities.includes('A'),
            deny: !!type.deny
        };
    }) || [];

    const Columns = useMemo(
        () => [
            columnHelper.accessor('subject', {
                maxSize: 350
            }),
            columnHelper.accessor('read', {
                cell: (data) => <InternalAccessCell value='R' disabled={disabled} cell={data.cell} data={data} />,
                meta: {
                    className: 'capabilities'
                }
            }),
            columnHelper.accessor('update', {
                cell: (data) => <InternalAccessCell value='U' disabled={disabled} cell={data.cell} data={data} />,
                meta: {
                    className: 'capabilities'
                }
            }),
            columnHelper.accessor('delete', {
                cell: (data) => <InternalAccessCell value='D' disabled={disabled} cell={data.cell} data={data} />,
                meta: {
                    className: 'capabilities'
                }
            }),
            columnHelper.accessor('publish', {
                cell: (data) => <InternalAccessCell value='P' disabled={disabled} cell={data.cell} data={data} />,
                meta: {
                    className: 'capabilities'
                }
            }),
            columnHelper.accessor('entitle', {
                cell: (data) => <InternalAccessCell value='E' disabled={disabled} cell={data.cell} data={data} />,
                meta: {
                    className: 'capabilities'
                }
            }),
            columnHelper.accessor('access', {
                cell: (data) => <InternalAccessCell value='A' disabled={disabled} cell={data.cell} data={data} />,
                meta: {
                    className: 'capabilities'
                }
            }),
            columnHelper.accessor('deny', {
                cell: (data) => <InternalAccessCell value='deny' disabled={disabled} cell={data.cell} data={data} />,
                meta: {
                    className: 'capabilities'
                }
            }),
            columnHelper.accessor('remove', {
                cell: (data) => <Button icon='close' disabled={disabled} type='surface' onClick={() => handleDeleteACL(data.row.original.subject)} />,
                meta: {
                    className: 'capabilities'
                }
            })
        ],
        [presentationData]
    );

    const handleDeleteACL = (subject) => setPresentationData({ ...presentationData, acl: presentationAcls.filter(acl => acl.subject !== subject) });
    const handleAddACL = (subject) => {
        const currentPresData = { ...presentationData };
        currentPresData.acl.push(ACLSubjects.find(acl => acl.subject === subject));
        setPresentationData(currentPresData);
    };

    const handleAddNewAcl = (subject) => {
        const newSubject = {
            capabilities: [],
            deny: false,
            subject
        };
        const currentPresData = { ...presentationData };
        currentPresData.acl.push(newSubject);
        setPresentationData(currentPresData);
    };

    if (!presentationData || loadingPresentation) return;

    return (
        <ContentAccordion title='Group Access' className='Presentation__InternalAccess'>
            <ACLChips
                acl={presentationAcls}
                aclTypes={presentationAclTypes}
                handleACLUpdate={(aclType, acl) => setPresentationData({ ...presentationData, aclType, acl })}
                className={!checkUserRole('ROLE_PRODUCER') ? 'ACLChips--disabled' : ''}
            />
            <Accordion
                title='Advanced'
                type='advancedDetails'
                iconOpen='small-caret-right'
                iconClose='small-caret-down'
            >
                {!!tableData.length &&
                <Table
                    columns={Columns}
                    data={tableData}
                    className='InternalAccessTable'
                />}
                <MenuSurface
                    searchable
                    className='ACL__MenuSurface'
                    handleKeyPress={handleAddNewAcl}
                    options={ACLSubjects.filter(acl => !presentationAcls.some(presentationAcl => presentationAcl.subject === acl.subject)).map((acl) => ({
                        ...acl,
                        text: acl.subject,
                        value: acl.subject,
                        onClick: () => handleAddACL(acl.subject)
                    }))}
                >
                    <Body number={2} className='title' padded faded>(ENTER CUSTOM ACCESS GROUP)</Body>
                </MenuSurface>
            </Accordion>
        </ContentAccordion>

    );
};

export default PresentationInternalAccess;
