import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import TopAppBar from './TopAppBar';
import Button from './Button';
import { Body, Subtitle } from './Typography';
import ChipSet from './ChipSet';
import TextLink from './TextLink';
import IconButton from './IconButton';

const PopUpCard = ({ buttonText, cardTitle, listTitle, listItems, chips }) => {
    const [open, setOpen] = useState('');
    const [maxHeight, setMaxHeight] = useState(0);

    useEffect(() => {
        const closeWithKeyPress = e => {
            if (e.key === 'Escape') setOpen(false);
        };
        if (open) window.addEventListener('keyup', closeWithKeyPress);
        return () => window.removeEventListener('keyup', closeWithKeyPress);
    }, [open]);

    useEffect(() => {
        const isDialogStillOpen = document.getElementsByClassName('DialogWrapper--open').length;
        if (open) {
            const TopAppBarHeight = document.querySelector('.PopupCard .TopAppBar').clientHeight;
            setMaxHeight(`calc(100% - ${TopAppBarHeight}px)`);
            window.document.body.style.overflow = 'hidden';
        } else if (!isDialogStillOpen) {
            window.document.body.style.overflow = null;
        }
    }, [open]);

    return (
        <div className='PopupCard--Wrapper'>
            <Button className='PopupCard--Button' type='surface' color='default' disabled={!listItems?.length && !chips?.length} onClick={() => setOpen(true)}>{buttonText || listItems?.length || chips?.length || 0}</Button>
            {
                open &&
                <>
                    <div className='PopupCard--Overlay' onClick={() => setOpen(false)} />
                    <div className='PopupCard padded__bottom'>
                        <TopAppBar className='flex'>
                            <Subtitle number={3}>{cardTitle}</Subtitle>
                            <IconButton name='close-round' onClick={() => setOpen(false)} />
                        </TopAppBar>
                        <div className='PopupCard--Content' style={{ maxHeight }}>
                            {
                                listTitle &&
                                <Body className='PopupCard--Subtitle' block>{listTitle} ({listItems.length || chips.length}):</Body>
                            }
                            {
                                !!listItems.length &&
                                <ul>
                                    {listItems.map(val => {
                                        return (
                                            <li key={val.id}>
                                                {
                                                    val.path
                                                        ? <TextLink to={val.path}>{val.name}</TextLink>
                                                        : val.name
                                                }

                                            </li>
                                        );
                                    }

                                    )}
                                </ul>
                            }
                            {!!chips.length && <ChipSet chips={chips} />}

                        </div>
                        <svg className='triangle' viewBox='0 0 100 100'>
                            <polygon points='50 15, 100 100, 0 100' />
                        </svg>
                    </div>
                </>
            }
        </div>
    );
};

export default PopUpCard;

PopUpCard.defaultProps = {
    chips: [],
    listItems: []
};

PopUpCard.propTypes = {
    buttonText: PropTypes.string,
    cardTitle: PropTypes.string,
    listTitle: PropTypes.string,
    listItems: PropTypes.array,
    chips: PropTypes.array
};
