import { useContext, useState } from 'react';
import { useMutation } from '@apollo/client';
import ContentAccordion from '../modules/ContentAccordion';
import { PresentationContext } from '../pages/Presentation';
import { DialogContext } from '../../contexts/DialogContextProvider';

import IconButton from '../modules/IconButton';

import { REMOVE_ASSOCIATIONS } from '../../queries';
import PresentationAddEpisodes from './PresentationAddEpisode';
import DashboardMenu from '../modules/DashboardMenu';
import PresentationEpisodesTable from './PresentationEpisodesTable';

const PresentationEpisodes = () => {
    const { deleteDialog, closeDialog, makeDialog } = useContext(DialogContext);
    const [episodePresentations, setEpisodePresentations] = useState([]);
    const { presentationData, setPresentationData, refetchPresentation, presentationId } = useContext(PresentationContext);
    const { associatedEpisodes } = presentationData?.associatedPresentations || {};

    const [removeAssociations] = useMutation(REMOVE_ASSOCIATIONS);

    const deleteEpisodePresentation = () => {
        deleteDialog({
            type: 'Episode(s)',
            action: 'Remove',
            confirmAction: 'Removed',
            name: episodePresentations.map((content) => content.name),
            size: 'medium',
            className: 'FileProcessing__Delete',
            setLoading: true,
            handleCancel: () => {
                setEpisodePresentations([]);
                closeDialog();
            },
            deleteMutation: async () => {
                for (const episode of episodePresentations) {
                    try {
                        const { data } = await removeAssociations({
                            variables: {
                                id: episode.id,
                                type: 'episode',
                                associations: {
                                    seriesPresentationId: presentationId,
                                    seasonPresentationId: episode.associations.seasonPresentationId
                                }
                            }
                        });
                        episode.result = data.removeAssociations.message;
                    } catch (error) {
                        console.log(error);
                    }
                }
                await refetchPresentation();
                return episodePresentations;
            },
            handleConfirmationClose: () => {
                setEpisodePresentations([]);
                closeDialog();
            }
        });
    };

    const menuData = {
        closeMenu: () => setEpisodePresentations([]),
        numberOfItems: episodePresentations.length,
        actions: [
            {
                text: 'Remove',
                icon: 'trash-can',
                onClick: deleteEpisodePresentation
            }
        ]
    };

    const addEpisodes = () => {
        makeDialog({
            title: 'Add Episodes',
            dialog: <PresentationAddEpisodes presentationData={presentationData} setPresentationData={setPresentationData} />,
            size: 'large',
            className: 'Series__AddEpisodes'
        });
    };

    return (
        <ContentAccordion title='Episode Details' className='Presentation__EpisodeDetails'>
            <div className='flex flex-end'>
                <IconButton name='plus-circle' onClick={() => addEpisodes()} />
            </div>
            <PresentationEpisodesTable
                episodes={associatedEpisodes}
                className='PresentationEpisodeDetails'
                setSelectedItems={setEpisodePresentations}
                selectedItems={episodePresentations}
            />
            <DashboardMenu menuData={menuData} />
        </ContentAccordion>

    );
};

export default PresentationEpisodes;
