import { DateTime } from 'luxon';
import PropTypes from 'prop-types';

const DateCell = ({ getValue }) => {
    const value = getValue();
    const numUnix = Number(value);
    return value ? DateTime.fromMillis(numUnix).toFormat('LL/dd/y') : '-';
};

export default DateCell;

DateCell.propTypes = {
    getValue: PropTypes.func
};
