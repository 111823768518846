import { useContext, useMemo, useState } from 'react';
import ContentAccordion from '../modules/ContentAccordion';
import Table, { columnHelper } from '../modules/Table';
import DashboardMenu from '../modules/DashboardMenu';
import DateCell from '../modules/TableCell/DateCell';
import TableCheckbox from '../modules/TableCell/TableCheckbox';
import TableCheckboxHeader from '../modules/TableCell/TableCheckboxHeader';
import IconButton from '../modules/IconButton';
import { PresentationContext } from '../pages/Presentation';
import { DataContext } from '../../contexts/DataContext';

const PresentationImages = props => {
    const { appConfig } = useContext(DataContext);
    const { imagick, imageTypes } = appConfig;
    const { presentationData, setPresentationData } = useContext(PresentationContext);
    const { images = [] } = presentationData?.doc ?? {};

    const [selectedImages, setSelectedImages] = useState([]);

    const data = images.reduce((acc, image) => {
        const { serveAs, type } = image;
        const assets = image.assets.map(asset => {
            return {
                ...asset,
                preview: `${imagick.baseUrl}${asset.url.slice(1)}.${asset.format}?r=${asset.aspectRatioFraction}`,
                type: `${imageTypes.find(img => img.id === type).display} (${asset.aspectRatioFraction})`,
                updated: asset.lastUpdatedAt,
                serveAs
            };
        });
        return [...acc, ...assets];
    }, []);

    const handleRemoveImage = async (imagesToRemove) => {
        const updatedImages = images.map(image => {
            return {
                ...image,
                assets: image.assets.filter(asset => !imagesToRemove.some(imageToRemove => imageToRemove.id === asset.id))
            };
        }).filter(image => !!image.assets.length);

        const updatedPresentation = { ...presentationData, doc: { ...presentationData.doc, images: updatedImages } };
        setPresentationData(updatedPresentation);
        setSelectedImages([]);
    };

    const columns = useMemo(
        () => [
            columnHelper.display({
                header: TableCheckboxHeader,
                id: 'checkbox',
                cell: TableCheckbox,
                enableSorting: false,
                size: 50,
                meta: {
                    className: 'CheckboxCell'
                }
            }),
            columnHelper.accessor('preview', {
                enableSorting: false,
                cell: ({ getValue }) => <img src={`${getValue()}&w=125`} />,
                size: 160
            }),
            columnHelper.accessor('type', {
                size: 300,
                meta: {
                    className: 'imageType'
                }
            }),
            columnHelper.accessor('serveAs', {
                header: 'Serve As',
                cell: ({ getValue }) => {
                    const value = getValue();
                    return value || 'Default';
                },
                size: 150
            }),
            columnHelper.accessor('lastUpdatedAt', {
                header: 'Updated',
                cell: DateCell,
                size: 150
            }),
            columnHelper.display({
                id: 'delete',
                cell: ({ row }) => <IconButton name='trash-can' onClick={() => handleRemoveImage([row.original])} />,
                size: 50,
                meta: {
                    className: 'IconCell'
                }
            })
        ],
        [images]
    );

    const menuData = {
        closeMenu: () => setSelectedImages([]),
        numberOfItems: selectedImages.length,
        actions: [
            {
                text: 'Delete',
                icon: 'trash-can',
                onClick: () => handleRemoveImage(selectedImages)
            }
        ]
    };

    return (
        <ContentAccordion title='Images' className='Presentation__Images'>
            <Table
                className='Presentation__Images--Table'
                data={data}
                columns={columns}
                setSelectedItems={setSelectedImages}
                selectedItems={selectedImages}
            />
            <DashboardMenu menuData={menuData} />
        </ContentAccordion>

    );
};

export default PresentationImages;
