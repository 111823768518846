import { useState, useEffect, useContext } from 'react';
import { Body, Caption } from '../../modules/Typography';
import { PresentationContext } from '../../pages/Presentation';
import { DataContext } from '../../../contexts/DataContext';
import checkUserRole from '../../../hooks/checkUserRole';

const PresentationFieldDocId = props => {
    const { presentationData } = useContext(PresentationContext);
    const { appConfig } = useContext(DataContext);
    const roleCMS = checkUserRole('ROLE_CMS');

    const [felixBase, setFelixBase] = useState('');

    useEffect(() => {
        appConfig ? setFelixBase(appConfig.felix.baseUrl) : setFelixBase(null);
    }, [appConfig]);

    if (!felixBase) return;
    return (
        <div className='DocumentID--Wrapper'>
            <Caption block>Document Id</Caption>
            <div className='DocumentID'>
                {
                    roleCMS
                        ? <a target='_blank' href={`${felixBase}${presentationData.type}/${presentationData.documentId}`} rel='noreferrer'>
                            {presentationData.documentId}
                        </a>
                        : <Body className='DocumentID--copy'>{presentationData.documentId}</Body>

                }

            </div>
        </div>
    );
};

export default PresentationFieldDocId;
