import PropTypes from 'prop-types';
import { Caption } from './Typography';

const TextFieldLabel = ({ className, id, label, disabled, required }) => {
    return (
        <label className={`TextField__label ${className ? `${className}__label` : ''}`} htmlFor={id || label}>
            <Caption faded={disabled}>{label}{required && '*'}</Caption>
        </label>
    );
};

export default TextFieldLabel;

TextFieldLabel.propTypes = {
    className: PropTypes.string,
    id: PropTypes.string,
    label: PropTypes.string,
    disabled: PropTypes.bool,
    required: PropTypes.bool
};

TextFieldLabel.defaultProps = {
    className: '',
    disabled: false,
    required: false
};
