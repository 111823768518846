const parseCSVData = (csvData) => {
    return csvData
        .split('\n')
        .map(row => row
            .split(/,(?=(?:(?:[^"]*"){2})*[^"]*$)/)
            .map(column => column
                // eslint-disable-next-line no-control-regex
                .replace(/[\x00-\x08\x0E-\x1F\x7F-\uFFFF]/g, '')
                .trim()
            )
        );
};

export const parseCSV = async (file, parseRows = false) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onerror = (error) => {
            reject(error);
        };
        reader.onloadend = () => {
            const parsedData = parseCSVData(reader.result);
            if (parseRows) {
                const parsedRows = parseCsvRows(parsedData);
                resolve(parsedRows);
            } else {
                resolve(parsedData);
            }
        };
        reader.readAsText(file);
    });
};

export const parseCsvRows = (csvData) => {
    const headers = [...csvData[0]];
    const csvRows = [...csvData];
    csvRows.shift();
    return csvRows.map(csvRow => {
        return csvRow.reduce((acc, rowData, idx) => {
            return { ...acc, [headers[idx]?.toLowerCase().replace(' ', '')]: rowData };
        }, {});
    });
};
