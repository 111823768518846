import { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import DialogFooter from '../modules/DialogFooter';
import { DialogContext } from '../../contexts/DialogContextProvider';
import Accordion from '../modules/Accordion';
import List from '../modules/List';
import { Body, Subtitle } from '../modules/Typography';
import { useLazyQuery, useMutation } from '@apollo/client';
import { BULK_UPDATE_EVENTS_CONTENT, GET_ALL_EVENTS_FOR_SEARCH } from '../../queries';
import ChipSelect from '../modules/ChipSelect';
import EventDetails from '../event/EventDetails';
import addNewEventContent from '../../hooks/event/addNewEventContent';
import { SnackbarDialogContext } from '../../contexts/SnackbarDialogContextProvider';

const AddPresentationToEventDialog = ({ presentations }) => {
    const { closeDialog } = useContext(DialogContext);
    const [options, setOptions] = useState([]);
    const [events, setEvents] = useState([]);
    const [selected, setSelected] = useState([]);
    const [selectedIds, setSelectedIds] = useState([]);
    const [page, setPage] = useState(1);
    const [newDetails, setNewDetails] = useState({});
    const [loading, setLoading] = useState(false);
    const { snackbarDialog } = useContext(SnackbarDialogContext);

    const [allEvents] = useLazyQuery(GET_ALL_EVENTS_FOR_SEARCH);
    const [bulkUpdate] = useMutation(BULK_UPDATE_EVENTS_CONTENT);

    const handleCloseAndReset = () => {
        setSelectedIds([]);
        setPage(1);
        setNewDetails({});
        setSelected([]);
        closeDialog();
    };

    const handleBack = () => {
        setPage(1);
    };

    const handleDetailsUpdate = async (value, field) => {
        const { type, id } = field;
        type === 'object' ? setNewDetails({ ...newDetails, ...value }) : setNewDetails({ ...newDetails, [id]: value });
    };

    const handleChange = async (v, index) => {
        setSelected(v);
        const selectedOptions = events.filter(e => v.includes(e.name)).map(event => event.id);
        setSelectedIds(selectedOptions);
    };

    const handleNext = async () => {
        if (selectedIds.length > 0 || selectedIds.length >= 20) {
            setPage(2);
        } else {
            setPage(1);
        }
    };

    const handleAddToEvent = async () => {
        setLoading(true);

        const updatedPresentations = [...presentations].map(selectedPresentation => {
            const { updatedContent } = addNewEventContent(selectedPresentation, newDetails);
            return updatedContent;
        });

        try {
            const { data } = await bulkUpdate({
                variables: {
                    data: {
                        eventIds: selectedIds,
                        contentIds: [...(presentations.map(content => content.id))],
                        contentDetails: updatedPresentations
                    }
                }
            });

            if (data.response.error <= 0) {
                snackbarDialog({
                    closeDelay: 30000,
                    snackbarType: 'success',
                    snackbarText: `Successully added ${presentations.length} presentations to ${data.response.success} events`,
                    dialogTitle: 'Bulk Edits Success'
                });
            } else {
                const formaterror = data.response.errors.map((e) => `${findEventNameById(e.eventId)} ${e.error}\n`);
                snackbarDialog({
                    snackbarText: formaterror,
                    dialogTitle: 'Bulk Edits Error'
                });
            }
        } catch (error) {
            snackbarDialog({
                snackbarText: 'Error Updating Content',
                dialogTitle: 'Bulk Edits Error',
                graphQLErrors: error?.graphQLErrors
            });
            setLoading(false);
        }
        setLoading(false);
        handleCloseAndReset();
    };

    useEffect(() => {
        getAllEvents();
    }, []);

    const findEventNameById = (id) => {
        const eventName = events.filter(e => e.id === id).map(event => event.name);
        return eventName;
    };

    const getAllEvents = async () => {
        const data = await allEvents();
        const result = await data.data.getAllEventsForSearch;
        setEvents(result);
        setOptions(result.map((event) => ({ text: event.name, value: event.name })));
    };

    return (
        <>
            {page === 1 &&
                <div className='padded__left padded__right DialogEventsBulk__Content'>
                    <div className='presentationContainer'>
                        <div className='presentatonLeftColumn padded__left padded__right DialogEventsBulk__Content'>
                            <p />
                            <Subtitle number={1}><b>Add content to event</b></Subtitle>
                            Follow the steps to add content to any events
                            <br /><br />
                            <div className='presentationBlueCircle'>1</div>
                            &nbsp;Select Event(s)<br /><br />
                            <div className='presentationGreyCircle'>2</div>
                            &nbsp;<span style={{ color: '#D2D2D2' }}>Presentation Details</span>
                        </div>
                        <div className='presentatonRightColumn padded__left padded__right DialogEventsBulk__Content'>
                            <Accordion
                                headerFlex
                                type='advancedDetails'
                                title='Show Presentations'
                                closeTitle='Hide Presentations'
                                headerContent={<Body number={2}>Presentations ({presentations.length})</Body>}>
                                <List
                                    className='PresentationList'
                                    items={presentations.map(pres => ({ name: pres.name, additional: pres.type }))} />
                            </Accordion>
                            <Subtitle number={3}><b>Select event(s)</b></Subtitle>
                            <br />
                            <ChipSelect
                                key='events'
                                id='events'
                                label='add to events'
                                addLabel={false}
                                chips={selected}
                                options={options}
                                className='ChipSetSelect required  ChipSetSelect--types'
                                handleChips={(updatedDetail) => handleChange(updatedDetail)}
                                required
                                searchable
                                classNameChips='ChipSetEditable--chipsAddPresEvent'
                            />
                        </div>
                    </div>

                    <DialogFooter
                        handleCancel={handleCloseAndReset}
                        handleConfirm={handleNext}
                        disabled={selectedIds.length <= 0 || selectedIds.length >= 20}
                        confirmText='Next'
                    />
                </div>}
            {page === 2 &&
                <div className='padded__left padded__right DialogEventsBulk__Content'>
                    <div className='presentationContainer'>
                        <div className='presentatonLeftColumn padded__left padded__right DialogEventsBulk__Content'>
                            <p />
                            <Subtitle number={1}><b>Add content to event</b></Subtitle>
                            Follow the steps to add content to any events
                            <br /><br />
                            <div className='presentationGreenCircle'>1</div>&nbsp;Select Event(s)<br /><br />
                            <div className='presentationBlueCircle'>2</div>&nbsp;Presentation Details
                        </div>
                        <div className='presentatonRightColumn padded__left padded__right DialogEventsBulk__Content'>
                            <EventDetails
                                details={newDetails}
                                setDetails={(updatedContent) => setNewDetails({ ...updatedContent })}
                                event={{}}
                                detailsType='content'
                                bulkEdit
                                createNew
                                parentDetails={newDetails}
                                handleDetailsChange={(field, value) => handleDetailsUpdate(field, value)}
                                handleDetailsBlur={(field, value) => handleDetailsUpdate(field, value)}
                            />
                            <div style={{ height: '110px' }}><br /></div>
                            <DialogFooter
                                handleCancel={handleBack}
                                confirmCancelText='Back'
                                handleConfirm={handleAddToEvent}
                                confirmText='Add to Event'
                                loading={loading}
                            />
                        </div>
                    </div>
                </div>}
        </>
    );
};

AddPresentationToEventDialog.propTypes = {
    presentation: PropTypes.object
};

export default AddPresentationToEventDialog;
