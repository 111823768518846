import { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useMutation } from '@apollo/client';

import TextField from '../TextField';
import DialogFooter from '../DialogFooter';
import { DialogContext } from '../../../contexts/DialogContextProvider';

import { ADD_ASSOCIATIONS } from '../../../queries';

import { createSeries, createSeasonFields, internalTitle } from '../../../config/createPresentationConfig';

import { isWholeNum } from '../../../utils/validation';

const CreatSeriesSeason = props => {
    const {
        isSeries,
        selectedSeries,
        setSelectedSeries,
        setSelectedSeason,
        seasons,
        presentationData,
        setPresentationData,
        dialog,
        createdPresentation,
        setCreatedPresentation,
        errors,
        setErrors
    } = props;

    const [dialogErrors, setDialogErrors] = useState({});

    const [addAssociations] = useMutation(ADD_ASSOCIATIONS);

    const [newAssociations, setNewAssociations] = useState({});
    const { seriesName, seasonNumber, seasonName } = newAssociations || {};

    const { closeDialog } = useContext(DialogContext);

    const handleTextChange = (value, id) => {
        const updatedCreate = { ...newAssociations, [id]: value };
        setNewAssociations({ ...updatedCreate });

        if (createdPresentation) setCreatedPresentation({ ...createdPresentation, [id]: value });

        if (id === 'seasonNumber') {
            const currErrs = dialog ? { ...dialogErrors } : { ...errors };
            !isWholeNum(value) || value.length > 9 ? currErrs[id] = true : delete currErrs[id];
            if (!currErrs[id] && !isSeries) {
                seasons.some(season => season.seasonNumber === Number(value))
                    ? currErrs[id] = true
                    : delete currErrs[id];
            }
            dialog ? setDialogErrors(currErrs) : setErrors(currErrs);
        }
    };

    useEffect(() => {
        if ((!seriesName && !selectedSeries.name) || !isWholeNum(seasonNumber)) return;
        const seasonName = `${isSeries ? newAssociations.seriesName : selectedSeries.name} - Season ${newAssociations.seasonNumber}`;
        setNewAssociations({ ...newAssociations, seasonName });
        if (createdPresentation) setCreatedPresentation({ ...createdPresentation, seasonName });
    }, [seriesName, seasonNumber, selectedSeries.name]);

    const handleClose = () => {
        setNewAssociations({});
        setDialogErrors({});
        closeDialog();
    };

    const handleConfirm = async () => {
        const variables = {
            newAssociations: {
                ...newAssociations,
                series: isSeries ? 'createNew' : selectedSeries.id,
                season: 'createNew',
                seasonNumber: Number(seasonNumber)
            },
            presentation: presentationData
        };

        const { data } = await addAssociations({ variables });
        const { seriesId, seasonId, history } = data.addAssociations;

        if (isSeries) setSelectedSeries({ id: seriesId, name: seriesName });

        setSelectedSeason({ id: seasonId, name: seasonName, seasonNumber: Number(seasonNumber) });
        setPresentationData({
            ...presentationData,
            associations: {
                ...presentationData.associations,
                seasonPresentationId: seasonId,
                seriesPresentationId: isSeries ? seriesId : presentationData.associations.seriesPresentationId
            },
            history
        });
        setNewAssociations({});
        closeDialog();
    };

    return (
        <>
            <div>
                {isSeries &&
                <TextField
                    key={createSeries.id}
                    label={createSeries.label}
                    id={createSeries.id}
                    onChange={({ target }) => handleTextChange(target.value, createSeries.id)}
                    value={newAssociations[createSeries.id] || ''}
                />}
                {(isSeries && dialog) &&
                    <TextField
                        key={internalTitle.id}
                        label={internalTitle.label}
                        id={internalTitle.id}
                        onChange={({ target }) => handleTextChange(target.value, internalTitle.id)}
                        value={newAssociations[internalTitle.id] || ''}
                    />}
                {
                    createSeasonFields.map(field => {
                        return (
                            <TextField
                                key={field.id}
                                label={field.label}
                                id={field.id}
                                onChange={({ target }) => handleTextChange(target.value, field.id, field.validation)}
                                error={errors[field.id] || dialogErrors[field.id] || false}
                                errorMessage={field.errorMessage}
                                value={newAssociations[field.id] || ''}
                            />
                        );
                    }
                    )
                }
            </div>
            {dialog &&
                <DialogFooter
                    confirmText='Create'
                    handleConfirm={handleConfirm}
                    handleCancel={handleClose}
                    disabled={!!_.size(dialogErrors) || (isSeries && !seriesName) || !isWholeNum(seasonNumber) || !seasonName}
                    setLoading
                />}

        </>
    );
};

export default CreatSeriesSeason;

CreatSeriesSeason.propTypes = {
    isSeries: false,
    dialog: false
};

CreatSeriesSeason.propTypes = {
    isSeries: PropTypes.bool,
    seasons: PropTypes.array,
    selectedSeries: PropTypes.object,
    presentationData: PropTypes.object,
    setPresentationData: PropTypes.func,
    setSelectedSeries: PropTypes.func,
    setSelectedSeason: PropTypes.func,
    dialog: PropTypes.bool,
    createdPresentation: PropTypes.object,
    setCreatedPresentation: PropTypes.func,
    errors: PropTypes.object,
    setErrors: PropTypes.func
};
