import { useContext, useEffect, useState } from 'react';
import { useLazyQuery, useQuery } from '@apollo/client';
import PropTypes from 'prop-types';
import _ from 'lodash';

import Select from '../Select';
import CreatSeriesSeason from './CreateSeriesSeason';

import { DialogContext } from '../../../contexts/DialogContextProvider';

import { SEARCH_PRESENTATIONS, GET_ASSOCIATIONS } from '../../../queries';
import { isWholeNum } from '../../../utils/validation';

const SeriesSelect = ({ id, episodeNumber, presentationData, setPresentationData, dialog, createdPresentation, setCreatedPresentation, setDisabled }) => {
    const { associatedSeries, associatedSeason } = presentationData?.associatedPresentations || {};
    const { makeDialog } = useContext(DialogContext);
    const [selectedSeries, setSelectedSeries] = useState(associatedSeries || {});
    const [selectedSeason, setSelectedSeason] = useState(associatedSeason || {});

    const [makeNew, setMakeNew] = useState({});

    const [errors, setErrors] = useState({});

    const [search, setSearch] = useState('');

    const [getAssociatedPresentations, { data: associatiedPresentationsData, loading: loadingAssociations }] = useLazyQuery(GET_ASSOCIATIONS, { variables: { associations: selectedSeries.associations } });

    const episodes = associatiedPresentationsData?.getAssociations?.associatedEpisodes;
    const seriesVariables = {
        search,
        orderBy: '',
        order: '',
        limit: 10,
        offset: 0,
        page: 1,
        types: 'series'
    };
    const seasonVariables = {
        search: selectedSeries.id,
        orderBy: '',
        order: '',
        limit: 10,
        offset: 0,
        page: 1,
        types: 'season'
    };
    const { data: seriesData, seriesLoading } = useQuery(SEARCH_PRESENTATIONS, { variables: seriesVariables });
    const { data: seasonData, seasonLoading } = useQuery(SEARCH_PRESENTATIONS, { variables: seasonVariables });
    const allSeries = seriesData?.allPresentations?.presentations || [];
    const seasons = _.sortBy(seasonData?.allPresentations?.presentations, 'seasonNumber') || [];

    if (
        selectedSeries.id &&
        !allSeries.some(series => series.id === selectedSeries.id) &&
        selectedSeries.id !== 'createNew'
    ) allSeries.push({ ...selectedSeries });
    const seriesOptions = allSeries.map(pres => ({ text: pres.name, value: pres.id }));

    if (
        selectedSeason.id &&
        !seasons.some(season => season.id === selectedSeason.id) &&
        selectedSeason.id !== 'createNew'
    ) seasons.push({ ...selectedSeason });

    const seasonOptions = seasons.map(pres => ({ text: pres.name, value: pres.id }));

    const handleAssociatedPresentations = async () => await getAssociatedPresentations();

    useEffect(() => setDisabled(seasonLoading || seriesLoading || loadingAssociations), [seasonLoading, seriesLoading, loadingAssociations]);

    useEffect(() => {
        if (!selectedSeries?.associations) return;
        handleAssociatedPresentations();
    }, [selectedSeries]);

    useEffect(() => {
        console.log(JSON.stringify(seasons));
        if (JSON.stringify(selectedSeason) === '{}' && seasons.length > 0) {
            setSelectedSeason(_.last(_.sortBy(seasons, 'seasonNumber')));
            handleChange(_.last(_.sortBy(seasons, 'seasonNumber')).id, 'seasonId');
        }
    }, [seasons]);

    useEffect(() => {
        if (!episodes || !episodeNumber || (!isWholeNum(selectedSeason.seasonNumber) && selectedSeason.id !== 'createNew')) return;
        const currErrors = { ...errors };
        const checkEpisodes = episodes.some(ep => ep.id !== presentationData?.id && ep.episodeNumber === episodeNumber && ep.seasonNumber === selectedSeason.seasonNumber);
        checkEpisodes ? currErrors.seasonId = true : delete currErrors.seasonId;
        if (selectedSeason.id === 'createNew') delete currErrors.seasonId;
        setErrors(currErrors);
    }, [episodes, episodeNumber, selectedSeason]);

    useEffect(() => setDisabled(!!_.size(errors)), [errors]);

    useEffect(() => {
        if (!createdPresentation || !!_.size(errors)) return;
        const { seriesName, seasonNumber, seasonName } = createdPresentation;
        if (makeNew.seriesId) setDisabled(!seriesName || !isWholeNum(seasonNumber) || !seasonName);
        if (makeNew.seasonId) setDisabled(!isWholeNum(seasonNumber) || !seasonName);
    }, [createdPresentation, makeNew]);

    const createSeriesSeasonProps = {
        seasons,
        selectedSeries,
        setSelectedSeason,
        setSelectedSeries,
        setPresentationData,
        presentationData,
        dialog,
        createdPresentation,
        setCreatedPresentation,
        errors,
        setErrors
    };

    const handleChange = (value, id) => {
        setMakeNew({});
        if (value === 'createNew') {
            if (createdPresentation) {
                id === 'seriesId' ? setSelectedSeries({ id: 'createNew' }) : setSelectedSeason({ id: 'createNew' });
                setCreatedPresentation({ ...createdPresentation, [id]: 'createNew', seasonNumber: null, seasonName: null });
            }
            return dialog
                ? makeDialog({
                    title: `Create New ${id === 'seriesId' ? 'Series &' : ''} Season`,
                    confirmText: 'Create',
                    padded: true,
                    disableCloseOnClick: true,
                    dialog: <CreatSeriesSeason
                        {...createSeriesSeasonProps}
                        isSeries={id === 'seriesId'}
                    />
                })
                : setMakeNew({ ...makeNew, [id]: true });
        }

        if (id === 'seriesId') {
            const chosenSeries = allSeries.find(series => series.id === value);
            if (presentationData) {
                setPresentationData({
                    ...presentationData,
                    associations: {
                        ...presentationData.associations,
                        seriesPresentationId: chosenSeries.id,
                        seasonPresentationId: null
                    }
                });
            }
            if (createdPresentation) {
                setCreatedPresentation({ ...createdPresentation, seriesId: chosenSeries.id });
            }
            setSelectedSeries(chosenSeries);
            setSelectedSeason({});
        }

        if (id === 'seasonId') {
            const chosenSeason = seasons.find(season => season.id === value);
            if (presentationData) {
                setPresentationData({
                    ...presentationData,
                    associations: {
                        ...presentationData.associations,
                        seasonPresentationId: chosenSeason.id
                    }
                });
            }
            if (createdPresentation) {
                setCreatedPresentation({ ...createdPresentation, seasonId: chosenSeason.id });
            }
            setSelectedSeason(chosenSeason);
        }
    };

    return (
        <>
            <Select
                key={id}
                onChange={({ value }) => handleChange(value, 'seriesId')}
                label='Series'
                selectedValue={selectedSeries.id}
                searchable
                handleSearchChange={(searchValue) => setSearch(searchValue)}
                className='Select--SeriesId'
                options={[...seriesOptions]}
                loading={seriesLoading}
                createNew
                createNewRole='ROLE_PRODUCER'
                placeholder='Select or create a series...'
            />

            {!makeNew.seriesId && <Select
                key='seasonId'
                onChange={({ value }) => handleChange(value, 'seasonId')}
                label='Season'
                selectedValue={selectedSeason.id}
                placeholder='Select or create a season...'
                searchable
                className='Select--SeasonId'
                options={[...seasonOptions]}
                loading={loadingAssociations}
                createNew
                createNewRole='ROLE_PRODUCER'
                error={errors.seasonId}
                errorMessage={`${selectedSeason.name} already has a presentation with episode number: ${episodeNumber}`}
            />}

            {(makeNew.seriesId || makeNew.seasonId) && <CreatSeriesSeason {...createSeriesSeasonProps} isSeries={selectedSeries.id === 'createNew'} />}
        </>
    );
};

export default SeriesSelect;

SeriesSelect.defaultProps = {
    dialog: false
};

SeriesSelect.propTypes = {
    id: PropTypes.string,
    episodeNumber: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    errors: PropTypes.object,
    setErrors: PropTypes.func,
    presentationData: PropTypes.object,
    setPresentationData: PropTypes.func,
    dialog: PropTypes.bool,
    createdPresentation: PropTypes.bool,
    setCreatedPresentation: PropTypes.func,
    setDisabled: PropTypes.func,
    disabled: PropTypes.bool
};
