import { gql } from '@apollo/client';

const ASSOCIATED_EPISODES = gql`
    fragment AssociatedEpisodes on Presentation {
        id
        name
        type
        status
        episodeNumber
        seasonName
        seasonNumber
        internalTitle
        documentId 
        seriesId
        authorized
        aclType
        acl {
            subject
            capabilities
            deny
        }
        associations {
			seriesPresentationId
            seasonPresentationId
            seasonPresentationIds
            episodePresentationIds
		}
        doc {
            metadata {
                seriesId
                episodeNumber
            }
            publish {
                status {
                    live {
                        value
                        lastUpdatedAt
                    }
                    preview {
                        value
                        lastUpdatedAt
                    }
                }
            }
        }
    }
`;

const ASSOCIATED_SEASON = gql`
    fragment AssociatedSeason on Presentation {
        id
        name
        seasonNumber
        authorized
    }
`;

const ASSOCIATED_SERIES = gql`
    fragment AssociatedSeries on Presentation {
        id
        name
        authorized
    }
`;

export const ASSOCIATED_PRESENTATIONS = gql`
    ${ASSOCIATED_EPISODES}
    ${ASSOCIATED_SEASON}
    ${ASSOCIATED_SERIES}
    fragment AssociatedPresentations on AssociatedPresentations {
        associatedEpisodes {
            ...AssociatedEpisodes
        }
        associatedSeason {
            ...AssociatedSeason
        }
        associatedSeasons { 
            ...AssociatedSeason
        }
        associatedSeries {
            ...AssociatedSeries
        }
    }
`;
