import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import Button from '../modules/Button';
import { Body } from '../modules/Typography';

import { useLazyQuery } from '@apollo/client';
import { LOGIN } from '../../queries';
import Loading from '../modules/Loading';

const Login = ({ unauthorized, error, logout, logoutLoading }) => {
    const [login, { loading }] = useLazyQuery(LOGIN);

    const [loadingRedirect, setLoadingRedirect] = useState(false);

    const fetchToken = !unauthorized && !error && !logout && !logoutLoading;

    const messages = {
        error: 'Login Error, please try again',
        unauthorized: 'Debut Backstage is not enabled for your Disney MyID account.',
        logout: 'You\'ve been successfully logged out of Debut Admin'
    };

    const handleLogin = async (e) => {
        if (e) { e.preventDefault(); }
        setLoadingRedirect(true);
        try {
            const { data } = await login();
            window.location.replace(data?.sentry?.sentryUrl);
        } catch (error) {
            setLoadingRedirect(false);
            console.log(error);
        }
    };

    useEffect(() => {
        if (fetchToken) {
            handleLogin();
        }
    }, []);

    if (loading) return <Loading />;
    return (
        <div className='Login flex flex-column center'>
            {!fetchToken &&
                <>
                    <Button type='primary' loading={loadingRedirect} onClick={handleLogin} className='Login__myid' disabled={unauthorized}>Log In with MyID</Button>
                    <Body error={error || unauthorized} block padded>
                        {
                            error
                                ? messages.error
                                : unauthorized
                                    ? messages.unauthorized
                                    : logout
                                        ? messages.logout
                                        : ''
                        }
                    </Body>
                </>}
        </div>
    );
};

export default Login;

Login.propTypes = {
    unauthorized: PropTypes.bool,
    error: PropTypes.bool,
    logout: PropTypes.bool
};
