import { createContext, useState, useContext } from 'react';

import PropTypes from 'prop-types';
import DialogWrapper from '../components/modules/DialogWrapper';
import List from '../components/modules/List';
import { Body } from '../components/modules/Typography';
import Icon from '../components/modules/Icon';
import Accordion from '../components/modules/Accordion';
import { SnackbarContext } from './SnackbarContextProvider';

export const DialogContext = createContext();

const DialogContextProvider = ({ children }) => {
    const { errorSnackbar } = useContext(SnackbarContext);
    const [deletedItems, setDeletedItems] = useState([]);
    const [dialogProps, setDialogProps] = useState({ open: false });
    const closeDialog = () => setDialogProps(prevProps => ({ ...prevProps, open: false }));
    const addDeletedItem = (id) => setDeletedItems([...deletedItems, id]);
    const updateDialogProps = (newProps) => setDialogProps(prevProps => ({ ...prevProps, ...newProps }));

    const confirmDialog = ({ title, text, handleConfirm, size = 'auto', handleCancel = null, handleConfirmationClose = null, className, setLoading, confirmText, hideCancel = false, disableCloseOnClick = false, hideCancelOnLoad = false }) => {
        setDialogProps({
            title,
            children: text,
            handleConfirm,
            open: true,
            padded: true,
            size,
            handleCancel,
            handleConfirmationClose,
            className,
            setLoading,
            confirmText,
            hideCancel,
            disableCloseOnClick,
            hideCancelOnLoad
        });
    };

    const bulkConfirmDialog = ({ title, type, confirmAction, action, results, handleConfirm, className = '', postConfirmLoading, setLoading, successText, errorText }) => {
        const success = results?.filter(res => res.result === 'OK' || res.result?.message === 'OK' || res.message === 'OK');
        const errors = results?.filter(res => res.result === 'error' || res.result?.message === 'error' || res.message === 'error');

        const dialogContent = (
            <div className='DeleteConfirmation'>
                {!!success.length &&
                    <BulkConfirmDialogContent
                        items={success}
                        type={type}
                        action={confirmAction || action}
                        icon='check-circle'
                        iconColor='success'
                        className='DeleteConfirmation--Success'
                        text={successText}
                    />}
                {!!errors.length &&
                    <BulkConfirmDialogContent
                        items={errors}
                        type={type}
                        action={errors.length > 1 ? 'Errors' : 'Error'}
                        icon='warning-round-alt'
                        iconColor='error'
                        className='DeleteConfirmation--Error'
                        text={errorText}
                    />}
            </div>
        );

        setDialogProps({
            open: true,
            padded: true,
            includeHeaderClose: true,
            title: `${confirmAction} ${title ?? type} Confirmation`,
            children: dialogContent,
            size: 'medium',
            handleConfirm,
            hideCancel: true,
            className: `Dialog__BulkConfirm ${className}`,
            postConfirmLoading,
            setLoading
        });
    };

    const deleteDialog = ({
        type,
        name,
        deleteMutation,
        hard = false,
        size = 'auto',
        handleCancel = null,
        action = 'Delete',
        handleConfirmationClose = null,
        confirmAction,
        className = '',
        warningText
    }) => {
        const handleDelete = async () => {
            try {
                updateDialogProps({ loading: true });
                const results = await deleteMutation();
                if (handleConfirmationClose) {
                    bulkConfirmDialog({ confirmAction, action, type, results, handleConfirm: handleConfirmationClose, handleCancel });
                }
            } catch (err) {
                console.log(err);
                closeDialog();
                errorSnackbar({ text: `${action} error` });
            }
        };

        const children = (
            <>
                <Body className='DeleteList__header'>Are you sure you want to {action} {name.length} {type}:</Body>
                <div className='DeleteList__wrapper'>
                    <List items={name.length ? name.map(n => ({ name: n })) : [{ name }]} className='BulkList' />
                </div>
                {warningText && <Body>{warningText}</Body>}
                {hard &&
                    <Body>This can not be reversed.</Body>}
            </>
        );

        setDialogProps({
            open: true,
            padded: true,
            title: `${action} ${type}`,
            children,
            size,
            handleCancel,
            handleConfirmationClose,
            handleConfirm: handleDelete,
            className: `Dialog__Delete manual-overflow ${className}`
        });
    };

    const makeDialog = ({ title, subtitle, dialog, open = true, size = 'medium', disableCloseOnClick = false, padded, heightSize = 'auto', className, hasBackgroundTint = true, includeHeaderClose = false, handleClose = null }) => {
        setDialogProps({
            title,
            subtitle,
            children: dialog,
            handleConfirm: () => {},
            open,
            size,
            hideFooter: true,
            padded: padded || false,
            disableCloseOnClick,
            heightSize,
            className,
            hasBackgroundTint,
            includeHeaderClose,
            handleClose
        });
    };

    return (
        <DialogContext.Provider value={{
            deleteDialog,
            bulkConfirmDialog,
            confirmDialog,
            makeDialog,
            setDialogProps,
            closeDialog,
            updateDialogProps,
            addDeletedItem
        }}
        >
            {children}
            <DialogWrapper
                {...dialogProps}
                handleCancel={dialogProps.handleCancel || (!dialogProps.hideCancel ? closeDialog : null)}
                handleConfirm={dialogProps.handleConfirm}
            />
        </DialogContext.Provider>
    );
};

export default DialogContextProvider;

DialogContextProvider.propTypes = {
    children: PropTypes.oneOfType([PropTypes.array, PropTypes.object])
};

const BulkConfirmDialogContent = ({ items, type, action, icon, iconColor, className, text }) => (
    <Accordion
        title={'Show ' + action}
        closeTitle={'Hide ' + action}
        type='advancedDetails'
        className={className}
        headerContent={
            <Body block>
                <Icon name={icon} color={iconColor} className='spacing__right' />
                {items.length} {text || `${(items?.length > 1 && !text ? type + 's' : type)} ${action}`}
            </Body>
        }
    >
        <div className='BulkList__wrapper'>
            <List
                items={
                    items.map(s => ({
                        name: s.name,
                        subtitle: (action === 'Errors' || action === 'Error')
                            ? (s?.result?.error?.graphQLErrors?.map(graphQLError => graphQLError.extensions.response.body.message) ?? s?.result?.error?.message ?? s?.error?.errorMessage ?? null)
                            : null
                    }))
                }
                className='BulkList' />
        </div>
    </Accordion>
);

BulkConfirmDialogContent.propTypes = {
    items: PropTypes.array,
    type: PropTypes.string,
    action: PropTypes.string,
    icon: PropTypes.string,
    iconColor: PropTypes.string
};
