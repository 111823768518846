import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Heading, Body } from './Typography';

const Drawer = props => {
    useEffect(() => {
        props.open ? window.document.body.classList.add('Body__Drawer--Open') : window.document.body.classList.remove('Body__Drawer--Open');
    }, [props.open]);
    return (
        <>
            {
                (props.backdrop && props.open) &&
                <div className='Drawer__Backdrop' />
            }
            <aside className={[
                'Drawer',
                props.open ? 'Drawer--open' : null,
                props.className,
                `Drawer--${props.position}`,
                `Drawer--${props.size}`,
                props.padded ? 'Drawer--padded' : null
            ].filter(i => i).join(' ')}
            >

                {props.title && <Heading number={6} block>{props.title}</Heading>}
                {props.subtitle && <Body number={2} block faded>{props.subtitle}</Body>}
                {props.children}
            </aside>
        </>
    );
};

export default Drawer;

Drawer.propTypes = {
    // title of the drawer
    title: PropTypes.string,

    // subtitle of teh drawer
    subtitle: PropTypes.string,

    // className to pass down to tags
    className: PropTypes.string,

    // position of the drawer's origin
    position: PropTypes.oneOf(['top', 'bottom', 'left', 'right']),

    // size of the drawer
    size: PropTypes.oneOf(['small', 'medium', 'large']),

    // whether or not the drawer is padded
    padded: PropTypes.bool,
    open: PropTypes.bool,
    children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    backdrop: PropTypes.bool

};

Drawer.defaultProps = {
    className: '',
    position: 'right',
    size: 'medium',
    padded: false,
    backdrop: true
};
