import { createContext, useContext, useState, useEffect } from 'react';
import { useMutation } from '@apollo/client';
import _ from 'lodash';
import TopAppBar from '../modules/TopAppBar';
import { Heading, Subtitle } from '../modules/Typography';
import Dashboard from '../modules/Dashboard';
import CreatePresentation from '../modules/CreatePresentation';
import Button from '../modules/Button';
import { DialogContext } from '../../contexts/DialogContextProvider';
import usePresentationTable from '../../hooks/presentation/usePresentationTable';

import { SEARCH_PRESENTATIONS, UPDATE_EVENT } from '../../queries';
import addNewEventContent from '../../hooks/event/addNewEventContent';
import checkUserRole from '../../hooks/checkUserRole';
import DialogFooter from '../modules/DialogFooter';
import EventAddContentDetails from './EventAddContentDetails';

import PresentationSeriesSubRow from '../presentation/PresentationSeriesSubRow';

import { eventConfig } from '../../config/eventConfig';
import { SnackbarDialogContext } from '../../contexts/SnackbarDialogContextProvider';

export const EventAddContentContext = createContext();

const EventAddContent = ({ contentDetails, handleNewContent, event, currentContent, isRelatedContent, handleRefetchEvent }) => {
    const { contentDetails: contentFields } = eventConfig;
    const { closeDialog } = useContext(DialogContext);
    const { snackbarDialog } = useContext(SnackbarDialogContext);

    const [handleEventUpdate] = useMutation(UPDATE_EVENT);

    const [loading, setLoading] = useState(false);
    const [resetDashboardData, setResetDashboardData] = useState(false);
    const [editDetails, setEditDetails] = useState(false);
    const [details, setDetails] = useState(false);

    useEffect(() => {
        const updatedDetails = {};
        contentFields.forEach(field => {
            const value = contentDetails[field];
            if (!value) return;
            updatedDetails[field] = value;
        });
        setDetails({ ...updatedDetails });
    }, [contentDetails]);

    const {
        columns,
        handleQuery,
        triggerRefetch,
        setTriggerRefetch,
        tableData,
        selectedPresentations,
        setSelectedPresentations,
        handlePostCreate,
        partialSuccess,
        defaultChecked,
        setDefaultChecked,
        createNew,
        setCreateNew,
        columnVisibility
    } = usePresentationTable({ isDashboard: false, renderSubComponent: (row) => <PresentationSeriesSubRow row={row} /> });

    const menuData = {
        closeMenu: () => setSelectedPresentations([]),
        numberOfItems: selectedPresentations?.length || 0,
        actions: [
            {
                text: 'Edit Details',
                icon: 'edit',
                onClick: () => setEditDetails(true)
            }
        ]
    };

    const validContentTypes = isRelatedContent ? { types: 'episode,promo,public,movie,series' } : { types: 'movie,episode,series' };

    const dashboardData = {
        gqlQuery: SEARCH_PRESENTATIONS,
        queryFilterConstraints: validContentTypes,
        handleQuery,
        tableData,
        columns,
        triggerRefetch,
        setTriggerRefetch,
        partialSuccess,
        queryWithSearchParams: false,
        resetDashboardData,
        setResetDashboardData,
        menuData,
        columnVisibility,
        dashboardTableState: {
            linkTargetBlank: true
        }
    };

    const handleClose = () => {
        setSelectedPresentations([]);
        setResetDashboardData(true);
        closeDialog();
    };

    const checkEndDate = (initEnd, newEnd) => ((!newEnd && !!initEnd) || (!!initEnd && newEnd > initEnd));
    const checkStartDate = (initStart, newStart) => ((!newStart && !!initStart) || (!!initStart && newStart < initStart));

    const handleDates = async (details) => {
        const minStart = details.startAt;
        const maxEnd = details.endAt;

        const currentEvent = await handleRefetchEvent();
        const { startAt: eventStart, endAt: eventEnd } = currentEvent;

        if (checkStartDate(eventStart, minStart)) currentEvent.startAt = minStart;
        if (checkEndDate(eventEnd, maxEnd)) currentEvent.endAt = maxEnd;

        if (isRelatedContent) {
            const currentDetails = { ...contentDetails };
            const { startAt: detailsStart, endAt: detailsEnd } = currentDetails;

            if (!detailsStart && !detailsEnd) return currentEvent;

            if (checkStartDate(detailsStart, minStart)) currentDetails.startAt = minStart;
            if (checkEndDate(detailsEnd, maxEnd)) currentDetails.endAt = maxEnd;

            if (!_.isEqual(currentDetails, contentDetails)) {
                const currentDetailsIndex = currentEvent.eventContent.findIndex(content => content.id === currentDetails.id);
                currentEvent.eventContent[currentDetailsIndex] = currentDetails;
            }
        }

        if (!_.isEqual(currentEvent, event)) {
            try {
                const variables = {
                    id: event.id,
                    data: {
                        ...currentEvent
                    }
                };
                await handleEventUpdate({ variables });
                return currentEvent;
            } catch (error) {
                console.log(error);
                snackbarDialog({
                    snackbarText: 'Error Updating Event Dates',
                    dialogTitle: 'Bulk Edits Error',
                    graphQLErrors: error?.graphQLErrors
                });
                return false;
            }
        }

        return currentEvent;
    };

    const handleAddContent = async () => {
        setLoading(true);
        const updatedPresentations = [...selectedPresentations].map(selectedPresentation => {
            const { updatedContent } = addNewEventContent(selectedPresentation, details);
            return updatedContent;
        });
        const updatedEvent = await handleDates(details);
        if (!updatedEvent) return setLoading(false);
        await handleNewContent(updatedPresentations, updatedEvent);
        handleClose();
        setLoading(false);
    };

    const handlePostCreateAdd = async (_, newPresentation) => {
        setSelectedPresentations([...selectedPresentations, newPresentation]);
        handlePostCreate(_, newPresentation);
        setCreateNew(false);
    };

    // const handleDetailsUpdate = async (value, field) => {
    //     const { type, id } = field;
    //     type === 'object' ? setDetails({ ...details, ...value }) : setDetails({ ...details, [id]: value });
    // };

    return (
        <EventAddContentContext.Provider value={{
            dashboardData,
            defaultChecked,
            setDefaultChecked,
            selectedPresentations: selectedPresentations ?? [],
            setSelectedPresentations,
            handlePostCreate,
            allowSeries: false,
            currentPresentations: currentContent,
            event,
            details,
            setDetails,
            editDetails,
            setEditDetails,
            tableData
        }}>
            <div className='EventContent__Add--Wrapper flex flex-start column'>
                <TopAppBar className='flex stretch-width EventContent__Add--Header'>
                    <Heading number={6} className='padded'>{editDetails ? 'Edit Details' : createNew ? 'Create Presentation' : 'Add Event Content'}</Heading>
                </TopAppBar>
                <div className='EventContent__Add'>
                    {!createNew
                        ? <>
                            <div className='flex EventContent__Add--SubHeader'>
                                <Subtitle number={4} fadedHalf block className='uppercase'>Select Presentations</Subtitle>
                                {checkUserRole('ROLE_PRODUCER') &&
                                <Button
                                    icon='plus-circle'
                                    className='EventContent__Create spacing__left'
                                    onClick={() => setCreateNew(true)}>Create New Presentation</Button>}
                            </div>
                            <Dashboard hasSideFilters dashboardData={dashboardData} type='Presentations' className='Presentations' />
                        </>
                        : <div className='EventContent__Add--CreateNew'>
                            <CreatePresentation
                                handleBack={() => setCreateNew(false)}
                                handlePostCreate={handlePostCreateAdd}
                                removeCreateTypes={isRelatedContent ? ['series'] : ['series', 'promo', 'public']}
                            />
                        </div>}

                    {editDetails && <EventAddContentDetails
                        event={event}
                        details={details}
                        setDetails={setDetails}
                    />}
                </div>

                <DialogFooter
                    handleConfirm={handleAddContent}
                    handleCancel={handleClose}
                    confirmText='Add'
                    loading={loading}
                    disabled={!selectedPresentations?.length}
                />
            </div>
        </EventAddContentContext.Provider>
    );
};

export default EventAddContent;
