import { useContext, useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import PropTypes from 'prop-types';
import _ from 'lodash';

import EventDetails from './EventDetails';
import EventRelatedContent from './EventRelatedContent';

import { SnackbarContext } from '../../contexts/SnackbarContextProvider';

import { UPDATE_EVENT_CONTENT, UPDATE_EVENT } from '../../queries';
import { EventContext } from '../pages/Event';

import useUpdateEventContent from '../../hooks/event/useUpdateEventContent';
import { SnackbarDialogContext } from '../../contexts/SnackbarDialogContextProvider';

const EventContentSubRow = ({ row, id, createNew, event, setEvent, parentContent }) => {
    const [contentErrors, setContentErrors] = useState({});
    const [contentDetails, setContentDetails] = useState({ ...row.original });
    const { successSnackbar, loadingSnackbar, closeSnackbar } = useContext(SnackbarContext);
    const { snackbarDialog } = useContext(SnackbarDialogContext);

    const { setInitEvent, initEvent } = useContext(EventContext) ?? {};
    const { id: eventId } = event;

    const contentId = createNew ? row?.original?.presentationId : row?.original?.id;
    const contentIndex = event.eventContent.findIndex(eventPres => eventPres.id === contentId || eventPres.presentationId === contentId);
    const parentId = createNew ? parentContent?.presentationId : parentContent?.id;
    const parentContentIndex = event.eventContent.findIndex(eventPres => eventPres.id === parentId || eventPres.presentationId === parentId);
    const relatedContentIndex = parentContent?.relatedContent?.findIndex(content => content.id === contentId || content.presentationId === contentId);

    useEffect(() => setContentDetails({ ...row.original }), [row.original]);

    const [updateEventContent] = useMutation(UPDATE_EVENT_CONTENT);
    const [handleEventUpdate] = useMutation(UPDATE_EVENT);

    const handleDates = async (currentDetails) => {
        const { startAt, endAt } = currentDetails;
        const currentEvent = _.cloneDeep({ ...event });

        if (!!startAt && startAt < currentEvent.startAt) currentEvent.startAt = startAt;
        if (!!endAt && endAt > currentEvent.endAt) currentEvent.endAt = endAt;
        if (!_.isEqual(currentEvent, event)) {
            try {
                const variables = {
                    id: event.id,
                    data: {
                        ...currentEvent
                    }
                };
                await handleEventUpdate({ variables });
            } catch (error) {
                console.log(error);
                closeSnackbar();
                snackbarDialog({
                    snackbarText: 'Error Updating Event Dates',
                    dialogTitle: 'Event Update Error',
                    graphQLErrors: error?.graphQLErrors
                });
                return false;
            }
        }
        return true;
    };

    const handleContentUpdate = async (value, field) => {
        const initContent = parentContent?.relatedContent?.[relatedContentIndex] ?? initEvent.eventContent[contentIndex];
        const { updatedDetails, updatedContent: relatedContent } = useUpdateEventContent(initContent, field, value);

        if ((field.id === 'eventDates') && !parentContent) {
            const updatedDates = await handleDates(updatedDetails);
            if (!updatedDates) return;
        }

        loadingSnackbar({ text: `Updating ${field.label ?? field.id}` });

        const data = parentContent
            ? {
                ...updatedDetails
            }
            : {
                ...updatedDetails,
                relatedContent
            };

        try {
            const eventContentVariables = {
                id: eventId,
                contentId,
                data
            };

            await updateEventContent({ variables: eventContentVariables });
            handleUpdatedContent(data);
            successSnackbar({ text: `Successfully updated ${field.label}` });
        } catch (error) {
            console.log(error);
            closeSnackbar();
            snackbarDialog({
                snackbarText: `Error Updating ${field.label}`,
                dialogTitle: 'Event Details Error',
                graphQLErrors: error?.graphQLErrors
            });
        }
    };

    const validate = (value, field) => {
        if (value === null) return false;
        const { validation, allowZero = true } = field;
        switch (validation) {
        case 'number': {
            const number = Number(value);
            return !allowZero ? !number || value <= 0 : !number;
        }
        default:
            return false;
        }
    };

    const handleContentChange = (value, field) => {
        const currentPres = { ...contentDetails };
        const { validation, id } = field;
        currentPres[id] = value;
        setContentDetails(currentPres);
        if (validation) {
            const valid = validate(value, field);
            if (contentErrors[id] !== valid) setContentErrors({ ...contentErrors, [id]: valid });
        };
    };

    const handleUpdatedContent = (updatedContent) => {
        const currentEvent = _.cloneDeep({ ...event });
        parentContent
            ? currentEvent.eventContent[parentContentIndex].relatedContent[relatedContentIndex] = updatedContent
            : currentEvent.eventContent[contentIndex] = updatedContent;
        setInitEvent ? setInitEvent(currentEvent) : setEvent(currentEvent);
    };

    const handleDetailsBlur = (value, field) => {
        const { updatedDetails, updatedContent } = useUpdateEventContent(row.original, field, value);
        handleUpdatedContent(parentContent ? updatedDetails : { ...updatedDetails, relatedContent: [...(updatedContent ?? [])] });
    };

    return (
        <>
            {
                {
                    details: <EventDetails
                        event={event}
                        details={contentDetails}
                        createNew={createNew}
                        setDetails={(updatedContent) => setContentDetails({ ...updatedContent })}
                        handleDetailsChange={(value, field) => handleContentChange(value, field)}
                        handleDetailsBlur={(value, field) => createNew ? handleDetailsBlur(value, field) : handleContentUpdate(value, field)}
                        errors={contentErrors}
                        detailsType='content'
                        parentDetails={parentContent}
                        strictParentDates={!!parentContent}
                    />,
                    relatedContent: <EventRelatedContent
                        parentContent={row.original}
                        createNew={createNew}
                        event={event}
                        setEvent={(updatedEvent) => setEvent(updatedEvent)}

                    />
                }[id ?? row.id]
            }
        </>
    );
};

export default EventContentSubRow;

EventContentSubRow.propTypes = {
    row: PropTypes.object,
    subRow: PropTypes.object,
    createNew: PropTypes.bool,
    event: PropTypes.object,
    setEvent: PropTypes.func,
    id: PropTypes.string,
    parentContent: PropTypes.object
};
