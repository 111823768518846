import { gql } from '@apollo/client';
import { EVENT_CONTENT_FIELDS } from '../fragments/eventContent';

export const GET_EVENT_CONTENT_BY_ID = gql`
    ${EVENT_CONTENT_FIELDS} 
    query eventContentById($id: ID!, $extended: Boolean, $paramsInput: ParamsInput) {
        eventContentById(id: $id, extended: $extended, paramsInput: $paramsInput) {
            eventContent {
                ...EventContentFields
            }
            total

        }
    }
`;

export const ADD_EVENT_CONTENT = gql`
    ${EVENT_CONTENT_FIELDS}
    mutation addEventContent($id: ID, $data: [EventContentInput]) {
        addEventContent(id: $id, data: $data) {
            ...EventContentFields
        }
    }
`;

export const UPDATE_EVENT_CONTENT = gql`
    ${EVENT_CONTENT_FIELDS}
    mutation updateEventContent($id: ID, $contentId: ID, $data: EventContentInput) {
        eventContent: updateEventContent(id: $id, contentId: $contentId, data: $data) {
            ...EventContentFields
        }
    }
`;

export const UPSERT_EVENT_CONTENT = gql`
    ${EVENT_CONTENT_FIELDS}
    mutation upsertEventContent($id: ID, $content: [EventContentInput]) {
        upsertEventContent(id: $id, content: $content) {
            ...EventContentFields
        }
    }
`;

export const EVENT_REMOVE_CONTENT = gql`
    mutation removeEventContent($id: ID, $data: [String]) {
        removeEventContent(id: $id, data: $data) {
            message
            error
        }
    }
`;

export const BULK_UPDATE_EVENT_CONTENT = gql`
    mutation bulkUpdateEventContent($id: ID, $data: BulkUpdateContentInput) {
        response: bulkUpdateEventContent(id: $id, data: $data) {
            id
            message
            name
            error {
                errorCode
                errorMessage
            }
        }
    }
`;

export const BULK_UPDATE_EVENTS_CONTENT = gql`
    mutation bulkUpdateEventsContent($data: BulkUpdateEventsContentInput) {
        response: bulkUpdateEventsContent(data: $data) {
            success
            error
            errors {
                eventId
                error
            }
        }
    }
`;
