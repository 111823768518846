import { DateTime } from 'luxon';
import PropTypes from 'prop-types';

const DateTimeCell = ({ getValue, row }) => {
    const value = getValue();
    if (!value) return 'Unlimited';
    const numUnix = Number(value);

    const valLength = value.toString().length;
    // Checking value is valid number && is the right length of a timestamp
    if (isNaN(numUnix) || (valLength < 10 || valLength > 16)) return '-';

    return (
        <div>
            <span className='block'>{DateTime.fromMillis(numUnix).setZone('America/Los_Angeles').toFormat('LL/dd/y')}</span>
            <span className='block'>{DateTime.fromMillis(numUnix).setZone('America/Los_Angeles').toFormat('h:mma ZZZZ')}</span>
        </div>
    );
};

export default DateTimeCell;

DateTimeCell.propTypes = {
    getValue: PropTypes.func,
    row: PropTypes.object
};
