export const publishingConfig = {
    movie: {
        environments: ['live', 'preview'],
        scopes: [
            'metadata',
            'window',
            'supplemental',
            'images',
            'assets',
            'chapters',
            'trailers',
            'bonuses',
            'links'
        ],
        hiddenScopes: [
            'chapters',
            'trailers',
            'bonuses',
            'links'
        ]
    },
    episode: {
        environments: ['live', 'preview'],
        scopes: ['metadata', 'images', 'assets', 'window'],
        validators: [
            {
                expression: 'metadata.episodeNumber',
                message: 'Episode Number is required'
            },
            {
                expression: 'metadata.seasonId',
                message: 'Season Number is required'
            }
        ]
    },
    promo: {
        environments: ['live', 'preview'],
        scopes: ['metadata', 'images', 'assets', 'window']
    },
    public: {
        environments: ['live', 'preview'],
        scopes: ['metadata', 'images', 'assets', 'window']
    },
    series: {
        environments: ['live', 'preview'],
        scopes: ['metadata', 'supplemental', 'images', 'trailers']
    }
};
