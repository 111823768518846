import PropTypes from 'prop-types';
import { Body } from './Typography';
import Icon from './Icon';
import MenuSurface from './MenuSurface';
import Checkbox from './Checkbox';
import { Link } from 'react-router-dom';

const MenuSurfaceOption = props => {
    let onClick;
    if (props.onClick) {
        onClick = (e) => {
            e.preventDefault();
            if (props.disabled) return false;
            props.onClick();
            if (!props.multiple) { props.closeParentMenu(); }
        };
    }

    return (
        <li className={`MenuSurfaceOption ${props.className}`}>
            <MenuWrapper subMenu={props.subMenu} closeParentMenu={props.closeParentMenu}>
                <LinkWrapper link={props.link} className='MenuSurfaceOption__button' onClick={onClick}>
                    {props.multiple && <Checkbox inline checked={props.checked} handleCheck={() => {}} />}
                    {props.icon && <Icon faded className='MenuSurfaceOption__icon' name={props.icon} />}
                    <Body className='MenuSurfaceOption__button__text' number={2} disabled={props.disabled}>{props.text}</Body>
                </LinkWrapper>
            </MenuWrapper>
        </li>
    );
};

const LinkWrapper = ({ link, children, ...props }) => link
    ? (
        <Link to={link} {...props}>{children}</Link>
    )
    : (
        <button {...props} type='button'>{children}</button>
    );

const MenuWrapper = ({ subMenu, children, ...props }) => subMenu?.length
    ? (
        <MenuSurface {...props} isSubMenu options={subMenu}>{children}</MenuSurface>
    )
    : children;

export default MenuSurfaceOption;

LinkWrapper.propTypes = {
    link: PropTypes.string,
    children: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
};

MenuWrapper.propTypes = {
    subMenu: PropTypes.array,
    children: PropTypes.object

};

MenuSurfaceOption.propTypes = {
    // text to display for option
    text: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
    // icon to prepend to text
    icon: PropTypes.string,

    // link option routes to
    link: PropTypes.string,

    // function to handle option selection
    onClick: PropTypes.func,

    // submenu options
    subMenu: PropTypes.array,
    disabled: PropTypes.bool,

    // function to close the entire menu
    closeParentMenu: PropTypes.func.isRequired,

    // whether multiple values can be selected and display checkboxes
    multiple: PropTypes.bool,
    // whether the displayed checkbox should be checked
    checked: PropTypes.bool,

    className: PropTypes.string
};

MenuSurfaceOption.defaultProps = {
    className: ''
};
