import { useContext } from 'react';
import _ from 'lodash';
import { DataContext } from '../contexts/DataContext';

const getThumbnail = (data, imagePath, height = 150) => {
    const { appConfig } = useContext(DataContext);
    const images = _.get(data, imagePath || 'doc.images');
    const thumbnail = images?.find(img => img.type === 'thumbnail')?.assets?.[0] ?? images?.[0]?.assets?.[0];
    const width = thumbnail ? (thumbnail.width / thumbnail.height) * height : height;
    const imageUrl = thumbnail ? `${thumbnail.url.slice(1)}.${thumbnail.format}?r=${thumbnail.aspectRatioFraction}&w=${Math.ceil(width)}` : `fake-thumbnail.jpg?r=1/1&w=${width}`;
    return appConfig.imagick.baseUrl + imageUrl;
};

export default getThumbnail;
