import { createContext, useState } from 'react';
import PropTypes from 'prop-types';
import { Body, Subtitle } from '../components/modules/Typography';
import Snackbar from '../components/modules/Snackbar';
import DialogWrapper from '../components/modules/DialogWrapper';

export const SnackbarDialogContext = createContext();

const SnackbarDialogContextProvider = ({ children }) => {
    const [snackbar, setSnackbar] = useState({ open: false });
    const [dialog, setDialog] = useState({ open: false });

    const closeSnackbarDialog = () => {
        if (dialog.onClose) dialog.onClose();
        setSnackbar({ open: false });
        setDialog({ open: false });
    };

    const makeErrorDialog = (dialogTitle, graphQLErrors, customDialogBody, onClose) => {
        const children = (
            <div className='padded__bottom'>
                {customDialogBody || graphQLErrors.map((err, i) => {
                    return (
                        <div key={err?.extensions?.response?.status + '__' + i}>
                            <Subtitle block>{err.message}</Subtitle>
                            <Body padded block>{err?.extensions?.response?.body?.message}</Body>
                        </div>
                    );
                })}
            </div>
        );

        setDialog({
            open: true,
            title: dialogTitle,
            includeHeaderClose: false,
            children,
            size: 'medium',
            padded: true,
            onClose
        });
    };

    const snackbarDialog = ({ snackbarText, snackbarButtonText, dialogTitle, graphQLErrors, autoOpenError = false, customDialogBody, customHandleButton, snackbarType = 'error', onClose }) => {
        if (autoOpenError) return makeErrorDialog(dialogTitle, graphQLErrors, customDialogBody, onClose);
        const sharedProps = {
            open: true,
            type: snackbarType,
            closeDelay: 30000,
            text: snackbarText,
            buttonText: snackbarButtonText ?? 'Learn\u00A0More'
        };

        (graphQLErrors?.length || !!customDialogBody)
            ? setSnackbar({
                ...sharedProps,
                handleButton: () => makeErrorDialog(dialogTitle, graphQLErrors, customDialogBody, onClose)
            })
            : setSnackbar({ ...sharedProps, handleButton: customHandleButton });
    };

    return (
        <SnackbarDialogContext.Provider value={{ snackbarDialog, makeErrorDialog, closeSnackbarDialog }}>
            {children}

            <Snackbar
                {...snackbar}
                handleClose={closeSnackbarDialog}
            />
            <DialogWrapper
                {...dialog}
                handleConfirm={closeSnackbarDialog}
            />
        </SnackbarDialogContext.Provider>
    );
};

SnackbarDialogContextProvider.propTypes = {
    children: PropTypes.oneOfType([PropTypes.array, PropTypes.object])
};

export default SnackbarDialogContextProvider;
