import { Link } from 'react-router-dom';
import Icon from '../modules/Icon';
import PropTypes from 'prop-types';
import Checkbox from './Checkbox';
import Radio from './Radio';
import TextLink from './TextLink';
import { Body } from './Typography';

const ListItem = props => (
    <li
        className={[
            'ListItem',
            props.className
        ].filter(i => i).join(' ')}
        style={props.style}
    >
        <LinkWrapper
            link={props.link}
            onClick={props.onSelect ? () => props.onSelect(props.value) : null}
            className={[
                'ListItem__wrapper',
                props.subtitle ? 'ListItem__wrapper--stacked' : null,
                props.className ? `${props.className}__wrapper` : null,
                props.link || props.onSelect ? 'ListItem__wrapper--hover' : null,
                props.selected ? 'ListItem--selected' : null
            ].filter(i => i).join(' ')}
        >
            {props.icon && <Icon name={props.icon} className='ListItem__icon ListItem__icon--lead' />}
            {props.checked !== undefined && !props.radioCheckbox && <Checkbox inline checked={props.checked} value={props.value} handleCheck={() => {}} />}
            {props.checked !== undefined && props.radioCheckbox && <Radio inline padded={false} selected={props.checked} value={props.value} onChange={() => {}} />}
            <div className='ListItem__text'>
                {props.url && <TextLink to={`${props.url}`} target='_blank'>{props.name}</TextLink>}
                {!props.url && props.additional && <div style={{ display: 'inline-block' }}><Body faded={!!props.subtitle} number={2} style={{ float: 'left' }}>{props.name}</Body> <Body faded={!!props.subtitle} number={1} style={{ float: 'right' }}>{props.additional}</Body></div>}
                {!props.url && !props.additional && <Body faded={!!props.subtitle} number={2}>{props.name}</Body>}
                {props.subtitle && <Body number={2} className='ListItem__text--subtitle'>{props.subtitle}</Body>}
            </div>
            {props.trailingIcon && <Icon name={props.trailingIcon} className='ListItem__icon' />}
        </LinkWrapper>
    </li>
);

const LinkWrapper = ({ link, children, ...props }) => link
    ? (
        <Link to={link} {...props}>{children}</Link>
    )
    : (<div {...props}>{children}</div>);

export default ListItem;

LinkWrapper.propTypes = {
    link: PropTypes.string,
    children: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
};

ListItem.propTypes = {
    // title of the list item
    name: PropTypes.string.isRequired,

    // link for the list item to route to
    link: PropTypes.string,

    // subtitle below the list item title
    subtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.node]),

    // className to pass onto listitem
    className: PropTypes.string,

    // function to handle item's selection
    onSelect: PropTypes.func,

    // value for the onSelect to handle
    value: PropTypes.string,

    // name of the icon to prepend
    icon: PropTypes.string,

    // name of the icon to append
    trailingIcon: PropTypes.string,

    // whether or not this item has been selected
    selected: PropTypes.bool,

    // whether or not the list item is checked
    // if undefined no checkbox will display
    checked: PropTypes.bool,

    // style to pass down to
    style: PropTypes.object,

    // whether or not the check type is a radio
    radioCheckbox: PropTypes.bool
};

ListItem.defaultProps = {
    className: '',
    selected: false,
    style: {},
    radioCheckbox: false
};
