import { useState, useEffect, useContext } from 'react';
import { DateTime } from 'luxon';
import { Caption, Subtitle } from './Typography';
import ChipSet from './ChipSet';
import TextField from './TextField';
import Button from './Button';
import { DashboardContext } from './Dashboard';

const DashboardHeader = props => {
    const { refetch, setVariables, variables, totalCount, type, searchPlaceholder, triggerFilter, className, filtersApplied } = useContext(DashboardContext);
    const { search } = variables;

    const [searchQuery, setSearchQuery] = useState(search || '');
    const [dateTime, setDateTime] = useState(null);
    const [reloading, setReloading] = useState(false);

    const setTime = () => setDateTime(DateTime.now().toFormat('LL/dd/y h:mma'));

    useEffect(() => {
        if (search === searchQuery) return;
        setSearchQuery(search);
    }, [search]);

    useEffect(setTime, []);

    const handleDataRefresh = async () => {
        setReloading(true);
        await refetch();
        setTime();
        setSearchQuery('');
        setReloading(false);
    };

    const handleClear = () => {
        setVariables({ ...variables, search: '', orderBy: '', order: '', page: 1 });
        setSearchQuery('');
    };

    const handleSearchSubmit = (val) => {
        setVariables({ ...variables, orderBy: '', order: '', search: val.trim(), page: 1 });
    };

    const handleSearch = (e) => {
        const { value } = e.target;
        if (!!searchQuery && !value) handleSearchSubmit(value);
        setSearchQuery(value);
    };

    return (
        <div className={`DashboardHeader DashboardHeader__${className} ${!totalCount && 'DashboardHeader__NoResults'}`}>
            <div className='DashboardHeader__Refresh flex flex-end half-spacing__bottom'>
                <Button icon='refresh-ccw' type='surface' size='small' onClick={handleDataRefresh} className={`DashboardHeader__Refresh${reloading ? '--loading' : '--static'}`} />
                <Caption className='spacing__left'>Refreshed {dateTime}</Caption>
            </div>
            <div className='flex stretch-width'>
                <div className='DashboardHeader__Title flex'>
                    {(totalCount && type) &&
                    <Subtitle number={1} className='flex flex-start' color='primary'>
                        All {type} <ChipSet chips={[`${totalCount}`]} color='primary' />
                    </Subtitle>}
                </div>

                <div className='DashboardHeader__Controls flex'>
                    <TextField
                        icon='Search'
                        placeholder={searchPlaceholder || `Search ${type}...`}
                        padded={false}
                        onChange={handleSearch}
                        value={searchQuery}
                        onSubmit={handleSearchSubmit}
                        className='DashboardHeader__Search'
                        clear={!!searchQuery}
                        onClear={handleClear}
                    />

                    {triggerFilter &&
                        <Button icon={filtersApplied ? 'filter-alt' : 'filter'} onClick={triggerFilter} className={`spacing__left ${filtersApplied ? 'filters-applied' : ''}`} />}
                </div>
            </div>
        </div>

    );
};

export default DashboardHeader;
