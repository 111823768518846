/* eslint-disable indent */

import { useState, useContext, useEffect, useRef } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import IsEmail from 'isemail';

import Table, { columnHelper } from '../modules/Table';
import TableCheckbox from '../modules/TableCell/TableCheckbox';
import TableCheckboxHeader from '../modules/TableCell/TableCheckboxHeader';
import Status from '../modules/Status';
import Select from '../modules/Select';
import DateTimeCell from '../modules/TableCell/DateTimeCell';
import IconButton from '../modules/IconButton';
import FileUploadButton from '../modules/FileUploadButton';
import Button from '../modules/Button';
import Tooltip from '../modules/Tooltip';

import EventAudienceInviteErrors from './EventAudienceInviteErrors';
import EventAudienceInvite, { EventAudienceInviteContext } from './EventAudienceInvite';

import { DialogContext } from '../../contexts/DialogContextProvider';
import { CreateEventContext } from '../dialogs/event/CreateEventDialog';
import { SnackbarContext } from '../../contexts/SnackbarContextProvider';
import { DataContext } from '../../contexts/DataContext';
import { EventTabsContext } from './EventTabs';
import { EventContext } from '../pages/Event';
import { EventAudienceContext } from './EventAudience';

import { parseCSV } from '../../utils/parseCSV';
import { checkPlural } from '../../utils/checkPlural';

import { GET_EVENT_ACCOUNT_STATUS } from '../../queries';
import { Body } from '../modules/Typography';
import Icon from '../modules/Icon';
import { SnackbarDialogContext } from '../../contexts/SnackbarDialogContextProvider';
import SearchInput from '../modules/SearchInput';

const EventAudienceTable = ({ createEventFlow, audience, handleAudience, currentAudience, newFile, uploadedInviteData, inviteOnly, handleLazyLoad }) => {
    const audienceTableHeader = useRef();
    const { appConfig } = useContext(DataContext);
    const { loadingSnackbar, closeSnackbar, errorSnackbar } = useContext(SnackbarContext);
    const { snackbarDialog } = useContext(SnackbarDialogContext);
    const { initEvent, setInitEvent } = useContext(EventContext) ?? {};
    const { selectedAudience, setSelectedAudience, handleRemoveAudience, handleNewAudience, handleCustomSearch, customSearch, handleCustomSort, customSort, customPaginationData } = useContext(EventAudienceContext) ?? {};
    const { uploadedFile, setUploadedFile } = useContext(CreateEventContext) ?? useContext(EventTabsContext) ?? useContext(EventAudienceInviteContext) ?? {};
    const { makeDialog } = useContext(DialogContext);

    const [tableSearch, setTableSearch] = useState('');
    const [searchErrors, setSearchErrors] = useState({});
    const [tableData, setTableData] = useState([...audience]);
    const [sorting, setSorting] = useState([{ id: createEventFlow ? 'email' : 'createdAt', desc: true }]);

    const [inviteParsing, setInviteParsing] = useState({});
    const [showInviteErrors, setShowInviteErrors] = useState(false);
    const [showCSVError, setShowCSVError] = useState(false);

    const [pagination, setPagination] = useState({
        limit: 100,
        page: 1,
        offset: 0,
        totalCount: createEventFlow ? audience?.length : initEvent?.audienceCount
    });

    useEffect(() => setPagination({ ...pagination, totalCount: audience.length }), [audience]);

    useEffect(() => {
        if (!uploadedInviteData) return;
        setInviteParsing({ ...uploadedInviteData });
    }, [uploadedInviteData]);

    const currentTableData = handleLazyLoad ? tableData : tableData?.slice(pagination.offset, (pagination.limit * pagination.page));

    const [getAccountStatus] = useMutation(GET_EVENT_ACCOUNT_STATUS);

    const handleTableSearch = (value, customSearchParams) => {
        setSearchErrors({});
        if (handleCustomSearch) return handleCustomSearch(value, customSearchParams);
        setTableSearch(value);
        value
            ? setTableData(audience.filter(data => data.email.toLowerCase().indexOf(value.toLowerCase()) !== -1))
            : setTableData(audience);
    };

    const handleSort = (id, isSorted) => {
        if (handleCustomSort) {
            return handleCustomSort(id, isSorted);
        };
        let desc = true;
        if (!isSorted || isSorted === 'desc') desc = false;
        setSorting([{ id, desc }]);
    };

    useEffect(() => {
        if (!uploadedFile && !newFile) return;
        handleCSV(uploadedFile || newFile);
    }, [uploadedFile, newFile]);

    useEffect(() => setTableData(audience), [audience]);

    const checkEmailDomain = (newinvites) => {
        const updatedInvites = [...newinvites].map(invite => {
            const email = invite.email;
            const emailDomain = email.split('@').pop().toLowerCase();
            let provider = 'EMAIL_2FA';
            if (appConfig.myIDEmailDomains.includes(emailDomain)) provider = 'MYID';
            return {
                ...invite,
                provider,
                email: email.toLowerCase()
            };
        });
        return new Promise((resolve, reject) => {
            resolve(updatedInvites);
        });
    };

    const inviteDialog = (newInvites = [], parsedInviteData = {}) => {
        setTableSearch('');
        makeDialog({
            dialog: <EventAudienceInvite
                newInvites={newInvites}
                inviteOnly
                handleNewAudience={handleNewAudience}
                currentAudience={audience || []}
                event={initEvent}
                setEvent={setInitEvent}
                inviteParsing={parsedInviteData}
                setInviteParsing={(updatedInviteParsing) => setInviteParsing(updatedInviteParsing)}
            />,
            disableCloseOnClick: true,
            size: 'xl',
            className: 'EventAddAudienceDialog manual-overflow'
        });
    };

    const checkEmailDuplicate = (emailList, email) => emailList?.some(a => a.email.toLowerCase().trim() === email.toLowerCase().trim());

    const handleAccountStatus = async (newInvites) => {
        try {
            const { data } = await getAccountStatus({ variables: { accounts: newInvites.map(invite => invite.email) } });
            const invitesWithProvider = await checkEmailDomain(data.accounts);
            return [...invitesWithProvider];
        } catch (error) {
            console.log(error);
            errorSnackbar({ text: 'Failed to fetch account details' });
            return false;
        }
    };

    const handleCSVParsingError = () => {
        snackbarDialog({
            snackbarText: 'Error parsing uploaded Audience CSV',
            dialogTitle: 'CSV Error',
            customHandleButton: () => {
                setShowCSVError(true);
                closeSnackbar();
            }
        });
    };

    const handleInviteData = async (newInvites, inviteFromCSV = false) => {
        const inviteErrors = [...(inviteParsing.errors ?? [])];
        const currentTotalInvites = inviteParsing.totalInviteCount ?? 0;
        const uniqeInvites = _.uniqWith(newInvites.filter(data => {
            const checkAudience = checkEmailDuplicate(audience, data.email);
            const checkCurrentAudience = checkEmailDuplicate(currentAudience, data.email);
            if (checkAudience || checkCurrentAudience) inviteErrors.push({ email: data.email, error: 'duplicate' });

            const validateEmail = IsEmail.validate(data.email, { minDomainAtoms: 2 });
            if (!validateEmail) inviteErrors.push({ email: data.email, error: 'invalid' });

            const isUniqueInvite = validateEmail && !checkAudience && !checkCurrentAudience;
            return isUniqueInvite;
        }), (a, b) => a.email.toLowerCase().trim() === b.email.toLowerCase().trim());

        const updatedInviteParsing = { errors: inviteErrors, totalInviteCount: inviteFromCSV ? newInvites.length + currentTotalInvites : currentTotalInvites };
        setInviteParsing(updatedInviteParsing);
        const inviteData = uniqeInvites.length ? await handleAccountStatus(uniqeInvites) : [];
        if (!inviteData) return;
        closeSnackbar();
        createEventFlow ? handleAudience([...inviteData, ...(audience ?? [])]) : inviteDialog(inviteData, updatedInviteParsing);
        if (createEventFlow) setTableSearch('');
        setTableData([...audience]);
    };

    const handleCSV = async (file) => {
        const csvData = await parseCSV(file, true);
        const checkEmailPresent = csvData.filter(data => !!data.email || !!data.emails || !!data.email_address1);
        if (checkEmailPresent.length) {
            const normalizedCSV = checkEmailPresent.map(data => ({ ...data, email: data.email ?? data.emails ?? data.email_address1 }));
            handleInviteData(normalizedCSV, true);
        } else {
            closeSnackbar();
            handleCSVParsingError();
        }
        setUploadedFile(null);
    };

    const updateProvider = (value, email) => {
        const currentData = [...audience];
        const audienceMember = currentData.find(data => data.email === email);
        audienceMember.provider = value;
        handleAudience(currentData);
    };

    const columns = [
        columnHelper.accessor('id', {
            header: TableCheckboxHeader,
            enableSorting: false,
            size: 50,
            cell: TableCheckbox,
            meta: {
                className: 'padded'
            }
        }),
        columnHelper.accessor('status', {
            enableSorting: false,
            cell: (data) => <Status value={data.getValue()} colorFamily='audience' />
        }),
        columnHelper.accessor('email', {
            meta: {
                className: 'flex-grow'
            }
        }),
        columnHelper.accessor('provider', {
            header: 'Email Type',
            enableSorting: false,
            cell: ({ getValue, row }) => {
                const changableStatus = ['new', 'pending', 'expired'];
                const value = getValue().toLowerCase();
                const { email, status } = row.original;
                const { providers } = appConfig;

                return (
                    <Select
                        id={email}
                        disabled={
                            !!status &&
                            !changableStatus.includes(status?.toLowerCase())
                        }
                        onChange={({ value }) => updateProvider(value, email)}
                        options={providers.map(provider => (
                            {
                                text: provider.display,
                                value: provider.name.toLowerCase().trim()
                            }
                        ))}
                        selectedValue={value || 'email'}
                    />
                );
            },
            size: 230,
            meta: {
                className: 'overflow--visible'
            }
        }),
        columnHelper.accessor('createdAt', {
            header: 'Added On',
            cell: DateTimeCell,
            size: 190
        }),
        columnHelper.accessor('remove', {
            header: '',
            enableSorting: false,
            cell: ({ row }) => (
                <IconButton
                    name='trash-can'
                    onClick={() => {
                        if (createEventFlow) {
                            handleAudience(
                                audience.filter(
                                    (invite) => invite.email !== row.original.email
                                )
                            );
                        } else {
                            handleRemoveAudience(row.original);
                        }
                    }}
                />
            ),
            size: 50,
            meta: {
                className: 'IconCell'
            }
        })
    ];

    const paginationData = customPaginationData ?? {
        ...pagination,
        updatePagination: (val) => {
            const { page: newPage, limit: newLimit } = val;
            setPagination({
                ...pagination,
                page: newPage,
                limit: newLimit,
                offset: newLimit * (newPage - 1)
            });
            audienceTableHeader.current.scrollIntoView();
        }
    };

    const handlePostErrorsDownload = () => {
        setInviteParsing({ ...inviteParsing, errors: [], totalInviteCount: 0 });
        setShowInviteErrors(false);
    };

    const closeCSVUploadErrors = () => {
        setInviteParsing({ ...inviteParsing, errors: [], totalInviteCount: 0 });
        setShowCSVError(false);
    };

    const handleInvite = (key, value) => {
        if (key === 'Enter') {
            const newInvites = value.indexOf(',') !== -1 ? value.split(',') : [value];
            const cleanInvites = newInvites.map(newInvite => ({ email: newInvite.trim() })).filter(newInvite => !!newInvite.email);
            if (!cleanInvites.every(invite => IsEmail.validate(invite.email.trim(), { minDomainAtoms: 2 }))) return setSearchErrors({ ...searchErrors, invalid: true });
            loadingSnackbar({ text: 'Fetching Audience Account Details' });
            handleInviteData(cleanInvites);
        }
    };

    return (
        <>
            <div className='flex EventAudience__Header' ref={audienceTableHeader}>
                <SearchInput
                    searchValue={customSearch ?? tableSearch}
                    debounceSearch={!createEventFlow}
                    updateSearchValue={(value, customSearchParams) => handleTableSearch(value, customSearchParams)}
                    className='EventAudience__Search flex-grow spacing__right'
                    placeholder={
                        createEventFlow
                        ? 'Search added audience, Manually add members to the Audience by entering in Email Address or Drag & Drop a CSV'
                        : 'Search for existing audience or Drag & Drop a CSV to add members to the audience'
                    }
                    handleKeyDown={createEventFlow ? (e) => handleInvite(e.key, e.target.value) : null}
                    error={!!_.size(searchErrors)}
                    errorMessage='Invalid email(s)'
                    searchParams={customPaginationData}
                />
                {
                    createEventFlow
                        ? <FileUploadButton
                                className='EventAudience__CTA' acceptedFiles='.csv' handleFile={(file) => {
                                loadingSnackbar({ text: 'Fetching Audience Account Details' });
                                handleCSV(file);
                            }} />
                        : <Button
                                className='EventAudience__CTA'
                                icon='plus-circle'
                                onClick={() => inviteDialog([], {})}
                        >Add Audience</Button>
                }

            </div>
            <Table
                className='EventAudience__Table'
                data={currentTableData}
                columns={columns}
                selectedItems={selectedAudience}
                setSelectedItems={setSelectedAudience}
                handleSort={handleSort}
                paginationData={paginationData}
                handleLazyLoad={handleLazyLoad}
                columnVisibility={createEventFlow
                    ? {
                        createdAt: false,
                        id: false
                    }
                    : {}}
                initialState={{ sorting: customSort ?? sorting }}
            />
            {
                !!inviteParsing?.errors?.length && (!!inviteOnly || !!createEventFlow) &&
                <div className='EventAudience__InviteErrors--Details flex'>
                    <Body className='padded__left padded__right'><Icon name='warning' /> Imported Audience: {inviteParsing.totalInviteCount}</Body>
                    <Tooltip
                        tooltipCopy='See Details'
                        className='EventAudience__InviteErrors--Trigger'
                        location='top'
                    >
                        <Button
                            onClick={() => setShowInviteErrors(true)}
                            icon='warning-round'
                            iconColor='error'>
                            <span className='underline'>{inviteParsing.errors.length} Audience Import {checkPlural('Error', inviteParsing.errors)}</span>
                        </Button>
                    </Tooltip>
                </div>
            }
            {(!!showInviteErrors || !!showCSVError) &&
                <EventAudienceInviteErrors
                    showCSVError={showCSVError}
                    inviteErrors={inviteParsing.errors}
                    closeInviteErrors={() => showCSVError ? closeCSVUploadErrors() : setShowInviteErrors(false)}
                    handlePostDownload={handlePostErrorsDownload}
                />}
        </>
    );
};

export default EventAudienceTable;

EventAudienceTable.defaultProps = {
    createEventFlow: false,
    inviteOnly: false
};

EventAudienceTable.proptypes = {
    createEventFlow: PropTypes.bool,
    audience: PropTypes.array,
    handleAudience: PropTypes.func,
    currentAudience: PropTypes.array,
    newFile: PropTypes.object,
    uploadedInviteData: PropTypes.object,
    inviteOnly: PropTypes.object
};
