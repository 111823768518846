import { Body } from '../modules/Typography';

const Unauthorized = props => {
    return (
        <>
            <div className='Unauthorized flex flex-column center'>
                <Body block error>
                    You are not authorized to view this page
                </Body>
            </div>
        </>
    );
};

Unauthorized.propTypes = {

};

export default Unauthorized;
