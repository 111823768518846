import { gql } from '@apollo/client';

const IMAGE_FIELDS = gql`
    fragment ImageFields on Images {
        type
        assets {
            aspectRatioFraction
            format
            height
            width
            url
        }
    }
`;

const EVENT_RELATED_CONTENT_FIELDS = gql`
    ${IMAGE_FIELDS}
    fragment EventRelatedContentFields on EventRelatedContent {
            presentationId
            id
            name
            startAt
            endAt
            timezone
            playCount
            approvalSourceId
            approvalSourceType
            tags
            maxDefinition
            watermarkType
            watermarkLocation
            watermarkPersistence
            docusignIntegration
            plainModeEnabled  
            status   
            internalTitle
            seriesName
            updatedAt
            updatedBy
            createdAt
            createdBy
            authorized
            allowOfflinePlayback
            documentId
            seriesId
            type
            images {
                ...ImageFields
            }
    }
`;

export const EVENT_CONTENT_FIELDS = gql`
    ${IMAGE_FIELDS}
    ${EVENT_RELATED_CONTENT_FIELDS}
    fragment EventContentFields on EventContent {
            presentationId
            id
            name
            startAt
            endAt
            timezone
            playCount
            approvalSourceId
            approvalSourceType
            tags
            maxDefinition
            watermarkType
            watermarkLocation
            watermarkPersistence
            docusignIntegration
            plainModeEnabled  
            status
            authorized   
            internalTitle
            seriesName
            updatedAt
            updatedBy
            createdAt
            createdBy
            allowOfflinePlayback
            documentId
            seriesId
            type
            seriesPresentationId
            images {
                ...ImageFields
            }
            relatedContent {
                ...EventRelatedContentFields
            }
    }
`;
