import Button from '../Button';

const SubRowToggle = ({ row, table, column }) => {
    const { setExpanded } = table;
    const { expanded } = table.options.state;
    const { id } = column;
    const subRow = row.subRows.find(row => row.original.id === id);
    const open = expanded[subRow.id];

    return (
        <Button
            className='SubRowToggle'
            capitalize
            type='tertiary'
            onClick={() => setExpanded(open ? {} : { [subRow.id]: true })}
            trailingIcon={open ? 'small-caret-down' : 'small-caret-right'}>
            {subRow.original.label || subRow.original.id}
        </Button>
    );
};

export default SubRowToggle;
