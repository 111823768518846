import { createContext, useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import _ from 'lodash';

import DialogFooter from './DialogFooter';

import { CREATE_PRESENTATION } from '../../queries';

import { UserContext } from '../../contexts/UserContextProvider';
import { SnackbarContext } from '../../contexts/SnackbarContextProvider';

import { authorized, subjectsFromUser, hasACLAccess } from '../../utils/acl';
import { checkPlural } from '../../utils/checkPlural';

import CreatePresentationFields from './CreatePresentationFields';

import { createPresentationConfig } from '../../config/createPresentationConfig';
import TopAppBar from './TopAppBar';
import { Body, Heading, Subtitle } from './Typography';
import List from './List';
import { SnackbarDialogContext } from '../../contexts/SnackbarDialogContextProvider';

export const CreatePresentationContext = createContext();

const CreatePresentation = ({
    handlePostCreate,
    processing,
    handleBack,
    processingFiles,
    filesOpen,
    noHeader,
    noFooter,
    cancelCopy,
    removeCreateTypes,
    errorSnackbarDialog,
    handlePresentation,
    initAcl,
    initAclType,
    handleClose
}) => {
    const { user } = useContext(UserContext);
    const { errorSnackbar, successSnackbar } = useContext(SnackbarContext);
    const { snackbarDialog } = useContext(SnackbarDialogContext);

    const [fileHeight, setFileHeight] = useState(filesOpen);
    const [dialogHeight, setDialogHeight] = useState(undefined);

    const [errors, setErrors] = useState({});
    const [disableCreate, setDisableCreate] = useState(false);

    const initPresentation = {
        aclType: initAclType ?? [],
        acl: initAcl ?? [],
        autoPublishToLive: false,
        autoPublishToPreview: true,
        playbackSource: 'debut'
    };
    const [createdPresentation, setCreatedPresentation] = useState({ ...initPresentation });
    const { name, type, episodeNumber, seasonNumber, seriesId, seasonId, acl } = createdPresentation || {};
    const { requiredFields, requiredEpisodeFields, requiredSeriesFields, requiredSeasonFields } = createPresentationConfig;

    const allRequired = requiredFields.every(field => !!createdPresentation[field]) &&
        (type === 'episode' ? requiredEpisodeFields.every(field => !!createdPresentation[field]) : true) &&
        (seriesId === 'createNew' ? requiredSeriesFields.every(field => !!createdPresentation[field]) : true) &&
        (type === 'episode' && !!seriesId && seriesId !== 'createNew' ? !!seasonId : true) &&
        (seasonId === 'createNew' ? requiredSeasonFields.every(field => !!createdPresentation[field]) : true);

    const [createPresentation] = useMutation(CREATE_PRESENTATION);
    const disabled = !disableCreate && allRequired && !_.size(errors) && authorized(createdPresentation.acl, ['R'], subjectsFromUser(user)) && hasACLAccess(user, acl);

    useEffect(() => {
        if (!handlePresentation) return;
        handlePresentation(createdPresentation, allRequired);
    }, [createdPresentation]);

    const resetCreate = () => {
        setCreatedPresentation({ ...initPresentation });
        setErrors({});
        if (handleClose) handleClose();
    };

    const handleCreatePresentation = async () => {
        const variables = {
            name,
            type: type.toLowerCase(),
            data: {
                ...createdPresentation,
                episodeNumber: Number(episodeNumber),
                seasonNumber: Number(seasonNumber),
                season: createdPresentation.seasonId,
                series: createdPresentation.seriesId
            }
        };
        try {
            const { data } = await createPresentation({ variables });

            if (data.createPresentation.partialSuccess) {
                const { code, message } = data.createPresentation.partialSuccess;
                const dialog = {
                    'auto-publishing-failed': {
                        copy: 'Auto-publish failed due to the following errors:',
                        title: 'Auto-publish Error' ?? 'Presentation Creation Errors',
                        snackbarText: '\nAuto\u2011publish failed'
                    }
                }[code];

                snackbarDialog({
                    snackbarType: 'warning',
                    snackbarText: `Successfully created new presentation ${dialog.snackbarText ?? code}`,
                    dialogTitle: dialog.title,
                    customDialogBody: <div className='padded'>
                        <Subtitle className='half-spacing__bottom block' number={3}>{createdPresentation.name} created successfully.</Subtitle>
                        <Body className='half-spacing__bottom block'>{dialog.copy ?? 'Errors:'}</Body>
                        <List items={[{ name: message }]} className='BulkList' />
                    </div>
                });
            } else {
                successSnackbar({ text: `Created new presentation: ${createdPresentation.name}` });
            }

            setErrors({});
            handlePostCreate(data.createPresentation.id, data.createPresentation);
            if (!processing) resetCreate();
        } catch (err) {
            errorSnackbar({ text: 'There was an error creating the new presentation' });
            console.log('create error', err);
        }
    };

    const handleCancel = () => {
        resetCreate();
        handleBack();
    };

    useEffect(() => {
        if (type !== 'episode' && errors.episodeNumber) {
            const newErrors = { ...errors };
            delete newErrors.episodeNumber;
            setErrors(newErrors);
        }
    }, [type]);

    useEffect(() => setFileHeight(document.querySelector('.FileProcessing__Files--Accordion')?.clientHeight), [filesOpen]);
    const maxHeight = fileHeight ? `calc(100% - (70px + 84px + ${fileHeight}px))` : 'calc(100% - (71px + 64px)';
    useEffect(() => setDialogHeight(document.querySelector('.FileProcessing__Presentation')?.querySelector('.DialogWrapper__box__content')?.clientHeight), [createdPresentation]);

    return (
        <CreatePresentationContext.Provider value={{
            createdPresentation,
            setCreatedPresentation,
            processing,
            processingFiles,
            setErrors,
            errors,
            filesOpen,
            disableCreate,
            setDisableCreate,
            removeCreateTypes
        }}>
            <>
                {
                    (!processing && !noHeader) &&
                    <TopAppBar static>
                        <Heading number={6}>Create Presentation</Heading>
                    </TopAppBar>

                }
                <div
                    className={`
                    CreatePresentation__Content 
                    stretch-width
                    ${!!dialogHeight && dialogHeight > window.innerHeight * 0.8 ? 'overflow' : 'overflow--visible'}
                    `}
                    style={{
                        maxHeight
                    }}
                >
                    {noHeader && <Subtitle number={4} fadedHalf block className='spacing__bottom'>CREATE NEW PRESENTATION</Subtitle>}
                    <CreatePresentationFields />
                </div>
                {
                    !noFooter &&
                    <DialogFooter
                        disabled={!disabled}
                        handleCancel={handleCancel}
                        confirmCancelText={cancelCopy || processing ? 'Back' : 'Cancel'}
                        handleConfirm={handleCreatePresentation}
                        confirmText={processing ? checkPlural('Process File', processingFiles) : 'Create Presentation'}
                        setLoading
                    />
                }

            </>

        </CreatePresentationContext.Provider>
    );
};

CreatePresentation.defaultProps = {
    processing: false,
    noHeader: false,
    noFooter: false
};

CreatePresentation.propTypes = {
    handlePostCreate: PropTypes.func,
    processing: PropTypes.bool,
    handleBack: PropTypes.func,
    processingFiles: PropTypes.array,
    filesOpen: PropTypes.bool,
    noHeader: PropTypes.bool,
    noFooter: PropTypes.bool,
    cancelCopy: PropTypes.string,
    removeCreateTypes: PropTypes.array,
    errorSnackbarDialog: PropTypes.func,
    handlePresentation: PropTypes.func,
    initAcl: PropTypes.array,
    initAclType: PropTypes.array
};

export default CreatePresentation;
