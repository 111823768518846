import { useState } from 'react';
import PropTypes from 'prop-types';

const Switch = props => {
    const [id] = useState(props.id || `switch-${Math.floor(Math.random() * 1000)}`);

    return (
        <div className={`Switch flex flex-start ${props.className}`}>
            <div className={`Switch__wrapper ${props.disabled ? 'Switch__wrapper--disabled' : ''} ${props.loading ? 'Switch__wrapper--loading' : ''}`}>
                <input
                    id={id}
                    className='Switch__input'
                    type='checkbox'
                    checked={props.checked}
                    onChange={props.onChange}
                />
                <label className='Switch__toggle' htmlFor={id} />
                <div className={`Switch__track ${props.checked ? 'Switch__track--checked' : ''}`} />
                <div className={`Switch__thumb ${props.checked ? 'Switch__thumb--checked' : ''}`} />
            </div>
            {props.label &&
                <label className='Switch__label Typography--body2'>{props.label}</label>}

        </div>
    );
};

export default Switch;

Switch.propTypes = {
    // whether or not the switch is checked
    checked: PropTypes.bool,

    // handle the switch change event
    onChange: PropTypes.func,

    // whether or not the switch is disabled
    disabled: PropTypes.bool,

    // className to pass down to tags
    className: PropTypes.string,

    // id to to associate the switch with
    id: PropTypes.string,

    // whether or not the mutation is loading
    loading: PropTypes.bool,

    label: PropTypes.string
};

Switch.defaultProps = {
    checked: true,
    disabled: false,
    className: '',
    loading: false
};
