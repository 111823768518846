import { useContext, useState } from 'react';
import { useMutation } from '@apollo/client';
import _ from 'lodash';

import EventContentTableV2 from './EventContentTableV2';
import Button from '../modules/Button';

import { SnackbarContext } from '../../contexts/SnackbarContextProvider';
import { EventContentTabContext } from './EventContentTab';
import { EventContext } from '../pages/Event';
import { SnackbarDialogContext } from '../../contexts/SnackbarDialogContextProvider';

import { UPDATE_EVENT_CONTENT } from '../../queries';
import TextField from '../modules/TextField';

import EventAddContent from './EventAddContent';
import { DialogContext } from '../../contexts/DialogContextProvider';

const EventRelatedContentV2 = ({ relatedContentParent }) => {
    const { initEvent, setInitEvent, event, handleRefetchEvent } = useContext(EventContext);
    const { id } = initEvent;
    const { id: contentId } = relatedContentParent;
    const { successSnackbar, loadingSnackbar } = useContext(SnackbarContext);
    const { snackbarDialog } = useContext(SnackbarDialogContext) ?? {};
    const { makeDialog } = useContext(DialogContext);
    const { handleGetContent } = useContext(EventContentTabContext) ?? {};

    const [updateEventContent] = useMutation(UPDATE_EVENT_CONTENT);

    const [relatedContentSearch, setRelatedContentSearch] = useState('');

    const relatedContent = relatedContentParent.relatedContent ?? [];

    const handleContentSearch = (allContent, search) => {
        return allContent.filter(content => {
            const { name, seriesName, internalTitle, presentationId, id, approvalSourceId } = content;
            const allValues = Object.values({ name, seriesName, internalTitle, presentationId, id, approvalSourceId }).filter(value => !!value);
            const hasSearch = allValues.some(value => value.toLowerCase().indexOf(search.toLowerCase()) !== -1);
            return hasSearch;
        });
    };

    const tableContent = relatedContentSearch ? handleContentSearch(relatedContent, relatedContentSearch) : relatedContent;

    const handleUpdateEventContent = async (updatedEventContent) => {
        loadingSnackbar({ text: 'Adding Related Content' });
        const variables = {
            id,
            contentId,
            data: {
                ...updatedEventContent
            }
        };

        try {
            await updateEventContent({ variables });
            const updatedEvent = await handleRefetchEvent();
            await handleGetContent(null, true, updatedEvent, true);
            successSnackbar({ text: `Successfully updated ${updatedEventContent.name}` });
        } catch (error) {
            console.log(error);
            snackbarDialog({
                snackbarText: `Failed to update ${updatedEventContent.name}`,
                dialogTitle: 'Event Content Update Error',
                graphQLErrors: error?.graphQLErrors
            });
        }
    };

    const handleNewContent = async (newContent, updatedEvent) => {
        const updatedRelatedContent = [...newContent, ...(relatedContent ?? [])];

        const currentEvent = _.cloneDeep(updatedEvent);
        const parentId = relatedContentParent?.id;
        const parentContentIndex = currentEvent.eventContent.findIndex(eventPres => eventPres.id === parentId || eventPres.presentationId === parentId);

        const currentEventContent = {
            ...currentEvent.eventContent[parentContentIndex],
            relatedContent: updatedRelatedContent
        };

        currentEvent.eventContent[parentContentIndex].relatedContent = updatedRelatedContent;
        await handleUpdateEventContent(currentEventContent, currentEvent);
    };

    const handleAddRelatedContent = () => {
        makeDialog({
            dialog: <EventAddContent
                contentDetails={relatedContentParent}
                handleNewContent={handleNewContent}
                event={event}
                setEvent={setInitEvent}
                currentContent={relatedContent}
                isRelatedContent
                handleRefetchEvent={handleRefetchEvent}
            />,
            disableCloseOnClick: true,
            size: 'xl',
            className: 'EventContent__Add--Dialog manual-overflow'
        });
    };

    return (
        <div className='EventContent__Related half-spacing__top'>
            <div className='EventContent__Related__Header flex'>
                <TextField onChange={({ target }) => setRelatedContentSearch(target.value)} value={relatedContentSearch} placeholder='Search Related Content...' />
                <Button icon='plus-circle' className='spacing__left EventContent__Related__Add' onClick={handleAddRelatedContent}>Add Related Content</Button>
            </div>
            <EventContentTableV2
                relatedContentParent={relatedContentParent}
                handleNewContent={(newContent) => handleNewContent(newContent)}
                eventContent={tableContent}
            />
        </div>
    );
};

export default EventRelatedContentV2;
