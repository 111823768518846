import { useContext } from 'react';
import ContentAccordion from '../modules/ContentAccordion';
import { Subtitle } from '../modules/Typography';
import TextField from '../modules/TextField';
import Textarea from '../modules/Textarea';
import Select from '../modules/Select';
import MultiSelect from '../modules/MultiSelect';
import AltIdTable from '../modules/AltIdTable';
import PresentationFieldDocId from './PresentationField/PresentationFieldDocId';
import { PresentationContext } from '../pages/Presentation';
import { metadataConfig } from '../../config/metadataConfig';
import { DataContext } from '../../contexts/DataContext';
import ChipSetEditable from '../modules/ChipSetEditable';
import SeriesSelect from '../modules/FormFields/SeriesSelect';
import checkUserRole from '../../hooks/checkUserRole';
import PresentationFieldLink from './PresentationField/PresentationFieldLink';

const PresentationMetadata = props => {
    const { doc, presentationData, setPresentationData, updateDoc, errors, setErrors, saveDisabled, setSaveDisabled } = useContext(PresentationContext);
    const { type, vppPlayback, vppCatalogId, vppPackageId } = presentationData;
    const { appConfig } = useContext(DataContext);
    const { gusteau } = appConfig;

    const { metadata, publish } = doc;
    const { live, preview } = publish?.state ?? {};
    const { shortSynopsis, synopsis, mediumSynopsis, longSynopsis, notes, tags: metadataTags } = metadata;
    const { titleInformation, includeTags, synopsisShort, synopsisMedium, synopsisLong, synopsisPromo } = metadataConfig[type] || {};

    let duration;
    let url;
    let tags;
    const video = doc.assets?.find(a => a.type === 'VIDEO');
    if (video) {
        duration = video.duration;
        url = video.url;
        tags = video.tags;
    }

    const handleMultiSelect = (field, value) => {
        const isChecked = metadata[field.id]?.some(option => option === value);
        const selectedOptions = isChecked ? metadata[field.id].filter(option => option !== value) : [...(metadata[field.id] || []), value];
        updateDoc(selectedOptions, field.id, field);
    };

    const renderTitleInfo = (field) => {
        const { id, path, label, errorMessage, config, type, presentation, maxLength, hideErrorStyles } = field;

        let value;
        if (presentation) {
            value = presentationData[id];
        } else {
            value = metadata[id] != null ? path ? metadata[path][0][id] : metadata[id] : '';
        }

        switch (type) {
        case 'text':
        case 'number':
        case 'wholeNumber':
            return (
                <TextField
                    key={id}
                    type={type}
                    label={label || id}
                    maxLength={maxLength}
                    onChange={(e) => updateDoc(e.target.value, id, field)}
                    value={value}
                    error={errors[id] || false}
                    errorMessage={errorMessage}
                    hideErrorStyles={hideErrorStyles}
                />
            );
        case 'select': {
            let disabled;
            let options;
            if (appConfig?.[config]) {
                options = appConfig[config].map(type => {
                    return { text: type, value: type };
                });
            }

            if (id === 'seriesId') {
                return (
                    <SeriesSelect
                        id={id}
                        errors={errors}
                        setErrors={setErrors}
                        episodeNumber={Number(metadata.episodeNumber)}
                        presentationData={presentationData}
                        setPresentationData={setPresentationData}
                        disabled={saveDisabled}
                        setDisabled={setSaveDisabled}
                        dialog
                    />
                );
            }

            return (
                <Select
                    key={id}
                    label={label}
                    options={options}
                    onChange={({ value }) => updateDoc(value, id, field)}
                    selectedValue={metadata[id]}
                    disabled={disabled}
                />
            ); }
        case 'multiSelect':
            return (
                <MultiSelect
                    key={id}
                    searchable
                    options={[...(appConfig?.[config] || [])].sort().map(type => {
                        return { text: type, value: type };
                    })}
                    label={label}
                    onChange={({ value }) => { handleMultiSelect(field, value); }}
                    selectedValue={metadata[id] || []}
                />
            );
        default:
            break;
        }
    };

    const handleTags = (tags) => setPresentationData({ ...presentationData, doc: { ...doc, metadata: { ...metadata, tags } } });

    return (

        <ContentAccordion title='Metadata' className={`PresentationMetadata--${presentationData?.type} ${!checkUserRole('ROLE_PRODUCER') ? 'PresentationMetadata--disabled' : ''}`}>
            <div className='PresentationMetadata'>
                <Subtitle number={2} block heavy bottomPadded>Identification</Subtitle>
                <div className='PresentationField--Identification'>
                    <div className='PresentationField--IDs'>
                        <TextField label='System Id' value={presentationData.id || ''} disabled className='SystemID' />
                        <PresentationFieldDocId />
                    </div>
                    <Textarea label='Notes' id='notes' className='PresentationField--Notes' value={notes || ''} placeholder='4000 characters Max' onChange={(e) => updateDoc(e.target.value, e.target.id)} maxLength='4000' />
                </div>
            </div>
            <div className='PresentationMetadata'>
                <Subtitle number={2} block heavy bottomPadded className='capitalize'>{type} information</Subtitle>
                <div className='PresentationField--Title flex flex-start align-flex-start'>
                    {titleInformation?.map(field => renderTitleInfo(field))}
                </div>
                {includeTags &&
                <ChipSetEditable chips={metadataTags} handleChips={handleTags} className='spacing__bottom' id='Tags' label='Tags' />}
                <Subtitle number={2} block heavy bottomPadded>Synopsis</Subtitle>
                {synopsisShort &&
                    <TextField label='Short' id='shortSynopsis' value={shortSynopsis || ''} placeholder='200 characters Max' maxLength='200' onChange={(e) => updateDoc(e.target.value, e.target.id)} />}
                {synopsisMedium &&
                    <Textarea className='PresentationField--longSynopsis' label='Medium' id='mediumSynopsis' value={mediumSynopsis || ''} placeholder='400 characters Max' maxLength='400' onChange={(e) => updateDoc(e.target.value, e.target.id)} />}
                {synopsisLong &&
                    <Textarea className='PresentationField--longSynopsis' label='Long' id='longSynopsis' value={longSynopsis || ''} placeholder='4000 characters Max' maxLength='4000' onChange={(e) => updateDoc(e.target.value, e.target.id)} />}
                {synopsisPromo &&
                    <Textarea className='PresentationField--longSynopsis' label='Long' id='synopsis' value={synopsis || ''} placeholder='4000 characters Max' maxLength='4000' onChange={(e) => updateDoc(e.target.value, e.target.id)} />}
            </div>
            <div className='PresentationMetadata'>
                <AltIdTable />
            </div>
            {
                type !== 'series' &&
                <div className='PresentationMetadata'>
                    <Subtitle number={2} block heavy bottomPadded>Playback Details</Subtitle>
                    <div className='PresentationField--Assets'>
                        <div className='PresentationField--PlaybackDetails'>
                            <TextField label='Streaming URL' value={url || ''} disabled className='StreamingUrl' />
                            <TextField label='Duration (in seconds)' value={duration || ''} disabled className='Duration' />
                            <TextField label='Tags' value={tags?.join(', ') || ''} disabled className='PresentationField--Assets__Tags' />
                        </div>
                    </div>
                </div>
            }

            {
                !!vppPlayback &&
                <div className='PresentationMetadata'>
                    <Subtitle number={2} block heavy bottomPadded>VPP Details</Subtitle>
                    <div className='PresentationField--VPP'>
                        <TextField label='Preview Version ID' value={preview?.metadata?.vppPackageVersion || ''} disabled className='previewVersionId' />
                        <TextField label='Live Version ID' value={live?.metadata?.vppPackageVersion || ''} disabled className='liveVersionId' />
                    </div>
                    <div className='PresentationField--VPP'>
                        <PresentationFieldLink
                            value={vppPackageId || ''}
                            label='Package ID'
                            link={vppPackageId ? `${gusteau.baseUrl}/packages/${vppPackageId}` : null}
                            disabled
                        />
                        <TextField label='Catalog ID' value={vppCatalogId || ''} disabled className='catalogId' />
                    </div>
                </div>
            }

        </ContentAccordion>

    );
};

export default PresentationMetadata;
