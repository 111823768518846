import { createContext, useState, useCallback } from 'react';
import { Outlet } from 'react-router-dom';

export const WidthContext = createContext();

const WidthContextProvder = () => {
    const getWidth = useCallback(() => {
        const documentWidth = window.innerWidth ||
    document.documentElement.clientWidth ||
    document.body.clientWidth;
        const isMobile = documentWidth <= 768;
        let size = 'xs';
        if (documentWidth >= 1500) {
            size = 'xxl';
        } else if (documentWidth >= 1200) {
            size = 'xl';
        } else if (documentWidth >= 992) {
            size = 'lg';
        } else if (documentWidth > 768) {
            size = 'md';
        } else if (documentWidth >= 576) {
            size = 'sm';
        }
        return {
            documentWidth, isMobile, size
        };
    }, []);

    const [width, setWidth] = useState(getWidth());

    // useEffect(() => {
    //     let timeoutId = null;
    //     const resizeListener = () => {
    //         clearTimeout(timeoutId);
    //         timeoutId = setTimeout(() => {
    //             setWidth(getWidth());
    //         }, 150);
    //     };

    //     window.addEventListener('resize', resizeListener);
    //     return () => {
    //         window.removeEventListener('resize', resizeListener);
    //     };
    // }, [getWidth]);

    const resetWidth = useCallback(() => setWidth(getWidth()), [getWidth, setWidth]);
    window.resetWidth = resetWidth;

    return (
        <WidthContext.Provider value={{ ...width, resetWidth }}>
            <Outlet />
        </WidthContext.Provider>
    );
};

export default WidthContextProvder;
