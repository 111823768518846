import PropTypes from 'prop-types';
import IconButton from '../IconButton';

const PresentationsEpisodeToggle = ({ row, table }) => {
    const { type } = row.original;
    const episodeAssociations = row?.original?.associations?.episodePresentationIds ?? [];

    const { setExpanded } = table;
    const { expanded } = table.options.state;

    const subRow = row.subRows.find(row => row.original.id === 'episodes');
    const open = expanded[subRow?.id];

    const toggleSubRow = () => setExpanded(open ? {} : { [subRow.id]: true });

    if (type !== 'series') return '';
    return (
        <IconButton name={open ? 'small-caret-down' : 'small-caret-right'} onClick={toggleSubRow} className='Presentations__SeriesToggle' disabled={!episodeAssociations.length} />
    );
};

export default PresentationsEpisodeToggle;

PresentationsEpisodeToggle.propTypes = {
    row: PropTypes.object,
    table: PropTypes.object
};
