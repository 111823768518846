import PropTypes from 'prop-types';
import MenuSurface from './MenuSurface';
import Button from './Button';
import Icon from './Icon';

const Footer = ({ disablePublish, disableUnpublish, handlePublish, handleUnpublish, handleSave, disableSave, loading, disableAll }) => {
    return (
        <div className='Footer Footer__fixed flex flex-end elevation-3'>
            <div className={`PublishMenu__Wrapper ${(disablePublish && !disableAll) && 'PublishMenu__Wrapper--disabled'} ${disableAll && 'PublishMenu__Wrapper--disabled--all'}`}>

                <Button
                    loading={loading}
                    type='primary'
                    onClick={handlePublish}
                    disabled={disablePublish || disableAll}
                >
                    Publish
                </Button>
                <MenuSurface
                    disabled={disableUnpublish || disableAll}
                    className='PublishMenu'
                    options={[{
                        text: 'Unpublish',
                        value: 'Unpublish',
                        onClick: handleUnpublish

                    }]}
                >
                    <Icon name='caret-up' />
                </MenuSurface>
            </div>
            {handleSave &&
                <Button
                    className='spacing__left'
                    text='Save'
                    type='primary'
                    onClick={handleSave}
                    disabled={disableSave || disableAll} />}
        </div>
    );
};

export default Footer;

Footer.proptypes = {
    disablePublish: PropTypes.bool,
    disableUnpublish: PropTypes.bool,
    disableSave: PropTypes.bool,
    disableAll: PropTypes.bool,
    handlePublish: PropTypes.func,
    handleUnpublish: PropTypes.func,
    handleSave: PropTypes.func,
    loading: PropTypes.bool
};
