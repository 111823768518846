import _ from 'lodash';
import moment from 'moment-timezone';
import { convertTimestamp } from './dateTimeUtils';

export const isNum = (val) => /^\d+$/.test(val);

export const isWholeNum = val => val % 1 === 0 && /^\d+$/.test(val);

export const isUrl = val => /(?:(http|https):\/\/)?(?:www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_+.~#?&/=]*)/g.test(val.trim());

export const formValidation = (type, value) => {
    switch (type) {
    case 'number':
        return !isNum(value);
    case 'wholeNumber':
        return !isWholeNum(value);
    case 'url':
        return !isUrl(value);
    default:
        return false;
    }
};

const handleDateCheck = (parent, checkFor, dateToCheck, selectedDateChange) => {
    if (checkFor === 'start' && (parent.startAt < dateToCheck || !parent.startAt)) return false;
    if (checkFor === 'end' && (parent.endAt > dateToCheck || !parent.endAt)) return false;

    const dates = _.cloneDeep([...(parent.eventContent ?? [])]);
    if (!dates?.length) return false;
    const filteredDates = selectedDateChange.length ? dates.filter(date => !selectedDateChange?.some(dateChange => dateChange.id === date.id)) : [...dates];
    return filteredDates.some(date => checkFor === 'start' ? (!!date.startAt && date.startAt < dateToCheck) : (!!date.endAt && date.endAt > dateToCheck));
};

const handleParentDateCheck = (parent, checkFor, newDate, checkUnlimted) => {
    const dates = _.cloneDeep([..._(parent.eventContent ?? [])]);
    if (!dates?.length) return false;
    if (checkUnlimted) return dates.some(date => checkFor === 'start' ? !date.startAt : !date.endAt);
    return dates.some(date => checkFor === 'start' ? (!!date.startAt && date.startAt < newDate) : (!!date.endAt && date.endAt > newDate));
};

const checkPastDate = (date, timezone, parentTimezone, initDate) => {
    const checkTimezone = timezone || parentTimezone;
    if (!date || !checkTimezone) return false;
    const checkInit = initDate ? initDate !== date : true;
    const dateMoment = convertTimestamp(date, checkTimezone);
    const currentMoment = convertTimestamp(moment().tz(checkTimezone).valueOf(), checkTimezone);
    return checkInit && dateMoment < currentMoment;
};

export const dateValidation = ({ startAt, endAt, parentDates, isRange, checkDates, strictParentDates = true, selectedDateChange = [], compareParentDates, timezone, parentTimezone, initStart }) => {
    const { startAt: parentStart, endAt: parentEnd } = parentDates;

    const errors = [
        {
            type: 'date',
            id: 'parentDateError',
            render: !!strictParentDates && ((!!parentEnd && !!endAt && endAt > parentEnd) || (!!startAt && !!parentStart && startAt < parentStart)),
            copy: 'Dates must be within parent dates'
        },
        {
            type: 'date',
            id: 'pastStartError',
            render: checkPastDate(startAt, timezone, parentTimezone, initStart),
            copy: `${isRange ? 'Start Date' : 'Date'} cannot be in the past`
        },
        {
            type: 'date',
            id: 'checkStart',
            copy: 'Event start date cannot exceed start date of content within the event',
            render: checkDates && !!startAt && handleDateCheck(parentDates, 'start', startAt, selectedDateChange)

        },
        {
            type: 'end',
            id: 'checkEnd',
            copy: 'Event end date cannot occur prior to the end date of content within the event',
            render: checkDates && endAt && handleDateCheck(parentDates, 'end', endAt, selectedDateChange)
        },
        {
            type: 'date',
            id: 'checkEventStart',
            copy: 'Event start date cannot exceed start date of content within the event',
            render: compareParentDates && !!startAt && handleParentDateCheck(parentDates, 'start', startAt)

        },
        {
            type: 'end',
            id: 'checkEventEnd',
            copy: 'Event end date cannot occur prior to the end date of content within the event',
            render: compareParentDates && endAt && handleParentDateCheck(parentDates, 'end', endAt)
        },
        {
            type: 'date',
            id: 'unlimitedContentStart',
            copy: 'Event has content with an unlimited start date.',
            render: compareParentDates && startAt && handleParentDateCheck(parentDates, 'start', startAt, true)
        },
        {
            type: 'end',
            id: 'unlimitedContentEND',
            copy: 'Event has content with an unlimited end date.',
            render: compareParentDates && endAt && handleParentDateCheck(parentDates, 'end', endAt, true)
        },
        {
            type: 'end',
            id: 'startDateError',
            render: !!startAt && !!endAt && startAt >= endAt,
            copy: 'End date must follow the selected start date'
        },
        {
            type: 'end',
            id: 'pastDateError',
            render: !!isRange && checkPastDate(endAt, timezone, parentTimezone),
            copy: 'End date cannot be in the past'
        }
    ];

    const hasErrors = errors.filter(error => !!error.render).length;

    return {
        hasErrors,
        errors
    };
};
