const Loading = () => (
    <div className='Loading'>
        <LoadingIcon />
    </div>
);

export default Loading;

export const LoadingIcon = () => (
    <div className='Loading__icon'>
        <div className='lds-ellipsis'><div /><div /><div /><div /></div>
    </div>
);
