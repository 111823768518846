import PropTypes from 'prop-types';
import { useLazyQuery } from '@apollo/client';
import Select from '../Select';
import TextField from '../TextField';
import { createSeasonFields } from '../../../config/createPresentationConfig';
import { useEffect, useState } from 'react';
import { Caption } from '../Typography';
import { isWholeNum } from '../../../utils/validation';
import { GET_ASSOCIATIONS } from '../../../queries';
import _ from 'lodash';

const SeasonSelect = ({ createNew, createdPresentation, createdPresentations, associatedPresentation, handleChange, disabled, setDisabled, seriesPresentation, shouldLoad }) => {
    const { seasonNumber, episodeNumber, seasonName } = associatedPresentation;

    const [errors, setErrors] = useState({});
    const [seasonOptions, setSeasonOptions] = useState([]);

    const seasonIds = seriesPresentation?.associations?.seasonPresentationIds || [];

    const [getSeasons, { data: seasons, loading: seasonsLoading }] = useLazyQuery(GET_ASSOCIATIONS, { variables: { associations: { seasonPresentationIds: [...seasonIds] } } });

    const seriesName = createdPresentation?.type === 'series' ? createdPresentation?.name : (associatedPresentation.seriesName || seriesPresentation.name);

    const handleSeasonOptions = async () => {
        const { data } = await getSeasons();
        const sorted = _.sortBy(data.getAssociations?.associatedSeasons, 'seasonNumber');
        setSeasonOptions(sorted.map(season => ({ text: season.name, value: season.id })));
        const lastItem = _.last(sorted);
        handleChange(!lastItem ? 'createNew' : lastItem?.id, 'seasonId');
    };

    useEffect(() => {
        if (createNew || !seriesPresentation || associatedPresentation.seasonId != null) return;
        handleSeasonOptions();
    }, [createNew, seriesPresentation, associatedPresentation.seasonId]);

    const handleUpdate = (value, field) => {
        let pickedSeason;
        if (field.id === 'seasonId') {
            if (value !== 'createNew') {
                pickedSeason = seasons?.getAssociations?.associatedSeasons?.find(season => season.id === value);
            } else {
                pickedSeason = { seasonName: '', seasonNumber: undefined };
            }
        }

        handleChange(!isWholeNum(value) ? value : Number(value), field.id, pickedSeason);
        const currErrors = { ...errors };
        !value ? currErrors[field.id] = true : delete currErrors[field.id];

        if (field.id === 'seasonNumber' && !currErrors.seasonNumber && (createNew || associatedPresentation.seasonId === 'createNew')) {
            !isWholeNum(value) || value.length > 9 ? currErrors.seasonNumber = true : delete currErrors.seasonNumber;
        }

        if (field.id === 'seasonId' && value === 'createNew') {
            delete currErrors.uniqueEpisodes;
        }

        setErrors(currErrors);
    };

    useEffect(() => setDisabled(!!_.size(errors) || disabled), [errors, disabled]);

    useEffect(() => {
        const seasonName = `${seriesName ? seriesName + ' -' : ''} Season ${associatedPresentation.seasonNumber}`;
        handleChange(isWholeNum(associatedPresentation.seasonNumber) ? seasonName : null, 'seasonName');
    }, [associatedPresentation.seasonNumber, seriesName]);

    useEffect(() => {
        if (!isWholeNum(seasonNumber)) return;
        const currErrors = { ...errors };
        const presentations = createdPresentations || seriesPresentation?.associatedPresentations?.associatedEpisodes || [];
        const checkSeason = presentations.filter(presentation =>
            presentation.episodeNumber === episodeNumber &&
                    presentation.seasonNumber === seasonNumber &&
                    presentation.id !== associatedPresentation.id);
        checkSeason.length ? currErrors.uniqueEpisodes = true : delete currErrors.uniqueEpisodes;

        if (associatedPresentation.seasonId === 'createNew') {
            delete currErrors.uniqueEpisodes;
        }

        if (seriesPresentation && !currErrors.seasonNumber) {
            const checkSeason = seriesPresentation?.associatedPresentations?.associatedSeasons?.filter(season => season.seasonNumber === seasonNumber && season.id !== associatedPresentation.seasonId);
            checkSeason?.length ? currErrors.seasonNumber = true : delete currErrors.seasonNumber;
        }

        setErrors(currErrors);
    }, [seasonNumber, episodeNumber, createdPresentations, seriesPresentation]);

    return (
        <div className='SeasonSelect'>
            <div className='flex align-flex-start'>
                {
                    !createNew &&
                    <Select
                        id='seasonId'
                        label='Season'
                        className='SeasonSelect--SeasonId'
                        onChange={({ value }) => handleUpdate(value, { id: 'seasonId' })}
                        options={seasonOptions}
                        selectedValue={associatedPresentation.seasonId}
                        loading={seasonsLoading}
                        createNew
                        createNewRole='ROLE_PRODUCER'
                    />
                }

                {
                    (createdPresentations?.seriesId === 'createNew' || associatedPresentation.seasonId === 'createNew' || createNew) &&
                createSeasonFields.map(field => {
                    const { id, label, errorMessage, type, maxLength, hideErrorStyles } = field;
                    return (
                        <TextField
                            key={id}
                            id={id}
                            type={type}
                            maxLength={maxLength}
                            label={label || id}
                            className={`SeasonSelect--${id}`}
                            hideErrorStyles={hideErrorStyles}
                            onChange={({ target }) => handleUpdate(target.value, field)}
                            error={errors[id]}
                            errorMessage={errorMessage}
                            value={associatedPresentation[id] ?? ''}
                        />
                    );
                })
                }
            </div>
            {
                errors.uniqueEpisodes &&
                <Caption
                    error style={{
                        margin: '-10px 0 10px 8px',
                        display: 'block'
                    }}>{seasonName} has duplicate episode number {episodeNumber}</Caption>
            }
        </div>
    );
};

export default SeasonSelect;

SeasonSelect.proptypes = {
    createNew: PropTypes.bool,
    createdPresentation: PropTypes.object,
    createdPresentations: PropTypes.array,
    associatedPresentation: PropTypes.object,
    handleChange: PropTypes.func,
    setDisabled: PropTypes.func,
    disabled: PropTypes.bool,
    seriesPresentation: PropTypes.object
};
