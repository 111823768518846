import { useState, useContext } from 'react';
import IconButton from '../modules/IconButton';
import Tooltip from '../modules/Tooltip';
import { DataContext } from '../../contexts/DataContext';
import { SnackbarContext } from '../../contexts/SnackbarContextProvider';

const PresentationDeepLink = ({ presentation, tooltipPosition }) => {
    const { appConfig } = useContext(DataContext);
    const { errorSnackbar } = useContext(SnackbarContext);
    const initTooltip = 'Copy Deep Link';
    const disabledCopy = 'Series and Season Required';
    const [tooltipCopy, setTooltipCopy] = useState(initTooltip);
    const { type: presentationType, documentId } = presentation ?? {};
    const { seriesId } = presentation?.doc?.metadata ?? presentation ?? {};
    const { baseUrl } = appConfig.debut;
    const disabled = (presentationType === 'episode' && !seriesId);

    const validTypes = ['movie', 'series', 'episode'];

    const handleDeepLink = () => {
        try {
            let type = presentationType;
            let id = documentId;
            if (presentationType === 'episode') {
                id = seriesId;
                type = 'series';
            }

            if (!type || !id) return errorSnackbar({ text: 'Failed to copy deep link' });
            const link = `${baseUrl}/${type}/${id}`;
            navigator.clipboard.writeText(link);
            setTooltipCopy('Copied to Clipboard');

            setTimeout(() => {
                setTooltipCopy(initTooltip);
            }, 10000);
        } catch (error) {
            console.log(error);
            errorSnackbar({ text: 'Failed to copy deep link' });
        }
    };

    if (!validTypes.includes(presentationType)) return;

    return (
        <Tooltip tooltipCopy={disabled ? disabledCopy : tooltipCopy} className='spacing__right' location={tooltipPosition}>
            <IconButton name='text-link' onClick={handleDeepLink} disabled={disabled} />
        </Tooltip>
    );
};

export default PresentationDeepLink;
