import { useContext } from 'react';
import { FiltersContext } from './Filters';
import { Heading } from '../Typography';
import ACLSelect from '../ACLSelect';
import Checkbox from '../Checkbox';
import CustomDatePicker from '../CustomDatePicker';
import _ from 'lodash';

const FilterGroups = () => {
    const { filterGroups: filters, setFilterGroups, queryFilterConstraints } = useContext(FiltersContext);

    const updateFilters = (newValue, filter) => {
        const { id } = filter;
        const currentFilters = _.cloneDeep([...filters]);
        const currentFilter = currentFilters.find(current => current.id === id);
        currentFilter.filterValues = newValue;
        setFilterGroups(currentFilters);
    };

    const handleCheckboxFilter = (checkboxValue, filter) => {
        const { filterValues } = filter;
        filterValues.includes(checkboxValue)
            ? updateFilters(filterValues.filter(filterValue => filterValue !== checkboxValue), filter)
            : updateFilters([...filterValues, checkboxValue], filter);
    };

    const renderFilter = (filter) => {
        const { type, values: initValues, filterValues, id } = filter;
        let values = initValues;
        if (queryFilterConstraints?.[id]) values = queryFilterConstraints[id].split(',');

        switch (type) {
        case 'acl':
            return (
                <ACLSelect
                    defaultSelected={false}
                    hideLabel
                    hideError
                    padded
                    onChange={(selectedAcl) => updateFilters(selectedAcl, filter)}
                    initACLs={filterValues}
                />
            );
        case 'dateRange':{
            const startId = `${id}From`;
            const endId = `${id}To`;
            return (
                <CustomDatePicker
                    isRange
                    onChange={({ startAt, endAt }) => updateFilters({ [startId]: startAt, [endId]: endAt }, filter)}
                    showTime
                    includePST
                    initStart={filterValues?.[startId] ?? null}
                    initEnd={filterValues?.[endId] ?? null}
                    startLabel='From'
                    endLabel='To'
                    orientation='column'
                />
            );
        }
        case 'checkboxList':
            return (
                <div className='Filter__Checkbox__List'>
                    {values.map(value => {
                        const valueToId = value.split(' ').join('_');
                        return (
                            <Checkbox
                                key={valueToId}
                                id={valueToId}
                                value={valueToId}
                                label={value}
                                checked={filterValues.includes(valueToId)}
                                handleCheck={({ value: checkboxValue }) => handleCheckboxFilter(checkboxValue, filter)}
                            />
                        );
                    })}
                </div>
            );
        default:
            break;
        }
    };

    return (
        <div className='Filters padded'>
            {
                filters.map(filter => (
                    <div className={`Filter--Wrapper Filter__${filter.id.split(' ').join('_')}`} key={filter.id.split(' ').join('_')}>
                        <Heading number={7} className='Filter__Title'>{filter.label ?? filter.id}</Heading>
                        <div className='Filter'>
                            {renderFilter(filter)}
                        </div>
                    </div>
                ))
            }
        </div>
    );
};

export default FilterGroups;
