import PropTypes from 'prop-types';
import { Body } from '../Typography';
import TextLink from '../TextLink';

const PresentationCell = ({ getValue }) => {
    const value = getValue();
    return value?.length
        ? <div className='ContentDeliveryDashboardTable__presentationLinks'>
            {value.map(pres => <TextLink key={pres.id} to={`/presentation/${pres.id}`}>{pres.name || '-'}</TextLink>)}
        </div>
        : <Body>-</Body>;
};

export default PresentationCell;

PresentationCell.propTypes = {
    getValue: PropTypes.func
};
