import { useContext } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { FileContext } from '../pages/File';

import Accordion from '../modules/Accordion';

const FileJson = ({ config }) => {
    const { file, activeFileHistory } = useContext(FileContext);
    const { id, label } = config;

    const omitNull = (obj) => _.omitBy(obj, _.isNil);

    const getData = () => {
        switch (id) {
        case 'MediaInfo': {
            const data = { ...file?.details?.mediaInfo?.MediaInfo };
            if (data?.media?.track) {
                data.media.track = data.media.track.map(obj => omitNull(obj));
            }
            return _.isEmpty(data) ? { error: 'MediaInfo not found' } : data;
        }
        case 'jobInput': {
            return activeFileHistory?.jobInput ? omitNull(activeFileHistory?.jobInput) : { error: 'JopInput not found' };
        }
        case 'jobOutput': {
            if (activeFileHistory.jobOutput) {
                return omitNull(activeFileHistory?.jobOutput);
            } else {
                try {
                    return { jobError: JSON.parse(activeFileHistory?.jobError) };
                } catch {
                    return { jobError: activeFileHistory?.jobError };
                }
            }
        }
        case 'details': {
            return file?.details ? omitNull(file?.details) : {};
        }
        default:
            return {};
        }
    };

    return (
        <Accordion
            title={`Show ${label}`}
            closeTitle={`Hide ${label}`}
            type='advancedDetails'
            iconOpen='small-caret-right'
            iconClose='small-caret-down'
        >
            <pre className='FileDetails--jsonContent'>
                <code>
                    {JSON.stringify(getData(), undefined, 2)}
                </code>
            </pre>
        </Accordion>
    );
};

export default FileJson;

FileJson.propTypes = {
    config: PropTypes.object
};
