import { gql } from '@apollo/client';

export const CODE_FIELDS = gql`
    fragment CodeFields on Code {
        id
        description
        policyId
        policyName
        policyExpiration
        sentryOrgs
        groupId
        createdBy
        createdAt
        modifiedAt
        enabled
        codeCount
        batchId
        eventId
    }
`;

export const CODE_POLICY_FIELDS = gql`
    fragment CodePolicyFields on Policy {
        id
        name
        description
        expiration
        allowedChars
        codeLength
        createdBy
        createdAt
        modifiedAt
    }
`;
