import PropTypes from 'prop-types';
import { useState } from 'react';
import Button from './Button';
import { Body } from './Typography';
import Icon from './Icon';

const DialogFooter = (props) => {
    const [footerLoading, setFooterLoading] = useState(false);
    const isLoading = footerLoading || props.loading;

    const dialogConfirm = async () => {
        if (props.setLoading) await setFooterLoading(true);
        await props.handleConfirm();
        setFooterLoading(props.postConfirmLoading);
    };

    return (
        <div className='DialogWrapper__box__footer'>
            {props.errorModalTrigger}
            {!!props.copy && <Body rightPadded>{props.copy}</Body>}
            {!!props.errorCount && <Body error rightPadded><Icon name='warning' color='error' /> {props.errorCount} error</Body>}
            {!!props.warningMessage && <Body className='WarningMessage flex flex-start'><Icon size='large' name='warning' color='warning' className='half-spacing__right' /> {props.warningMessage}</Body>}
            {props.handleCancel && <Button type='secondary' className={`spacing__right ${!!props.hideCancelOnLoad && !!isLoading ? 'DialogWrapper__FooterButton--hide' : ''}`} text={props.confirmCancelText} onClick={props.handleCancel} />}
            {props.handleConfirm && <Button loading={isLoading} type='primary' text={props.confirmText} onClick={dialogConfirm} disabled={props.disabled} />}
        </div>
    );
};

export default DialogFooter;

DialogFooter.propTypes = {
    // whether or not the confirm button is disabled
    disabled: PropTypes.bool,

    // function to cancel or close the dialog
    handleCancel: PropTypes.func,

    // text of the cancel button
    confirmCancelText: PropTypes.string,

    // function to confirm the dialog
    handleConfirm: PropTypes.func,

    // text of the confirm button
    confirmText: PropTypes.string,

    // whether or not the confirm action is loading and should be disabled
    loading: PropTypes.bool,

    // whether or not the handle confirm should set a loading state
    setLoading: PropTypes.bool,

    errorCount: PropTypes.number,

    // Additional context copy for dialog
    copy: PropTypes.oneOfType([PropTypes.object, PropTypes.array, PropTypes.string]),

    errorModalTrigger: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),

    postConfirmLoading: PropTypes.bool
};

DialogFooter.defaultProps = {
    warningMessage: '',
    confirmCancelText: 'Cancel',
    confirmText: 'OK',
    disabled: false,
    loading: false,
    setLoading: false,
    postConfirmLoading: false
};
