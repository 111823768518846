import PropTypes from 'prop-types';
import timezones from 'timezones.json';
import _ from 'lodash';
import Select from '../Select';
import { useEffect, useState } from 'react';

const TimezoneSelect = ({ timezone, formField, formData, setFormData, handleChange, allowBlank, className }) => {
    const [currentTimezone, setCurrentTimezone] = useState(timezone);
    const handleTimezoneChange = ({ value }) => {
        const newTimezone = !value ? value : value.split(',')[0];
        setCurrentTimezone(newTimezone);
        handleChange
            ? handleChange(newTimezone)
            : setFormData({ ...formData, timezone: newTimezone });
    };

    useEffect(() => setCurrentTimezone(timezone), [timezone]);

    const { id, label } = formField;
    const timezonesSorted = _.sortBy(timezones, 'offset');
    const timezoneOptions = [
        ...(allowBlank ? [{ text: 'None', value: '' }] : []),
        ...(timezonesSorted?.map(zone => ({ text: zone.text, value: `${zone.utc.join()},${zone.abbr},${zone.value},${zone.text}` })) ?? [])
    ];

    return (
        <Select
            key={id}
            className={`Select__timezone ${className}`}
            label={label}
            searchable
            options={timezoneOptions}
            selectedValue={currentTimezone}
            onChange={(val) => handleTimezoneChange(val)}
        />
    );
};

export default TimezoneSelect;

TimezoneSelect.defaultProps = {
    allowBlank: false
};

TimezoneSelect.proptypes = {
    timezone: PropTypes.string,
    formField: PropTypes.object,
    formData: PropTypes.object,
    setFormData: PropTypes.func,
    handleChange: PropTypes.func,
    allowBlank: PropTypes.bool
};
