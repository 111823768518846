/* eslint-disable indent */
import { useMemo } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import TableCheckbox from '../modules/TableCell/TableCheckbox';
import TableCheckboxHeader from '../modules/TableCell/TableCheckboxHeader';
import Table, { columnHelper } from '../modules/Table';
import getThumbnail from '../../hooks/getThumbnail';
import PresentationDetailsCell from '../modules/TableCell/PresentationDetailsCell';
import { Body, Caption } from '../modules/Typography';
import PresentationDeepLink from './PresentationDeepLink';
import Loading from '../modules/Loading';

const PresentationEpisodesTable = ({ episodes, setSelectedItems, selectedItems, className, mergeOnDefaultChecked, loading, hideDeepLink }) => {
    const columns = useMemo(
        () => [
            columnHelper.display({
                header: TableCheckboxHeader,
                id: 'checkbox',
                cell: TableCheckbox,
                enableSorting: false,
                size: 50,
                meta: {
                    className: 'CheckboxCell'
                }
            }),
            columnHelper.accessor('name', {
                header: 'Episode',
                cell: PresentationDetailsCell,
                size: 250,
                meta: {
                    className: 'flex-grow'
                }
            }),
            columnHelper.accessor('episodeNumber', {
                enableSorting: false,
                header: 'Episode Number',
                meta: {
                    className: 'flex'
                }
            }),
            columnHelper.accessor('seasonName', {
                enableSorting: false,
                header: 'Season',
                size: 250,
                cell: ({ getValue, row }) => {
                    return (
                        <div>
                            <Body block number={2}>{getValue()}</Body>
                            <Caption faded>Season Number: {row.original.seasonNumber}</Caption>
                        </div>
                    );
                },
                meta: {
                    className: 'flex-grow'
                }
            }),
            columnHelper.display({
                id: 'deepLink',
                cell: ({ row }) => <PresentationDeepLink presentation={row.original} tooltipPosition='left' />,
                size: 50,
                meta: {
                    className: 'IconCell'
                }
            })
        ],
        []
    );

    const tableData = episodes?.map(episode => {
        const { doc } = episode;
        const { live, preview } = doc.publish.status;

        return {
            ...episode,
            episodeNumber: episode.doc.metadata.episodeNumber,
            artwork: getThumbnail(doc, 'images', 60),
            live,
            preview,
            images: doc.images
        };
    });

    return (
        <>
            {
                loading
                    ? <Loading />
                    : <Table
                            className={className}
                            data={_.orderBy(tableData, ['seasonNumber', 'episodeNumber'], ['desc', 'desc'])}
                            columns={columns}
                            setSelectedItems={setSelectedItems}
                            selectedItems={selectedItems}
                            mergeOnDefaultChecked={mergeOnDefaultChecked}
                            columnVisibility={hideDeepLink ? { deepLink: false } : {}}
                    />
            }
        </>

    );
};

export default PresentationEpisodesTable;

PresentationEpisodesTable.defaultProps = {
    className: '',
    mergeOnDefaultChecked: false,
    loading: false,
    hideDeepLink: false
};

PresentationEpisodesTable.proptypes = {
    episodes: PropTypes.array,
    setSelectedItems: PropTypes.func,
    selectedItems: PropTypes.array,
    className: PropTypes.string,
    mergeOnDefaultChecked: PropTypes.bool,
    loading: PropTypes.bool,
    hideDeepLink: PropTypes.bool
};
