import { useState, useEffect, useRef, useContext } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useLazyQuery } from '@apollo/client';
import TextField from './TextField';
import Loading from './Loading';
import Button from './Button';
import { Caption } from './Typography';

import getThumbnail from '../../hooks/getThumbnail';
import useOnScreen from '../../hooks/useOnScreen';
import { SnackbarContext } from '../../contexts/SnackbarContextProvider';

const SearchDropdown = ({ padded, icon, placeholder, searchData, handleSearchResultClick, initialData, className, filterBy, handleCreateNew, createNewCopy, label, selectedValue }) => {
    const { errorSnackbar } = useContext(SnackbarContext);
    const [showResults, setShowResults] = useState(false);
    const [searchResults, setSearchResults] = useState([]);
    const searchResultsRef = useRef(null);
    const loadMoreRef = useRef(null);
    const loadMore = useOnScreen(loadMoreRef, searchResultsRef, searchResults);

    const { dataPath, gqlQuery, types = '', addtionalVariables = {} } = searchData;
    const { resetOnSelect } = addtionalVariables;
    const initSearchVariables = {
        search: '',
        orderBy: '',
        order: '',
        types,
        limit: 10,
        offset: 0,
        page: 1,
        ...addtionalVariables
    };

    const [searchVariables, setSearchVariables] = useState({ ...initSearchVariables });

    const [fetchSearchResults, { data, loading }] = useLazyQuery(gqlQuery);

    const resetForm = () => {
        setSearchVariables({ ...initSearchVariables });
        setShowResults(false);
    };

    useEffect(() => {
        const closeWithKeyPress = e => {
            if (e.key === 'Escape') resetForm();
        };

        if (showResults) window.addEventListener('keyup', closeWithKeyPress);
        return () => window.removeEventListener('keyup', closeWithKeyPress);
    }, [showResults]);

    useEffect(() => {
        if (!searchVariables.search) return;
        handleFetchSearchResults();
    }, [searchVariables]);

    useEffect(() => {
        if (!loadMore) return;
        setSearchVariables({
            ...searchVariables,
            offset: searchVariables.offset + searchVariables.limit,
            page: searchVariables.page + 1
        });
    }, [loadMore]);

    const handleFetchSearchResults = async () => {
        try {
            setShowResults(true);
            const { data: results } = await fetchSearchResults({
                variables: searchVariables
            });
            // Filtering out data that may already exist in parent component or catch duplicate returned ids
            const newData = _.get(results, dataPath)?.filter(result => !initialData?.some(x => x[filterBy] === result.id)).filter(result => !searchResults.some(searchResult => searchResult.id === result.id)) || [];
            setSearchResults([...searchResults, ...newData]);
        } catch (e) {
            console.log(e);
            errorSnackbar({ text: 'Search Error' });
            resetForm();
        }
    };

    const handleSearch = (e) => {
        const search = e.target.value;
        setSearchResults([]);
        if (!search) return resetForm();
        setSearchVariables({ ...initSearchVariables, search });
    };

    const handleClick = (searchResult) => {
        handleSearchResultClick(searchResult);
        if (resetOnSelect) setSearchResults([]);
        resetForm();
    };

    const handleClear = () => {
        handleSearchResultClick({});
        resetForm();
    };

    const handleNew = () => {
        handleCreateNew();
        resetForm();
    };

    return (
        <div className={[
            'SearchDropdown__Wrapper',
            padded ? 'padded' : null,
            showResults ? 'SearchDropdown--open' : '',
            className
        ].filter(i => i).join(' ')}>
            <div className='SearchDropdown__Backdrop' onClick={resetForm} />
            <TextField
                value={!showResults && !!selectedValue ? selectedValue : searchVariables.search}
                onChange={(e) => handleSearch(e)}
                icon={icon}
                placeholder={placeholder}
                label={label}
                onClick={handleCreateNew || !!selectedValue ? () => setShowResults(true) : null}
                clear={!!selectedValue}
                onClear={selectedValue ? () => handleClear() : null}
            />

            {
                (showResults && (!!searchVariables.search || handleCreateNew)) &&
                <div className='SearchDropdown'>
                    <ul className='SearchDropdown__List' ref={searchResultsRef}>
                        {
                            handleCreateNew &&
                            <li className='SearchDropdown__Item SearchDropdown__Item--CreateNew'>
                                <Button
                                    icon='plus'
                                    className='SearchDropdown__Button SearchDropdown__Button--CreateNew'
                                    onClick={() => handleNew()}>{createNewCopy}</Button>
                            </li>
                        }
                        {
                            !!searchResults.length &&
                            <>
                                {searchResults.map((resultData, idx) => {
                                    const optionalProps = idx === searchResults.length - 3 && data?.[_.findKey(data, 'total')]?.total > (searchVariables.limit * searchVariables.page)
                                        ? { ref: loadMoreRef }
                                        : {};
                                    return (
                                        <li className='SearchDropdown__Item' key={resultData.id} {...optionalProps}>
                                            <SearchDowndownItem
                                                resultData={resultData}
                                                onClick={() => handleClick(resultData)}
                                                searchData={searchData}
                                            />
                                        </li>

                                    );
                                }

                                )}
                            </>
                        }
                        {loading &&
                            <li className='SearchDropdown__Item SearchDropdown__Item--loading '>
                                <Loading />
                            </li>}
                    </ul>

                    {
                        (!loading && !searchVariables.search && !!showResults && !searchResults?.length) &&
                        <Caption faded padded className='block SearchDropdown__Item'>{placeholder}</Caption>
                    }

                    {
                        (!loading && !searchResults?.length && !!searchVariables.search) &&
                        <Caption faded padded className='block SearchDropdown__Item'>No Results Found</Caption>
                    }

                </div>
            }

        </div>
    );
};

export default SearchDropdown;

SearchDropdown.defaultProps = {
    icon: 'Search',
    placeholder: 'Search...',
    initialData: [],
    className: '',
    filterBy: 'id',
    createNewCopy: 'Create New'
};

SearchDropdown.propTypes = {
    padded: PropTypes.bool,
    icon: PropTypes.string,
    placeholder: PropTypes.string,
    searchData: PropTypes.object,
    handleSearchResultClick: PropTypes.func,
    initialData: PropTypes.array,
    className: PropTypes.string,
    filterBy: PropTypes.string,
    handleCreateNew: PropTypes.func,
    createNewCopy: PropTypes.string,
    label: PropTypes.string
};

const SearchDowndownItem = ({ resultData, onClick, searchData }) => {
    const thumbnailUrl = getThumbnail(resultData, searchData.imagePath, 96);
    return (
        <Button onClick={onClick} className='SearchDropdown__Button'>
            <img src={thumbnailUrl} alt='' />
            <span className='flex column'>
                <span>{resultData.name}</span>
                {
                    (resultData?.internalTitle) &&
                    <Caption faded>{resultData.internalTitle}</Caption>
                }
                {
                    resultData.type &&
                    <Caption faded className='SearchDropdown__Item--type'>{resultData.type}</Caption>
                }
            </span>

        </Button>
    );
};
