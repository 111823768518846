import { gql } from '@apollo/client';
import { ASSOCIATED_PRESENTATIONS } from '../fragments/associatedPresentation.js';
import { DOCUMENT_HISTORY_FIELDS } from '../fragments/document.js';

export const ADD_ASSOCIATIONS = gql`
	${ASSOCIATED_PRESENTATIONS}
	${DOCUMENT_HISTORY_FIELDS}
	mutation addAssociations($newAssociations: newAssociationsInput, $presentation: PresentationInput, $getAssociations: Boolean) {
		addAssociations(newAssociations: $newAssociations, presentation: $presentation, getAssociations: $getAssociations) {
			seriesId,
			seasonId,
			episodeId,
			associatedPresentations {
				...AssociatedPresentations
			}
			history {
				...DocumentHistoryFields
			}
		}
	}
`;

export const REMOVE_ASSOCIATIONS = gql`
	mutation removeAssociations($id: ID!, $type: String, $associations: PresentationAssociationsInput) {
        removeAssociations(id: $id, type: $type, associations: $associations) {
			message
		}
	}
`;

export const GET_ASSOCIATIONS = gql`
	${ASSOCIATED_PRESENTATIONS}
	query getAssociations($associations: PresentationAssociationsInput) {
		getAssociations(associations: $associations) {
			...AssociatedPresentations
		}
	}
`;
