export const filtersConfig = {
    files: [
        {
            id: 'types',
            type: 'checkboxList',
            values: ['video', 'audio', 'subtitle', 'image', 'atmos', 'dolby', 'xml', 'unknown', 'unsupported']
        },
        {
            id: 'statuses',
            type: 'checkboxList',
            values: ['uploading', 'new', 'processing', 'complete', 'error', 'rejected']
        },
        {
            id: 'aclTypes',
            label: 'Group Access',
            type: 'acl'
        }
    ],
    presentations: [{
        id: 'types',
        type: 'checkboxList',
        values: ['movie', 'episode', 'promo', 'series', 'public']
    },
    {
        id: 'aclTypes',
        label: 'Group Access',
        type: 'acl'
    }],
    events: [
        {
            id: 'aclTypes',
            label: 'Group Access',
            type: 'acl'
        },
        {
            filterIds: ['startAtFrom', 'startAtTo'],
            type: 'dateRange',
            id: 'startAt',
            label: 'Start Date',
            filterValues: {
                startAtFrom: null,
                startAtTo: null
            }
        },
        {
            filterIds: ['endAtFrom', 'endAtTo'],
            type: 'dateRange',
            id: 'endAt',
            label: 'End Date',
            filterValues: {
                endAtFrom: null,
                endAtTo: null
            }
        },
        {
            id: 'statuses',
            type: 'checkboxList',
            values: ['requested', 'ready', 'in progress', 'completed', 'unpublished']
        },
        {
            id: 'approvalSourceTypes',
            label: 'Approval Source',
            type: 'checkboxList',
            values: ['GSS', 'FP', 'SP', 'Other']
        }
    ]
};
