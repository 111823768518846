import { useContext, useMemo } from 'react';
import Typography, { Heading, Body, Caption } from '../modules/Typography';
import Button from '../modules/Button';
import IconButton from '../modules/IconButton';
import Table, { columnHelper } from '../modules/Table';
import { SnackbarContext } from '../../contexts/SnackbarContextProvider';
import { AsperaUploadContext } from './AsperaUploadDialog';

const AsperaUploadTable = props => {
    const { errorSnackbar } = useContext(SnackbarContext);
    const { uploadFiles, setUploadFiles, acceptedFileTypes, handleFiles } = useContext(AsperaUploadContext);

    const removeFile = (row) => {
        const { name } = row.original ?? {};
        if (!name) return errorSnackbar({ text: 'Error Removing File' });
        setUploadFiles(uploadFiles?.filter(uploadFile => uploadFile.name !== name));
    };

    const columns = useMemo(
        () => [
            columnHelper.accessor('name', {
                header: `Files (${uploadFiles?.length})`,
                cell: ({ getValue }) => {
                    const val = getValue();
                    return val.split('\\').pop().split('/').pop();
                },
                meta: {
                    className: 'flex-grow'
                }
            }),
            columnHelper.accessor('delete', {
                header: '',
                id: 'delete',
                cell: ({ row }) => <IconButton name='trash-can' onClick={() => removeFile(row)} />,
                meta: {
                    className: 'IconButtonCell'
                }
            })
        ],
        [uploadFiles]
    );

    const clearFiles = () => setUploadFiles([]);

    return (
        <div className='AsperaUpload__Dialog--Wrapper flex center align-center column'>
            <div className='AsperaUpload__Dialog--Header padded stretch-width rounded flex column center align-center' onClick={() => handleFiles()}>
                <Heading number={5}>Drop new files here to upload</Heading>
                <div className='flex center align-center'>
                    <Body>or</Body>&nbsp;
                    <Typography type='button' color='primary'>browse</Typography>
                </div>
            </div>
            {uploadFiles?.length > 0 &&
            <>
                <div className='AsperaUpload__Table--Wrapper'>
                    <Table
                        className='AsperaUpload__Table'
                        columns={columns}
                        data={uploadFiles} />
                </div>
                <Button type='primary' className='half-spacing__bottom' onClick={clearFiles}>Clear Files</Button>
            </>}

            <Caption className='spacing__top spacing__bottom'> Accepted file types: {acceptedFileTypes.map(type => ` .${type}`)}.</Caption>
        </div>
    );
};

export default AsperaUploadTable;
