import { gql } from '@apollo/client';

export const ASPERA_UPLOAD_SPEC = gql`
    mutation asperaUploadSpec($file: AsperaFileInput){
		asperaUploadSpec(file: $file) {
            assets
            transferResponse
        }
	}
`;
