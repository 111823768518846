import { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Select from '../Select';
import { DataContext } from '../../../contexts/DataContext';
import { Caption } from '../Typography';

const ContentWatermark = ({ content, handleUpdate }) => {
    const { watermarkType, watermarkLocation, watermarkPersistence } = content;
    const { appConfig } = useContext(DataContext);
    const { watermarkTypes } = appConfig ?? {};
    const [watermark, setWatermark] = useState({ watermarkType, watermarkLocation, watermarkPersistence });

    useEffect(() => setWatermark({ watermarkType, watermarkLocation, watermarkPersistence }), [watermarkType, watermarkLocation, watermarkPersistence]);

    const [invalidWatermark, setInvalidWatermark] = useState(false);

    useEffect(() => setWatermark({ watermarkType, watermarkLocation, watermarkPersistence }), [watermarkType, watermarkLocation, watermarkPersistence]);

    const watermarkFields = [
        {
            id: 'watermarkType',
            label: 'Type',
            defaultValue: '',
            appConfigData: appConfig.watermarkingType
        },
        {
            id: 'watermarkLocation',
            label: 'Location',
            defaultValue: '',
            appConfigData: appConfig.watermarkingLocation
        },
        {
            id: 'watermarkPersistence',
            label: 'Persistence',
            defaultValue: '',
            width: '33',
            appConfigData: appConfig.watermarkingPersistence
        }
    ];

    const handleChange = (value, id) => {
        const updatedWatermark = { ...watermark, [id]: value };
        setWatermark(updatedWatermark);
        const currentWatermark = Object.values(updatedWatermark).filter(v => !!v);
        const hasType = !watermarkTypes?.some(type => type.id === currentWatermark.join('-'));
        const invalidMark = !!currentWatermark.length && hasType;
        setInvalidWatermark(invalidMark);
        const matchesCurrentContent = Object.keys(updatedWatermark).every(key => updatedWatermark[key] === content[key]);
        if (!invalidMark && !matchesCurrentContent) {
            handleUpdate(updatedWatermark);
        }
    };

    return (
        <div className='FormFields__Watermark stretch-width flex flex-wrap padded__bottom'>
            {
                watermarkFields.map(watermarkField =>
                    (
                        <Select
                            key={watermarkField.id}
                            label={watermarkField.label}
                            options={watermarkField.appConfigData?.sort((a, b) => a.order - b.order).map(val => ({ text: val.name ?? val, value: val.id ?? val }))}
                            disabled={!watermarkField.appConfigData?.length}
                            selectedValue={watermark[watermarkField.id] ?? watermarkField.defaultValue}
                            className={`Select__${watermarkField.id} width-33`}
                            onChange={({ value }) => handleChange(value, watermarkField.id)}
                        />
                    )
                )
            }
            {invalidWatermark && <Caption error className='block stretch-width half-spacing__top padded__left'>This watermark combination is not currently supported</Caption>}

        </div>
    );
};

export default ContentWatermark;

ContentWatermark.proptypes = {
    content: PropTypes.object,
    handleUpdate: PropTypes.func
};
