import { useContext } from 'react';
import Checkbox from '../Checkbox';

import { FileProcessingContext } from '../../fileProcessing/FileProcessing';
import { fileProcessingConfig } from '../../../config/fileProcessingConfig';

const FileProcessingDefaultCheckbox = props => {
    const { setProcessingFiles, tableData, setDefaultChecked, defaultChecked } = useContext(FileProcessingContext);
    const { disabledFileTypes } = fileProcessingConfig;

    const handleDefaultCheck = ({ checked }) => {
        setDefaultChecked(checked);
        setProcessingFiles(checked ? tableData.filter(job => !disabledFileTypes.includes(job.type)) : []);
    };

    return (
        <Checkbox
            handleCheck={handleDefaultCheck}
            checked={defaultChecked}
        />
    );
};

export default FileProcessingDefaultCheckbox;
