import { useContext, useEffect } from 'react';
import Accordion from '../modules/Accordion';
import Button from '../modules/Button';
import { UserContext } from '../../contexts/UserContextProvider';
import { SnackbarContext } from '../../contexts/SnackbarContextProvider';

const UserMenu = props => {
    const { user, handleLogout, logoutError } = useContext(UserContext);
    const { errorSnackbar } = useContext(SnackbarContext);

    useEffect(() => {
        if (!logoutError) return;
        errorSnackbar({ text: 'Logout Error. Please try again later.' });
    }, [logoutError]);

    return (
        <Accordion title={`${user?.firstName} ${user?.lastName}`} type='userDetails'>
            <Button type='surface' onClick={handleLogout} className='logout'>Logout</Button>
        </Accordion>
    );
};

export default UserMenu;

UserMenu.propTypes = {
};
