import { useContext, useState, useMemo } from 'react';
import { useMutation } from '@apollo/client';

import { EventContext } from '../pages/Event';
import Table, { columnHelper } from '../modules/Table';
import TableCheckbox from '../modules/TableCell/TableCheckbox';
import TableCheckboxHeader from '../modules/TableCell/TableCheckboxHeader';
import PresentationDetailsCell from '../modules/TableCell/PresentationDetailsCell';
import PopUpCard from '../modules/PopUpCard';
import IconButton from '../modules/IconButton';
import SubRowToggle from '../modules/TableCell/SubRowToggle';
import DateTimeCell from '../modules/TableCell/DateTimeCell';
import EventContentSubRowV2 from './EventContentSubRowV2';
import DashboardMenu from '../modules/DashboardMenu';
import { DialogContext } from '../../contexts/DialogContextProvider';
import EventContentBulkEdits from './EventContentBulkEdits';
import { SnackbarContext } from '../../contexts/SnackbarContextProvider';
import PresentationDeepLink from '../presentation/PresentationDeepLink';
import { Subtitle } from '../modules/Typography';

import { EVENT_REMOVE_CONTENT } from '../../queries';
import { EventContentTabContext } from './EventContentTab';
import { EventTabsContext } from './EventTabs';
import TextLink from '../modules/TextLink';

const EventContentTableV2 = ({ eventContent, relatedContentParent }) => {
    const { handleGetContent, handleLoadMore, handleSortContent, contentSort, paginationData, loading } = useContext(EventContentTabContext);
    const { contentVariables } = useContext(EventTabsContext) ?? {};
    const { makeDialog, deleteDialog, closeDialog } = useContext(DialogContext);
    const { initEvent, setInitEvent, event, setEvent, handleRefetchEvent } = useContext(EventContext);
    const { successSnackbar, errorSnackbar } = useContext(SnackbarContext);

    const [sorting, setSorting] = useState([{ id: 'startAt', desc: true }]);

    const handleSort = (id, isSorted) => {
        let desc = true;
        if (!isSorted || isSorted === 'desc') desc = false;
        setSorting([{ id, desc }]);
    };

    const [removeEventContent] = useMutation(EVENT_REMOVE_CONTENT);

    const [selectedContent, setSelectedContent] = useState([]);

    const renderSubComponent = (row, subRow, id) => (
        <EventContentSubRowV2
            row={row}
            subRow={subRow}
            id={id}
            relatedContentParent={relatedContentParent}
        />
    );

    const handleRemoveContent = async (content) => {
        const removeMessaging = relatedContentParent
            ? {
                error: `Failed to remove Related Content from ${relatedContentParent.name}`,
                success: `Successfully removed Related Content from ${relatedContentParent.name}`
            }
            : {
                error: 'Error removing content',
                success: `Successfully removed ${content.length} piece${content.length > 1 ? 's' : ''} of content `
            };
        deleteDialog({
            type: 'Content',
            action: 'Remove',
            confirmAction: 'Removed',
            name: content.map((content) => content.name),
            size: 'medium',
            className: 'EventContent__Delete',
            handleCancel: () => {
                setSelectedContent([]);
                closeDialog();
            },
            deleteMutation: async () => {
                const variables = {
                    id: event.id,
                    data: content.map(c => c.id)
                };

                const { data } = await removeEventContent({ variables });
                if (data.error) {
                    errorSnackbar({ text: removeMessaging.error });
                } else {
                    successSnackbar({ text: removeMessaging.success });
                    const updatedEvent = await handleRefetchEvent();
                    await handleGetContent(null, true, updatedEvent, true);
                }
                setSelectedContent([]);
                closeDialog();
            }
        });
    };

    const tableData = eventContent?.map(content => {
        return {
            ...content,
            startAt: content.startAt,
            endAt: content.endAt,
            subRows: [
                {
                    id: 'details',
                    renderSubComponent: (row, subRow, id) => renderSubComponent(row, subRow, id)
                },
                {
                    id: 'relatedContent',
                    label: `Related Content (${content.relatedContent?.length || 0})`,
                    renderSubComponent: (row, subRow, id) => renderSubComponent(row, subRow, id)
                }
            ]
        };
    }) ?? [];

    const columns = useMemo(
        () => [
            columnHelper.display({
                header: TableCheckboxHeader,
                id: 'checkbox',
                cell: TableCheckbox,
                enableSorting: false,
                size: 50,
                meta: {
                    className: 'CheckboxCell'
                }
            }),
            columnHelper.accessor('name', {
                header: 'content name',
                cell: PresentationDetailsCell,
                size: 250,
                meta: {
                    className: 'flex-grow'
                }
            }),
            columnHelper.accessor('approvalSourceId', {
                header: 'Source Id',
                size: 125
            }),
            columnHelper.accessor('tags', {
                header: 'Event Tags',
                size: 125,
                enableSorting: false,
                cell: ({ getValue, row }) => <PopUpCard chips={getValue()} cardTitle={row.original.name} listTitle='Event Tags' />,
                meta: {
                    className: 'overflow--visible'
                }
            }),
            columnHelper.accessor('seriesName', {
                header: 'series name',
                meta: {
                    className: 'flex-grow'
                },
                cell: ({ getValue, row }) => {
                    const { associatedSeries } = row?.original?.associatedPresentations || {};
                    const name = associatedSeries?.name ?? getValue() ?? 'N/A';
                    return name !== 'N/A'
                        ? <div className='flex PresentationDetailsCell align-flex-start'>
                            <div className='PresentationDetailsCell__Content'>
                                <TextLink to={`/presentation/${row?.original?.seriesPresentationId}`} target='_blank'>{name}</TextLink>
                            </div>
                        </div>
                        : name;
                }
            }),
            columnHelper.accessor('startAt', {
                header: 'Start Date',
                cell: DateTimeCell,
                size: 150
            }),
            columnHelper.accessor('endAt', {
                header: 'End Date',
                cell: DateTimeCell,
                size: 150
            }),
            columnHelper.accessor('details', {
                header: '',
                cell: SubRowToggle,
                size: 135
            }),
            columnHelper.accessor('relatedContent', {
                header: '',
                cell: SubRowToggle,
                size: 201
            }),
            columnHelper.display({
                id: 'deepLink',
                cell: ({ row }) => <PresentationDeepLink presentation={row.original} />,
                size: 50,
                meta: {
                    className: 'IconCell'
                }
            }),
            columnHelper.display({
                id: 'delete',
                cell: ({ row }) => <IconButton name='trash-can' onClick={() => handleRemoveContent([row.original])} />,
                size: 50,
                meta: {
                    className: 'IconCell'
                }
            })
        ], [initEvent]);

    const initialState = {
        expanded: {},
        sorting: contentSort ?? sorting
    };

    const handlePostBulk = async (contentDetails) => {
        const updatedEvent = await handleRefetchEvent();

        const currentContent = [...updatedEvent.eventContent];

        [...selectedContent].forEach(content => {
            let tags = content?.tags ?? [];

            if (contentDetails.tags?.length) {
                switch (contentDetails.tagAction) {
                case 'replace':
                    tags = contentDetails.tags;
                    break;
                case 'add':
                    tags = [...new Set([...tags, ...contentDetails.tags])];
                    break;
                case 'remove':
                    tags = tags.filter(tag => !contentDetails.tags.some(removeTag => removeTag === tag));
                    break;
                default:
                    break;
                }
            }

            const details = {
                ...contentDetails,
                tags: tags.length ? tags : null
            };

            delete details.tagAction;

            let updatedRelated;
            if (content.relatedContent?.length) {
                updatedRelated = [...content.relatedContent].map(related => ({ ...related, ...details }));
            }

            const updatedContent = {
                ...content,
                ...details,
                relatedContent: updatedRelated
            };

            const index = currentContent.findIndex(c => c.id === content.id);
            currentContent[index] = updatedContent;
        });

        setInitEvent({ ...updatedEvent, eventContent: currentContent });
        setSelectedContent([]);
    };

    const handleBulkContentEdits = (content) => {
        makeDialog({
            dialog: <EventContentBulkEdits
                event={event}
                setEvent={(updatedEvent) => setEvent(updatedEvent)}
                parentDetails={relatedContentParent}
                handlePostConfirm={handlePostBulk}
                handlePostCancel={() => setSelectedContent([])}
                selectedContent={content}
            />,
            disableCloseOnClick: true,
            size: 'xl',
            className: 'EventContent__BulkEdits manual-overflow'
        });
    };

    const editMenu = [{
        text: 'Edit',
        icon: 'edit',
        onClick: () => handleBulkContentEdits(selectedContent)
    }];

    const menuData = {
        closeMenu: () => setSelectedContent([]),
        numberOfItems: selectedContent.length,
        actions: [
            ...(!relatedContentParent ? editMenu : []),
            {
                text: 'Delete',
                icon: 'trash-can',
                onClick: () => handleRemoveContent(selectedContent)
            }
        ]
    };

    return (
        <>
            <Table
                className='EventContent__Table'
                data={tableData}
                columns={columns}
                setSelectedItems={setSelectedContent}
                selectedItems={selectedContent}
                renderSubComponent={renderSubComponent}
                initialState={initialState}
                columnVisibility={{ relatedContent: !relatedContentParent, deepLink: !relatedContentParent }}
                handleSort={!relatedContentParent ? handleSortContent : handleSort}
                handleLazyLoad={!relatedContentParent ? handleLoadMore : null}
                paginationData={!relatedContentParent ? paginationData : null}
            />
            {(!relatedContentParent && !!contentVariables.search && !eventContent.length && !loading) &&
                <Subtitle className='flex center padded'>No results found for search term: <span className='bold half-spacing__left'> {contentVariables.search}</span></Subtitle>}
            <DashboardMenu menuData={menuData} />
        </>
    );
};

export default EventContentTableV2;
