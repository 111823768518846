/* eslint-disable indent */
import { useState, useContext, createContext, useEffect, useRef } from 'react';
import { useMutation } from '@apollo/client';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { SEARCH_PRESENTATIONS, FILE_UPLOAD_PROCESS, UPDATE_PRESENTATION } from '../../queries';
import { SnackbarContext } from '../../contexts/SnackbarContextProvider';
import { DialogContext } from '../../contexts/DialogContextProvider';
import AsperaUploadTable from './AsperaUploadTable';
import DialogFooter from '../modules/DialogFooter';
import AsperaUploadConfirm from './AsperaUploadConfirm';
import ACLSelect from '../modules/ACLSelect';
import { UserContext } from '../../contexts/UserContextProvider';
import { authorized, subjectsFromUser, hasACLAccess, mergeAcl, removeAcl } from '../../utils/acl';
import { DataContext } from '../../contexts/DataContext';
import UploadDropzone from '../modules/UploadDropzone';
import Select from '../modules/Select';
import SearchDropdown from '../modules/SearchDropdown';
import CreatePresentation from '../modules/CreatePresentation';
import checkUserRole from '../../hooks/checkUserRole';
import FileProcessingDialog from '../fileProcessing/FileProcessingDialog';
import makeCommitMessage from '../../utils/makeCommitMessage';

export const AsperaUploadContext = createContext();

const AsperaUploadDialog = ({ handlePostUpload, handlePostCancel, initialFiles, acceptedFileTypes, presentation }) => {
    const [fileUploadProcess] = useMutation(FILE_UPLOAD_PROCESS);
    const [updatePresentation] = useMutation(UPDATE_PRESENTATION);

    const { errorSnackbar, warningSnackbar } = useContext(SnackbarContext);
    const { closeDialog, setDialogProps } = useContext(DialogContext);
    const { user } = useContext(UserContext);
    const { appConfig, asperaWeb } = useContext(DataContext);

    const [presentationDetails, setPresentationDetails] = useState(presentation);
    const [createNewPresentation, setCreateNewPresentation] = useState(false);

    const [uploadFiles, setUploadFiles] = useState([...(initialFiles ?? [])]);
    const [acl, setAcl] = useState(presentation?.acl ?? []);
    const [aclType, setAclType] = useState(presentation?.aclType ?? []);
    const uploadFilesRef = useRef(uploadFiles);

    const [showConfirm, setShowConfirm] = useState(false);
    const [failedFiles, setFailedFiles] = useState([]);
    const [transferedFiles, setTransferedFiles] = useState(0);
    const [showFileProcess, setShowFileProcess] = useState(false);
    const [processingFiles, setProcessingFiles] = useState([]);

    const [isUploading, setIsUploading] = useState(false);
    const userACLAccess = !hasACLAccess(user, acl);
    const disableUpload = isUploading || !uploadFiles?.length || !authorized(acl, ['R'], subjectsFromUser(user)) || userACLAccess;

    const isProducer = checkUserRole('ROLE_PRODUCER');
    const shouldShowFileProcessing = uploadFiles.some(file => file.type.match('image.*')) && presentationDetails;
    const allowSeries = uploadFiles.every(file => file.type.indexOf('image') !== -1);

    const handleACL = (selectedACLs, combinedAcl) => {
        setAcl(combinedAcl);
        setAclType(selectedACLs);
    };

    useEffect(() => {
        const transferEvent = window.AW4.Connect.EVENT.TRANSFER;
        if (asperaWeb) {
            asperaWeb.addEventListener(transferEvent, (type, { transfers }) => {
                if (type === transferEvent && transfers.length) {
                    setProcessingFiles(prevState => [...prevState].map(file => ({
                        ...file,
                        transferStatus: transfers.find(transfer => transfer.explorer_path === file.name)?.status ?? file.transferStatus
                    })));
                }
            });
        }
        return () => asperaWeb.removeEventListener(transferEvent);
    }, [asperaWeb]);

    useEffect(() => {
        if (!initialFiles?.length) return;
        setUploadFiles(initialFiles);
    }, [initialFiles]);

    useEffect(() => {
        setPresentationDetails(presentation);
    }, [presentation]);

    useEffect(() => {
        if (!presentationDetails) return;
        const { acl: presentationAcl, aclType: presentationAclType } = presentationDetails ?? {};
        if (!presentationAcl?.length && !presentationAclType?.length) return;
        setAcl(presentationAcl);
        setAclType(presentationAclType);
    }, [presentationDetails]);

    useEffect(() => {
        if (!processingFiles.length && showFileProcess) {
            if (handlePostUpload) handlePostUpload();
            handleClose();
        };
    }, [processingFiles]);

    useEffect(() => {
        if (showFileProcess) setDialogProps(prevProps => ({ ...prevProps, size: 'xl' }));
    }, [showFileProcess]);

    useEffect(() => {
        uploadFilesRef.current = uploadFiles;
        if (!allowSeries && presentationDetails?.type === 'series' && !presentation?.id) {
            warningSnackbar({ text: 'Invalid Files for a Series.' });
            const { updatedAcl, updatedAclType } = removeAcl(aclType, presentationDetails?.aclType, appConfig.ACLs);
            setAcl(updatedAcl);
            setAclType(updatedAclType);
            setPresentationDetails({});
        }
    }, [uploadFiles]);

    const addFilesTouploadFiles = async (files) => {
        const currentFiles = [...(uploadFilesRef.current ?? [])];
        const uniqueFiles = _.uniqBy([...currentFiles, ...files], (item) => {
            return item.name;
        }).map(file => {
            const extension = file.name.split('.').pop();
            const lowerCasedExtension = extension.toLowerCase();
            file.name = file.name.replace(extension, lowerCasedExtension);
            return file;
        });

        if (!uniqueFiles.length) {
            return errorSnackbar({ text: 'Invalid File. Removed files with duplicate names.' });
        } else {
            setUploadFiles(uniqueFiles);
        }
    };

    const handleUpload = async () => {
        setIsUploading(true);
        const uploadedFiles = await sendFiles();
        if (handlePostUpload) handlePostUpload();
        setShowConfirm(true);
        setIsUploading(false);
        return uploadedFiles ?? [];
    };

    const handleFiles = () => {
        const addFilesToQueue = paths => {
            const { files } = paths.dataTransfer;
            if (files.length) {
                addFilesTouploadFiles(files);
            }
        };
        // Adding addtional extension codes for aspera
        const acceptedExtensions = [...acceptedFileTypes, 'damf', 'ec-3', 'dlbe', 'ec3'];

        return asperaWeb.showSelectFileDialog({ success: addFilesToQueue }, {
            title: 'Select Content Files',
            allowMultipleSelection: true,
            allowedFileTypes: [
                {
                    filter_name: 'Content Files',
                    extensions: acceptedExtensions
                }
            ]
        });
    };

    const sendFiles = async () => {
        let uploadedFiles = [];
        try {
            const presentationId = presentationDetails?.id;
            const { data } = await fileUploadProcess({
                variables: {
                    uploadFiles,
                    acl,
                    aclType,
                    presentationId
                }
            });
            uploadedFiles = data.fileUploadProcess;
            for (const file of uploadedFiles) {
                const { transferResponse } = file;
                await asperaWeb.startTransfer(transferResponse, { allow_dialogs: 'no' });
                setTransferedFiles(files => files + 1);
            }
            await updateAclsOnPresentation();
            setFailedFiles(uploadFiles.filter(file => !!file.error));
        } catch (error) {
            console.log(error);
        };
        return uploadedFiles.filter(file => !file.error);
    };

    const handleRunJob = async () => {
        setIsUploading(true);
        const uploadedFiles = await sendFiles();
        const filesToProcess = await Promise.all(uploadedFiles
            .filter(file => !!file.type.match('image.*'))
            .map(async file => {
                return ({
                    ...file,
                    disabled: false,
                    type: 'image',
                    fileDefaults: {
                        fileType: 'image',
                        imageType: 'hero',
                        jobType: 'standard',
                        serveAs: 'default'
                    }
                });
            })
        );
        const filesWithProcessDetails = uploadedFiles.map(file => ({
            ...(
                filesToProcess.find(findingFile => findingFile.name === file.name) ?? { ...file, disabled: true }
            )
        })).sort(file => !file.disabled);
        setProcessingFiles(filesWithProcessDetails);
        setIsUploading(false);
        if (filesToProcess.length) return setShowFileProcess(true);
    };

    const handleClose = () => {
        setPresentationDetails(presentation);
        setUploadFiles([]);
        setAcl(presentation?.acl);
        setAclType(presentation?.aclType);
        setCreateNewPresentation(false);
        closeDialog();
        setShowConfirm(false);
        setShowFileProcess(false);
        setIsUploading(false);
    };

    const handleCancel = () => {
        if (handlePostCancel) handlePostCancel();
        handleClose();
    };

    const handleACLRemove = () => {
        const { updatedAcl, updatedAclType } = removeAcl(aclType, presentationDetails?.aclType, appConfig.ACLs);
        setAcl(updatedAcl);
        setAclType(updatedAclType);
    };

    const handlePresentation = (presentation) => {
        if (_.isEmpty(presentation) && !createNewPresentation) handleACLRemove();
        setPresentationDetails(presentation);
        setCreateNewPresentation(false);
    };

    const handleCreateNew = () => {
        if (!_.isEmpty(presentationDetails)) handleACLRemove();
        setCreateNewPresentation(true);
    };

    const handleCreatedPresentation = presentation => {
        setPresentationDetails(presentation);
        setCreateNewPresentation(false);
    };

    const updateAclsOnPresentation = async () => {
        const updatedAcls = mergeAcl(acl ?? [], presentationDetails?.acl);
        const uniqTypes = _.uniq([...(aclType ?? []), ...presentationDetails?.aclType]);
        const updatedPresentation = { ...presentationDetails, acl: updatedAcls, aclType: uniqTypes };
        const { message, diff } = await makeCommitMessage(presentationDetails, updatedPresentation);
        const variables = {
            id: presentationDetails.id,
            type: presentationDetails.type.toLowerCase(),
            commitMessage: message,
            diff,
            data: updatedPresentation
        };
        await updatePresentation({ variables, errorPolicy: 'all' });
    };

    return (
        <AsperaUploadContext.Provider value={{
            uploadFiles,
            setUploadFiles,
            acceptedFileTypes,
            failedFiles,
            transferedFiles,
            handleFiles,
            handleClose,
            presentationDetails,
            setPresentationDetails
        }}>
            {createNewPresentation
                ? <CreatePresentation
                        initAcl={acl}
                        initAclType={aclType}
                        cancelCopy='Back'
                        handlePostCreate={(_, createdPresentation) => handleCreatedPresentation(createdPresentation)}
                        handleBack={() => setCreateNewPresentation(false)}
                />
                : showFileProcess
                ? <FileProcessingDialog
                        processingFiles={processingFiles}
                        setProcessingFiles={setProcessingFiles}
                        presentation={presentationDetails}
                        />
                : !showConfirm
                    ? <UploadDropzone className='AsperaUpload__Dropzone' multipleFiles acceptedFiles={acceptedFileTypes.join(',')} handleFile={(files) => addFilesTouploadFiles(files)} asperaWeb={asperaWeb} isSeries={presentation?.type === 'series'}>
                        <div className={`padded AsperaUpload__Content ${createNewPresentation ? 'AsperaUpload__Content--Scroll' : ''}`}>
                            <AsperaUploadTable />
                            {
                                presentation
                                    ? <Select
                                            label='Presentation'
                                            selectedValue={presentationDetails?.id}
                                            options={[{ value: presentationDetails?.id, text: presentationDetails?.name }]}
                                            disabled />
                                    : <SearchDropdown
                                            className='spacing__bottom'
                                            label='Presentation'
                                            searchData={{
                                            gqlQuery: SEARCH_PRESENTATIONS,
                                            dataPath: 'allPresentations.presentations',
                                            types: allowSeries ? 'episode,promo,public,movie,series' : 'episode,promo,public,movie',
                                            addtionalVariables: {
                                                fetchAssociations: false,
                                                resetOnSelect: true
                                            }
                                        }}
                                            placeholder='Search for presentations...'
                                            handleSearchResultClick={(presentation) => handlePresentation(presentation)}
                                            handleCreateNew={isProducer ? () => handleCreateNew() : null}
                                            createNewCopy='Create New Presentation'
                                            selectedValue={createNewPresentation ? 'Create New Presentation' : presentationDetails?.name}
                                    />
                            }
                            <ACLSelect onChange={handleACL} initACLs={aclType} initAccess={acl} />

                        </div>
                        <DialogFooter
                            handleCancel={handleCancel}
                            handleConfirm={shouldShowFileProcessing ? handleRunJob : handleUpload}
                            confirmText={shouldShowFileProcessing ? 'Upload and Continue' : 'Upload'}
                            loading={isUploading}
                            disabled={disableUpload}
                        />
                    </UploadDropzone>
                    : <AsperaUploadConfirm />}
        </AsperaUploadContext.Provider>
    );
};

AsperaUploadDialog.defaultProps = {
    acceptedFileTypes: ['mp4'],
    initialFiles: []
};

AsperaUploadDialog.propTypes = {
    handlePostUpload: PropTypes.func,
    initialFiles: PropTypes.any,
    acceptedFileTypes: PropTypes.array
};

export default AsperaUploadDialog;
