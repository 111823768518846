import TopAppBar from '../modules/TopAppBar';
import { Body, Heading, Subtitle } from '../modules/Typography';
import IconButton from '../modules/IconButton';
import Table, { columnHelper } from '../modules/Table';
import ButtonDownloadCSV from '../modules/ButtonDownloadCSV';

const CodeBatchErrors = ({ errors, handleClose, handlePostDownload, createOnly }) => {
    const invalidCodes = errors.invalidCodes?.map(invalidCode => ({ code: invalidCode, error: 'invalid for current policy' })) ?? [];
    const existingCodes = errors.existingCodes?.map(existingCode => ({ code: existingCode, error: 'code in use' })) ?? [];

    const tableData = [...invalidCodes, ...existingCodes];
    const largeErrorBatch = tableData.length > 10;

    const columns = [
        columnHelper.accessor('code'),
        columnHelper.accessor('error', {
            meta: {
                className: 'flex-grow'
            }
        })
    ];

    return (
        <div className={`CodeErrors--Dialog ${createOnly ? 'CodeErrors--Dialog__createOnly' : ''}`}>
            <TopAppBar static className='flex' padded={false}>
                <Heading number={6} className='padded__left'>Code Upload Errors</Heading>
                <IconButton onClick={handleClose} name='close-round' />
            </TopAppBar>
            <div className='spacing CodeErrors--Dialog__Content flex-grow'>
                {
                    largeErrorBatch
                        ? <div className='CodeErrors--LargeBatch'>
                            <Subtitle number={1} className='spacing__bottom'>Total Errors: {existingCodes.length + invalidCodes.length}</Subtitle>
                            {!!existingCodes.length && <Body number={2}>Existing Codes: {existingCodes.length} </Body>}
                            {!!invalidCodes.length && <Body number={2}>Invalid Codes: {invalidCodes.length} </Body>}
                            <Body number={2} className='spacing__top'>For futher details download the CSV</Body>
                        </div>
                        : <Table columns={columns} data={tableData} className='CodeErrors--Table' />
                }
            </div>

            <div className='padded flex flex-end'>
                <ButtonDownloadCSV CSVData={tableData} CSVHeaders={['code', 'error']} CSVName='CodeUploadErrors' handlePostDownload={handlePostDownload} />
            </div>
        </div>
    );
};

export default CodeBatchErrors;
