import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import TextField from './TextField';
import { Heading, Subtitle, Body } from './Typography';
import { formErrorMessages } from '../../config/formConfig';
import Select from './Select';
import { DataContext } from '../../contexts/DataContext';

import { formValidation } from '../../utils/validation';
import CustomDatePicker from './CustomDatePicker';
import Radio from './Radio';
import FileUploadButton from './FileUploadButton';

const Form = ({ formFields, formData, setFormData, handleFormData, handleFile }) => {
    const { appConfig } = useContext(DataContext);
    const [formErrors, setFormErrors] = useState({});

    const updateFormData = (value, field) => {
        const { id, validation } = field;
        const updatedFormData = {
            ...formData,
            [id]: value
        };
        handleFormData ? handleFormData(updatedFormData) : setFormData(updatedFormData);

        if (validation) {
            const updatedErrors = validation.reduce((acc, currValidation) => {
                const hasError = formValidation(currValidation, value);
                const currFieldErrors = acc[id] || {};
                return { ...acc, [id]: { ...currFieldErrors, [currValidation]: hasError } };
            }, { ...formErrors });
            setFormErrors(updatedErrors);
        }
    };

    const renderTextField = (field) => {
        const { id, label, validation, errorMessage } = field;
        const value = formData[id] ?? '';
        const fieldErrors = validation ? [...validation].filter(val => !!formErrors[id]?.[val]) : [];
        const validationErrorMessage = fieldErrors.reduce((acc, fieldError) => formErrorMessages[fieldError] ? acc + '\n' + '\u2022 ' + formErrorMessages[fieldError] : acc, '');
        return (
            <TextField
                key={id}
                id={id}
                label={label}
                error={!!fieldErrors.length}
                errorMessage={errorMessage || validationErrorMessage.trim() || 'Invalid Input'}
                value={value}
                onChange={({ target }) => updateFormData(target.value, field)}
            />
        );
    };

    const renderSelect = (field) => {
        const { id, label, defaultValue, options, appConfigValue } = field;
        const value = formData[id] || defaultValue;
        const selectOptions = options ||
            appConfig[appConfigValue].map(val => ({
                text: val.display || val.id || val.name || val,
                value: val.id || val.name || val
            })
            );
        return (
            <Select
                key={id}
                id={id}
                label={label}
                selectedValue={value}
                options={selectOptions}
            />
        );
    };

    const renderRaidoField = (field) => {
        const { id, options, conditionalFields } = field;

        const value = formData[id];

        return (
            <div key={id} className='Radio__Group'>
                {options.map(option =>
                    <Radio
                        key={option.id}
                        groupName={id}
                        name={option.label}
                        selected={value === option.id}
                        value={option.id}
                        onChange={(value) => setFormData({ ...formData, [id]: value })}
                    />
                )}
                {!!conditionalFields[value] &&
                    <div className='Form__ConditionalFields'>
                        {conditionalFields[value].map(conditionalField => renderFormFields(conditionalField))}
                    </div>}
            </div>
        );
    };

    const renderFormFields = (field) => {
        const { type, copy, id, isRange, label, acceptedFiles, buttonType, padded, className, header, hasDividerAbove, includeTimezone } = field;
        switch (type) {
        case 'title':
            return <Heading key={id}>{copy}</Heading>;
        case 'subtitle':
            return <Subtitle block number={4} fadedHalf key={id} className='spacing__bottom'>{copy}</Subtitle>;
        case 'body':
            return <Body className='spacing__bottom' key={id}>{copy}</Body>;
        case 'text':
            return renderTextField(field);
        case 'select':
            return renderSelect(field);
        case 'dateTime': {
            const { endId } = field;
            return (
                <CustomDatePicker
                    key={id}
                    id={id}
                    endId={endId}
                    onChange={(val) => setFormData({ ...formData, ...val })}
                    isRange={isRange}
                    startLabel={label}
                    timezone={formData.timezone}
                    showTime
                    initStart={formData[id]}
                    initEnd={formData[endId]}
                    includePST
                    includeTimezone={includeTimezone}
                />
            );
        };
        case 'radio':
            return renderRaidoField(field);
        case 'fileUpload':{
            if (header && hasDividerAbove) {
                return (
                    <span className='UploadCodes flex column'>
                        <span className='bold'>{header}</span>
                        <FileUploadButton
                            value={formData.uploadedFile}
                            key={id}
                            buttonCopy={copy}
                            handleFile={(file) => handleFile(file)}
                            acceptedFiles={acceptedFiles}
                            type={buttonType}
                            padded={padded}
                            className={className}
                            disabled={!!formData.uploadedFile}
                            showFile
                        />
                    </span>
                );
            }
            return (
                <React.Fragment>
                    <FileUploadButton
                        value={formData.uploadedFile}
                        key={id}
                        buttonCopy={copy}
                        handleFile={(file) => handleFile(file)}
                        acceptedFiles={acceptedFiles}
                        type={buttonType}
                        padded={padded}
                        className={className}
                    />
                    {
                        formData.uploadedFile &&
                        <Body>File(s): {formData.uploadedFile}</Body>
                    }
                </React.Fragment>
            ); }
        default:
            return false;
        }
    };

    return (
        <div className='Form__Wrapper'>
            <form className='Form'>
                {formFields?.map(field => renderFormFields(field))}
            </form>
        </div>
    );
};

export default Form;

Form.proptypes = {
    formFields: PropTypes.array,
    formData: PropTypes.object,
    setFormData: PropTypes.func,
    handleFormData: PropTypes.func,
    handleFile: PropTypes.func
};
