import { eventConfig } from '../../config/eventConfig';
import _ from 'lodash';

const addNewEventContent = (newContent, event) => {
    const { contentDetails } = eventConfig;

    const updatedEvent = { ...event };
    const { id, presentationId, name, doc, status, internalTitle, acl, aclType, type } = newContent;
    const { associatedSeries } = newContent.associatedPresentations || {};
    const { seriesPresentationId } = newContent.associations ?? {};
    const { images } = doc || {};

    const initPres = {
        presentationId: id ?? presentationId,
        name,
        status,
        images: [...(images ?? [])],
        seriesName: associatedSeries?.name,
        internalTitle,
        acl,
        aclType,
        authorized: true,
        seriesId: null
    };

    if (type === 'episode') {
        initPres.seriesId = seriesPresentationId;
    }

    const updatedContent = contentDetails.reduce((acc, curr) => {
        const newDetail = updatedEvent[curr];
        return newDetail ? { ...acc, [curr]: newDetail } : { ...acc };
    }, { ...initPres });

    const currentContent = updatedEvent.eventContent || [];
    updatedEvent.eventContent = _.uniqBy([...currentContent, updatedContent], 'presentationId');
    return { updatedEvent, updatedContent };
};

export default addNewEventContent;
