import { createContext, useContext, useEffect, useState } from 'react';
import _ from 'lodash';

import { GET_FILE_PROCESSING_INFO } from '../../queries';

import { useLazyQuery } from '@apollo/client';

import { Caption } from '../modules/Typography';

import JobDetails from './fileProcessingJob/JobDetails';
import JobFields from './fileProcessingJob/JobFields';
import JobFileList from './fileProcessingJob/JobFileList';
import JobSubmit from './fileProcessingJob/JobSubmit';

import FileProcessingVideoOrder from './FileProcessingVideoOrder';

import { fileProcessingConfig } from '../../config/fileProcessingConfig';
import { FileProcessingDialogContext } from './FileProcessingDialog';
import { DataContext } from '../../contexts/DataContext';

export const FileProcessingJobContext = createContext();

const FileProcessingJob = props => {
    const { presentationId, processingFiles, setProcessingFiles, selectedPresentation, isVpp, handlePrimaryVideo } = useContext(FileProcessingDialogContext);
    const { asperaWeb } = useContext(DataContext);

    const processingFilesInOrder = _.sortBy(processingFiles.filter(file => file.type !== 'dolby'), [
        function (obj) {
            return _.indexOf(['video', 'audio', 'subtitle', 'image'], obj.type);
        },
        'disabled'
    ]);

    const firstValidFile = processingFilesInOrder.findIndex(file => !file.disabled);

    const [filesToProcess, setFilesToProcess] = useState(processingFilesInOrder);
    const [currentFile, setCurrentFile] = useState(filesToProcess[firstValidFile]);
    const { type, id } = currentFile || {};
    const [videoJobType, setVideoJobType] = useState(null);
    const [initalConfig, setInitalConfig] = useState({});
    const [jobConfig, setJobConfig] = useState({});
    const [errors, setErrors] = useState({});
    const [reprocessFile, setReprocessFile] = useState(false);
    const [getProcessingInformation, {
        data,
        loading: fetchingProcessingInfo,
        errors: processingInfoErrors
    }] = useLazyQuery(GET_FILE_PROCESSING_INFO, {
        variables: {
            id,
            presentationId,
            isVideo: type === 'video',
            isSubtitle: type === 'subtitle',
            isAudio: type === 'audio',
            isImage: type === 'image'
        }
    });

    const { published } = data?.fileProcessingInfo || {};
    const fileDetails = currentFile?.fileDetails ?? data?.fileProcessingInfo?.fileDetails ?? null;
    const fileDefaults = currentFile?.fileDefaults ?? data?.fileProcessingInfo?.fileDefaults ?? null;

    const lastFile = _.last(filesToProcess).id === currentFile.id;
    const dolbyFiles = processingFiles.filter(file => file.type === 'dolby') || [];
    const proxisJob = ['proxisUnprotected', 'proxisShortform'].some(job => videoJobType === job);

    const asperaCompletedStatus = window.AW4.Connect.TRANSFER_STATUS.COMPLETED;
    const loadingProcessingInfo = fetchingProcessingInfo || (currentFile.transferStatus && !(currentFile.transferStatus === asperaCompletedStatus));

    useEffect(() => setJobConfig(initalConfig), [initalConfig]);
    useEffect(() => { if (!currentFile?.fileDefaults) getProcessingInformation(); }, [currentFile.type]);

    useEffect(() => {
        setFilesToProcess(prevState => prevState.map(updateTransferStatus));
        setCurrentFile(updateTransferStatus);
    }, [processingFiles]);

    const updateTransferStatus = file => ({
        ...file,
        transferStatus: processingFiles.find(processingFile => processingFile.id === file.id)?.transferStatus
    });

    const resetJob = (updatedFilesToProcess) => {
        if (lastFile && !reprocessFile) {
            return setProcessingFiles([]);
        }

        setFilesToProcess(updatedFilesToProcess);
        setErrors({});

        if (reprocessFile) {
            setReprocessFile(false);
            setJobConfig(initalConfig);
        } else {
            setJobConfig({});
            setCurrentFile(filesToProcess[filesToProcess.findIndex(file => currentFile.id === file.id) + 1]);
        }
    };

    useEffect(() => {
        if (currentFile.type === 'image' && !currentFile.fileDetails) {
            updateImageDetails(currentFile);
        }
    }, [currentFile]);

    const updateImageDetails = async ({ name, type }) => {
        const imageBase64 = await new Promise((resolve, reject) => asperaWeb
            .readAsArrayBuffer({ path: name }, {
                success: ({ data }) => resolve(data),
                error: reject
            }));
        const imagePreview = `data:${type};base64,${imageBase64}`;
        const img = new Image();
        img.src = imagePreview;
        img.onload = () => {
            const { naturalWidth, naturalHeight } = img;
            setCurrentFile(prevState => ({
                ...prevState,
                fileDetails: {
                    imagePreview,
                    resolution: `${naturalWidth}x${naturalHeight}`
                }
            }));
        };
    };

    const updateVideoDefaults = (fileDefaults) => {
        if (!videoJobType) setVideoJobType(fileDefaults.jobType);
        const customFileDefaults = { ...fileDefaults };
        if (proxisJob && customFileDefaults.fileFormat === 'sd-hd_sdr') {
            customFileDefaults.fileFormat = 'uhd_sdr';
        }
        if (customFileDefaults.fileFormat === 'uhd_dovi' && dolbyFiles.length >= 1) {
            customFileDefaults.dolbyVisionXml = (isVpp) ? dolbyFiles[0].id : dolbyFiles[0].location;
        }
        return customFileDefaults;
    };

    const updateFileDefaults = (fileDefaults) => {
        const customFileDefaults = { ...fileDefaults };
        if (!proxisJob) {
            customFileDefaults.jobType = videoJobType || fileDefaults.jobType;
        } else {
            customFileDefaults.jobType = videoJobType === 'proxisUnprotected' ? 'standard' : 'shortform';
        }
        return customFileDefaults;
    };

    useEffect(() => {
        if (fileDefaults) {
            type === 'video' ? setInitalConfig(updateVideoDefaults({ ...fileDefaults })) : setInitalConfig(updateFileDefaults({ ...fileDefaults }));
        }
    }, [fileDefaults]);

    return (
        <FileProcessingJobContext.Provider value={{
            loadingProcessingInfo,
            fileDetails,
            fileDefaults,
            filesToProcess,
            setFilesToProcess,
            dolbyFiles,
            fileProcessingConfig: fileProcessingConfig[currentFile.videoType === 'dubbing credit' ? 'videoDubCard' : type] || {},
            currentFile: currentFile || {},
            setCurrentFile,
            errors,
            setErrors,
            reprocessFile,
            setReprocessFile,
            setInitalConfig,
            jobConfig,
            setJobConfig,
            resetJob,
            lastFile,
            videoJobType,
            setVideoJobType,
            processingInfoErrors,
            setProcessingFiles
        }}
        >
            <div className='padded FileProcessing__Job'>
                <JobFileList />
                {processingFiles && <>
                    <JobDetails />
                    {(published && jobConfig.jobType === 'shortform') &&
                    <Caption error block className='spacing__top'>
                        Presentation&nbsp;
                        <span className='bold capitalize'>'{selectedPresentation.name}'</span>
                        &nbsp;is already published, this&nbsp;
                        <span className='bold capitalize'>{type}</span>
                        &nbsp;file will be published automatically
                    </Caption>}
                    <JobFields />
                </>}
            </div>
            <JobSubmit />
            {handlePrimaryVideo && <FileProcessingVideoOrder />}
        </FileProcessingJobContext.Provider>
    );
};

export default FileProcessingJob;
