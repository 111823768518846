export const getObjectKeyPaths = (obj, key, prev = '') => {
    const result = [];
    for (const k in obj) {
        const path = prev + (prev ? '.' : '') + k;
        if (k === key) {
            result.push(path);
        } else if (typeof obj[k] === 'object') {
            result.push(...getObjectKeyPaths(obj[k], key, path));
        }
    }
    return result;
};
