import { useMemo, useState, useEffect, useContext } from 'react';
import Checkbox from './Checkbox';
import { Subtitle } from './Typography';
import Table, { columnHelper } from './Table';

import { PresentationContext } from '../pages/Presentation';

const AltIdTable = props => {
    const [defaultAltChecked, setDefaultAltChecked] = useState();
    const [altData, setAltData] = useState([]);
    const [altJobs, setAltJobs] = useState([]);

    const { doc } = useContext(PresentationContext);

    const handleAltCheck = ({ value, checked }) => setAltJobs(checked ? [...altJobs, value] : altJobs.filter(job => job !== value));

    useEffect(() => {
        if (!altData.length) return;
        defaultAltChecked ? setAltJobs(doc.metadata.alternateIds.map(data => data.id)) : setAltJobs([]);
    }, [defaultAltChecked]);

    useEffect(() => { if (!altJobs.length) setDefaultAltChecked(false); }, [altJobs]);

    useEffect(() => {
        if (!doc.metadata?.alternateIds) return;
        const altIdData = doc.metadata.alternateIds.map(altId => {
            return {
                checkbox: <Checkbox handleCheck={handleAltCheck} value={altId.id} checked={altJobs.includes(altId.id)} />,
                ids: altId.id,
                type: altId.type
            };
        });

        setAltData(altIdData);
    }, [doc, altJobs]);

    const altIdsColumns = useMemo(
        () => [
            columnHelper.accessor('ids'),
            columnHelper.accessor('type')
        ],
        [defaultAltChecked]
    );

    return (
        <>
            <Subtitle number={2} block heavy bottomPadded>Alternate IDs</Subtitle>
            <Table
                columns={altIdsColumns}
                data={altData}
                className='AltIdsTable'
            />
        </>
    );
};

export default AltIdTable;
