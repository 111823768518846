import { gql } from '@apollo/client';

export const FILE_DETAILS_FIELDS = gql`
    fragment FileDetailsFields on Details {
        language
        framerate
        firstCueStartInSeconds
        key
        size
        width
        format
        height
        aspectRatio
        isTransparent
        aspectRatioFraction
        imagePreview
        ColorSpace
        resolution
        Channels
        Duration
        timestamp
        location
        presentation
        mediaInfo
    }
`;
