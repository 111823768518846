import { createContext, useContext } from 'react';
import PropTypes from 'prop-types';
import { DataContext } from './DataContext';

export const FeatureFlagContext = createContext();

const FeatureFlagContextProvider = ({ children }) => {
    const { appConfig } = useContext(DataContext);
    const { featureFlags } = appConfig;

    const checkFeatureFlag = (flag) => {
        return featureFlags.some(featureFlag => featureFlag.id === flag && !!featureFlag.active);
    };

    return (
        <FeatureFlagContext.Provider value={{
            checkFeatureFlag
        }}
        >
            {children}
        </FeatureFlagContext.Provider>
    );
};

FeatureFlagContextProvider.propTypes = {
    children: PropTypes.oneOfType([PropTypes.array, PropTypes.object])
};

export default FeatureFlagContextProvider;
