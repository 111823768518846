import { createContext, useContext, useEffect, useState } from 'react';
import _ from 'lodash';
import { useMutation, useLazyQuery } from '@apollo/client';
import EventAudienceTable from './EventAudienceTable';
import DashboardMenu from '../modules/DashboardMenu';
import List from '../modules/List';
import Icon from '../modules/Icon';
import Accordion from '../modules/Accordion';
import { Body, Subtitle } from '../modules/Typography';
import DialogFooter from '../modules/DialogFooter';

import { SnackbarDialogContext } from '../../contexts/SnackbarDialogContextProvider';
import { DialogContext } from '../../contexts/DialogContextProvider';
import { SnackbarContext } from '../../contexts/SnackbarContextProvider';
import { EventContext } from '../pages/Event';
import { EventTabsContext } from './EventTabs';

import { EVENT_UNASSIGN_AUDIENCE, GET_EVENT_AUDIENCE_BY_ID, RESEND_INVITES } from '../../queries';
import checkUserRole from '../../hooks/checkUserRole';
import { checkPlural } from '../../utils/checkPlural';

export const EventAudienceContext = createContext();

const EventAudience = () => {
    const { deleteDialog, makeDialog, closeDialog } = useContext(DialogContext);
    const { snackbarDialog } = useContext(SnackbarDialogContext);
    const { errorSnackbar, successSnackbar, loadingSnackbar, closeSnackbar } = useContext(SnackbarContext);
    const { initEvent, setInitEvent, handleRefetchEvent, refetchAudienceTab, setRefetchAudienceTab } = useContext(EventContext);
    const { id } = initEvent;
    const { audienceVariables, setAudienceVariables, audienceTotal, setAudienceTotal } = useContext(EventTabsContext) ?? {};
    const [audience, setAudience] = useState([]);
    const [selectedAudience, setSelectedAudience] = useState([]);

    const [unassignEventAudience] = useMutation(EVENT_UNASSIGN_AUDIENCE);
    const [resendInvites] = useMutation(RESEND_INVITES);

    const [getEventAudienceById, {
        loading: loadingEventAudienceById
    }] = useLazyQuery(GET_EVENT_AUDIENCE_BY_ID);

    const [customLoading, setCustomLoading] = useState(false);

    const handleGetAudience = async (updatedParams, replaceAudience = true, refetchEvent = true) => {
        if (!updatedParams || updatedParams.limit > 50) loadingSnackbar({ text: 'Fetching Event Audience' });
        setCustomLoading(true);
        const variables = {
            id,
            paramsInput: { ...(updatedParams ?? audienceVariables) }
        };
        try {
            const { data: eventAudienceData } = await getEventAudienceById({ variables });
            const { eventAudience, total } = eventAudienceData?.eventAudienceById;
            let updatedAudience = replaceAudience ? eventAudience : [...(initEvent.eventAudience ?? []), ...eventAudience];
            if (!replaceAudience) updatedAudience = _.uniqBy(updatedAudience, 'id');
            let updatedEvent = null;
            if (refetchEvent) updatedEvent = await handleRefetchEvent();
            setInitEvent({ ...(updatedEvent ?? initEvent), eventAudience: updatedAudience });
            setAudienceTotal(total);
            setRefetchAudienceTab(false);
            setCustomLoading(false);
            closeSnackbar();
        } catch (error) {
            if (error.name !== 'AbortError') {
                setCustomLoading(false);
                console.log(error);
                errorSnackbar({ text: 'Failed to fetch event audience' });
            }
        }
    };

    const handleLoadMore = async () => {
        const { limit, page } = audienceVariables;
        if (page * limit >= audienceTotal || loadingEventAudienceById) return;
        const updatedParams = {
            ...audienceVariables,
            page: page + 1
        };
        await handleGetAudience(updatedParams, false, false);
        setAudienceVariables(updatedParams);
    };

    useEffect(() => {
        if (!initEvent.eventAudience) {
            if (!initEvent.audienceCount) return;
            handleGetAudience();
        } else {
            setAudience(initEvent.eventAudience);
        }
    }, [initEvent.eventAudience]);

    const refetchAudience = async () => {
        const updatedParams = {
            ...audienceVariables,
            limit: initEvent.eventContent?.length ?? audienceVariables.limit,
            page: 1
        };
        handleGetAudience(updatedParams);
    };

    useEffect(() => {
        if (!refetchAudienceTab || !initEvent.audienceCount) return;
        refetchAudience();
    }, [refetchAudienceTab]);

    const handleDeleteError = (error) => {
        snackbarDialog({
            snackbarText: 'Error Removing Audience',
            dialogTitle: 'Event Removing Audience Error',
            graphQLErrors: error?.graphQLErrors
        });
    };

    const handleRemoveAudience = (emailToRemove) => {
        const emailsToRemove = emailToRemove
            ? [emailToRemove.email]
            : selectedAudience.reduce((acc, a) => [...acc, a.email], []);

        deleteDialog({
            type: 'Audience Member',
            action: 'Remove',
            confirmAction: 'Removed',
            name: emailsToRemove,
            size: 'medium',
            handleCancel: () => {
                setSelectedAudience([]);
                closeDialog();
            },
            deleteMutation: async () => {
                try {
                    const variables = { id, eventAudience: emailsToRemove };
                    const { data } = await unassignEventAudience({ variables });
                    const { message, error } = data.unassignEventAudience;
                    if (!!error || !message) {
                        console.log(error);
                        handleDeleteError(error);
                    } else {
                        successSnackbar({ text: `Removed ${emailsToRemove.length} Audience Member(s)` });
                    }
                } catch (error) {
                    console.log(error);
                    handleDeleteError(error);
                }
                await handleGetAudience(audienceVariables);
                setSelectedAudience([]);
                closeDialog();
            }
        });
    };

    const handleSendInvitation = (audience) => {
        const audiences = audience ? [audience] : selectedAudience;
        const resendStatus = ['new', 'expired', 'sent', 'scheduled'];
        const checkInvitations = audiences.reduce((acc, audience) => {
            return resendStatus.includes(audience.status.toLowerCase())
                ? { ...acc, canResend: [...acc.canResend, audience] }
                : { ...acc, cannotResend: [...acc.cannotResend, audience] };
        }, { canResend: [], cannotResend: [] });
        const resends = checkInvitations.canResend;
        const failedResends = checkInvitations.cannotResend;

        const checkResendIsPlural = checkPlural('audience member', resends);
        const checkFailedResendIsPlural = checkPlural('audience member', failedResends);
        makeDialog({
            title: 'Resend Invite',
            dialog: <>
                <div className='padded__left padded__right DialogPresentationsBulk__Content'>
                    {!!resends.length &&
                    <Subtitle number={5} className='spacing__bottom block DialogPresentationsBulk__Warning'>
                        Invites will be sent immediately. Are you sure you want to continue?
                    </Subtitle>}
                    {!!resends.length &&
                    <Accordion
                        title={`Show ${checkResendIsPlural}`}
                        closeTitle={`Hide ${checkResendIsPlural}`}
                        type='advancedDetails'
                        headerContent={<Body number={1}>{resends.length} {checkResendIsPlural} will be resent invites immediately</Body>}>
                        <List items={resends.map(pres => ({ name: pres.email }))} className='PresentationList' />
                    </Accordion>}
                    {!!failedResends.length &&
                    <Accordion
                        type='advancedDetails'
                        title={`Show ${checkFailedResendIsPlural}`}
                        closeTitle={`Hide ${checkFailedResendIsPlural}`}
                        headerContent={
                            <Body number={1}>
                                <Icon name='warning' color='warning' className='half-spacing__right block' />
                                {failedResends.length} {checkFailedResendIsPlural} {failedResends.length > 1 ? 'are' : 'is'} not eligible to receive Invites due to their account status.
                            </Body>
                        }>
                        <List items={failedResends.map(pres => ({ name: pres.email }))} className='PresentationList' />
                    </Accordion>}
                </div>
                <DialogFooter
                    setLoading
                    confirmText={resends.length ? 'Send' : 'Ok'}
                    handleConfirm={async () => {
                        if (resends.length) {
                            try {
                                const resendEmails = resends.map(a => a.email);
                                await resendInvites({ variables: { id, emails: resendEmails } });
                                successSnackbar({ text: `${resends.length} ${checkPlural('Invite', resends)} Sent` });
                            } catch (error) {
                                snackbarDialog({
                                    snackbarText: 'Error Resending Invites',
                                    dialogTitle: 'Event Resend Invites Error',
                                    graphQLErrors: error?.graphQLErrors
                                });
                            }
                            setSelectedAudience([]);
                        }
                        closeDialog();
                    }}
                />
            </>,
            size: 'medium',
            className: 'DialogPresentationsBulk',
            includeHeaderClose: true
        });
    };

    const handleCustomSearch = async (value, customSearchParams) => {
        const { limit, offset } = customSearchParams ?? audienceVariables;
        const updatedParams = { search: value, page: 1, limit, offset };

        if (!value) {
            updatedParams.orderBy = 'createdAt';
            updatedParams.order = 'desc';
        }

        if (value.length >= 2 || !value) {
            await handleGetAudience(updatedParams);
        };
        setAudienceVariables(updatedParams);
    };

    const handleCustomSort = async (id, isSorted) => {
        let order = 'desc';
        if (!isSorted || isSorted === 'desc') order = 'asc';
        const updatedParams = { ...audienceVariables, orderBy: id, order, page: 1 };
        await handleGetAudience(updatedParams, false);
        setAudienceVariables(updatedParams);
    };

    const menuData = {
        closeMenu: () => setSelectedAudience([]),
        numberOfItems: selectedAudience.length,
        actions: [
            {
                text: 'Resend Invite',
                icon: 'send',
                onClick: () => handleSendInvitation(),
                hide: !checkUserRole('ROLE_INVITE')
            },
            {
                text: 'Remove User',
                icon: 'trash-can',
                onClick: () => handleRemoveAudience(null)
            }
        ]
    };

    const handleLimitChange = async (value) => {
        const currentLength = initEvent.eventAudience?.length;
        const page = currentLength > value ? Math.ceil(currentLength / value) : 1;

        const updatedParams = {
            ...audienceVariables,
            page,
            limit: value
        };

        if (currentLength < value * page) {
            await handleGetAudience(updatedParams, false);
        }

        setAudienceVariables(updatedParams);
    };

    const customPaginationData = {
        lazyLoading: true,
        ...audienceVariables,
        showCount: initEvent.eventAudience?.length,
        totalCount: audienceTotal,
        handleLimitChange
    };

    const handleNewAudience = async () => {
        await handleGetAudience(audienceVariables);
    };

    return (
        <EventAudienceContext.Provider value={{
            audience,
            setAudience,
            selectedAudience,
            setSelectedAudience,
            handleRemoveAudience,
            handleNewAudience,
            handleCustomSearch,
            customSearch: audienceVariables?.search,
            handleCustomSort,
            customSort: audienceVariables.orderBy
                ? [{
                    id: audienceVariables.orderBy,
                    desc: audienceVariables.order === 'desc'
                }]
                : [],
            customPaginationData
        }}>
            <EventAudienceTable
                audience={audience}
                handleAudience={(updatedAudience) => setAudience(updatedAudience)}
                handleLazyLoad={handleLoadMore}
            />

            {(!!audienceVariables.search && !audience.length && !customLoading && !loadingEventAudienceById) &&
                <Subtitle className='flex center padded'>No results found for search term: <span className='bold half-spacing__left'> {audienceVariables.search}</span></Subtitle>}

            <DashboardMenu menuData={menuData} />

            <DashboardMenu menuData={menuData} />

        </EventAudienceContext.Provider>
    );
};

export default EventAudience;
