import { useQuery } from '@apollo/client';
import { GET_PRESENTATION_BY_ID } from '../../queries';
import { useContext, useEffect, useState } from 'react';

import { SnackbarContext } from '../../contexts/SnackbarContextProvider';
import PresentationEpisodesTable from './PresentationEpisodesTable';
import { PresentationsContext } from '../pages/PresentationsDashboard';
import { EventAddContentContext } from '../event/EventAddContent';

const PresentationSeriesSubRow = ({ row }) => {
    const id = row.original.id;
    const { loadingSnackbar, errorSnackbar, closeSnackbar, warningSnackbar } = useContext(SnackbarContext);
    const { selectedPresentations, setSelectedPresentations, currentPresentations } = useContext(PresentationsContext) ?? useContext(EventAddContentContext) ?? {};

    const [episodes, setEpisodes] = useState([]);

    const { data, loading, error } = useQuery(GET_PRESENTATION_BY_ID, { variables: { id } });

    useEffect(() => {
        if (!error) return;
        errorSnackbar({ text: 'Failed to fetch episodes' });
    }, [error]);

    useEffect(() => {
        loading ? loadingSnackbar({ text: 'Fetching Episodes' }) : closeSnackbar();
        if (!data) return;
        const associatedEpisodes = data?.presentation?.associatedPresentations?.associatedEpisodes ?? [];
        if (!associatedEpisodes?.length && !loading) warningSnackbar({ text: 'No Episodes Found' });
        const currentEpisodes = !currentPresentations
            ? associatedEpisodes
            : associatedEpisodes?.filter(associatedEpisode =>
                !currentPresentations.some(currentPresentation =>
                    currentPresentation.id === associatedEpisode.id ||
                        currentPresentation.presentationId === associatedEpisode.id
                )
            );
        setEpisodes([...(currentEpisodes ?? [])]);
    }, [data, loading]);

    if (!episodes) return;

    return (
        <PresentationEpisodesTable
            episodes={episodes}
            setSelectedItems={setSelectedPresentations}
            selectedItems={selectedPresentations}
            className='Presentation__Series--SubRow'
            mergeOnDefaultChecked
            loading={loading}
            hideDeepLink
        />
    );
};

export default PresentationSeriesSubRow;
