/* eslint-disable indent */

import { useMemo, useContext, useState } from 'react';
import _ from 'lodash';
import Box from '../modules/Box';
import Table, { columnHelper } from '../modules/Table';

import { Subtitle, Heading } from '../modules/Typography';
import { FileContext } from '../pages/File';
import DateCell from '../modules/TableCell/DateCell';
import Status from '../modules/Status';
import { DataContext } from '../../contexts/DataContext';

const FileProcessingTable = props => {
    const { fileHistory, setActiveFileHistory, fileType, file } = useContext(FileContext);
    const { appConfig } = useContext(DataContext);
    const [activeRow, setActiveRow] = useState({ 0: true });

    const tableData = fileHistory.map(history => {
        return {
            id: history.id,
            job: history.jobInput.jobType,
            format: history.jobInput.fileFormat,
            createdAt: history.createdAt,
            createdBy: history.createdBy,
            status: history.jobStatus
        };
    });

    const imageTableData = fileHistory.map((history, idx) => {
        if (fileType !== 'image' || !file.details.key) return false;
        const images = history?.jobOutput?.images?.filter(image => {
            const assets = image.assets.filter(asset => asset.url.indexOf(file.details.key.replace('ingest/', '')) !== -1);
            return !!assets.length;
        });

        if (!images?.length) return false;

        const image = _.last(images?.find(image => image.type === history.jobInput.imageType)?.assets);
        const { url, format, aspectRatioFraction } = image || {};
        const imageUrl = url + '.' + format;
        return {
            ...history,
            ...history.jobInput,
            ...image,
            status: history.jobStatus,
            origin: appConfig.imagick.baseUrl.slice(0, -1) + imageUrl,
            cdn: appConfig.imageCdnUrl.baseUrl + imageUrl + '?r=' + aspectRatioFraction
        };
    });

    const handleRowClick = (id, original) => {
        setActiveRow({ [id]: true });
        setActiveFileHistory(fileHistory.find(file => file.id === original.id));
    };

    const columns = useMemo(
        () => [
            columnHelper.accessor('job', {
                enableSorting: false,
                cell: ({ getValue }) => getValue() || '-'
            }),
            columnHelper.accessor('format', {
                enableSorting: false,
                cell: ({ getValue }) => getValue() || '-'
            }),
            columnHelper.accessor('createdAt', {
                header: 'Processed On',
                cell: DateCell,
                enableSorting: false
            }),
            columnHelper.accessor('createdBy', {
                header: 'Processed By',
                enableSorting: false
            }),
            columnHelper.accessor('status', {
                cell: ({ getValue }) => <Status value={getValue()} />,
                enableSorting: false
            })
        ],
        [fileHistory]
    );

    const imageColumns = useMemo(
        () => [
            columnHelper.accessor('imageType', {
                header: 'Image Type',
                enableSorting: false,
                cell: ({ getValue }) => getValue() || '-'
            }),
            columnHelper.accessor('aspectRatioFraction', {
                header: 'Aspect Ratio',
                enableSorting: false,
                cell: ({ getValue }) => getValue() || '-'
            }),
            columnHelper.accessor('serveAs', {
                header: 'Serve As',
                enableSorting: false,
                cell: ({ getValue }) => getValue() || '-'
            }),
            columnHelper.accessor('createdAt', {
                header: 'Processed On',
                cell: DateCell,
                enableSorting: false
            }),
            columnHelper.accessor('createdBy', {
                header: 'Processed By',
                enableSorting: false
            }),
            columnHelper.accessor('origin', {
                enableSorting: false,
                cell: ({ getValue }) => <a className='TextLink' href={getValue()} target='_blank' rel='noreferrer'>Origin</a>
            }),
            columnHelper.accessor('cdn', {
                header: 'CDN',
                enableSorting: false,
                cell: ({ getValue }) => <a className='TextLink' href={getValue()} target='_blank' rel='noreferrer'>CDN</a>
            }),
            columnHelper.accessor('status', {
                cell: ({ getValue }) => <Status value={getValue()} />,
                enableSorting: false
            })
        ],
        [fileHistory]
    );

    return (
        <Box
            type='white'
            borderType='none'
            shadow
            elevated={2}
            padded={false}
            className='flex align-flex-start flex-start margin FileDetails FileDetails--ProcessingTable'
            style={{ height: 'auto' }}
        >
            <Heading number={5} capitalize>Processing History</Heading>
            {fileType === 'image'
                ? <Table
                        data={_.compact(imageTableData)}
                        columns={imageColumns}
                        className='FileProcessingTable spacing__top'
                />
                : <Table
                        data={tableData}
                        columns={columns}
                        className='FileProcessingTable spacing__top'
                        handleRowClick={handleRowClick}
                        rowSelection={activeRow}
                />}
            {(!tableData.length && !_.compact(imageTableData).length) &&
                <div className='flex center align-center padded' style={{ width: '100%' }}>
                    <Subtitle className='padded' block>No processing jobs found</Subtitle>
                </div>}
        </Box>

    );
};

export default FileProcessingTable;
