import { createContext, useContext, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import _ from 'lodash';
import Proptypes from 'prop-types';

import Table from './Table';
import Loading from './Loading';
import { Subtitle } from './Typography';
import DashboardHeader from './DashboardHeader';
import DashboardMenu from './DashboardMenu';
import Filters from './Filters/Filters';

import getDashboardData from '../../hooks/getDashboardData';

import { filtersConfig } from '../../config/filtersConfig';
import { SnackbarContext } from '../../contexts/SnackbarContextProvider';

export const DashboardContext = createContext();

const Dashboard = ({ dashboardData, type, className, hasSideFilters }) => {
    const {
        gqlQuery,
        queryFilterConstraints,
        handleQuery,
        tableData,
        tableSort,
        columns,
        columnVisibility,
        triggerRefetch,
        menuData,
        hideHeader,
        searchPlaceholder,
        partialSuccess,
        queryWithSearchParams = true,
        resetDashboardData,
        setResetDashboardData,
        dashboardTableState
    } = dashboardData;

    const { closeSnackbar, loadingSnackbar } = useContext(SnackbarContext);

    const [searchParams, setSearchParams] = useSearchParams();
    const searchParamsObject = Object.fromEntries(searchParams);

    Object.keys(searchParamsObject).forEach((param) => {
        const val = searchParamsObject[param];
        searchParamsObject[param] = isNaN(val) ? val : Number(val);
    });

    const defaultVals = {
        limit: 100,
        page: 1,
        offset: 0
    };

    const paramsObject = queryWithSearchParams
        ? {
            search: searchParamsObject.search || '',
            orderBy: searchParamsObject.orderBy || '',
            order: searchParamsObject.order || '',
            limit: searchParamsObject.limit || defaultVals.limit,
            offset: searchParamsObject.offset || defaultVals.offset,
            page: searchParamsObject.page || defaultVals.page
        }
        : {
            ...defaultVals,
            search: '',
            orderBy: '',
            order: ''
        };

    const [variables, setVariables] = useState({ ...paramsObject });

    const { limit, page, order, orderBy } = variables;
    const pickedVariables = _.pickBy(variables);

    const [showFiltering, setShowFiltering] = useState(false);
    const searchParamsFilters = filtersConfig[type?.toLowerCase()].reduce((acc, a) => {
        const paramValue = _.pick(searchParamsObject, a.filterIds ?? a.id);
        return { ...acc, ...paramValue };
    }, {});
    const [filtersVariables, setFiltersVariables] = useState(queryWithSearchParams ? searchParamsFilters : {});
    const filtersApplied = !!_.size(_.omitBy(filtersVariables, _.isNil));

    const [totalCount, setTotalCount] = useState(null);

    const initialState = {
        ...(dashboardTableState ?? {}),
        sorting: orderBy
            ? [
                {
                    id: orderBy,
                    desc: order === 'desc'
                }
            ]
            : [...(tableSort ?? [])]
    };

    const {
        data,
        loading,
        refetch: refetchDashboardData
    } = gqlQuery
        ? getDashboardData(gqlQuery, { ...variables, ...filtersVariables }, queryFilterConstraints)
        : {};

    useEffect(() => {
        if (!resetDashboardData) return;
        setFiltersVariables({});
        setVariables({ ...paramsObject });
    }, [resetDashboardData]);

    const handleData = () => {
        if (!gqlQuery) return;
        if (loading && !partialSuccess && !resetDashboardData) return loadingSnackbar({ text: `Fetching ${type} Data` });
        if (!data && !partialSuccess) return closeSnackbar();
        if (!partialSuccess) closeSnackbar();
        handleQuery(data);
        if (setResetDashboardData) setResetDashboardData(false);
        const newVariables = { ...pickedVariables, ...filtersVariables };
        if (!_.isEqual(searchParamsObject, newVariables) && !!queryWithSearchParams) setSearchParams(newVariables);
        setTotalCount(data[`all${type}`]?.total || '');
    };

    useEffect(() => {
        handleData();
    }, [data, loading]);

    useEffect(() => {
        if (!_.isEqual(pickedVariables, searchParamsObject)) {
            const currentVars = { ...variables };
            Object.keys(currentVars).map((key) => {
                const defaultVal = defaultVals[key];
                const val = searchParamsObject[key] || (defaultVal >= 0 ? defaultVal : '');
                currentVars[key] = val;
                return true;
            });
            setVariables(currentVars);
        }
    }, [searchParams]);

    useEffect(() => {
        if (triggerRefetch) {
            refetchDashboardData && refetchDashboardData();
        }
    }, [triggerRefetch]);

    const handleSort = (id, isSorted) => {
        let order = 'desc';
        if (!isSorted || isSorted === 'desc') order = 'asc';
        setVariables({ ...variables, orderBy: id, order });
    };

    const paginationData = {
        totalCount,
        limit,
        page,
        updatePagination: (val) => {
            const { page: newPage, limit: newLimit } = val;
            setVariables({
                ...variables,
                page: newPage,
                limit: newLimit,
                offset: newLimit * (newPage - 1)
            });
        }
    };

    const handleFiltersChange = (updatedFilters) => {
        const newFilters = updatedFilters.reduce((acc, updatedFilter) => {
            const { filterValues, id } = updatedFilter;
            if (_.isArray(filterValues)) {
                return { ...acc, [id]: filterValues.join(',') };
            } else {
                return { ...acc, ...filterValues };
            }
        }, {});
        setFiltersVariables(_.pickBy(newFilters));
    };

    return (
        <DashboardContext.Provider
            value={{
                loading,
                refetch: refetchDashboardData,
                setVariables,
                variables,
                totalCount,
                type,
                searchPlaceholder,
                triggerFilter: () => setShowFiltering(true),
                menuData,
                className,
                setFiltersVariables,
                setSearchParams,
                filtersApplied
            }}
        >
            {!hideHeader && <DashboardHeader filtersApplied={filtersApplied} />}
            {loading
                ? (
                    <Loading />
                )
                : (
                    <div
                        className={`Dashboard--Table ${className && className + '__table'}`}
                    >
                        <Table
                            className={`${type && type + 'Table'}`}
                            data={tableData}
                            paginationData={gqlQuery ? paginationData : null}
                            columns={columns}
                            columnVisibility={columnVisibility}
                            handleSort={handleSort}
                            initialState={initialState}
                        />
                        {!tableData?.length && (
                            <div className='flex column align-center padded'>
                                <Subtitle className='padded'>No results found</Subtitle>
                            </div>
                        )}
                        {
                            hasSideFilters &&
                            <Filters
                                filterType={type}
                                open={showFiltering}
                                handleClose={() => setShowFiltering(false)}
                                handleFilters={(filters) => handleFiltersChange(filters)}
                                queryFilters={filtersVariables}
                                queryFilterConstraints={queryFilterConstraints}
                            />
                        }
                    </div>
                )}

            {!!menuData && <DashboardMenu />}
        </DashboardContext.Provider>
    );
};

export default Dashboard;

Dashboard.defaultProps = {
    className: ''
};

Dashboard.propTypes = {
    dashboardData: Proptypes.object,
    type: Proptypes.string,
    className: Proptypes.string
};
