import PropTypes from 'prop-types';
import { Caption, Body } from './Typography';
import MenuSurface from './MenuSurface';
import Icon from './Icon';

const MultiSelect = props => {
    return (
        <div className={[
            'Select',
            props.disabled ? 'Select--disabled' : null,
            `Select--${props.styleType}`,
            props.className
        ].filter(i => i).join(' ')}
        >

            {props.label && (
                <label className='Select__label'>
                    <Caption faded={props.disabled}>{props.label}</Caption>
                </label>
            )}

            <div className='Select__wrapper'>
                {props.icon && <Icon name={props.icon} className='Select__icon' />}
                <MenuSurface
                    multiple
                    searchable={props.searchable}
                    className='Select__MenuSurface'
                    options={props.options.map((option, index) => ({
                        ...option,
                        multiple: true,
                        checked: props.selectedValue?.includes(option.value),
                        onClick: () => props.onChange({ value: option.value, index })
                    }))}
                >
                    <div className='Select__bar'>
                        <Body className='Select__bar__text' number={2}>{props.buttonCopy || props.selectedValue.join(', ') || props.placeholder}</Body>
                    </div>
                </MenuSurface>
            </div>
        </div>
    );
};

export default MultiSelect;

MultiSelect.propTypes = {
    // function to handle select change event
    onChange: PropTypes.func,

    // options in the select drop down
    options: PropTypes.array,

    // whether or not the select input is disabled
    disabled: PropTypes.bool,

    // items that has been selected
    selectedValue: PropTypes.array,

    // className to pass down to the tags
    className: PropTypes.string,

    // label to associate with the select menu
    label: PropTypes.string,

    // type of style the select is
    styleType: PropTypes.oneOf(['default', 'dark']),

    // icon to showcase left of the select menu
    icon: PropTypes.string,

    // whether or not the select field is searchable
    searchable: PropTypes.bool,
    placeholder: PropTypes.string,

    buttonCopy: PropTypes.string
};

MultiSelect.defaultProps = {
    disabled: false,
    className: '',
    styleType: 'default',
    searchable: false,
    placeholder: '',
    selectedValue: []
};
