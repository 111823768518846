import _ from 'lodash';
import { eventConfig } from '../../config/eventConfig';

const useUpdateEventContent = (eventDetails, field, newValue) => {
    const { id, type, matchAll = false } = field;
    const val = newValue;
    const newData = type === 'object' ? newValue : { [id]: val };
    const updatedDetails = { ...eventDetails, ...newData };

    const initValue = eventDetails[id];
    const contentToUpdate = eventDetails.relatedContent ?? eventDetails.eventContent;

    const { contentDetails } = eventConfig;
    if (type !== 'object' && !contentDetails.some(contentDetail => contentDetail === id) && !field.relatedValues) return { updatedDetails, updatedConten: contentToUpdate };

    const updateContentArray = (contentArray) => contentArray?.map(content => {
        let relatedContent = content.relatedContent;
        if (!!relatedContent && !!relatedContent.length) {
            relatedContent = updateContentArray(_.cloneDeep(relatedContent));
        }
        const value = {};

        if (type === 'object') {
            const matchesCurrentContent = Object.keys(newValue).every(key => eventDetails[key] === content[key]);
            _.forEach(newValue, function (v, k) {
                let updatedValue;
                if (matchAll && !matchesCurrentContent) {
                    updatedValue = content[k];
                } else {
                    updatedValue = eventDetails[k] === content[k] ? v : content[k];
                }
                value[k] = updatedValue;
            });
        } else {
            value[id] = (!content[id] && !initValue) || content[id] === initValue ? newValue : content[id];
        }

        if (field.id === 'eventDates') {
            const { startAt, endAt } = newValue;
            value.startAt = eventDetails.startAt === content.startAt || (!!startAt && content.startAt < startAt) ? startAt : content.startAt;
            value.endAt = eventDetails.endAt === content.endAt || (!!endAt && content.endAt > endAt) ? endAt : content.endAt;
        }

        if (field.relatedValues) {
            field.relatedValues.forEach(relatedValue => {
                const updateValue = newValue === relatedValue.value || newValue.includes(relatedValue.value);
                value[relatedValue.id] = updateValue ? relatedValue.updatedValue : (relatedValue.defaultValue ?? '');
            });
        }

        const updatedRelatedContent = relatedContent ? { relatedContent } : {};
        return {
            ...content,
            ...value,
            ...updatedRelatedContent
        };
    });

    const updatedContent = contentToUpdate ? updateContentArray(_.cloneDeep(contentToUpdate)) : null;

    return { updatedDetails, updatedContent };
};

export default useUpdateEventContent;

// Props
// current set of details being updated
// eventDetails

// the field within details that is being updated
// field,

// the updated value
// newValue
