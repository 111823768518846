import { useContext } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import { SnackbarContext } from '../../contexts/SnackbarContextProvider';
import DialogFooter from '../modules/DialogFooter';
import { DialogContext } from '../../contexts/DialogContextProvider';
import { Body, Subtitle } from '../modules/Typography';
import { DELETE_PRESENTATION } from '../../queries';

const DeletePresentationDialog = ({ presentation }) => {
    const navigate = useNavigate();
    const { closeDialog } = useContext(DialogContext);
    const { errorSnackbar, successSnackbar } = useContext(SnackbarContext);

    const [deletePresentation] = useMutation(DELETE_PRESENTATION);

    const handleDelete = async () => {
        try {
            await deletePresentation({ variables: { id: presentation.id } });
            successSnackbar({ text: `Successfully deleted presentation: ${presentation.name}` });
            navigate('/presentations');
            closeDialog();
        } catch (err) {
            console.log(err);
            errorSnackbar({ text: 'Error deleteing presentation' });
        }
    };

    return (
        <>
            <Subtitle number={1} className='DialogWrapper__box__subtitle'>Are you sure you want to delete the following presentation(s):</Subtitle>
            <ul className='spacing__top'>
                <li><Body number={2}>{presentation.name}</Body></li>
            </ul>
            <DialogFooter
                handleCancel={closeDialog}
                handleConfirm={handleDelete}
                confirmText='Delete'
            />
        </>
    );
};

DeletePresentationDialog.propTypes = {
    presentation: PropTypes.object
};

export default DeletePresentationDialog;
