import { gql } from '@apollo/client';

export const EVENT_AUDIENCE_FIELDS = gql`
    fragment EventAudienceFields on EventAudience {
        id
        eventId
        email
        provider
        createdAt
        createdBy
        status
        invitation {
            sendAt
            subject
            redirectUrl
            timezone
        }
    }
`;
