import { createContext, useState } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import { GET_APP_CONFIG } from '../queries';

export const DataContext = createContext();

const DataContextProvider = ({ children }) => {
    const { data, loading: loadingConfigs } = useQuery(GET_APP_CONFIG);
    const [asperaInstaller, setAsperaInstaller] = useState(null);
    const [asperaWeb, setAsperaWeb] = useState(null);
    if (loadingConfigs) return;
    return (
        <DataContext.Provider value={{
            loadingConfigs,
            appConfig: data.config || {},
            asperaInstaller,
            setAsperaInstaller,
            asperaWeb,
            setAsperaWeb
        }}
        >
            {children}
        </DataContext.Provider>
    );
};

DataContextProvider.propTypes = {
    children: PropTypes.oneOfType([PropTypes.array, PropTypes.object])
};

export default DataContextProvider;
