import Table, { columnHelper } from '../modules/Table';

const EventHistory = () => {
    const testingData = [
        {
            updatedDate: '08/01/2022',
            updatedBy: 'Emma Kinch',
            changedField: 'Window Start Date',
            changedFrom: '07/25/2022; 11:00PM PST',
            changedTo: '08/01/2022; 11:00PM PST'
        }
    ];

    const columns = [
        columnHelper.accessor('updatedDate', {
            header: 'Updated Date'
        }),
        columnHelper.accessor('updatedBy', {
            header: 'Updated By'
        }),
        columnHelper.accessor('changedField', {
            header: 'Changed Field'
        }),
        columnHelper.accessor('changedFrom', {
            header: 'Changed From',
            size: 225
        }),
        columnHelper.accessor('changedTo', {
            header: 'Changed To',
            size: 225
        })
    ];

    return (
        <Table
            data={testingData}
            columns={columns}
        />
    );
};

export default EventHistory;
