export const eventConfig = {
    contentDetails: [
        'startAt',
        'endAt',
        'timezone',
        'playCount',
        'approvalSourceId',
        'approvalSourceType',
        'tags',
        'maxDefinition',
        'watermarkType',
        'watermarkLocation',
        'watermarkPersistence',
        'docusignIntegration',
        'plainModeEnabled',
        'allowOfflinePlayback'
    ],
    eventDetailsFields: [
        {
            id: 'name',
            label: 'Event Name',
            type: 'textField',
            required: true,
            errorMessage: 'Event name is required',
            width: '100',
            detailsType: 'event'
        },
        {
            type: 'eventTemplate',
            id: 'eventTemplate',
            createEventOnly: true,
            detailsType: 'event'
        },
        {
            id: 'types',
            label: 'Event Type',
            type: 'chipselect',
            required: true,
            errorMessage: 'Event Type is required',
            appConfigData: 'entitlementReportingTags',
            detailsType: 'event',
            relatedValues: [
                {
                    value: 'Executive',
                    id: 'allowOfflinePlayback',
                    updatedValue: true,
                    defaultValue: false
                }
            ]
        },
        {
            label: 'Group Access',
            id: 'aclType',
            type: 'aclSelect',
            required: true,
            detailsType: 'event'
        },
        {
            type: 'eventApprovalSource',
            id: 'eventApprovalSource'
        },
        {
            type: 'dateTime',
            id: 'dateTime',
            includeTimezone: {
                id: 'timezone',
                label: 'Event Timezone'
            }
        },
        {
            id: 'tags',
            label: 'Tags',
            type: 'tags'
        },
        {
            type: 'subtitle',
            copy: 'content security',
            id: 'subtitle2'
        },
        {
            id: 'maxDefinition',
            label: 'Max Definition',
            type: 'select',
            appConfigData: 'resolutions',
            searchable: false,
            required: true,
            width: '33'
        },
        {
            id: 'playCount',
            label: 'Playback Count',
            type: 'textField',
            validation: 'number',
            allowZero: false,
            errorMessage: 'Must be a number greater than 0',
            width: '33'
        },
        {
            id: 'docusignIntegration',
            label: 'DocuSign',
            type: 'select',
            appConfigData: 'ndaTemplates',
            searchable: true,
            defaultValue: '',
            width: '33'
        },
        {
            type: 'subtitle',
            copy: 'content watermark',
            id: 'subtitle3'
        },
        {
            id: 'contentWatermark',
            type: 'contentWatermark'
        },
        {
            id: 'plainModeEnabled',
            label: 'Plain Mode',
            type: 'switch',
            defaultValue: false,
            detailsType: 'event',
            width: '33',
            subtitle: 'Plain mode',
            copy: 'Enable Plain Mode for all content in this event?'
        },
        {
            id: 'allowOfflinePlayback',
            label: 'Offline Playback',
            type: 'switch',
            defaultValue: false,
            requiredRole: 'ROLE_OFFLINE_ENTITLEMENT',
            requiredDetail: {
                id: 'types',
                value: 'Executive'
            },
            width: '66',
            subtitle: 'Offline Playback',
            copy: 'Enable Offline Playback for all content in this event?'
        }
    ],
    eventInviteFields: [
        {
            type: 'body',
            copy: 'Attendees that don\'t have an account will need to be invited. Please fill out the invitation form below.',
            id: 'body1'
        },
        {
            type: 'text',
            label: 'Redirect to',
            id: 'redirectUrl',
            required: true,
            validation: ['url']
        },
        {
            type: 'text',
            label: 'Subject',
            id: 'subject',
            required: true
        },
        {
            type: 'body',
            copy: 'Select what date and time to send the invitation.',
            id: 'body2'
        },
        {
            type: 'dateTime',
            id: 'sendAt',
            label: 'Send Date',
            isRange: false,
            includeTimezone: {
                id: 'timezone',
                label: 'Geographic Location'
            }
        }

    ],
    createEventSteps: [
        {
            label: 'Event Details',
            id: 'eventDetails'
        },
        {
            label: 'Add Content',
            id: 'addContent'
        },
        {
            label: 'Add Audience',
            id: 'addAudience',
            uploadDropzone: true
        },
        {
            label: 'Add Codes',
            id: 'addCodes',
            uploadDropzone: true
        }

    ],
    audienceInviteSteps: [
        {
            label: 'Add Email',
            id: 'addEmail'
        },
        {
            label: 'Invite',
            id: 'invite'
        }
    ]
};
