import { useState } from 'react';
import Proptypes from 'prop-types';
import { Heading, Subtitle } from '../modules/Typography';
import DialogFooter from '../modules/DialogFooter';
import PresentationEpisodesTable from '../presentation/PresentationEpisodesTable';
import TopAppBar from '../modules/TopAppBar';
import addNewEventContent from '../../hooks/event/addNewEventContent';

const EventContentEpisodes = ({ parentContent, createNew, handleCancel, handleNewContent, event, initialData }) => {
    const { associatedEpisodes } = parentContent?.associatedPresentations || {};
    const episodes = associatedEpisodes?.filter(episode => !initialData.some(data => data.presentationId === episode.id));
    const [selectedEpisodes, setSelectedEpisodes] = useState([]);

    const reset = () => {
        setSelectedEpisodes([]);
        handleCancel();
    };

    const updateSelectedEpisodes = () => {
        const updatedEpisodes = selectedEpisodes.map(selectedEpisode => {
            const { updatedContent } = addNewEventContent(selectedEpisode, event);
            return {
                ...updatedContent,
                seriesName: parentContent.type === 'series'
                    ? parentContent.name
                    : parentContent.associatedPresentations.associatedSeries.name
            };
        });
        handleNewContent(updatedEpisodes);
        reset();
    };

    return (
        <div className='EventContent__Episodes flex column'>
            <TopAppBar>
                <Heading number={6} block>Add Episodes</Heading>
            </TopAppBar>
            <Subtitle number={1} block className='padded'>Select Episodes from {parentContent.name}</Subtitle>
            <div className='EventContent__Episodes--Table--Wrapper padded__left padded__right stretch-width'>
                <PresentationEpisodesTable
                    episodes={episodes}
                    className='EventContent__Episodes--Table'
                    setSelectedItems={setSelectedEpisodes}
                    selectedItems={selectedEpisodes}
                    hideDeepLink
                />
            </div>
            <DialogFooter
                handleCancel={reset}
                handleConfirm={updateSelectedEpisodes}
                disabled={!selectedEpisodes.length}
                confirmText={createNew ? 'Next' : 'Add'}
            />
        </div>
    );
};

export default EventContentEpisodes;

EventContentEpisodes.proptypes = {
    handleNewContent: Proptypes.func,
    parentContent: Proptypes.object,
    initialData: Proptypes.array
};
