/* eslint-disable indent */
import { Fragment, createContext, useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { useQuery } from '@apollo/client';

import Loading from '../modules/Loading';
import TopNav from '../layout/TopNav';
import TextField from '../modules/TextField';
import FileFooter from '../file/FileFooter';

import { GET_FILE_DETAILS } from '../../queries';
import { fileConfig } from '../../config/fileConfig';
import { Body } from '../modules/Typography';
import FileHeader from '../file/FileHeader';
import FileProcessingData from '../file/FileProcessingData';
import FileProcessingTable from '../file/FileProcessingTable';
import { DataContext } from '../../contexts/DataContext';
import Textarea from '../modules/Textarea';
import { formatValues } from '../../utils/formatValues';

export const FileContext = createContext();
const File = () => {
    const { presentationId, fileId } = useParams();
    const { data, loading } = useQuery(GET_FILE_DETAILS, { variables: { id: fileId, presentationId } });
    const { file, fileHistory } = data?.fileDetails || {};
    const currentFileConfig = fileConfig[file?.type];
    const [activeFileHistory, setActiveFileHistory] = useState({});
    const { appConfig } = useContext(DataContext);
    useEffect(() => setActiveFileHistory(fileHistory?.[0]), [data, loading]);

    const renderDefault = (field, value) => {
        const { id, label, format, textField } = field;
        const { fieldId, type } = textField || {};
        return (
            <Fragment key={id ?? fieldId}>
                {!textField
                    ? <Body number={2} key={id} className={`flex align-flex-start flex-start FileDetails--${id}`}>
                        <span className='Typography--heavy Typography--fadedTwoThirds'>{label}:</span>
                        {formatValues(format, value || 'Not Found', appConfig)}
                    </Body>
                    : <div className={`${type === 'textarea' ? 'FileDetails--textarea' : 'FileDetails--text'} flex center align-center`} key={id}>
                        <Body number={2} heavy fadedTwoThirds>{label}:</Body>
                        {
                            type === 'textarea'
                            ? <Textarea
                                    key={fieldId}
                                    disabled
                                    padded={false}
                                    defaultValue={value}
                              />
                            : <TextField
                                    key={fieldId}
                                    disabled
                                    padded={false}
                                    defaultValue={value}
                              />
                        }

                    </div>}
            </Fragment>
        );
    };

    if (loading) return <Loading />;

    return (
        <FileContext.Provider value={{
            currentFileConfig,
            file: file || {},
            fileType: file.type,
            fileHistory,
            activeFileHistory: activeFileHistory || {},
            setActiveFileHistory,
            presentationId,
            renderDefault,
            fileId
        }}
        >

            <Helmet><title>{file?.name}</title></Helmet>
            <TopNav heading={`${file?.type} Details`} />
            <div className={`File PageContent File--${file?.type}`}>
                <FileHeader />
                {(fileHistory.length > 1 || (file.type === 'image' && !!fileHistory.length)) && <FileProcessingTable />}
                {!!fileHistory.length && file.type !== 'image' && <FileProcessingData />}
                <FileFooter />
            </div>

        </FileContext.Provider>
    );
};

export default File;
