import { useQuery } from '@apollo/client';

const getDashboardData = (graphqlQuery, variables, queryFilterConstraints) => {
    if (!variables.types) delete variables.types;
    if (!variables.aclTypes) delete variables.aclTypes;
    if (!variables.statuses) delete variables.statuses;
    if (!variables.approvalSourceTypes) delete variables.approvalSourceTypes;

    if (queryFilterConstraints) {
        Object.keys(queryFilterConstraints).forEach(key => {
            if (!variables[key]) variables[key] = queryFilterConstraints[key];
        });
    }

    const { data, loading, refetch } = useQuery(graphqlQuery, {
        variables
    });

    return { data, loading, refetch };
};

export default getDashboardData;
