import { useContext } from 'react';
import DialogFooter from '../../modules/DialogFooter';
import { DialogContext } from '../../../contexts/DialogContextProvider';
import { Subtitle } from '../../modules/Typography';

const PreviewEmailDialog = props => {
    const { closeDialog } = useContext(DialogContext);

    return (
        <>
            <div className='padded'>
                <Subtitle number={1} className='DialogWrapper__box__subtitle'>Preview Email</Subtitle>
            </div>
            <DialogFooter
                handleCancel={closeDialog}
            />
        </>
    );
};

export default PreviewEmailDialog;
