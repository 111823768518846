import { gql } from '@apollo/client';

export const GET_USER = gql`
	query getUser {
		user: getUser {
			id
			authorities
			name
			firstName
			lastName
			orgs
			authenticated
			authorized
			type
		}
	}
`;

export const LOGOUT = gql`
	query logout {
		logout {
			message
			error
		}
	}
`;

export const LOGIN = gql`
	query login {
		sentry: login {
			sentryUrl
		}
	}
`;

export const CHECK_ACCESS_TOKEN = gql`
	query checkAccessToken {
		checkAccessToken {
			validAccessToken
			unauthorizedUser
		}
	}
`;
