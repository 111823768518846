import { useContext } from 'react';
import { useMutation } from '@apollo/client';
import { FileProcessingDialogContext } from '../FileProcessingDialog';
import { SnackbarContext } from '../../../contexts/SnackbarContextProvider';
import { SnackbarDialogContext } from '../../../contexts/SnackbarDialogContextProvider';
import { FileProcessingJobContext } from '../FileProcessingJob';
import DialogFooter from '../../modules/DialogFooter';
import { fileNameFromPath } from '../../../utils/fileNameFromPath';

import { PROCESS_FILE } from '../../../queries';
import Button from '../../modules/Button';

const JobSubmit = () => {
    const { presentationId, isVpp } = useContext(FileProcessingDialogContext);
    const { snackbarDialog, makeErrorDialog, closeSnackbarDialog } = useContext(SnackbarDialogContext);
    const { successSnackbar } = useContext(SnackbarContext);
    const { currentFile, errors, jobConfig, fileProcessingConfig, resetJob, lastFile, filesToProcess, processingInfoErrors } = useContext(FileProcessingJobContext);
    const has400Error = processingInfoErrors?.some(err => err?.extensions?.response?.status === 400 || err?.message?.indexOf('400') !== -1);

    const { id: fileId, name } = currentFile;
    const { requiredFields } = fileProcessingConfig;

    const submitDisabled = Object.values(errors).some(val => !!val) || requiredFields?.some(field => !jobConfig[field]);

    const [processFile, { loading: processingFiles }] = useMutation(PROCESS_FILE);

    const handleNextClick = async () => {
        let videoType;
        if (currentFile.type === 'video') {
            videoType = currentFile.videoType === 'dubbing credit' ? 'dubbing credit' : 'primary';
        }

        const variables = { presentationId, fileId, jobConfig: { ...jobConfig, videoType } };
        const currentJobs = [...filesToProcess];
        const current = currentJobs.find(job => job.id === fileId);

        try {
            await processFile({ variables });
            current.jobConfig = { ...jobConfig, processingResult: true };
            successSnackbar({ text: `Started processing ${fileNameFromPath(name)}` });
        } catch (err) {
            current.jobConfig = { ...jobConfig, processingResult: false };
            snackbarDialog({
                snackbarText: `Error processing ${fileNameFromPath(name)}`,
                dialogTitle: 'File Processing Error',
                graphQLErrors: err?.graphQLErrors
            });
            console.log('create error', err);

            const vppError = err?.graphQLErrors?.some(e => {
                const errorCode = e.extensions?.response?.body?.errorCode;
                return isVpp && (errorCode === 'ingest:vpp-missing-video' || errorCode === 'ingest:invalid-video-type');
            });

            if (vppError) return;
        }

        resetJob(currentJobs);
    };

    const errorModalTrigger = () => {
        return (
            <Button
                onClick={() => makeErrorDialog(
                    'File Processing Error',
                    processingInfoErrors,
                    null,
                    () => closeSnackbarDialog()
                )}
                className='half-spacing__right'
                icon='warning-round'
                iconColor='error'>
                <span className='underline'>File Processing Error</span>
            </Button>
        );
    };

    return (
        <DialogFooter
            loading={processingFiles}
            disabled={submitDisabled || has400Error}
            handleConfirm={handleNextClick}
            confirmText={lastFile ? 'Submit' : 'Next'}
            errorModalTrigger={has400Error ? errorModalTrigger() : null}
        />
    );
};

export default JobSubmit;
