import { useContext } from 'react';
import PropTypes from 'prop-types';
import { PresentationContext } from '../../pages/Presentation';
import Checkbox from '../Checkbox';

const InternalAccessCell = ({ value, cell, disabled }) => {
    const { presentationData, setPresentationData } = useContext(PresentationContext);

    const handleCheck = (checked, val, subject) => {
        const currentPres = { ...presentationData };
        const currentSubject = currentPres.acl.find(acl => acl.subject === subject);
        if (val === 'deny') {
            currentSubject.deny = checked;
        } else {
            checked ? currentSubject.capabilities.push(val) : currentSubject.capabilities.splice(currentSubject.capabilities.indexOf(val), 1);
        }
        currentPres.acl[currentPres.acl.indexOf(currentSubject)] = currentSubject;
        setPresentationData(currentPres);
    };

    return (
        <Checkbox
            value={value}
            checked={cell.row.original[cell.column.id]}
            disabled={disabled}
            handleCheck={({ checked, value }) => handleCheck(checked, value, cell.row.original.subject)}
        />
    );
};

InternalAccessCell.propTypes = {
    value: PropTypes.string,
    cell: PropTypes.object,
    disabled: PropTypes.bool
};

export default InternalAccessCell;
