import { useContext, useState } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import Footer from '../modules/Footer';
import { GET_EVENT_CONTENT_BY_ID, PUBLISH_EVENT, UNPUBLISH_EVENT } from '../../queries';
import { EventContext } from '../pages/Event';
import { DialogContext } from '../../contexts/DialogContextProvider';
import { Body, Subtitle } from '../modules/Typography';
import { SnackbarContext } from '../../contexts/SnackbarContextProvider';
import { SnackbarDialogContext } from '../../contexts/SnackbarDialogContextProvider';
import Accordion from '../modules/Accordion';
import List from '../modules/List';

const EventFooter = props => {
    const { event, handleRefetchEvent, setRefetchAudienceTab, setRefetchContentTab, userCanPublish } = useContext(EventContext);
    const { confirmDialog, closeDialog } = useContext(DialogContext);
    const { successSnackbar } = useContext(SnackbarContext);
    const { snackbarDialog } = useContext(SnackbarDialogContext);
    const { publishedStatus } = event;

    const [hasTimeoutError, setHasTimeoutError] = useState(false);

    const variables = {
        id: event.id,
        event
    };

    const [publishEvent] = useMutation(PUBLISH_EVENT, { variables });
    const [unpublishEvent] = useMutation(UNPUBLISH_EVENT, { variables });
    const [getEventContent] = useLazyQuery(GET_EVENT_CONTENT_BY_ID, {
        variables: {
            id: event.id,
            extended: true
        }
    });

    const handlePublish = async (publish) => {
        const action = publish ? 'publish' : 'unpublish';
        const contents = await getEventContent();
        const unpublished = contents.data.eventContentById.eventContent.filter(d => d.status[0] === 'live_dirty' || d.status[0] === 'live_unpublished');

        confirmDialog({
            title: `${action} Event`,
            text: <>
                <div className='padded__left padded__right DialogEventsBulk__Content'>
                    <Subtitle number={5} className='spacing__bottom block DialogEventsBulk__Warning'><b>Are you sure you want to {action} "{event.name}"?</b></Subtitle>
                    {action === 'publish' && unpublished.length > 0 &&
                    <div>
                        <Body number={1}>There are presentations within this event that have not been published. Please review the list.</Body>
                        <p />
                        <Accordion
                            headerFlex
                            type='advancedDetails'
                            title='Show Presentations'
                            closeTitle='Hide Presentations'
                            headerContent={<Body number={2}>Presentations Not published ({unpublished.length})</Body>}>
                            <List className='PresentationList' items={unpublished.map(pres => ({ name: pres.name, url: `presentation/${pres.presentationId}` }))} />
                        </Accordion>
                    </div>}

                </div>

            </>,
            handleConfirm: async () => {
                try {
                    publish ? await publishEvent() : await unpublishEvent();
                    setRefetchAudienceTab(true);
                    setRefetchContentTab(true);
                    await handleRefetchEvent();
                    successSnackbar({ text: `${event.name} ${action}ed` });
                    closeDialog();
                } catch (error) {
                    const networkTimeout = !!error.networkError && (error.networkError.message.indexOf('504') !== -1 || error.networkError.message.indexOf('408') !== -1);
                    const graphQLTimeout = !!error.graphQLErrors && (error.graphQLErrors.some(graphQLError => graphQLError.message.indexOf('504') !== -1 || graphQLError.message.indexOf('408') !== -1));
                    const isTimeout = networkTimeout || graphQLTimeout;
                    setHasTimeoutError(isTimeout);
                    closeDialog();
                    const snackbarDialogOptions = isTimeout
                        ? {
                            autoOpenError: true,
                            snackbarText: `Timeout Error: ${action}ing Event`,
                            dialogTitle: `Event ${action}ing Timeout Error`,
                            customDialogBody: <>
                                <div className='flex column padded__top padded__bottom'>
                                    <Subtitle className='half-spacing__bottom' number={3}>Event {action} timed out due to Event size.</Subtitle>
                                    <Body className='half-spacing__bottom' number={2}>Please wait 5 minutes and refresh to check if the {action} was successful.</Body>
                                    <Body number={2}>If the Event has not {action}ed at that time, please try again</Body>
                                </div>
                            </>
                        }
                        : {
                            snackbarText: `Error ${action}ing Event`,
                            dialogTitle: `Event ${action}ing Error`
                        };
                    snackbarDialog({
                        graphQLErrors: error?.graphQLErrors,
                        ...snackbarDialogOptions
                    });
                }
            },
            handleCancel: () => closeDialog(),
            disableCloseOnClick: true,
            hideCancelOnLoad: true,
            size: 'xl',
            className: 'EventPublish',
            setLoading: true
        });
    };
    return (
        <Footer
            disableAll={!userCanPublish || hasTimeoutError}
            disablePublish={publishedStatus === 'published'}
            disableUnpublish={publishedStatus === 'unpublished'}
            handlePublish={() => handlePublish(true)}
            handleUnpublish={() => handlePublish(false)}
        />
    );
};

export default EventFooter;
