import { useContext } from 'react';
import PropTypes from 'prop-types';
import { Heading, Subtitle, Body } from '../modules/Typography';
import CustomDatePicker from '../modules/CustomDatePicker';
import ACLChips from '../modules/ACLChips';
import Select from '../modules/Select';
import Switch from '../modules/Switch';
import TextField from '../modules/TextField';

import { eventConfig } from '../../config/eventConfig';
import { DataContext } from '../../contexts/DataContext';
import ChipSelect from '../modules/ChipSelect';
import ContentWatermark from '../modules/FormFields/ContentWatermark';
import EventApprovalSource from '../modules/FormFields/EventApprovalSource';
import checkUserRole from '../../hooks/checkUserRole';
import EventTemplate from '../modules/FormFields/EventTemplate';
import Tags from '../modules/FormFields/Tags';

const EventDetails = ({ event, setEvent, details, setDetails, handleDetailsChange, handleDetailsBlur, errors, detailsType, parentDetails, createNew, bulkEdit, checkDates, compareParentDates, strictParentDates, dateErrors, setDateErrors }) => {
    const { appConfig } = useContext(DataContext);
    const { eventDetailsFields } = eventConfig;

    const handleDateDetails = (newDates, fieldDetails) => {
        setDetails({ ...details, ...newDates });
        handleChangeMulti(newDates, fieldDetails);
    };

    const renderDatePicker = (id, includeTimezone) => {
        return (
            <CustomDatePicker
                key={id}
                showTime
                isRange
                initStart={details.startAt}
                initEnd={details.endAt}
                initRemoveDates={details.removeDates}
                timezone={details.timezone}
                timezoneCheck={event.timezone}
                allowNoTimezone={bulkEdit}
                checkDates={bulkEdit || checkDates}
                compareParentDates={compareParentDates}
                strictParentDates={strictParentDates}
                includePST
                includeTimezone={includeTimezone}
                parentDates={parentDetails ?? event}
                dateErrors={dateErrors}
                setDateErrors={setDateErrors}
                showRemoveDateCheckbox={bulkEdit && !createNew}
                disableRemoveStartDateCheckbox={!!event.publishedAt}
                hideErrors={parentDetails ? parentDetails.status === 'completed' : details.status === 'completed'}
                bulkEdit={bulkEdit}
                onChange={(changeDateEvent) => handleDateDetails(changeDateEvent, { label: 'Event Dates', id: 'eventDates', matchAll: false })} />
        );
    };

    const renderTextField = (field) => {
        const { label, id, errorMessage, disabled, width, required } = field;
        let message = errorMessage;
        if (id === 'name' && errors.duplicateName) {
            message = `An event with  the name ${details.name} already exists. Please enter a unique name.`;
        }
        return (
            <>
                <TextField
                    key={id}
                    className={`TextField__${id} ${width ? 'width-' + width : ''} ${required && !bulkEdit ? 'required' : ''}`}
                    label={label}
                    error={errors?.[id] || (id === 'name' && errors.duplicateName)}
                    errorMessage={message}
                    onChange={({ target }) => handleDetailsChange(field.validation === 'number' ? parseInputNumber(target.value) : target.value, field)}
                    disabled={disabled}
                    value={details[id] ?? ''}
                    onBlur={handleDetailsBlur ? ({ target }) => handleDetailsBlur(field.validation === 'number' ? parseInputNumber(target.value) : target.value, field) : null}
                    required={!bulkEdit && required}
                />
            </>
        );
    };

    const parseInputNumber = value => (isNaN(value) || value === '') ? null : Number(value);

    const handleChange = (value, field) => {
        if (!createNew) setDetails({ ...details, [field.id]: value });
        handleDetailsBlur(value, field);
    };

    const handleChangeMulti = (valuesObject, fieldDetails) => handleDetailsBlur(valuesObject, { ...fieldDetails, type: 'object' });

    const handleACLUpdate = (aclType, acl) => {
        if (!details?.aclType || (details.aclType?.length === 1 && details?.aclType.every(ACE => ACE === 'Default') && !aclType?.length)) return;
        setDetails({ ...details, aclType, acl });
        handleChangeMulti({ aclType, acl }, { label: 'Group Access', id: 'ACL' });
    };

    const renderSelect = (field) => {
        const { label, id, appConfigData, options: fieldOptions, defaultValue = null, width, required, errorMessage, searchable } = field;
        let options = fieldOptions || [];
        if (appConfigData && appConfig[appConfigData]) {
            options = appConfig[appConfigData].map(val => ({ text: val.name ?? val, value: val.id ?? val }));
        }
        if (defaultValue === '' && !options.some(opt => opt.value === '')) {
            options.unshift({ text: 'None', value: null });
        }

        return (
            <Select
                key={id}
                label={label}
                options={options}
                disabled={!options.length}
                searchable={searchable}
                selectedValue={details[id] ?? defaultValue}
                className={`Select__${id} ${width ? 'width-' + width : ''} ${!bulkEdit && required ? 'required' : ''}`}
                onChange={({ value }) => handleChange(value, field)}
                error={errors?.[id]}
                errorMessage={errorMessage}
                required={!bulkEdit && required}
                handleClear={bulkEdit && !createNew ? () => handleChange(null, field) : null}
            />
        );
    };

    const renderForm = (field) => {
        const {
            type,
            subtitle,
            copy,
            label,
            id,
            defaultValue,
            detailsType: configDetailsType,
            required,
            width,
            appConfigData,
            includeTimezone,
            requiredRole,
            requiredDetail,
            createEventOnly,
            searchable,
            sortOptions
        } = field;

        if (
            (createEventOnly && !createNew) ||
            (configDetailsType && configDetailsType !== detailsType)
        ) return;

        const requiredClass = !bulkEdit && required ? 'required' : '';
        const widthClass = width ? `width-${width}` : '';
        const hasRole = checkUserRole(requiredRole);

        const hasRequiredDetail = !requiredDetail || event[requiredDetail?.id] === requiredDetail?.value || event[requiredDetail?.id]?.includes(requiredDetail?.value);
        const hideClass = hasRequiredDetail ? '' : 'EventDetails__Hide';

        switch (type) {
        case 'title':
            return <Heading key={id} number={1} block>{copy}</Heading>;
        case 'subtitle':
            return <Subtitle key={id} number={4} fadedHalf block className='uppercase spacing__bottom stretch-width'>{copy}</Subtitle>;
        case 'copy':
            return <Body key={id} number={2} block fadedHalf className='stretch-width'>{copy}</Body>;
        case 'select':
            return renderSelect(field);
        case 'textField':
            return renderTextField(field);
        case 'dateTime':
            return renderDatePicker(id, includeTimezone);
        case 'aclSelect': {
            return (
                <ACLChips
                    key={id}
                    className={`${requiredClass} ${widthClass}`}
                    label={label}
                    aclTypes={details.aclType}
                    acl={details.acl}
                    handleACLUpdate={(aclType, acl) => handleACLUpdate(aclType, acl)}
                    required={required}
                />
            );
        }
        case 'switch': {
            return (
                <div className={`${widthClass} ${hideClass} EventDetails__Switch`} key={id}>
                    {subtitle && <Subtitle number={4} fadedHalf block className='uppercase stretch-width'>{subtitle}</Subtitle>}
                    {copy && <Body number={2} block fadedHalf className='stretch-width'>{copy}</Body>}
                    <Switch
                        disabled={!hasRole}
                        label={label}
                        checked={details[id] || defaultValue}
                        onChange={({ target }) => handleChange(target.checked, field)}
                    />
                </div>

            );
        }
        case 'chipselect': {
            return (
                <ChipSelect
                    key={id}
                    id={id}
                    label={label ?? id}
                    chips={details[id]}
                    options={appConfig[appConfigData].map(val => ({ text: val.name || val, value: val.id || val }))}
                    className={`${requiredClass} ${widthClass} ChipSetSelect--${id}`}
                    handleChips={(updatedDetail) => handleChange(updatedDetail, field)}
                    required={required}
                    searchable={searchable}
                    sortOptions={sortOptions}
                />
            );
        }
        case 'contentWatermark': {
            return (
                <ContentWatermark
                    key={id}
                    content={details}
                    handleUpdate={(watermark) => handleChangeMulti(
                        { ...watermark },
                        { id: 'watermark', label: 'Content Watermark', matchAll: true }
                    )}
                />
            );
        }
        case 'eventApprovalSource' : {
            return (
                <EventApprovalSource
                    className={bulkEdit && !createNew ? 'width-66' : 'width-100'}
                    key={id}
                    eventDetails={details}
                    createNew={createNew}
                    resetApprovalSource={bulkEdit}
                    handleUpdate={(approvalSource) => handleChangeMulti({ ...approvalSource }, { id: 'approvalSource', label: 'Approval Source', matchAll: true })}
                />
            );
        }
        case 'eventTemplate' : {
            return (
                <EventTemplate
                    key={id}
                    event={event}
                    setEvent={(updatedEvent) => setEvent(updatedEvent)}
                />
            );
        }
        case 'tags' : {
            return (
                <Tags
                    selectedTags={details.tags}
                    handleChange={(updatedDetail) => handleChange(updatedDetail, field)}
                />
            );
        }
        default:
            return false;
        }
    };
    return (
        <div className='EventDetails flex wrap'>
            <Subtitle number={4} fadedHalf block className='uppercase spacing__bottom stretch-width'>{detailsType} Details</Subtitle>
            {eventDetailsFields.map(field => renderForm(field))}
        </div>
    );
};

export default EventDetails;

EventDetails.defaultProps = {
    detailsType: 'event',
    bulkEdit: false,
    checkDates: false,
    compareParentDates: false,
    strictParentDates: false
};

EventDetails.propTypes = {
    event: PropTypes.object,
    setEvent: PropTypes.func,
    details: PropTypes.object,
    setDetails: PropTypes.func,
    handleDetailsChange: PropTypes.func,
    handleDetailsBlur: PropTypes.func,
    errors: PropTypes.object,
    detailsType: PropTypes.oneOf(['event', 'content']),
    parentDetails: PropTypes.object,
    createNew: PropTypes.bool,
    bulkEdit: PropTypes.bool,
    checkDates: PropTypes.bool,
    compareParentDates: PropTypes.bool
};
