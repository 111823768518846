import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { Heading, Subtitle, Body } from '../modules/Typography';
import TopAppBar from '../modules/TopAppBar';
import List from '../modules/List';

import Table, { columnHelper } from '../modules/Table';
import { checkPlural } from '../../utils/checkPlural';
import Icon from '../modules/Icon';
import IconButton from '../modules/IconButton';
import ButtonDownloadCSV from '../modules/ButtonDownloadCSV';
import Button from '../modules/Button';

const EventAudienceInviteErrors = ({ inviteErrors, closeInviteErrors, handlePostDownload, showCSVError }) => {
    const [errors, setErrors] = useState(inviteErrors);
    const closeWithKeyPress = (e) => {
        if (e.key === 'Escape') closeInviteErrors();
    };

    useEffect(() => {
        setErrors(inviteErrors);
    }, [inviteErrors]);

    useEffect(() => {
        if (!closeInviteErrors) return;
        window.addEventListener('keyup', closeWithKeyPress);
        return () => window.removeEventListener('keyup', closeWithKeyPress);
    }, []);

    const columns = [
        columnHelper.accessor('email', {
            meta: {
                className: 'flex-grow'
            }
        }),
        columnHelper.accessor('error')
    ];

    const tableData = _.sortBy(errors, 'error');

    return (
        <div className='EventAudience__InviteErrors--Wrapper'>
            <div className={`EventAudience__InviteErrors__Dialog flex flex-start column ${showCSVError ? 'EventAudience__InviteErrors--CSVError' : ''}`}>
                <TopAppBar static className='flex' padded={false}>
                    <Heading number={6} className='padded__left'>Audience Import Errors</Heading>
                    <IconButton onClick={closeInviteErrors} name='close-round' />
                </TopAppBar>
                <div className='padded stretch flex-grow EventAudience__InviteErrors flex column'>

                    {showCSVError && !errors.length &&
                        <>
                            <div className='EventAudience__CSVError flex-grow'>
                                <Subtitle className='half-spacing__bottom block' number={3}>There was an error parsing the uploaded CSV.</Subtitle>
                                <Body className='half-spacing__bottom block' number={2}>Common issues:</Body>
                                <List items={[
                                    {
                                        name: 'CSV doesn\'t include one of the following headers: email, emails or EMAIL_ADDRESS1'
                                    }, {
                                        name: 'No valid emails found within the csv'
                                    }
                                ]} />
                            </div>
                            <Button type='primary' onClick={closeInviteErrors} className='align-self-flex-end spacing__top'>OK</Button>
                        </>}

                    {!!errors.length &&
                        <>
                            <Subtitle number={1} className='flex flex-start spacing__bottom'>
                                <Icon name='warning-round' color='error' className='half-spacing__right' />
                                {errors.length} {checkPlural('Error', errors)}
                            </Subtitle>
                            <div className='EventAudience__InviteErrors__Table--Wrapper stretch-width flex-grow'>
                                <Table
                                    data={tableData}
                                    columns={columns}
                                    className='EventAudience__InviteErrors__Table'
                                />
                            </div>
                            <ButtonDownloadCSV CSVData={tableData} CSVHeaders={['email', 'error']} CSVName='EventInviteErrors' handlePostDownload={handlePostDownload} className='align-self-flex-end spacing__top' />
                        </>}

                </div>
            </div>
        </div>
    );
};

export default EventAudienceInviteErrors;

EventAudienceInviteErrors.proptypes = {
    inviteErrors: PropTypes.array,
    closeInviteErrors: PropTypes.func,
    handlePostDownload: PropTypes.func,
    showCSVError: PropTypes.bool
};
