export const createSeasonFields = [
    {
        id: 'seasonNumber',
        label: 'Season Number',
        type: 'wholeNumber',
        maxLength: '5',
        validation: 'wholeNumber',
        hideErrorStyles: true
    },
    {
        id: 'seasonName',
        label: 'Season Name (optional)',
        type: 'text',
        autoPopulate: {
            concat: ['seriesName', 'seasonNumber']
        }
    }
];

const seasonField = {
    id: 'season',
    type: 'select',
    searchable: true,
    conditionalFields: {
        createNew: [...createSeasonFields]
    }
};

export const createSeries = {
    id: 'seriesName',
    label: 'Series Name',
    type: 'text',
    errorMessage: 'Series Name is required'
};

const episodeNumberField = {
    id: 'episodeNumber',
    label: 'Episode Number',
    type: 'wholeNumber',
    maxLength: '5',
    validation: 'wholeNumber',
    hideErrorStyles: true
};

export const createEpisode = [
    {
        id: 'episodeName',
        label: 'Episode Name',
        type: 'text'
    },
    { ...episodeNumberField }
];

export const episodeSelect = {
    id: 'episodeId',
    label: 'Episode',
    type: 'select',
    searchable: true
};

export const internalTitle = {
    id: 'internalTitle',
    label: 'Internal Title (optional)',
    type: 'text',
    conditionalHide: {
        id: 'type',
        value: 'promo'
    }
};

export const createPresentationConfig = {
    requiredFields: ['name', 'type', 'acl', 'aclType'],
    requiredEpisodeFields: ['episodeNumber'],
    requiredSeriesFields: ['seriesName', 'seasonNumber'],
    requiredSeasonFields: ['seasonNumber'],
    fields: [
        {
            id: 'name',
            type: 'text',
            errorMessage: 'Name is required'
        },
        {
            id: 'type',
            type: 'select',
            appConfigData: 'presentationTypes',
            removeOptions: [
                {
                    value: 'season'
                }
            ],
            conditionalFields: {
                episode: [
                    { ...episodeNumberField },
                    {
                        id: 'seriesId',
                        label: 'Series',
                        type: 'select',
                        searchable: true
                    }
                ]
            },
            relatedValues: {
                series: {
                    id: 'seriesId',
                    value: 'createNew'
                }
            }
        },
        {
            ...internalTitle
        },
        {
            type: 'ACLSelect'
        },
        {
            id: 'playbackSource',
            type: 'select',
            appConfigData: 'processingPlaybackSource',
            label: 'Playback Source',
            defaultVal: 'debut',
            featureFlag: 'VPPIntegration'
        }
    ]
};

export const addEpisodeConfig = {
    requiredFields: ['episodeId', 'seasonId'],
    requiredNewEpisode: ['episodeName', 'episodeNumber'],
    requiredNewSeason: ['seasonName', 'seasonNumber'],
    fields: [
        {
            ...episodeSelect,
            validation: 'uniqueEpisodeNumber',
            errorMessage: ['Series already has this episode number'],
            conditionalFields: {
                createNew: [
                    ...createEpisode
                ]
            }
        },
        { ...seasonField }
    ]
};

export const customAvailableContentFormat = {
    series: ['jpg', 'jpeg', 'png', 'pdf']
};
