import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { ApolloProvider, ApolloClient, InMemoryCache, HttpLink, ApolloLink, from } from '@apollo/client';
import App from './components/App';
import * as serviceWorker from './serviceWorker';

import { onError } from '@apollo/client/link/error';

const uri = '/api/graphql';

const httpLink = new HttpLink({ uri, credentials: 'same-origin' });

const authMiddleware = new ApolloLink((operation, forward) => {
    // const token = localStorage.getItem('token');

    console.log({ operation });

    const headers = {};

    // if(operation.operationName === "login"){
    // headers = {}
    // } else {
    //   headers = token ? {'X-API-KEY': token,}:{}
    // }

    operation.setContext({
        headers
    });

    return forward(operation);
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
        graphQLErrors.map(({ message, path }) => {
            console.log(
                `[GraphQL error]: Message: ${message}, Path: ${path}`
            );
            return message;
        });
    }

    if (networkError) console.log(`[Network error]: ${networkError}`);
});

const defaultOptions = {
    watchQuery: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'all'
    },
    query: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'all'
    }
};

const client = new ApolloClient({
    link: from([authMiddleware, errorLink, httpLink]),
    cache: new InMemoryCache({ addTypename: false }),
    defaultOptions
});

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
    <StrictMode>
        <ApolloProvider client={client}>
            <App />
        </ApolloProvider>
    </StrictMode>);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
