import { createContext, useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import Snackbar from '../components/modules/Snackbar';

export const SnackbarContext = createContext();

const SnackbarContextProvider = ({ children }) => {
    const currentRoute = useLocation();
    const [snackbar, setSnackbar] = useState({
        open: false
    });

    const makeSnackbar = (snackbarOptions) => setSnackbar({ ...snackbarOptions, open: true });
    const successSnackbar = (snackbarOptions) => setSnackbar({ type: 'success', open: true, ...snackbarOptions });
    const errorSnackbar = (snackbarOptions) => {
        console.log('ERROR SNACKBAR', snackbarOptions);
        setSnackbar({ type: 'error', open: true, ...snackbarOptions });
    };
    const warningSnackbar = (snackbarOptions) => setSnackbar({ type: 'warning', open: true, ...snackbarOptions });
    const loadingSnackbar = (snackbarOptions) => setSnackbar({ type: 'loading', open: true, ...snackbarOptions });
    const closeSnackbar = () => setTimeout(() => setSnackbar({ open: false }), 1000);

    useEffect(() => closeSnackbar, [currentRoute]);

    return (
        <SnackbarContext.Provider value={{ snackbar, setSnackbar, successSnackbar, errorSnackbar, warningSnackbar, closeSnackbar, loadingSnackbar, makeSnackbar }}>
            {children}
            <Snackbar
                {...snackbar}
                handleClose={snackbar.handleClose ?? closeSnackbar}
            />
        </SnackbarContext.Provider>
    );
};

export default SnackbarContextProvider;

SnackbarContextProvider.propTypes = {
    children: PropTypes.object
};
