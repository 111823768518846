import { useContext } from 'react';
import { AsperaUploadContext } from './AsperaUploadDialog';
import DialogFooter from '../modules/DialogFooter';
import Icon from '../modules/Icon';
import Accordion from '../modules/Accordion';
import List from '../modules/List';
import TopAppBar from '../modules/TopAppBar';
import { Body, Heading } from '../modules/Typography';
import { checkPlural } from '../../utils/checkPlural';

const AsperaUploadConfirm = props => {
    const { handleClose, failedFiles, uploadFiles, transferedFiles, presentationDetails } = useContext(AsperaUploadContext);
    const failedCount = failedFiles?.length ?? 0;
    const successCount = uploadFiles?.length - failedCount;
    const fileIsPlural = checkPlural('file', failedFiles);

    return (
        <>
            <TopAppBar>
                <Heading number={6}>Uploaded Files{presentationDetails?.name ? `: ${presentationDetails.name}` : ''}</Heading>
            </TopAppBar>
            <div className='AsperaUpload__Confirm flex column padded'>
                {successCount > 0 &&
                    <Body number={1}><Icon name='check-circle' color='success' className='half-spacing__right' />Created {successCount} files</Body>}
                {
                    transferedFiles > 0 &&
                        <Body number={1}><Icon name='check-circle' color='success' className='half-spacing__right' />Uploaded {transferedFiles} files</Body>

                }
                {
                    failedCount > 0 &&
                    <Accordion
                        type='advancedDetails'
                        title={`Show ${fileIsPlural}`}
                        closeTitle={`Hide ${fileIsPlural}`}
                        headerContent={
                            <Body number={1}>
                                <Icon name='warning-round' color='error' className='half-spacing__right block' />
                                {failedCount} {fileIsPlural} failed to be created and uploaded
                            </Body>
                        }>
                        <div className='BulkList__wrapper'>
                            <List items={failedFiles.map(error => ({ name: error.name }))} className='BulkList' />
                        </div>
                    </Accordion>
                }
            </div>
            <DialogFooter
                handleConfirm={handleClose}
                confirmText='Done'
            />
        </>
    );
};

export default AsperaUploadConfirm;
