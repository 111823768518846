import PropTypes from 'prop-types';
import MultiSelect from './MultiSelect';
import TextFieldLabel from './TextFieldLabel';
import ChipSetEditable from './ChipSetEditable';

const ChipSelect = ({ label, chips, handleChips, options, className, id, selectPlaceholder, searchable, sortOptions, required, addLabel = true, classNameChips = 'ChipSetEditable--chips' }) => {
    const handleSelect = ({ value }) => {
        const currOpt = options.find(option => option.text === value);
        const checked = chips?.some(chip => chip === currOpt.value);
        handleChips(checked ? chips.filter(chip => chip !== currOpt.value) : [...(chips ?? []), currOpt.value]);
    };

    return (
        <div className={`ChipSetSelect ${className} ${searchable ? 'ChipSetSelect--searchable' : ''}`}>
            {
                addLabel && label &&
                <TextFieldLabel
                    label={label}
                    required={required}
                />
            }
            <ChipSetEditable
                id={id}
                chips={chips ?? []}
                allowRemove
                handleChips={(updatedChips) => handleChips(updatedChips)}
                classNameChips={classNameChips}
            />
            <MultiSelect
                searchable={searchable}
                options={sortOptions
                    ? options.sort((a, b) => a.value.localeCompare(b.value))
                    : options}
                selectedValue={chips ?? []}
                onChange={handleSelect}
                buttonCopy={selectPlaceholder ?? `Select ${label}`}
            />
        </div>
    );
};

export default ChipSelect;

ChipSelect.defaultProps = {
    options: [],
    chips: [],
    searchable: false,
    required: false,
    sortOptions: false
};

ChipSelect.proptypes = {
    label: PropTypes.string,
    chips: PropTypes.array,
    handleChips: PropTypes.func,
    className: PropTypes.string,
    options: PropTypes.array,
    id: PropTypes.string.isRequired,
    selectPlaceholder: PropTypes.string,
    searchable: PropTypes.bool,
    sortOptions: PropTypes.bool,
    required: PropTypes.bool
};
