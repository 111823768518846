import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Icon from './Icon';
import Box from './Box';
import { Subtitle } from './Typography';

const ContentAccordion = props => {
    const [open, setOpen] = useState(props.initOpen);

    const toggle = () => { setOpen(!open); };

    useEffect(() => setOpen(props.initOpen), [props.initOpen]);

    return (
        <Box
            type='white'
            borderType='none'
            shadow
            elevated={2}
            className={`
						flex 
						column 
                        margin
                        ${props.className}
                        ContentAccordion__wrapper
					`}
            style={{
                height: 'auto'
            }}
        >
            <div className={`ContentAccordion ContentAccordion--${props.type} ${props.padded ? 'ContentAccordion--padded' : ''}`}>
                <div className='ContentAccordion__header' onClick={toggle}>
                    <Subtitle>{props.title}</Subtitle>
                    <Icon name={open ? 'caret-up' : 'caret-right'} />
                </div>
                {open &&
                    <div className='ContentAccordion__content'>
                        {props.children}
                    </div>}
            </div>
        </Box>
    );
};

export default ContentAccordion;

ContentAccordion.propTypes = {
    // title of the ContentAccordion
    title: PropTypes.string,

    // style of the ContentAccordion
    type: PropTypes.oneOf(['default']),

    // whether or not it intializes open
    initOpen: PropTypes.bool,
    children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),

    // whether or not the ContentAccordion is padded below
    padded: PropTypes.bool,

    className: PropTypes.string
};

ContentAccordion.defaultProps = {
    type: 'default',
    initOpen: true,
    padded: false,
    className: ''
};
