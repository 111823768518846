import PropTypes from 'prop-types';
import Checkbox from '../Checkbox';

const TableCheckbox = ({ table, row }) => {
    const value = row.original.id;
    const { data, state, setSelectedItems } = table.options;
    const { selectedItems, defaultChecked, currentItems } = state;

    return (
        <Checkbox
            handleCheck={({ checked }) => setSelectedItems(checked ? [...selectedItems, { ...data.find(data => data.id === value) }] : selectedItems.filter(item => item.id !== value))}
            value={value}
            checked={defaultChecked || selectedItems?.some(item => item.id === value)}
            disabled={!!currentItems?.length && currentItems?.some(currentItem => currentItem.id === value || currentItem.presentationId === value)}
        />
    );
};

export default TableCheckbox;

TableCheckbox.propTypes = {
    row: PropTypes.object
};
