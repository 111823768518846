import { useEffect, useState } from 'react';
import Select from './Select';

const PaginationLazyLoading = ({ className, paginationData }) => {
    const { totalCount, page, limit, handleLimitChange } = paginationData;
    const amountLoaded = limit * page;
    const showCount = totalCount <= amountLoaded ? totalCount : amountLoaded;

    const [selectedLimit, setSelectedLimit] = useState(25);

    const paginationOptions = [
        { text: '25', value: 25 },
        { text: '50', value: 50 },
        { text: '100', value: 100 },
        { text: '500', value: 500 },
        { text: 'All', value: 'All' }
    ];

    useEffect(() => {
        if (!limit) return setSelectedLimit(25);
        if (limit === selectedLimit) return;
        if (paginationOptions.some(paginationOption => paginationOption.value === limit)) return setSelectedLimit(limit);
    }, [limit]);

    const onChange = ({ value }) => {
        setSelectedLimit(value);
        handleLimitChange(value === 'All' ? totalCount : value);
    };

    return (
        <div className={`Pagination Pagination__header Pagination__LazyLoading flex space-between ${className ?? ''}`}>
            {
                !!totalCount &&
                    <>
                        <div>
                            <p className='Pagination__range'>1 - {showCount ?? selectedLimit} of {totalCount} results</p>
                        </div>
                        <Select
                            label={`Load ${selectedLimit} results`}
                            options={paginationOptions}
                            selectedValue={selectedLimit}
                            onChange={onChange}
                            className='flex Pagination__Select center'
                        />
                    </>
            }

        </div>
    );
};

export default PaginationLazyLoading;
