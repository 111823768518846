import { useContext } from 'react';
import { useMutation } from '@apollo/client';
import { Body } from '../../modules/Typography';
import Select from '../../modules/Select';
import { FileProcessingJobContext } from '../FileProcessingJob';
import { SnackbarContext } from '../../../contexts/SnackbarContextProvider';
import { DataContext } from '../../../contexts/DataContext';
import { UPDATE_FILE } from '../../../queries';
import { formatValues } from '../../../utils/formatValues';
import { FeatureFlagContext } from '../../../contexts/FeatureFlagContext';
import { FileProcessingDialogContext } from '../FileProcessingDialog';

const JobDetails = props => {
    const { errorSnackbar, successSnackbar } = useContext(SnackbarContext);
    const { checkFeatureFlag } = useContext(FeatureFlagContext);
    const { selectedPresentation } = useContext(FileProcessingDialogContext);
    const { appConfig } = useContext(DataContext);
    const { fileTypes: initFileTypes } = appConfig;
    const { fileProcessingConfig, fileDetails, setCurrentFile, currentFile, filesToProcess, setFilesToProcess } = useContext(FileProcessingJobContext);
    const { id, type, videoType } = currentFile;
    const { details } = fileProcessingConfig || [];

    const [updateFile] = useMutation(UPDATE_FILE);

    const selectedType = videoType === 'dubbing credit' ? 'videoDubCard' : type;
    const isVpp = !!selectedPresentation.vppPlayback;

    const fileTypes = isVpp && checkFeatureFlag('VPPIntegration')
        ? initFileTypes
        : initFileTypes?.filter(fileType => fileType.id !== 'videoDubCard');

    const handleType = async ({ value }) => {
        const isDubCard = value === 'videoDubCard';
        const type = isDubCard ? 'video' : value;
        const videoType = isDubCard || value === 'video'
            ? {
                videoType: value === 'videoDubCard' ? 'dubbing credit' : 'primary'
            }
            : {};
        try {
            const variables = { id, type };
            const { data } = await updateFile({ variables });
            const updatedFile = {
                ...data.file,
                ...videoType
            };
            const files = [...filesToProcess];
            files[files.findIndex(file => file.id === id)] = updatedFile;
            setFilesToProcess(files);
            setCurrentFile(updatedFile);
            successSnackbar({ text: 'File type updated' });
        } catch (err) {
            errorSnackbar({ text: 'There was an error updating file type' });
        }
    };

    return (
        <div className='FileProcessing__Details flex flex-start column'>
            <Select
                options={fileTypes?.map(fileType => {
                    return { text: fileType.display, value: fileType.id };
                })} onChange={handleType} label='Type' selectedValue={selectedType ?? ''}
            />
            {fileDetails && details?.map(detail =>
                <Body key={detail.id.split(' ').join('')} number={2}>
                    <span className='Typography--heavy capitalize'>{detail.label || detail.id}: </span>
                    {formatValues(detail.format, fileDetails?.[detail.id] || 'Not Found', appConfig)}
                </Body>
            )}
        </div>
    );
};

export default JobDetails;
