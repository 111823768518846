import { useContext } from 'react';

import Button from '../modules/Button';
import { FileContext } from '../pages/File';

const FileFooter = props => {
    const { presentationId } = useContext(FileContext);
    return (
        <div className='Footer Footer__fixed flex flex-end elevation-3'>
            <Button text='Back' type='primary' link={`/presentation/${presentationId}`} />
        </div>
    );
};

export default FileFooter;
