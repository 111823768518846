/* eslint-disable indent */
import { createContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { useLazyQuery } from '@apollo/client';
import _ from 'lodash';

import TopNav from '../layout/TopNav';
import Box from '../modules/Box';

import EventHeader from '../event/EventHeader';
import EventTabs from '../event/EventTabs';

import { CKECK_EVENT_PUBLISH_PERMISSIONS, GET_EVENT_BY_ID } from '../../queries';
import EventFooter from '../event/EventFooter';

export const EventContext = createContext();

const Event = () => {
    const { id } = useParams();
    const [event, setEvent] = useState({});
    const [refetchAudienceTab, setRefetchAudienceTab] = useState(true);
    const [refetchContentTab, setRefetchContentTab] = useState(true);
    const [initEvent, setInitEvent] = useState({});
    const [getEventById, { loading, refetch: refetchEvent }] = useLazyQuery(GET_EVENT_BY_ID, { variables: { id, extended: false } });
    const [checkPermissions, { data: checkEventPublishPermissionsData, loading: checkEventPublishPermissionsLoading, error: checkEventPublishPermissionsError }] = useLazyQuery(CKECK_EVENT_PUBLISH_PERMISSIONS, { variables: { id } });

    useEffect(() => setEvent({ ...initEvent }), [initEvent]);

    const getEvent = async () => {
        try {
            const { data } = await getEventById();
            const eventDetails = _.omitBy(data.event, _.isNil);
            setInitEvent({ ...initEvent, ...eventDetails });
            await checkPermissions();
        } catch (error) {
            if (error.name !== 'AbortError') {
                console.log(error);
            }
        }
    };

    useEffect(() => {
        if (!_.isEmpty(initEvent)) return;
        getEvent();
    }, []);

    const handleRefetchEvent = async () => {
        const { data: refetchedData } = await refetchEvent();
        if (!refetchedData.event) return;
        const { endAt, startAt } = refetchedData.event;
        const eventDetails = _.omitBy(refetchedData.event, _.isNil);
        const updatedEvent = { ...initEvent, ...eventDetails, endAt, startAt };
        setInitEvent(updatedEvent);
        return updatedEvent;
    };

    return (
        <EventContext.Provider value={{
            id,
            event,
            setEvent,
            setInitEvent,
            initEvent,
            handleRefetchEvent,
            refetchAudienceTab,
            refetchContentTab,
            setRefetchAudienceTab,
            setRefetchContentTab,
            userCanPublish: !checkEventPublishPermissionsData?.checkEventPublishPermissions?.error && !checkEventPublishPermissionsError
        }}
        >
            <Helmet><title>Event</title></Helmet>
            <TopNav heading='Event Details' />
            <div className='Event PageContent flex column flex-start align-flex-start'>
                {!loading && <EventHeader />}
                <Box
                    loading={loading}
                    type='white'
                    borderType='none'
                    shadow
                    elevated={2}
                    padded={false}
                    className={!loading ? 'EventTabs--wrapper flex-grow flex column' : ''}>
                    <EventTabs />
                </Box>
                {(!loading || !checkEventPublishPermissionsLoading) && <EventFooter />}
            </div>
        </EventContext.Provider>
    );
};

export default Event;
