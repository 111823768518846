import { useContext } from 'react';
import Button from './Button';
import ChipSet from './ChipSet';
import { TabsContext } from './Tabs';
import checkUserRole from '../../hooks/checkUserRole';

const TabsHeader = props => {
    const { activeTab, setActiveTab, tabsData } = useContext(TabsContext);

    return (
        <div className='Tabs__Header spacing__bottom'>
            {
                tabsData.map(tab => {
                    if (!!tab.role && !checkUserRole(tab.role)) return false;
                    return (
                        <Button
                            key={tab.id}
                            className={[
                                'Tabs__Button',
                                tab.id === activeTab.id ? 'Tabs__Button--active' : null
                            ].filter(i => i).join(' ')}
                            type='surface'
                            onClick={() => setActiveTab(tab)}>
                            {tab.label}
                            {tab.count && <ChipSet chips={[`${tab.count}`]} />}
                        </Button>
                    );
                })
            }
        </div>
    );
};
export default TabsHeader;
