import PropTypes from 'prop-types';
import { createContext, useEffect, useState } from 'react';
import UploadDropzone from './UploadDropzone';
import TabsHeader from './TabsHeader';

export const TabsContext = createContext();

const Tabs = ({ tabsData, initTab, className, handleTabChange, children }) => {
    const initTabData = initTab ? tabsData.find(tab => tab.id === initTab) : tabsData[0];
    const [activeTab, setActiveTab] = useState(initTabData);
    const { uploadDropzoneData } = activeTab;

    useEffect(() => {
        if (!handleTabChange) return;
        handleTabChange(activeTab);
    }, [activeTab]);

    return (
        <TabsContext.Provider value={{
            activeTab,
            setActiveTab,
            tabsData
        }}>
            <div className={[
                'Tabs',
                uploadDropzoneData ? 'flex column stretch-width' : '',
                className
            ].filter(i => i).join(' ')}>
                <UploadDropzone {...(uploadDropzoneData || {})} disabled={!uploadDropzoneData} className='flex-grow stretch-width'>
                    <TabsHeader />
                    <div className='Tabs__Content'>
                        {children ?? activeTab.content}
                    </div>
                </UploadDropzone>
            </div>
        </TabsContext.Provider>
    );
};

export default Tabs;

Tabs.defaultProps = {
    className: ''
};

Tabs.propTypes = {
    tabsData: PropTypes.array,
    className: PropTypes.string,
    children: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
};
