import { useContext } from 'react';
import { DateTime } from 'luxon';

import Box from '../modules/Box';
import { Body, Heading, Caption, Subtitle } from '../modules/Typography';

import DeletePresentationDialog from '../dialogs/DeletePresentationDialog';

import { PresentationContext } from '../pages/Presentation';
import { SnackbarContext } from '../../contexts/SnackbarContextProvider';
import { DialogContext } from '../../contexts/DialogContextProvider';
import Tooltip from '../modules/Tooltip';

import checkUserRole from '../../hooks/checkUserRole';
import getThumbnail from '../../hooks/getThumbnail';
import IconButton from '../modules/IconButton';
import Icon from '../modules/Icon';
import PresentationAutoPublish from './PresentationAutoPublish';
import PresentationDeepLink from './PresentationDeepLink';

const PresentationHeader = () => {
    const roleProducer = checkUserRole('ROLE_PRODUCER');
    const { presentationData, setPresentationData, doc, refetchPresentation } = useContext(PresentationContext);
    const { status } = doc.publish ?? {};
    const { live, preview } = status || {};
    const imageSrc = getThumbnail(doc, 'images', 150);
    const deleteDisabled = !doc ? false : (live?.value !== 'unpublished' || preview?.value !== 'unpublished');

    const { warningSnackbar } = useContext(SnackbarContext);
    const { makeDialog } = useContext(DialogContext);

    const handleDelete = async () => {
        const { data } = await refetchPresentation();
        // TODO: Remove this addtional catch when publishing doesn't happen in felix
        const { doc: currentDoc } = data.presentation || {};
        const { live: liveStatus, preview: previewStatus } = currentDoc?.publish?.status || {};
        if (!!currentDoc && (liveStatus?.value !== 'unpublished' || previewStatus?.value !== 'unpublished')) {
            return warningSnackbar({ text: 'Must be unpublished in every environment to delete.' });
        }

        makeDialog({
            dialog: <DeletePresentationDialog key={Math.random()} size='medium' presentation={presentationData} />,
            title: 'Delete Presentation',
            padded: true
        });
    };

    const presentationActions = [
        {
            icon: 'info',
            tooltip: <>
                <Body number={2} block><span className='Typography--heavy'>Created:</span> {DateTime.fromMillis(Number(presentationData.createdAt)).toFormat('LL/dd/y h:mm a')} by {presentationData.createdBy}</Body>
                <Body number={2} block><span className='Typography--heavy'>Updated:</span> {DateTime.fromMillis(Number(presentationData.updatedAt)).toFormat('LL/dd/y h:mm a')} by {presentationData.updatedBy}</Body>
            </>
        },
        {
            tooltip: deleteDisabled ? 'Must be unpublished in every environment to delete.' : 'Delete',
            icon: 'trash-can',
            disabled: deleteDisabled,
            onClick: handleDelete
        }
    ];

    const icon = {
        dirty: {
            name: 'warning-round',
            color: 'warning'
        },
        unpublished: {
            name: 'stop',
            faded: true
        },
        published: {
            name: 'check-circle',
            color: 'success'
        }
    };

    const statusIcon = (env) => {
        const envStatus = status?.[env]?.value;
        if (!envStatus) return;
        return (
            <Tooltip
                key={env}
                icon={icon[envStatus].name}
                iconColor={icon[envStatus].color ?? 'default'}
                iconFaded={icon[envStatus].faded}
                iconReverse
                className='PresentationHeader__Status'
                tooltipCopy={envStatus === 'dirty' ? 'modified' : envStatus}
                color='custom'
            >
                <Caption className='half-spacing__left spacing__right capitalize'>{env}</Caption>
            </Tooltip>
        );
    };

    return (
        <Box
            type='white'
            borderType='none'
            shadow
            elevated={2}
            className={`
						flex 
                        align-flex-start
                        margin
                        PresentationHeader
					`}
            style={{
                height: 'auto'
            }}
        >
            <div className='flex align-stretch'>
                <img src={`${imageSrc}`} className='spacing__right rounded' />
                <div className='flex column'>
                    <div>
                        <Heading number={5}>{presentationData.name}</Heading>
                        <div className='flex flex-start PresentationHeader__PresentationDetails'>
                            <Subtitle number={1} className='spacing__right capitalize'>{presentationData.type}</Subtitle>
                            {['preview', 'live'].map(env => statusIcon(env))}
                        </div>
                        {presentationData.type !== 'series' &&
                            <Body number={2}><span
                                className='Typography--semiBold'>Playback Source:</span> {presentationData.vppPlayback ? 'VPP' : 'Debut'}
                            </Body>}
                    </div>
                    {presentationData && <PresentationAutoPublish presentation={presentationData} setPresentation={setPresentationData} />}
                </div>
            </div>
            {
                roleProducer &&
                <div className='flex'>
                    <PresentationDeepLink presentation={presentationData} />
                    {presentationActions.map(action =>
                        <Tooltip
                            key={action.icon}
                            tooltipCopy={action.tooltip}
                            className={`PresentationAction PresentationAction--${action.icon} spacing__right`}
                            location='bottom'
                            disabled={action.disabled}
                        >
                            {action.onClick
                                ? (
                                    <IconButton
                                        name={action.icon}
                                        onClick={action.onClick}
                                        disabled={action.disabled}
                                    />
                                )
                                : <Icon name={action.icon} />}
                        </Tooltip>
                    )}
                </div>
            }
        </Box>

    );
};

export default PresentationHeader;
