import { useEffect, useState, useContext } from 'react';
import _ from 'lodash';
import { DataContext } from '../../contexts/DataContext';
import { UserContext } from '../../contexts/UserContextProvider';
import PropTypes from 'prop-types';
import MultiSelect from './MultiSelect';
import { authorized, subjectsFromUser, mergeAcl, hasACLAccess } from '../../utils/acl';
import { Caption } from './Typography';
import Icon from './Icon';

const ACLSelect = ({ className, onChange, label, initACLs, initAccess, hideLabel, aclChips, hideError }) => {
    const [aclOptions, setAclOptions] = useState([]);
    const [ACLs, setACLs] = useState([]);
    const [selectedACLs, setSelectedACLs] = useState(initACLs);
    const [aclAccess, setACLAccess] = useState(initAccess);
    const { appConfig } = useContext(DataContext);
    const { user } = useContext(UserContext);

    const userHasACLAccess = hasACLAccess(user, aclAccess);

    const handleACL = ({ value }) => {
        const currentACLs = selectedACLs.includes(value) ? selectedACLs.filter(e => e !== value) : [...selectedACLs, value];
        const aclResult = currentACLs.reduce((acc, curr) => {
            const newAcl = ACLs.get(curr);
            if (!newAcl) return acc;
            return mergeAcl(acc, newAcl);
        }, []);
        setSelectedACLs(currentACLs);
        setACLAccess(aclResult);
        onChange(currentACLs, aclResult);
    };

    useEffect(() => setSelectedACLs(initACLs), [initACLs]);
    useEffect(() => setACLAccess(initAccess), [initAccess]);

    useEffect(() => {
        let ACLEntries = appConfig.ACLs;
        if (user) {
            const subs = subjectsFromUser(user);
            ACLEntries = _.pickBy(ACLEntries, function (acl, key) {
                if (acl.some(a => subs.includes(a.subject) && authorized(acl, ['R'], subs))) return { [key]: acl };
            });
        }

        ACLEntries = Object.entries(ACLEntries).sort();

        const aclOptions = ACLEntries.map(([key, _]) => {
            return { text: key, value: key };
        });

        const ACLs = new Map(ACLEntries);

        if (selectedACLs.length === 0 && aclOptions.length === 1) {
            const userValue = aclOptions[0].value;
            const userAccess = mergeAcl([], ACLs.get(userValue));
            setACLAccess(userAccess);
            onChange([userValue], userAccess);
        }

        setAclOptions(aclOptions);
        setACLs(ACLs);
    }, [appConfig]);

    return (
        <>
            <MultiSelect
                searchable
                className={`ACLSelect ${className}`}
                options={aclOptions}
                label={hideLabel ? null : label}
                onChange={handleACL}
                selectedValue={selectedACLs}
                buttonCopy={aclChips ? 'Search for additional group access' : null}
            />
            {(!userHasACLAccess && !hideError && !!selectedACLs.length) &&
                <Caption className='ACLSelect__Caption flex flex-start align-flex-end'>
                    <Icon name='warning' color='warning' size='medium' />
                    <span>Your current profile doesn't have access to selected group access</span>
                </Caption>}
        </>
    );
};

export default ACLSelect;

ACLSelect.defaultProps = {
    label: 'Group Access*',
    initACLs: [],
    initAccess: [],
    hideError: false
};

ACLSelect.propTypes = {
    // function to handle select change event
    onChange: PropTypes.func,
    className: PropTypes.string,
    label: PropTypes.string,
    initACLs: PropTypes.array,
    aclChips: PropTypes.bool,
    initAccess: PropTypes.array,
    hideError: PropTypes.bool
};
