import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Select from './Select';
import Button from './Button';
import PaginationRange from './PaginationRange';

const Pagination = ({ paginationData }) => {
    if (!paginationData) return;
    const { totalCount, limit, page, updatePagination } = paginationData;
    const [totalPages, setTotalPages] = useState('');
    const [selectedVal, setSelectedVal] = useState(limit);

    const paginationOptions = [
        { text: '10', value: 10 },
        { text: '50', value: 50 },
        { text: '100', value: 100 },
        { text: '500', value: 500 },
        { text: 'All', value: 'All' }
    ];

    const controls = [
        {
            icon: 'first',
            disabled: page === 1,
            pageValue: 1
        },
        {
            icon: 'caret-left',
            disabled: page === 1,
            pageValue: page - 1
        },
        {
            icon: 'caret-right',
            disabled: page === totalPages,
            pageValue: page + 1
        },
        {
            icon: 'last',
            disabled: page === totalPages,
            pageValue: totalPages
        }
    ];

    const onSelectChange = (e) => {
        if (e.value === 'All') {
            updatePagination({
                limit: totalCount,
                page: 1
            });
        } else {
            const currentIndex = limit * page - limit + 1;
            updatePagination({
                limit: e.value,
                page: (currentIndex < e.value) ? 1 : Math.ceil(currentIndex / e.value)
            });
            setSelectedVal(e.value);
        }
    };

    useEffect(() => setTotalPages(Math.ceil(totalCount / limit)), [totalCount, limit]);

    useEffect(() => {
        if (!paginationOptions.some(v => v.value === limit)) setSelectedVal('All');
    }, []);

    return (
        <div className='Pagination Pagination__footer flex flex-end'>

            <Select
                className='flex center'
                label='Rows Per Page:'
                selectedValue={selectedVal}
                options={paginationOptions}
                searchable={false}
                onChange={onSelectChange}
            />

            <PaginationRange paginationData={paginationData} />

            <div className='Pagination__controls'>
                {controls.map(control =>
                    <Button
                        key={control.icon}
                        className='Button'
                        icon={control.icon}
                        type='surface'
                        disabled={control.disabled}
                        onClick={() => updatePagination({ page: control.pageValue, limit })}
                    />
                )}
            </div>

        </div>
    );
};

Pagination.propTypes = {
    paginationData: PropTypes.object
};

export default Pagination;
