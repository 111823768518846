import { useEffect, useContext, useState } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import { Body } from '../modules/Typography';
import Icon from '../modules/Icon';
import Accordion from '../modules/Accordion';
import { SnackbarContext } from '../../contexts/SnackbarContextProvider';
import TextField from '../modules/TextField';
import { UPDATE_FILE } from '../../queries';
import DialogFooter from '../modules/DialogFooter';

const FileProcessingRename = ({ processingFiles, setProcessingFiles }) => {
    const { successSnackbar, errorSnackbar } = useContext(SnackbarContext);
    const [currentFile, setCurrentFile] = useState(processingFiles[0]);
    const { id, name: initialName } = currentFile;
    const [name, setName] = useState(initialName);
    const [errorMessage, setErrorMessage] = useState('');
    const currentIndex = processingFiles.findIndex(file => file.id === currentFile.id);

    const lastFile = _.isEqual(_.last(processingFiles), currentFile);

    const [updateFile] = useMutation(UPDATE_FILE);

    useEffect(() => setName(initialName), [currentFile]);

    useEffect(() => {
        // eslint-disable-next-line no-control-regex
        const fileRegex = /^(?!.*[<>:"/\\|?*\x00-\x1F]).*$/;
        let message = '';
        if (initialName.includes('.') && name.split('.').pop() !== initialName.split('.').pop()) {
            message += '\n\u2022 File extension changed';
        }
        if (!fileRegex.test(name)) {
            message += '\n\u2022 No special characters';
        }
        if (name.length > 260) {
            message += "\n\u2022 Filename shouldn't be larger than 260 characters";
        }
        if (name === '') {
            message = '\n\u2022 Filename is required';
        }
        setErrorMessage(message);
    }, [name]);

    const handleNextClick = async () => {
        const filesCopy = [...processingFiles];
        const file = filesCopy.find(f => f.id === id);
        if (name !== initialName) {
            try {
                const variables = { id, name };
                const { data } = await updateFile({ variables });
                successSnackbar({ text: 'Updated filename' });
                file.name = data.file.name;
                file.updated = true;
            } catch (error) {
                file.updated = false;
                errorSnackbar({ text: 'Failed to update filename' });
            }
        } else {
            file.updated = true;
        }

        if (lastFile) {
            setProcessingFiles([]);
        } else {
            setProcessingFiles(filesCopy);
            setCurrentFile(processingFiles[currentIndex + 1]);
        }
    };

    return (
        <>
            <div className='FileProcessing__Rename padded'>
                <Accordion
                    className='FileProcessing__Files--Accordion'
                    initOpen={processingFiles.length <= 10 && processingFiles.length > 1}
                    title={
                        <>
                            <span className='Accordion__toggle--fileName colorBase'>
                                <span className='bold'>Rename File: </span>
                                {currentFile.name}
                            </span>
                            <span className='clickable'>{`Show Files (${processingFiles.length})`}</span>
                        </>
                    }
                    closeTitle={
                        <>
                            <span className='Accordion__toggle--fileName colorBase'>
                                <span className='bold'>Rename File: </span>
                                {currentFile.name}
                            </span>
                            <span className='clickable'>Hide Files</span>
                        </>
                    }
                    type='advancedDetails'>
                    <div className='FileProcessing__Files--wrapper padded'>
                        <div className='FileProcessing__Files FileProcessing__Files--list'>
                            {
                                processingFiles.map((job, idx) =>
                                    <div key={job.id} className={`FileProcessing__Files--list--file flex ${job.id === id && 'current'}`}>
                                        <Body number={2} disabled={idx < currentIndex}>{job.name}</Body>
                                        {job.updated &&
                                        <Icon name={job.updated ? 'check' : 'close'} className={`spacing__left ${job.updated ? 'success' : 'error'}`} />}
                                    </div>
                                )
                            }
                        </div>
                    </div>
                </Accordion>
                <TextField
                    label='Filename'
                    value={name}
                    error={!!errorMessage}
                    errorMessage={errorMessage}
                    maxLength='260'
                    onChange={({ target }) => setName(target.value)}
                />
            </div>
            <DialogFooter
                disabled={!!errorMessage}
                handleCancel={() => setProcessingFiles([])}
                handleConfirm={handleNextClick}
                confirmText={lastFile ? 'Submit' : 'Next'}
            />
        </>
    );
};

export default FileProcessingRename;

FileProcessingRename.propTypes = {
    processingFiles: PropTypes.array,
    setProcessingFiles: PropTypes.func
};
