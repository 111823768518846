import Checkbox from '../modules/Checkbox';

const PresentationAutoPublishCheckbox = ({ value: publishingEnvironments, onChange, disabled = {} }) => {
    const environmentCheckboxes = ['preview', 'live'];
    return environmentCheckboxes.map(environment =>
        <Checkbox
            key={environment}
            className='spacing__right spacing__bottom capitalize'
            label={environment}
            value={environment}
            disabled={disabled[environment]}
            inline
            handleCheck={({ value, checked }) => {
                const environments = checked
                    ? [...publishingEnvironments, value]
                    : [...publishingEnvironments.filter(env => env !== value)];
                onChange(environments);
            }}
            checked={publishingEnvironments.includes(environment)}
        />
    );
};

export default PresentationAutoPublishCheckbox;
