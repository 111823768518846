/* eslint-disable indent */
import { createContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Body } from './Typography';
import Button from './Button';
import Icon from './Icon';

export const StepsContext = createContext();

const Steps = props => {
    const {
        stepsData,
        initStep,
        handleCancel,
        cancelCopy,
        stepBackCopy,
        handleFinalStep,
        finalStepConfirmCopy,
        confirmCopy,
        handleCurrentStep,
        disableNext,
        errorCount,
        loading,
        type,
        dialog,
        children,
        footerCopy
    } = props;
    const [activeStep, setActiveStep] = useState(initStep ? stepsData.find(step => step.id === initStep) : stepsData[0]);
    const activeIndex = stepsData.findIndex(step => step.id === activeStep?.id);
    const isLastStep = activeIndex === stepsData.length - 1;

    useEffect(() => {
        if (!handleCurrentStep) return;
        handleCurrentStep(activeStep);
    }, [activeStep]);

    const finalStep = async () => {
        if (handleFinalStep) await handleFinalStep();
        setActiveStep(initStep ?? stepsData[0]);
    };

    return (
        <StepsContext.Provider value={{
            stepsData,
            activeStep,
            setActiveStep
        }}>
            <div className={[
                    'Steps',
                    `Steps--${type}`,
                    isLastStep ? 'Steps--isLast' : null,
                    dialog ? 'Steps--inDialog' : null
                ].filter(i => i).join(' ')}>
                <div className={`Steps__Wrapper stretch Steps__Wrapper--${activeStep?.id}`}>
                    <div className='Steps__Header flex'>
                        {
                        stepsData.map((step, idx) => (
                            <Body
                                key={step.id}
                                className={[
                                    'Steps__Label',
                                    step.id === activeStep.id && 'Steps__Label--active',
                                    stepsData.findIndex(s => s.id === step.id) < stepsData.findIndex(s => s.id === activeStep.id) && 'Steps__Label--complete',
                                    'spacing__bottom'
                                ].filter(i => i).join(' ')}>
                                {type === 'secondary' && `Step ${idx + 1}: `}{step.label}
                            </Body>))
                    }
                    </div>
                    <div className={`Steps__Content Steps__Content--${activeStep.id} padded`}>
                        {children ?? activeStep.content}
                    </div>
                </div>

                {
                    (handleCancel || handleFinalStep) &&
                    <div className='Steps__Footer flex flex-end padded stretch-width'>
                        {!!footerCopy && <Body className='Steps__Footer__Copy' rightPadded>{footerCopy}</Body>}
                        {!!errorCount && <Body error rightPadded><Icon name='warning' color='error' /> {errorCount} error</Body>}
                        {handleCancel &&
                        <Button
                            type='secondary'
                            onClick={
                                () =>
                                    activeIndex === 0
                                        ? handleCancel()
                                        : setActiveStep(stepsData[activeIndex - 1])
                            }
                        >
                            {activeIndex === 0 ? cancelCopy : stepBackCopy}
                        </Button>}

                        {handleFinalStep &&
                            <Button
                                type='primary'
                                className='spacing__left'
                                disabled={disableNext}
                                loading={loading}
                                onClick={() =>
                                    isLastStep
                                    ? finalStep()
                                    : setActiveStep(stepsData[activeIndex + 1])}
                        >
                                {isLastStep ? finalStepConfirmCopy : confirmCopy}
                            </Button>}

                    </div>
                }

            </div>
        </StepsContext.Provider>
    );
};

export default Steps;

Steps.defaultProps = {
    confirmCopy: 'Next',
    finalStepConfirmCopy: 'Submit',
    cancelCopy: 'Cancel',
    stepBackCopy: 'Back',
    disableNext: false,
    loading: false,
    type: 'primary',
    dialog: false
};

Steps.proptypes = {
    stepsData: PropTypes.array,
    initStep: PropTypes.string,
    handleCancel: PropTypes.func,
    handleFinalStep: PropTypes.func,
    confirmCopy: PropTypes.string,
    finalStepConfirmCopy: PropTypes.string,
    cancelCopy: PropTypes.string,
    stepBackCopy: PropTypes.string,
    disableNext: PropTypes.bool,
    errorCount: PropTypes.number,
    loading: PropTypes.bool,
    type: PropTypes.oneOf(['primary', 'secondary']),
    dialog: PropTypes.bool,
    children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    footerCopy: PropTypes.string
};
