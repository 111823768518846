import { gql } from '@apollo/client';

const DOCUMENT_IMAGE_FIELDS = gql`
    fragment DocumentImageFields on Images {
        id
            type
            window
            serveAs
            assets {
                id
                aspectRatioFraction
                format
                height
                width
                size
                url
                lastUpdatedAt
                isTransparent
            }
    }
`;

export const DOCUMENT_FIELDS = gql`
    ${DOCUMENT_IMAGE_FIELDS}
	fragment DocumentFields on Document {
		metadata {
            id
            availTitle
            originalTitle
            runtime
            seo {
                title
                slug
                isPrimary
                shouldRedirect
            }
            slug
            title
            longSynopsis
            shortSynopsis
            rating
            cmsTitle
            tags
            episodeNumber
            originalAirDate
            seasonId
            seriesId
            mediumSynopsis
            synopsis
            playbackRestriction
            umid
            umidEdition
            sortTitle
            releaseYear
            copyrightInfo
            countryOfOrigin
            primarySpokenLanguage
            productionCompany
            studios
            primarySeo {
                title
                slug
                isPrimary
                shouldRedirect
            }
            publishWindow {
                endDate
                startDate
            }
            alternateIds {
                id
                type
            }
            notes
            credits {
                name
                type
                character
            }
        }
        assets {
            id
            type
            contentId
            height
            width
            duration
            url
            urls
            tags
            lastUpdatedAt
            window
            framerate
            activeWidth
            activeHeight
            offsetX
            offsetY
            name
            window
            drfdma
            fairplayKeyId
            playreadyKeyId
            widevineKeyId
        }
        images {
           ...DocumentImageFields
        }
        statuses
        availTitle
        cmsTitle
        version
        publish {
            status {
                live {
                    value
                    lastUpdatedAt
                }
                preview {
                    value
                    lastUpdatedAt
                }
            }
            state
        }
	}
`;

export const DOCUMENT_HISTORY_FIELDS = gql`
    fragment DocumentHistoryFields on DocumentHistory {
        audit {
            user {
                email
                id
            }
            message
            lastUpdatedAt
        }
        version
        id
    }
`;
