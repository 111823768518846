import { useContext, useState } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import { UPDATE_PRESENTATION } from '../../queries';
import { SnackbarContext } from '../../contexts/SnackbarContextProvider';
import Textarea from '../modules/Textarea';
import DialogFooter from '../modules/DialogFooter';
import { DialogContext } from '../../contexts/DialogContextProvider';
import { Body, Subtitle } from '../modules/Typography';
import List from '../modules/List';
import { SnackbarDialogContext } from '../../contexts/SnackbarDialogContextProvider';

const SavePresentationDialog = ({ variables, changedFields, setInitPresentation }) => {
    const [updatePresentation, { loading, error }] = useMutation(UPDATE_PRESENTATION);
    const { errorSnackbar, successSnackbar } = useContext(SnackbarContext);
    const { snackbarDialog } = useContext(SnackbarDialogContext);
    const { closeDialog } = useContext(DialogContext);
    const [errors, setErrors] = useState([]);

    const [textValue, setTextValue] = useState(changedFields);

    const handleDocUpdate = async () => {
        try {
            const { data, errors } = await updatePresentation({ variables: { ...variables, commitMessage: textValue }, errorPolicy: 'all' });
            if (!errors) {
                setInitPresentation(_.cloneDeep(data.presentation));
                closeDialog();
                if (data.presentation.partialSuccess) {
                    const { code, message } = data.presentation.partialSuccess;
                    const dialog = {
                        'auto-publishing-failed': {
                            copy: 'Auto-publish failed due to the following errors:',
                            title: 'Auto-publish Error',
                            snackbarText: '\nAuto\u2011publish failed'
                        }
                    }[code];

                    snackbarDialog({
                        snackbarType: 'warning',
                        snackbarText: `Successfully saved presentation ${dialog.snackbarText || code}`,
                        dialogTitle: dialog.title ?? 'Presentation Errors',
                        customDialogBody: <div className='padded'>
                            <Subtitle className='half-spacing__bottom block' number={3}>{data.presentation.name} changes saved.</Subtitle>
                            <Body className='half-spacing__bottom block'>{dialog.copy ?? 'Errors:'}</Body>
                            <List items={[{ name: message }]} className='BulkList' />
                        </div>
                    });
                } else {
                    successSnackbar({ text: 'Successfully updated document' });
                }
            } else {
                setErrors(errors);
            }
        } catch (err) {
            console.log(err);
            console.log(error);
            closeDialog();
            errorSnackbar({ text: 'Error updating document' });
        }
    };

    return (
        <div className='SavePresentationDialog'>
            <Textarea value={textValue} onChange={(e) => setTextValue(e.target.value)} label='Comments' />
            {!!errors.length &&
            <div className='flex column'>
                <Body error>Error(s):</Body>
                <ul>
                    {errors.map((err, i) => <li className='error' key={err.message}>{err?.extensions?.response?.body?.message || err?.message}</li>)}
                </ul>

            </div>}
            <DialogFooter
                handleCancel={closeDialog}
                handleConfirm={handleDocUpdate}
                confirmText={variables.data.autoPublishToLive || variables.data.autoPublishToPreview ? 'Publish' : 'Save'}
                disabled={textValue === ''}
                loading={loading}
            />
        </div>
    );
};

SavePresentationDialog.propTypes = {
    variables: PropTypes.object,
    changedFields: PropTypes.string,
    setInitPresentation: PropTypes.func
};

export default SavePresentationDialog;
