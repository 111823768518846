import PropTypes from 'prop-types';
import Checkbox from '../Checkbox';
import _ from 'lodash';

const TableCheckboxHeader = ({ table }) => {
    const { onClickDefault, data, state, setSelectedItems } = table.options;
    const { mergeOnDefaultChecked, selectedItems } = state;

    const altDefaultChecked = !!data.length && data.every(d => selectedItems.some(selectedItem => selectedItem.id === d.id || selectedItem.presentationId === d.id));

    const handleDefaultCheck = ({ checked }) => {
        onClickDefault();

        if (!mergeOnDefaultChecked) return setSelectedItems(checked ? data : []);
        const uniqItems = _.uniqBy([...selectedItems, ...data], 'id');
        checked
            ? setSelectedItems([...uniqItems])
            : setSelectedItems(selectedItems.filter(selectedItem => !data.some(d => selectedItem.id === d.id || selectedItem.presentationId === d.id)));
    };

    return (
        <Checkbox
            handleCheck={handleDefaultCheck}
            checked={state.defaultChecked || altDefaultChecked}
        />
    );
};

export default TableCheckboxHeader;

TableCheckboxHeader.propTypes = {
    row: PropTypes.object,
    table: PropTypes.object
};
