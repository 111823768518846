import { useContext, useEffect, useState } from 'react';
import Button from '../modules/Button';
import _ from 'lodash';

import { PresentationContext } from '../pages/Presentation';
import { UserContext } from '../../contexts/UserContextProvider';
import { DialogContext } from '../../contexts/DialogContextProvider';
import { SnackbarContext } from '../../contexts/SnackbarContextProvider';
import Tooltip from '../modules/Tooltip';
import { authorized, subjectsFromUser } from '../../utils/acl';

import { GET_DIFF } from '../../queries';
import { useLazyQuery } from '@apollo/client';
import PublishingDialog from '../dialogs/PublishDialog';
import { publishingConfig } from '../../config/publishingConfig';
import UnpublishDialog from '../dialogs/UnpublishDialog';
import MenuSurface from '../modules/MenuSurface';
import Icon from '../modules/Icon';
import checkUserRole from '../../hooks/checkUserRole';

const PresentationFooter = props => {
    const { errorSnackbar } = useContext(SnackbarContext);
    const { makeDialog } = useContext(DialogContext);
    const { user } = useContext(UserContext);
    const { handleDocUpdate, saveDisabled, errors, initPresentation, presentationData, refetchPresentation } = useContext(PresentationContext);
    const { live, preview } = presentationData.doc.publish.status;
    const { type, documentId, doc } = initPresentation || {};
    const { validators } = publishingConfig[type] || {};
    const fieldsWithErrors = _.keys(_.pickBy(errors));
    const [publishingErrors, setPublishingErrors] = useState([]);
    const canPublish = authorized(presentationData.acl, ['P'], subjectsFromUser(user));

    const [getDiff, { loading }] = useLazyQuery(GET_DIFF);

    const publishDisabled = !canPublish || !!publishingErrors.length || (live.value === 'published' && preview.value === 'published') || !checkUserRole('ROLE_PRODUCER');
    const unpublishDisabled = !canPublish || (live?.value === 'unpublished' && preview?.value === 'unpublished');

    const episodeSaveDisable = presentationData.type === 'episode' && presentationData.associations?.seriesPresentationId && !presentationData.associations?.seasonPresentationId;
    const disableSave = saveDisabled ||
    _.isEqual(
        { ...initPresentation, files: [], associatedPresentations: {}, history: [] },
        { ...presentationData, files: [], associatedPresentations: {}, history: [] }
    ) ||
    !!fieldsWithErrors.length ||
    episodeSaveDisable;

    const startPublishingProcess = async () => {
        try {
            const { data } = await getDiff({ variables: { documentId, type } });
            makeDialog({
                dialog: <PublishingDialog key={Math.random()} publishingDiff={data.diff} presentation={presentationData} refetchPresentation={refetchPresentation} />,
                title: 'Publish',
                padded: true,
                size: 'xl',
                heightSize: 'xl',
                className: 'PublishingDialog__Wrapper manual-overflow'
            });
        } catch (err) {
            console.log(err);
            errorSnackbar({ text: 'Falied to fetch publishing diff' });
        }
    };

    const handleUnpublish = () => {
        makeDialog({
            dialog: <UnpublishDialog key={Math.random()} size='medium' presentation={presentationData} refetchPresentation={refetchPresentation} />,
            title: 'Unpublish Presentation',
            padded: true,
            className: 'Presentation__UnpublishDialog'
        });
    };

    useEffect(() => {
        if (!validators) return;
        setPublishingErrors([]);
        const errors = [];
        validators.map(validator => !_.get(doc, validator.expression) && errors.push(validator.message));
        setPublishingErrors(errors);
    }, [doc]);

    return (
        <div className='Footer Footer__fixed flex flex-end elevation-3'>
            <div className={`PublishMenu__Wrapper ${publishDisabled && 'PublishMenu__Wrapper--disabled'}`}>

                <Tooltip
                    TooltipCopy={_.uniq(publishingErrors).map((error, idx) => <span key={idx} className='block'>{error}</span>)}
                    disabled={!publishingErrors.length}
                    location='top'
                >
                    <Button
                        loading={loading}
                        type='primary'
                        onClick={startPublishingProcess}
                        disabled={publishDisabled}
                    >
                        Publish
                    </Button>
                </Tooltip>

                <MenuSurface
                    disabled={unpublishDisabled}
                    className='PublishMenu'
                    options={[{
                        text: 'Unpublish',
                        value: 'Unpublish',
                        onClick: handleUnpublish

                    }]}
                >
                    <Icon name='caret-up' />
                </MenuSurface>
            </div>
            <Button
                className='spacing__left'
                text='Save'
                type='primary'
                onClick={handleDocUpdate}
                disabled={disableSave} />
        </div>

    );
};

export default PresentationFooter;
