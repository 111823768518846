import { gql } from '@apollo/client';

import { ASSOCIATED_PRESENTATIONS } from '../fragments/associatedPresentation.js';
import { PRESENTATION_DETAILED, PRESENTATION_SIMPLIFIED } from '../fragments/presentation.js';

export const SEARCH_PRESENTATIONS = gql`
	${PRESENTATION_SIMPLIFIED}
	${ASSOCIATED_PRESENTATIONS}
	query allPresentations($search: String!, $orderBy: String, $order: String, $limit: Int, $offset: Int, $types: String, $aclTypes: [String], $fetchAssociations: Boolean) {
		allPresentations(search: $search, orderBy: $orderBy, order: $order, limit: $limit, offset: $offset, types: $types, aclTypes: $aclTypes, fetchAssociations: $fetchAssociations) {
			presentations {
				...PresentationSimplified
			}
			total
		}
	}
`;

export const GET_PRESENTATION_BY_ID = gql`
	${PRESENTATION_DETAILED}
	query presentationById($id: ID!) {
        presentation: presentationById(id: $id) {
            ...PresentationDetailed
			
        }
	}
`;

export const CREATE_PRESENTATION = gql`
	${PRESENTATION_DETAILED}
	mutation createPresentation($name: String!, $type: String!, $data: createPresentationInput) {
		createPresentation(name: $name, type: $type, data: $data) {
			...PresentationDetailed
		}
	}
`;

export const UPDATE_PRESENTATION = gql`
	${PRESENTATION_DETAILED}
	mutation updatePresentation($id: ID!, $type: String!, $commitMessage: String, $diff: [String], $data: PresentationInput!) {
		presentation: updatePresentation(id: $id, type: $type, commitMessage: $commitMessage, diff: $diff, data: $data) {
			...PresentationDetailed
		}
	}
`;

export const DELETE_PRESENTATION = gql`
	mutation deletePresentation($id: ID!) {
        deletePresentation(id: $id) {
			message
			error
		}
	}
`;
