/* eslint-disable indent */
import { useContext } from 'react';
import { DataContext } from '../../contexts/DataContext';
import { CreatePresentationContext } from './CreatePresentation';

import { createPresentationConfig } from '../../config/createPresentationConfig';

import ACLSelect from './ACLSelect';
import TextField from './TextField';
import Select from './Select';
import SeriesSelect from './FormFields/SeriesSelect';
import AddEpisodeToSeries from './AddEpisodeToSeries';
import { isNum, isWholeNum } from '../../utils/validation';
import PresentationAutoPublish from '../presentation/PresentationAutoPublish';
import { FeatureFlagContext } from '../../contexts/FeatureFlagContext';

const CreatePresentationFields = () => {
    const { fields } = createPresentationConfig;
    const { appConfig } = useContext(DataContext);
    const { checkFeatureFlag } = useContext(FeatureFlagContext);
    const {
        createdPresentation,
        setCreatedPresentation,
        processing,
        processingFiles,
        setErrors,
        errors,
        setDisableCreate,
        disableCreate,
        removeCreateTypes
    } = useContext(CreatePresentationContext);

    const { type, aclType, acl, episodeNumber } = createdPresentation || {};

    const validate = (value, type, id) => {
        const currErrors = { ...errors };
        delete currErrors[id];
        if (type === 'number') {
            !isNum(value) ? currErrors[id] = true : delete currErrors[id];
        }
        if (type === 'wholeNumber') {
            !isWholeNum(value) || value.length > 9 ? currErrors[id] = true : delete currErrors[id];
        }
        setErrors(currErrors);
    };

    const handleUpdate = async (value, field) => {
        const { id, validation } = field;
        await setCreatedPresentation({ ...createdPresentation, [id]: value });
        if (!!value && validation) return validate(value, validation, id);
    };

    const renderTextField = (field) => {
        const { label, id, errorMessage, maxLength, type } = field;

        return (
            <TextField
                value={createdPresentation[id] || ''}
                key={id}
                type={type}
                label={label || id}
                maxLength={maxLength}
                onChange={({ target }) => handleUpdate(target.value, field)}
                error={errors[id]}
                errorMessage={errorMessage}
            />
        );
    };

    const renderSelect = (field) => {
        const { label, id, appConfigData, conditionalFields, removeOptions, searchable, errorMessage, defaultVal } = field;

        let options = [];
        if (appConfigData) {
            const AppOptions = appConfig[appConfigData]?.map(type => ({ text: type.display ?? type.name, value: type.id }));
            options = removeOptions
                ? AppOptions.filter(opt => !removeOptions.some(o => o.value === opt.value))
                : AppOptions;
            if (id === 'type' && processing && !processingFiles.every(file => file.type === 'image')) {
                options = options.filter(o => o.value !== 'series');
            }
            if (id === 'type' && removeCreateTypes) {
                options = options.filter(option => !removeCreateTypes.some(createType => option.value === createType));
            }
        }

        const selectedValue = createdPresentation[id];

        return (
            <>
                {id === 'seriesId'
                    ? <SeriesSelect
                            key={id}
                            id={id}
                            episodeNumber={Number(episodeNumber)}
                            createdPresentation={createdPresentation}
                            setCreatedPresentation={setCreatedPresentation}
                            setDisabled={setDisableCreate}
                            disabled={disableCreate}
                    />
                    : <Select
                            key={id}
                            options={options}
                            onChange={({ value }) => handleUpdate(value, field)}
                            label={label || id}
                            selectedValue={selectedValue ?? defaultVal}
                            searchable={searchable}
                            className={`Select--${id}`}
                            errorMessage={errorMessage}
                            error={errors[id]}
                    />}

                {(conditionalFields && conditionalFields[selectedValue]) &&
                    conditionalFields[selectedValue]?.map(field => renderFields(field))}
            </>
        );
    };

    const renderAclSelect = () => (
        <ACLSelect
            key='ACLSelect'
            initACLs={aclType}
            initAccess={acl}
            onChange={(aclType, acl) => setCreatedPresentation({ ...createdPresentation, aclType, acl })}
        />
    );

    const renderFields = (field) => {
        if (field.featureFlag && !checkFeatureFlag(field.featureFlag)) return;
        const { conditionalHide, relatedValues } = field;
        const { id, value, hideNull } = conditionalHide || {};
        // Check if a feild should be conditionally hidden when there is a speific value or if the id is null
        if (conditionalHide && (createdPresentation[id] === value || (hideNull && !createdPresentation[id]))) return;
        // Check is related values are present and should be changed
        if (relatedValues && relatedValues.id !== relatedValues.value) setCreatedPresentation({ ...createdPresentation, [relatedValues.id]: relatedValues.value });

        return {
            select: renderSelect(field),
            text: renderTextField(field),
            number: renderTextField(field),
            wholeNumber: renderTextField(field),
            ACLSelect: renderAclSelect(field)
        }[field.type];
    };

    return (
        <div
            className={`
            CreatePresentationFields 
            ${processing ? 'padded__left padded__right padded__bottom' : 'padded'}
            `}
        >
            {fields.map(field => renderFields(field))}
            {
                type === 'series' &&
                <AddEpisodeToSeries
                    createdPresentation={createdPresentation}
                    setCreatedPresentation={setCreatedPresentation}
                    disabled={disableCreate}
                    setDisabled={setDisableCreate}
                />
            }
            {
                type !== 'series' &&
                <PresentationAutoPublish
                    presentation={createdPresentation}
                    setPresentation={setCreatedPresentation}
                    createNewPresentation
                />
            }
        </div>
    );
};

export default CreatePresentationFields;
