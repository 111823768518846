import { createContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Login from '../components/pages/Login';
import { useLazyQuery, useQuery } from '@apollo/client';
import { CHECK_ACCESS_TOKEN, GET_USER, LOGOUT } from '../queries';

export const UserContext = createContext();

const UserContextProvider = ({ children }) => {
    const [logout, setLogout] = useState(false);
    const [logoutError, setLogoutError] = useState(false);
    const [logoutLoading, setLogoutLoading] = useState(false);
    const [getUser, { data: userData, loading: loadingUser, error: userError }] = useLazyQuery(GET_USER);
    const { data: checkAccessToken, loading: loadingAccessToken, error: accessTokenError } = useQuery(CHECK_ACCESS_TOKEN);
    const { validAccessToken, unauthorizedUser } = checkAccessToken?.checkAccessToken ?? {};

    const [handleUserLogout] = useLazyQuery(LOGOUT);

    const handleLogout = async () => {
        setLogoutError(false);
        setLogoutLoading(true);

        try {
            const { data: logoutData } = await handleUserLogout();
            const { error } = logoutData?.logout ?? {};
            error ? setLogoutError(true) : setLogout(true);
        } catch (error) {
            console.log(error);
            setLogoutError(true);
        }

        setLogoutLoading(false);
    };

    useEffect(() => {
        if (!!validAccessToken && !unauthorizedUser) {
            getUser();
        }
    }, [validAccessToken]);

    if (validAccessToken === false || unauthorizedUser || userError || logout) {
        return (
            <Login
                unauthorized={unauthorizedUser}
                error={!!userError || !!accessTokenError}
                logout={logout}
                logoutLoading={logoutLoading}
            />
        );
    }

    if (loadingUser || !userData || loadingAccessToken) return;

    return (
        <UserContext.Provider value={{ authorizedUser: validAccessToken, user: userData.user, handleLogout, logoutError }}>
            {children}
        </UserContext.Provider>
    );
};

export default UserContextProvider;

UserContextProvider.propTypes = {
    children: PropTypes.oneOfType([PropTypes.array, PropTypes.object])
};
