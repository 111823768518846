import { useContext, useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';

import { DataContext } from '../../../contexts/DataContext';
import TextField from '../TextField';
import Select from '../Select';
import { Caption } from '../Typography';

const EventApprovalSource = ({ eventDetails, handleUpdate, createNew, resetApprovalSource, className }) => {
    const { approvalSourceType, approvalSourceId } = eventDetails;
    const { appConfig } = useContext(DataContext);
    const { eventApprovalSource } = appConfig ?? {};
    const options = eventApprovalSource?.map(val => ({ text: val.name ?? val, value: val.id?.toLowerCase() ?? val.toLowerCase() }));
    const [approvalSource, setApprovalSource] = useState({});
    const [error, setError] = useState(false);
    const [placeholderId, setPlaceholderId] = useState('');
    const prevApprovalSourceType = useRef(approvalSourceType);

    useEffect(() => {
        if (!approvalSourceType && !approvalSourceId && !placeholderId) return;
        const newApprovalSourceId = !!prevApprovalSourceType.current && !approvalSourceType && !resetApprovalSource
            ? placeholderId
            : approvalSourceId;
        setApprovalSource({
            approvalSourceType,
            approvalSourceId: newApprovalSourceId
        });
        prevApprovalSourceType.current = approvalSourceType;
    }, [approvalSourceType, approvalSourceId]);

    useEffect(() => {
        if (!approvalSource.approvalSourceId) return;
        setPlaceholderId(approvalSource.approvalSourceId);
    }, [approvalSource.approvalSourceId]);

    const handleChange = (value, id) => {
        const initType = approvalSource.approvalSourceType;
        const updatedApprovalSource = { ...approvalSource, [id]: value };
        setApprovalSource(updatedApprovalSource);
        const { approvalSourceType: currentType, approvalSourceId: currentId } = updatedApprovalSource;
        const cancelError = id === 'approvalSourceType' && !!initType && !value;
        const hasError = (!!currentId && !currentType && !cancelError) || (!currentId && !!currentType);
        setError(hasError);
        if (cancelError) updatedApprovalSource.approvalSourceId = '';
        if (!hasError) handleUpdate(updatedApprovalSource);
    };

    return (
        <div className={`FormFields__ApprovalSource flex flex-wrap padded__bottom ${className}`}>
            <Select
                label='Approval Source'
                options={[{ text: 'None', value: '' }, ...options]}
                disabled={!eventApprovalSource?.length}
                selectedValue={approvalSource.approvalSourceType?.toLowerCase() ?? ''}
                className='Select__approvalSouceType width-50'
                onChange={({ value }) => handleChange(value, 'approvalSourceType')}
            />
            <TextField
                label='Source Id'
                className='TextField__approvalSourceId width-50'
                value={approvalSource.approvalSourceId ?? ''}
                onBlur={() => handleChange(approvalSource.approvalSourceId, 'approvalSourceId')}
                onChange={({ target }) => setApprovalSource({ ...approvalSource, approvalSourceId: target.value })}
                disabled={!approvalSource.approvalSourceType}
            />
            {error && <Caption error className='block stretch-width half-spacing__top padded__left'>Both 'Source Id' and 'Approval Source' are required</Caption>}
        </div>
    );
};

export default EventApprovalSource;

EventApprovalSource.proptypes = {
    eventDetails: PropTypes.object,
    handleUpdate: PropTypes.func,
    createNew: PropTypes.bool
};
