import { gql } from '@apollo/client';
import { FILE_FIELDS } from '../fragments/file.js';
import { FILE_DETAILS_FIELDS } from '../fragments/fileDetails';

export const SEARCH_FILES = gql`
	${FILE_FIELDS}
	query allFiles($search: String!, $orderBy: String, $order: String, $limit: Int, $offset: Int, $statuses: [String], $types: [String], $aclTypes: [String]){
		allFiles(search: $search, orderBy: $orderBy, order: $order, limit: $limit, offset: $offset, statuses: $statuses, types: $types, aclTypes: $aclTypes) {
			files {
				...FileFields
				presentations {
					id
					name
					documentId
					authorized
					type
				}
			}
			total
		}
	}
`;

export const CREATE_FILE = gql`
	${FILE_FIELDS}
	mutation createFile($name: String!, $status: String!, $acl: [ACEInput]!, $aclType: [String]!) {
		file: createFile(name: $name, status: $status, acl: $acl, aclType: $aclType) {
			...FileFields
		}
	}
`;

export const UPDATE_FILE = gql`
	${FILE_FIELDS}
	mutation updateFile($id: ID!, $name: String, $type: String, $status: String, $location: String) {
		file: updateFile(id: $id, name: $name, type: $type, status: $status, location: $location) {
			...FileFields
		}
	}
`;

export const GET_FILE_DETAILS = gql`
	${FILE_FIELDS}
	${FILE_DETAILS_FIELDS}
	query getFileDetails($id: ID, $presentationId: String) {
		fileDetails: getFileDetails(id: $id, presentationId: $presentationId) {
		file {
			...FileFields
			details {
				...FileDetailsFields
			}
			acl {
				subject
				capabilities
			}
		}
		fileHistory {
			id
            fileId
            presentationId
            jobId
            jobInput 
            jobOutput
            jobError
            jobStatus
            workflowName
            createdAt
            updatedAt
            createdBy
            updatedBy
            pipelineId
		}
	}
	}
`;
