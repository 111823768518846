import { useContext, useState, useMemo } from 'react';
import ContentAccordion from '../modules/ContentAccordion';
import { PresentationContext } from '../pages/Presentation';
import { Subtitle } from '../modules/Typography';
import Button from '../modules/Button';
import Table, { columnHelper } from '../modules/Table';
import PublishStatusCell from '../modules/TableCell/PublishStatusCell';
import DateTimeCell from '../modules/TableCell/DateTimeCell';

const PresentationHistory = props => {
    const { history, doc, presentationData } = useContext(PresentationContext);
    const [showAll, setShowAll] = useState(false);
    const { lastPublishVersion } = presentationData;
    const { live, preview } = doc.publish.status;
    const published = live.value === 'published' && preview.value === 'published';
    const unpublished = live.value === 'unpublished' || preview.value === 'unpublished';

    const historyData = showAll || unpublished
        ? history
        : history.filter(val => val.version > Math.min(lastPublishVersion.preview, lastPublishVersion.live));

    const data = historyData.map(val => {
        const { audit, version } = val;
        return {
            ...audit,
            live,
            preview,
            version,
            lastPublishVersion
        };
    });

    const columns = useMemo(
        () => [
            columnHelper.accessor('lastUpdatedAt', {
                header: 'Changed',
                cell: DateTimeCell,
                size: 125
            }),
            columnHelper.accessor('user', {
                header: 'Changed By',
                cell: ({ getValue }) => {
                    const user = getValue();
                    return user.email || user.id;
                },
                size: 300
            }),
            columnHelper.accessor('message', {
                meta: {
                    className: 'HistoryMessage'
                }
            }),
            columnHelper.accessor('preview', {
                cell: PublishStatusCell,
                size: 100,
                meta: {
                    className: 'PresentationStatus'
                }
            }),
            columnHelper.accessor('live', {
                cell: PublishStatusCell,
                size: 100,
                meta: {
                    className: 'PresentationStatus'
                }
            })
        ],
        [history]
    );

    return (
        <ContentAccordion title='History' className='Presentation__History'>
            {
                ((published && showAll) || !published) &&
                <div className='flex flex-end spacing__bottom'>
                    <Button
                        type='primary'
                        text={published && showAll
                            ? 'Hide All'
                            : showAll && !unpublished
                                ? 'Show Latest'
                                : 'Show All'}
                        onClick={() => setShowAll(!showAll)}
                        disabled={unpublished}
                    />
                </div>
            }
            <Table
                columns={columns}
                data={data}
            />
            {
                (published && !showAll) &&
                <div className='flex column align-center padded'>
                    <Subtitle className='padded center'>{data.length ? 'Show previous published changes' : 'All changes have been published'}</Subtitle>
                    <Button
                        type='primary'
                        text='Show All'
                        onClick={() => setShowAll(!showAll)}
                    />
                </div>
            }
        </ContentAccordion>
    );
};

export default PresentationHistory;
