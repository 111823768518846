import { gql } from '@apollo/client';

import { EVENT_CONTENT_FIELDS } from './eventContent';
import { EVENT_AUDIENCE_FIELDS } from './eventAudience';
import { EVENT_CODE } from './eventCode';

export const EVENT_SIMPLIFIED = gql`
    fragment EventSimplified on Event {
        id
        name
        aclType
        acl {
            subject
            capabilities
            deny
        }
        audienceCount
        contentCount
        eventContent {
            name
            presentationId
        }
        startAt
        endAt
        publishedAt
        timezone
        status
        tags
        types
        approvalSourceId
        publishedAt
    }
`;

export const EVENT_DETAILS = gql`
    fragment EventDetails on Event {
        id
        name
        acl {
            subject
            capabilities
            deny
        }
        aclType
        startAt
        endAt
        timezone
        maxDefinition
        watermarkType
        watermarkLocation
        watermarkPersistence
        docusignIntegration
        plainModeEnabled
        playCount
        tags
        updatedAt
        updatedBy
        createdAt
        createdBy
        status
        approvalSourceType
        approvalSourceId
        types
        publishedAt
        publishedVersion
        publishedStatus
        contentCount
        codeBatchCount
        allowOfflinePlayback
    }
`;

export const EVENT_EXPANDED = gql`
    ${EVENT_DETAILS}
    ${EVENT_CONTENT_FIELDS}
    ${EVENT_AUDIENCE_FIELDS}
    ${EVENT_CODE}
    fragment EventExpanded on Event {
        ...EventDetails
        eventContent {
            ...EventContentFields  
        }
        audienceCount
        eventAudience {
            ...EventAudienceFields
        }
        eventCodes {
            ...EventCode
        }
    }
`;
