import { useState, useContext, createContext, useEffect, useRef } from 'react';
import { Helmet } from 'react-helmet';
import _ from 'lodash';
import { useMutation } from '@apollo/client';

import TopNav from '../layout/TopNav';
import CreatePresentation from '../modules/CreatePresentation';
import Dashboard from '../modules/Dashboard';
import { DialogContext } from '../../contexts/DialogContextProvider';
import { UserContext } from '../../contexts/UserContextProvider';
import List from '../modules/List';
import Icon from '../modules/Icon';
import Accordion from '../modules/Accordion';
import { Body, Subtitle } from '../modules/Typography';
import DialogFooter from '../modules/DialogFooter';
import PresentationAutoPublishCheckbox from '../presentation/PresentationAutoPublishCheckbox';
import { publishingConfig } from '../../config/publishingConfig';

import { SEARCH_PRESENTATIONS, PUBLISH_PRESENTATION, UNPUBLISH_PRESENTATION, DELETE_PRESENTATION } from '../../queries';
import { checkPlural } from '../../utils/checkPlural';
import checkUserRole from '../../hooks/checkUserRole';
import { authorized, subjectsFromUser } from '../../utils/acl';
import usePresentationTable from '../../hooks/presentation/usePresentationTable';

import PresentationSeriesSubRow from '../presentation/PresentationSeriesSubRow';
import AddPresentationToEventDialog from '../dialogs/AddPresentationToEventDialog';

export const PresentationsContext = createContext();

const PresentationsDashboard = () => {
    const { makeDialog, closeDialog, bulkConfirmDialog } = useContext(DialogContext);
    const { user } = useContext(UserContext);

    const defaultEnvironments = ['live', 'preview'];
    const environments = useRef(defaultEnvironments);

    const [deletePresentation] = useMutation(DELETE_PRESENTATION);
    const [publishPresentation] = useMutation(PUBLISH_PRESENTATION);
    const [unpublishPresentation] = useMutation(UNPUBLISH_PRESENTATION);

    const {
        columns,
        handleQuery,
        triggerRefetch,
        setTriggerRefetch,
        tableData,
        selectedPresentations,
        setSelectedPresentations,
        handlePostCreate,
        partialSuccess,
        defaultChecked,
        setDefaultChecked,
        columnVisibility
    } = usePresentationTable({ isDashboard: true, renderSubComponent: (row) => <PresentationSeriesSubRow row={row} /> });

    useEffect(() => {
        if (selectedPresentations.length) return;
        setDefaultChecked(false);
    }, [selectedPresentations]);

    const handleAction = async (action, confirmAction, presentations) => {
        if (!presentations.length) return closeDialog();
        const handleBulk = {
            delete: deletePresentation,
            publish: publishPresentation,
            unpublish: unpublishPresentation
        };

        const currentPresentations = [...presentations];
        for (const presentation of currentPresentations) {
            const { id, type, documentId } = presentation;

            const additionalVariables = {};
            if (action === 'publish' || action === 'unpublish') {
                additionalVariables.documentId = documentId;
                additionalVariables.publishData = {
                    type,
                    environments: environments.current,
                    scopes: publishingConfig[type].scopes
                };
            }

            try {
                const { data } = await handleBulk[action]({ variables: { id, ...additionalVariables } });
                const key = _.findKey(data, 'message');
                presentation.result = data[key];

                if (action === 'publish' || action === 'unpublish') {
                    presentation.result = { message: 'OK', error: null };
                }
            } catch (error) {
                presentation.result = { message: 'error', error };
            }
        }
        bulkConfirmDialog({
            title: checkPlural('presentation', presentations),
            type: 'Presentation',
            confirmAction,
            action,
            results: currentPresentations,
            loading: triggerRefetch,
            handleConfirm: () => {
                closeDialog();
                setSelectedPresentations([]);
                setTriggerRefetch(true);
            },
            className: 'DialogPresentationsBulk',
            postConfirmLoading: true
        });
    };

    useEffect(() => {
        if (triggerRefetch) return;
        closeDialog();
    }, [triggerRefetch]);

    const actionDialog = (action, confirmAction, failedAction) => {
        environments.current = defaultEnvironments;
        const dialogTitle = {
            delete: 'Do you wish to continue?',
            publish: 'Are you sure you want to Publish?',
            unpublish: 'Are you sure you want to Unpublish?'
        };

        const warningCopy = {
            delete: 'Deleting a presentation will permanently remove it from the system and cannot be undone.',
            publish: 'This action may affect ongoing Events',
            unpublish: 'This action may affect ongoing Events'
        };

        let presentations = [...selectedPresentations];
        let failedPresentations = [];

        if (action === 'delete') {
            const checkPresentations = presentations.reduce((acc, presentation) => {
                return presentation.status.every(s => s.indexOf('unpublished') !== -1)
                    ? { ...acc, canDelete: [...acc.canDelete, presentation] }
                    : { ...acc, cannotDelete: [...acc.cannotDelete, presentation] };
            }, { canDelete: [], cannotDelete: [] });

            presentations = checkPresentations.canDelete;
            failedPresentations = checkPresentations.cannotDelete;
        }

        if (action === 'publish') {
            const checkPresentations = presentations.reduce((acc, presentation) => {
                return authorized(presentation.acl, ['P'], subjectsFromUser(user))
                    ? { ...acc, canPublish: [...acc.canPublish, presentation] }
                    : { ...acc, cannotPublish: [...acc.cannotPublish, presentation] };
            }, { canPublish: [], cannotPublish: [] });

            presentations = checkPresentations.canPublish;
            failedPresentations = checkPresentations.cannotPublish;
        }

        if (action === 'unpublish') {
            const checkPresentations = presentations.reduce((acc, presentation) => {
                return authorized(presentation.acl, ['P'], subjectsFromUser(user))
                    ? { ...acc, canUnpublish: [...acc.canUnpublish, presentation] }
                    : { ...acc, cannotUnpublish: [...acc.cannotUnpublish, presentation] };
            }, { canUnpublish: [], cannotUnpublish: [] });

            presentations = checkPresentations.canUnpublish;
            failedPresentations = checkPresentations.cannotUnpublish;
        }

        const checkPresentationIsPlural = checkPlural('presentation', presentations);
        const checkFailedPresentationIsPlural = checkPlural('presentation', failedPresentations);

        const BulkPresentationAction = () => {
            const [publishingEnvironment, setpublishingEnvironment] = useState(environments.current);
            return (
                <>
                    <div className='padded__left padded__right DialogPresentationsBulk__Content'>
                        {(dialogTitle[action] && !!presentations.length) &&
                        <Subtitle number={5} className='spacing__bottom block DialogPresentationsBulk__Warning'>{dialogTitle[action]}</Subtitle>}
                        {(action === 'publish' || action === 'unpublish') &&
                        <>
                            <Subtitle number={1} block className='spacing__bottom'>Environments</Subtitle>
                            <div className='flex flex-start'>
                                <PresentationAutoPublishCheckbox
                                    value={publishingEnvironment}
                                    onChange={(checked) => {
                                        setpublishingEnvironment(checked);
                                        environments.current = checked;
                                    }} />
                            </div>
                        </>}
                        {!!presentations.length &&
                        <Accordion
                            title={`Show ${checkPresentationIsPlural}`}
                            closeTitle={`Hide ${checkPresentationIsPlural}`}
                            type='advancedDetails'
                            headerContent={<Body number={1}>{presentations.length} {checkPresentationIsPlural} to be {confirmAction}</Body>}>
                            <List items={presentations.map(pres => ({ name: pres.name }))} className='PresentationList' />
                        </Accordion>}
                        {!!failedPresentations.length &&
                        <Accordion
                            type='advancedDetails'
                            title={`Show ${checkFailedPresentationIsPlural}`}
                            closeTitle={`Hide ${checkFailedPresentationIsPlural}`}
                            headerContent={
                                <Body number={1}>
                                    <Icon name='warning' color='warning' className='half-spacing__right block' />
                                    {failedPresentations.length} <span className='Typography--heavy capitalize'>{failedAction ?? confirmAction}</span> {checkFailedPresentationIsPlural} cannot be {action}.
                                </Body>
                            }>
                            <List items={failedPresentations.map(pres => ({ name: pres.name }))} className='PresentationList' />
                        </Accordion>}
                    </div>
                    <DialogFooter
                        setLoading
                        warningMessage={presentations.length ? warningCopy[action] : ''}
                        confirmText={presentations.length ? action : 'Ok'}
                        handleConfirm={() => handleAction(action, confirmAction, presentations)}
                    />
                </>
            );
        };

        makeDialog({
            title: `${action} ${checkPresentationIsPlural}`,
            dialog: <BulkPresentationAction />,
            size: 'medium',
            className: 'DialogPresentationsBulk',
            includeHeaderClose: true
        });
    };

    const handleAddPresentationToEventDialogAction = async (presentations) => {
        makeDialog({
            title: 'Add to Event',
            dialog: <AddPresentationToEventDialog presentations={[...selectedPresentations]} />,
            size: 'xl',
            className: 'DialogPresentationsBulk',
            includeHeaderClose: false
        });
    };

    const menuData = {
        closeMenu: () => setSelectedPresentations([]),
        numberOfItems: selectedPresentations.length,
        actions: [
            {
                text: 'Publish',
                icon: 'check-circle',
                disabled: !checkUserRole('ROLE_PRODUCER'),
                onClick: () => actionDialog('publish', 'published')
            },
            {
                text: 'Unpublish',
                icon: 'stop',
                onClick: () => actionDialog('unpublish', 'unpublished')
            },
            {
                text: 'Add to Event',
                icon: 'plus-square',
                onClick: () => handleAddPresentationToEventDialogAction()
            },
            {
                text: 'Delete',
                icon: 'trash-can',
                onClick: () => actionDialog('delete', 'deleted', 'published')
            }
        ]
    };

    const handleData = (data) => {
        handleQuery(data);
        closeDialog();
    };

    const dashboardData = {
        gqlQuery: SEARCH_PRESENTATIONS,
        handleQuery: (data) => handleData(data),
        tableData,
        menuData,
        columns,
        triggerRefetch,
        setTriggerRefetch,
        partialSuccess,
        columnVisibility
    };

    const createDialog = () => {
        makeDialog({
            dialog: (
                <CreatePresentation
                    handleBack={closeDialog}
                    handlePostCreate={handlePostCreate}
                    handleClose={closeDialog}
                />
            ),
            size: 'xl',
            className:
                'FileProcessing__Presentation FileProcessing__Presentation--create manual-overflow'
        });
    };

    return (
        <PresentationsContext.Provider
            value={{
                selectedPresentations,
                setSelectedPresentations,
                tableData,
                defaultChecked,
                setDefaultChecked,
                allowSeries: true
            }}
        >
            <Helmet>
                <title>Presentations</title>
            </Helmet>
            <TopNav
                className='flex space-between'
                heading='Presentations'
                onClick={createDialog}
                btnText='Create Presentation'
                btnRole='ROLE_PRODUCER'
            />
            <div className='content-wrapper'>
                <Dashboard
                    hasSideFilters
                    dashboardData={dashboardData}
                    type='Presentations'
                    className='Presentations'
                />

            </div>
        </PresentationsContext.Provider>
    );
};

export default PresentationsDashboard;
