/* eslint-disable indent */

import PropTypes from 'prop-types';
import DialogFooter from '../modules/DialogFooter';
import { useContext, useEffect, useState } from 'react';
import { DialogContext } from '../../contexts/DialogContextProvider';
import Select from '../modules/Select';
import { useMutation, useQuery } from '@apollo/client';
import { GET_CODE_POLICIES, CREATE_CODE_BATCH, VALIDATE_CODE_BATCH } from '../../queries';
import { createNewCodeBatchFields } from '../../config/codeConfig';
import Form from '../modules/Form';
import UploadDropzone from '../modules/UploadDropzone';
import { Body, Subtitle } from '../modules/Typography';
import Tooltip from '../modules/Tooltip';
import Button from '../modules/Button';
import Icon from '../modules/Icon';

import { checkPlural } from '../../utils/checkPlural';

import { SnackbarContext } from '../../contexts/SnackbarContextProvider';
import { SnackbarDialogContext } from '../../contexts/SnackbarDialogContextProvider';
import CodeBatchErrors from './CodeBatchErrors';

const CreateNewCodeBatch = ({ handleCreatedCodes, createOnly, newCodes, file, handleCSV, handleCreateCancel }) => {
    const { closeDialog } = useContext(DialogContext);
    const { successSnackbar, warningSnackbar, loadingSnackbar, closeSnackbar } = useContext(SnackbarContext);
    const { snackbarDialog } = useContext(SnackbarDialogContext);

    const [codeBatch, setCodeBatch] = useState({});
    const { createType } = codeBatch;

    const [uploadedCodeErrors, setUploadedCodeErrors] = useState({});
    const allErrors = [...(uploadedCodeErrors.invalidCodes ?? []), ...(uploadedCodeErrors.existingCodes ?? [])];

    const [showBatchErrors, setShowBatchErrors] = useState(false);

    const { data: policyData, loading: loadingPolicy } = useQuery(GET_CODE_POLICIES);
    const [createCodeBatch, { loading: loadingCodeBatch }] = useMutation(CREATE_CODE_BATCH);
    const [validateCodeBatch, { loading: loadingValidateCodeBatch }] = useMutation(VALIDATE_CODE_BATCH);

    const allRequired = createNewCodeBatchFields.filter(field => !!field.required).every(f => !!codeBatch[f.id]) && !!codeBatch.policyId;
    const allRequiredConditionalFields = createNewCodeBatchFields.reduce((acc, field) => {
        if (!field.conditionalFields) return acc;
        return [...acc, field.conditionalFields?.[codeBatch[field.id]]?.filter(f => !!f.required).every(f => !!codeBatch[f.id])];
    }, []).every(Boolean);

    useEffect(() => {
        if (!createType) return;
        if (createType === 'upload') {
            setCodeBatch({ ...codeBatch, size: null });
        } else {
            setCodeBatch({ ...codeBatch, codes: null, uploadedFile: null });
            setUploadedCodeErrors({});
        }
    }, [createType]);

    const resetDialog = () => {
        setCodeBatch({});
        setUploadedCodeErrors({});
        setShowBatchErrors(false);
        handleCreateCancel();
        if (createOnly) {
            closeDialog();
        }
    };

    const validateCodes = async (codes, policyId) => {
        loadingSnackbar({ text: 'Validating Uploaded Codes' });
        const updatedCodeBatch = {
            ...codeBatch,
            uploadedFile: file,
            codes: [...codes],
            createType: codes?.length ? 'upload' : null
        };
        if (!policyId && !!codes.length) {
            closeSnackbar();
            return setCodeBatch({ ...updatedCodeBatch });
        }
        try {
            const { data } = await validateCodeBatch({
                variables: {
                    codes,
                    policy: policyData.getCodePolicies.find(policy => policy.id === policyId)
                }
            });

            const { invalidCodes, existingCodes, validCodes } = data.validateCodeBatch ?? {};
            setUploadedCodeErrors({ existingCodes, invalidCodes });
            setCodeBatch({
                ...updatedCodeBatch,
                codes: validCodes,
                uploadedFile: validCodes?.length ? updatedCodeBatch.uploadedFile : null,
                createType: validCodes?.length ? 'upload' : null
            });
            closeSnackbar();
        } catch (error) {
            console.log(error);
            warningSnackbar({ text: 'Failed to validate uploaded codes. System will retry on submit' });
            return setCodeBatch({ ...updatedCodeBatch });
        }
    };

    useEffect(() => {
        if (!newCodes.length) {
            setCodeBatch({ ...codeBatch, codes: null, uploadedFile: null });
            return;
        };
        const { policyId } = codeBatch;
        validateCodes(newCodes, policyId);
    }, [newCodes, file]);

    useEffect(() => {
        const { codes, policyId } = codeBatch;
        if (!policyId || (!codes?.length && !allErrors.length)) return;
        const recheckCodes = [...(codes ?? []), ...allErrors];
        validateCodes(recheckCodes, policyId);
    }, [codeBatch.policyId]);

    const handleFile = file => {
        setUploadedCodeErrors({});
        if (!newCodes.length || !file) {
            setCodeBatch({ ...codeBatch, codes: null, uploadedFile: null });
            setShowBatchErrors(false);
        };
        handleCSV(file);
    };

    const handleCreateCodes = async () => {
        try {
            const { data } = await createCodeBatch({ variables: { createCodeBatch: codeBatch } });
            handleCreatedCodes(data.code);
            successSnackbar({ text: `Created code batch ${codeBatch.description}` });
            resetDialog();
        } catch (error) {
            console.log(error);
            snackbarDialog({
                snackbarText: 'Error Creating New Code Batch',
                dialogTitle: 'Codes Creation Error',
                graphQLErrors: error?.graphQLErrors
            });
        }
    };

    const handlePostDownload = () => {
        setShowBatchErrors(false);
        setUploadedCodeErrors([]);
    };

    const errorModalTrigger = () => {
        const totalUploadsCount = (codeBatch?.codes?.length ?? 0) + (allErrors.length ?? 0);

        return (
            <div className='CodeErrors--Trigger__Wrapper'>
                <Body number={2} className='flex Codes__Total'><Icon name='warning' />Total Uploads: {totalUploadsCount}</Body>
                <Tooltip
                    tooltipCopy='See Details'
                    className='CodeErrors--Trigger'
                    location='top'
                    >
                    <Button
                        onClick={() => setShowBatchErrors(true)}
                        icon='warning-round'
                        iconColor='error'>
                        <span className='underline'>{allErrors.length} Code Upload {checkPlural('Error', allErrors)}</span>
                    </Button>
                </Tooltip>
            </div>
        );
    };

    return (
        <UploadDropzone acceptedFiles='.csv' handleFile={(file) => handleFile(file)} disabled={!createOnly || !!codeBatch.uploadedFile} className='Code__CreateNew'>
            <div className={createOnly ? 'padded' : ''}>
                <Subtitle block number={4} fadedHalf className='spacing__bottom'>CREATE NEW CODE BATCH</Subtitle>
                <Select
                    searchable
                    label='Policy Name*'
                    placeholder='Search Policies...'
                    loading={loadingPolicy}
                    options={policyData?.getCodePolicies?.map(policy => ({ text: policy.name, value: policy.id })) || []}
                    onChange={({ value }) => setCodeBatch({ ...codeBatch, policyId: value })}
                    selectedValue={codeBatch.policyId}
                />
                <Form
                    formFields={createNewCodeBatchFields}
                    formData={codeBatch}
                    setFormData={setCodeBatch}
                    handleFile={(file) => handleFile(file)}
                />
                {!!codeBatch.codes?.length && <Body number={2}>New Codes: {codeBatch?.codes?.length}</Body>}
                {showBatchErrors && <CodeBatchErrors handleClose={() => setShowBatchErrors(false)} errors={uploadedCodeErrors} createOnly={createOnly} handlePostDownload={handlePostDownload} />}
            </div>
            <DialogFooter
                errorModalTrigger={allErrors.length ? errorModalTrigger() : null}
                disabled={!allRequiredConditionalFields || !allRequired || (!codeBatch.codes?.length && codeBatch.createType === 'upload')}
                handleConfirm={handleCreateCodes}
                handleCancel={resetDialog}
                loading={loadingCodeBatch || loadingValidateCodeBatch}
                confirmText='Create Codes' />
        </UploadDropzone>
    );
};

export default CreateNewCodeBatch;

CreateNewCodeBatch.defaultProps = {
    createOnly: false,
    newCodes: []
};

CreateNewCodeBatch.propTypes = {
    handleCreatedCodes: PropTypes.func,
    createOnly: PropTypes.bool,
    newCodes: PropTypes.array,
    handleCSV: PropTypes.func,
    handleCreateCancel: PropTypes.func,
    file: PropTypes.object
};
