import { format } from 'date-fns-tz';
import moment from 'moment-timezone';

export const makeTimestamp = (dateStr, timezone) => {
    if (!dateStr) return;
    const dateString = format(dateStr, 'yyyy-MM-dd HH:mm:ssXXX', { timeZone: timezone });
    const event = new Date(dateString);
    const utc = event.toUTCString();
    return roundTime(utc);
};

export const roundTime = (minutes = 1, date) => {
    const ms = 1000 * 60 * minutes;
    const roundedDate = Math.ceil(date / ms) * ms;
    return roundedDate;
};

export const convertTimestamp = (date, timezone) => {
    if (!date) return;
    const dateString = format(date, 'yyyy-MM-dd HH:mm:ssXXX', { timeZone: timezone });
    const dateMoment = moment.tz(dateString, timezone);
    return dateMoment.valueOf();
};
