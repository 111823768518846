export const createNewCodeBatchFields = [
    {
        label: 'Code Batch Name*',
        id: 'description',
        type: 'text',
        required: true
    },
    {
        type: 'subtitle',
        copy: 'CODE SOURCE',
        id: 'subtitle1'
    },
    {
        type: 'radio',
        id: 'createType',
        required: true,
        options: [
            {
                id: 'generate',
                label: 'Generate Codes'
            },
            {
                id: 'upload',
                label: 'Upload Codes'
            }
        ],
        conditionalFields: {
            upload: [{
                type: 'fileUpload',
                id: 'uploadedFile',
                required: true,
                copy: 'Browse Files',
                acceptedFiles: '.csv',
                buttonType: 'secondary',
                padded: true,
                className: 'UploadCodes__Button',
                hasDividerAbove: true,
                header: 'Upload Codes'
            }],
            generate: [{
                id: 'size',
                type: 'text',
                label: 'Batch Size*',
                required: true,
                validation: ['wholeNumber']
            }]

        }
    }
];
