const globalFields = {
    internalTitle: {
        label: 'Internal Title',
        type: 'text'
    },
    name: {
        presentation: true,
        id: 'name',
        type: 'text',
        validation: ['required'],
        errorMessage: 'Required Field'
    },
    runtime: {
        id: 'runtime',
        label: 'Runtime',
        type: 'text',
        maxLength: '10',
        validation: ['number'],
        errorMessage: 'Must be valid number'
    },
    rating: {
        id: 'rating',
        label: 'Rating',
        type: 'select',
        config: 'ratings'
    },
    slug: {
        id: 'slug',
        label: 'slug',
        type: 'text',
        validation: ['noSpaces'],
        errorMessage: 'Spaces are not allowed for SEO'
    },
    studios: {
        id: 'studios',
        label: 'Studios',
        type: 'multiSelect',
        config: 'studios',
        selectedValue: 'studios'
    }
};

export const metadataConfig = {
    movie: {
        titleInformation: [
            {
                ...globalFields.name,
                label: 'Movie Title*'
            },
            { ...globalFields.runtime },
            { ...globalFields.rating },
            {
                ...globalFields.internalTitle,
                id: 'availTitle'
            },
            {
                ...globalFields.slug,
                path: 'seo'
            },
            { ...globalFields.studios }
        ],
        includeTags: true,
        synopsisShort: true,
        synopsisMedium: true,
        synopsisLong: true
    },
    episode: {
        titleInformation: [
            {
                ...globalFields.name,
                label: 'Episode Title*'
            },
            {
                id: 'episodeNumber',
                label: 'Episode Number*',
                type: 'wholeNumber',
                maxLength: '5',
                validation: ['required', 'number'],
                hideErrorStyles: true
            },
            { ...globalFields.slug },
            {
                ...globalFields.internalTitle,
                id: 'cmsTitle'
            },
            {
                id: 'seriesId',
                label: 'Series',
                type: 'select'
            },
            { ...globalFields.runtime },
            { ...globalFields.studios }
        ],
        includeTags: true,
        synopsisShort: true,
        synopsisMedium: true,
        synopsisLong: true
    },
    promo: {
        titleInformation: [
            {
                ...globalFields.name,
                label: 'Promo Title*'
            },
            { ...globalFields.runtime },
            { ...globalFields.slug },
            { ...globalFields.studios }
        ],
        includeTags: true,
        synopsisPromo: true,
        synopsisShort: false,
        synopsisMedium: false,
        synopsisLong: false
    },
    public: {
        titleInformation: [
            {
                ...globalFields.name,
                label: 'Public Title*'
            },
            { ...globalFields.runtime },
            { ...globalFields.slug },
            {
                ...globalFields.internalTitle,
                id: 'cmsTitle'
            },
            { ...globalFields.studios }
        ],
        includeTags: true,
        synopsisPromo: false,
        synopsisShort: true,
        synopsisMedium: true,
        synopsisLong: true
    },
    series: {
        titleInformation: [
            {
                ...globalFields.name,
                label: 'Series Title*'
            },
            { ...globalFields.slug },
            {
                ...globalFields.internalTitle,
                id: 'cmsTitle'
            },
            { ...globalFields.studios }
        ],
        includeTags: true,
        synopsisShort: true,
        synopsisMedium: true,
        synopsisLong: true
    }
};
