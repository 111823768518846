import _ from 'lodash';

export const authorized = (acl, capabilities, subjects) => {
    if (!acl) {
        return false;
    }

    const allAllow = acl.filter((acl) => !acl.deny && subjects.includes(acl.subject)).flatMap((acl) => acl.capabilities);
    const allDeny = acl.filter((acl) => acl.deny && subjects.includes(acl.subject)).flatMap((acl) => acl.capabilities);
    const allowed = allAllow.filter(n => !allDeny.includes(n));
    return capabilities.every(capability => allowed.includes(capability));
};

export const subjectsFromUser = (user) => {
    if (!user) return [];
    return [user.id, user.name, ...(user.orgs || []), ...(user.authorities || [])];
};

export const mergeAcl = (existingAcl, newAcl) => {
    const updatedAcl = [...existingAcl];
    newAcl.forEach((ace) => {
        const currentAcl = updatedAcl.find((existingAce) => existingAce.subject === ace.subject);
        currentAcl ? currentAcl.capabilities = [...new Set([...currentAcl.capabilities, ...ace.capabilities])] : updatedAcl.push(ace);
    });
    return updatedAcl;
};

export const removeAcl = (existingAclType, removeAclType, allACL) => {
    if (_.isEqual(existingAclType, removeAclType)) return { updatedAcl: [], updatedAclType: [] };
    const updatedAclType = [...existingAclType].filter(aclType => !removeAclType.some(type => type === aclType));
    const updatedAcl = updatedAclType.reduce((acc, curr) => {
        const newAcl = allACL[curr];
        return mergeAcl(acc, newAcl);
    }, []);
    return {
        updatedAcl,
        updatedAclType
    };
};

export const hasACLAccess = (user, defaultACL = []) => {
    const { authorities, orgs, name } = user;
    const userAccess = [...authorities, ...orgs, name];
    return defaultACL?.some(access => {
        return userAccess.includes(access.subject);
    });
};

export const hasExecACL = (acls) => {
    return acls.some(acl => acl.subject.startsWith('content:exec'));
};
