import { createContext, useContext, useState } from 'react';
import { EventContext } from '../pages/Event';
import Tabs from '../modules/Tabs';
import EventHistory from './EventHistory';
import EventNotifications from './EventNotifications';

import { UPDATE_EVENT, CHECK_EVENT_NAME, GET_EVENT_CONTENT_BY_ID } from '../../queries';
import { useMutation, useLazyQuery } from '@apollo/client';
import EventAudience from './EventAudience';
import { SnackbarContext } from '../../contexts/SnackbarContextProvider';
import EventDetails from './EventDetails';
import useUpdateEventContent from '../../hooks/event/useUpdateEventContent';
import EventCodes from './EventCodes';

import { dateValidation } from '../../utils/validation';
import EventContentTab from './EventContentTab';
import { SnackbarDialogContext } from '../../contexts/SnackbarDialogContextProvider';

export const EventTabsContext = createContext();

const EventTabs = props => {
    const { event, setEvent, setInitEvent, initEvent } = useContext(EventContext);
    const { successSnackbar, loadingSnackbar } = useContext(SnackbarContext);
    const { snackbarDialog } = useContext(SnackbarDialogContext);
    const [uploadedFile, setUploadedFile] = useState(null);
    const [activeTab, setActiveTab] = useState(null);
    const [errors, setErrors] = useState({});
    const [dateErrors, setDateErrors] = useState([]);

    const defaultVariables = {
        search: '',
        orderBy: 'createdAt',
        order: 'desc',
        limit: 25,
        page: 1,
        offset: 0
    };

    const [audienceVariables, setAudienceVariables] = useState({ ...defaultVariables });
    const [audienceTotal, setAudienceTotal] = useState(null);
    const [contentVariables, setContentVariables] = useState({ ...defaultVariables, orderBy: 'startAt' });
    const [contentTotal, setContentTotal] = useState(null);

    const [updateEvent] = useMutation(UPDATE_EVENT);
    const [checkEventName] = useLazyQuery(CHECK_EVENT_NAME);
    const [getEventContent] = useLazyQuery(GET_EVENT_CONTENT_BY_ID, {
        variables: {
            id: event.id,
            extended: false
        }
    });

    const handleTabChange = (activeTab) => {
        setActiveTab(activeTab);
        setUploadedFile(null);
    };

    const tabsData = [
        {
            label: 'Details',
            id: 'details'
        },
        {
            label: 'Content',
            id: 'content',
            count: `${initEvent?.contentCount ?? 0}`
        },
        {
            label: 'Audience',
            id: 'audience',
            count: `${initEvent?.audienceCount ?? 0}`,
            uploadDropzoneData: {
                acceptedFiles: '.csv',
                handleFile: (file) => {
                    loadingSnackbar({ text: 'Fetching Audience Account Details' });
                    setUploadedFile(file);
                }
            }
        },
        {

            label: 'Codes',
            id: 'codes',
            count: `${initEvent?.codeBatchCount ?? 0}`,
            uploadDropzoneData: {
                acceptedFiles: '.csv',
                handleFile: (file) => {
                    loadingSnackbar({ text: 'Fetching Code Details' });
                    setUploadedFile(file);
                }
            }
        }
    ];

    const handleDetailsUpdate = async (value, field) => {
        const { id, relatedValues } = field;
        if (errors[id]) return;
        const currentEvent = { ...initEvent };

        if (id === 'eventDates') {
            if (!initEvent?.eventContent?.length && !!initEvent.contentCount) {
                const { data } = await getEventContent();
                currentEvent.eventContent = data.eventContent;
            }

            const { startAt, endAt, timezone } = value;
            const { hasErrors, errors: currentErrors } = dateValidation({
                startAt,
                endAt,
                parentDates: currentEvent,
                isRange: true,
                compareParentDates: true,
                strictParentDates: false,
                timezone,
                initStart: currentEvent.startAt
            });

            if (hasErrors) {
                setDateErrors(currentErrors);
                return setInitEvent({ ...currentEvent, ...value });
            }
            setDateErrors([]);
        }

        loadingSnackbar({ text: `Updating ${field.label}` });

        const { updatedDetails } = useUpdateEventContent(currentEvent, field, value);

        if (relatedValues) {
            relatedValues.forEach(relatedValue => {
                const updateValue = value === relatedValue.value || value.includes(relatedValue.value);
                updatedDetails[relatedValue.id] = updateValue ? relatedValue.updatedValue : relatedValue.defaultValue;
            });
        }

        try {
            const { data } = await updateEvent({
                variables: {
                    id: event.id,
                    data: {
                        ...updatedDetails
                    }
                }
            });

            setInitEvent({ ...updatedDetails, ...data.event });
            successSnackbar({ text: `Successfully updated ${field.label}` });
        } catch (error) {
            console.log(error);
            snackbarDialog({
                snackbarText: `Error Updating ${field.label}`,
                dialogTitle: 'Event Details Error',
                graphQLErrors: error?.graphQLErrors
            });
        }
    };

    const checkNameDuplicate = async (name, field) => {
        const { data } = await checkEventName({ variables: { name } });
        const duplicateName = data.checkEventName.events.find(event => event.name.toLowerCase().trim() === name?.toLowerCase().trim());
        if (!!duplicateName && duplicateName.id === event.id) return;
        setErrors({ ...errors, duplicateName: !!duplicateName });
        if (!duplicateName) handleDetailsUpdate(name, field);
    };

    return (
        <EventTabsContext.Provider value={{
            uploadedFile,
            setUploadedFile,
            audienceVariables,
            setAudienceVariables,
            audienceTotal,
            setAudienceTotal,
            contentVariables,
            setContentVariables,
            contentTotal,
            setContentTotal
        }}
        >
            <div className={`EventTabs EventTabs__${activeTab?.id} flex column flex-grow stretch-width`}>
                <Tabs
                    tabsData={tabsData}
                    handleTabChange={(activeTab) => handleTabChange(activeTab)}
                    initTab='details'
                    className='stretch-width flex-grow'>
                    {
                        {
                            details: <EventDetails
                                event={event}
                                details={event}
                                setDetails={setEvent}
                                handleDetailsChange={(val, field) => setEvent({ ...event, [field.id]: val })}
                                handleDetailsBlur={(val, field) => field.id === 'name' ? checkNameDuplicate(val, field) : handleDetailsUpdate(val, field)}
                                errors={errors}
                                setErrors={setErrors}
                                dateErrors={dateErrors}
                                setDateErrors={setDateErrors}
                                detailsType='event'
                                compareParentDates
                            />,
                            content: <EventContentTab />,
                            audience: <EventAudience />,
                            codes: <EventCodes />,
                            history: <EventHistory />,
                            notifications: <EventNotifications />
                        }[activeTab?.id]
                    }
                </Tabs>
            </div>

        </EventTabsContext.Provider>
    );
};

export default EventTabs;
