import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

const PaginationRange = ({ paginationData, type }) => {
    if (!paginationData) return;
    const { limit, page, totalCount, shownCount } = paginationData;
    const [rangeLow, setRangeLow] = useState(page || 1);
    const [rangeHigh, setRangeHigh] = useState(limit);

    useEffect(() => {
        if (limit >= totalCount) {
            setRangeLow(1);
            setRangeHigh(totalCount);
        } else {
            setRangeLow(Math.floor((page - 1) * limit + 1));
            const high = Math.floor(page * limit);
            setRangeHigh(Math.min(high, totalCount));
        }
    }, [limit, page, totalCount]);

    return (
        <p className='Pagination__range'>
            {type === 'header' && 'Showing '}
            {!isNaN(rangeLow) && !isNaN(rangeHigh) ? `${rangeLow}-${rangeHigh}` : ''}
            {shownCount || ''} of {totalCount}{type === 'header' && ' results'}
        </p>
    );
};

export default PaginationRange;

PaginationRange.propTypes = {
    paginationData: PropTypes.object,
    type: PropTypes.string
};
