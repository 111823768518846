import { Outlet } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';
import Footer from './Footer';
import ErrorFallBack from './ErrorFallBack';
import SideNav from './SideNav';

const Layout = props => {
    return (
        <ErrorBoundary FallbackComponent={ErrorFallBack}>
            <div id='app' className='flex align-flex-start flex-start relative'>
                <SideNav />
                <div className='Layout'>
                    <Outlet />
                    <Footer />
                </div>
            </div>
        </ErrorBoundary>
    );
};

export default Layout;
