/* eslint-disable indent */
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { DialogContext } from '../../contexts/DialogContextProvider';
import Box from '../modules/Box';
import Status from '../modules/Status';
import { Heading, Body, Caption } from '../modules/Typography';

import Tooltip from '../modules/Tooltip';
import { EventContext } from '../pages/Event';

import getDateTime from '../../hooks/getDateTime';

import { DELETE_EVENT } from '../../queries';
import { useMutation } from '@apollo/client';
import { SnackbarContext } from '../../contexts/SnackbarContextProvider';
import Icon from '../modules/Icon';
import IconButton from '../modules/IconButton';
import { SnackbarDialogContext } from '../../contexts/SnackbarDialogContextProvider';
import { CopyEventDialog } from './CopyEventDialog';

const EventHeader = props => {
    const { deleteDialog, closeDialog, makeDialog } = useContext(DialogContext);
    const { successSnackbar } = useContext(SnackbarContext);
    const { snackbarDialog } = useContext(SnackbarDialogContext);
    const { event } = useContext(EventContext);
    const { publishedStatus, updatedAt, updatedBy, createdAt, createdBy, startAt, endAt } = event ?? {};
    const currentEventStatus = event.status;
    const eventStatusOrder = ['requested', 'ready', 'in_progress', 'completed'];
    const deleteDisabled = publishedStatus !== 'unpublished';

    const navigate = useNavigate();

    const [deleteEvent] = useMutation(DELETE_EVENT);

    const icon = {
        modified: {
            name: 'warning-round',
            color: 'warning'
        },
        unpublished: {
            name: 'stop',
            faded: true
        },
        published: {
            name: 'check-circle',
            color: 'success'
        }
    };

    const eventActions = [
        {
            icon: 'info',
            tooltip: <>
                <Body number={2} block><span className='Typography--heavy'>Created:</span> {createdBy}; {getDateTime(createdAt, 'America/Los_Angeles')}</Body>
                <Body number={2} block><span className='Typography--heavy'>Updated:</span> {updatedBy}; {getDateTime(updatedAt, 'America/Los_Angeles')}</Body>
            </>
        },
        {
            tooltip: 'Duplicate',
            icon: 'copy',
            onClick: () => {
                makeDialog({
                    title: 'Duplicate Event',
                    dialog: <CopyEventDialog event={event} />,
                    size: 'xl'
                });
            }
        },
        {
            tooltip: deleteDisabled ? 'Unable to Delete. Please Unpublish the Event prior to deleting' : 'Delete',
            icon: 'trash-can',
            disabled: deleteDisabled,
            onClick: () => {
                deleteDialog({
                    type: 'Event',
                    action: 'Remove',
                    name: [event.name],
                    size: 'medium',
                    handleCancel: () => {
                        closeDialog();
                    },
                    deleteMutation: async () => {
                        try {
                            const { data } = await deleteEvent({ variables: { eventIds: [event.id] } });
                            const { error } = data.deleteEvent;

                            if (error) {
                                console.log(error);
                                snackbarDialog({
                                    snackbarText: 'Error Deleting Event',
                                    dialogTitle: 'Event Deleting Error',
                                    graphQLErrors: error?.graphQLErrors
                                });
                                closeDialog();
                            } else {
                                successSnackbar({ text: 'Successfully deleted events' });
                                closeDialog();
                                navigate('/event-planner');
                            }
                        } catch (error) {
                            console.log(error);
                        }
                    }
                });
            }
        }
    ];

    if (!event) return;
    return (
        <Box
            type='white'
            borderType='none'
            shadow
            elevated={2}
            className='margin EventHeader'
        >

            <Icon
                className='EventHeader__PublishStatus'
                name={icon[publishedStatus]?.name}
                color={icon[publishedStatus]?.color ?? 'default'}
                faded={icon[publishedStatus]?.faded ?? false}
                size='medium' />
            <div className='flex-grow flex column'>
                <Heading number={5}>{event.name}</Heading>
                <Body number={2}><span className='Typography--heavy'>Source Id:</span> {event.approvalSourceId || '--'}</Body>
                <div className='flex flex-start spacing__top EventHeader__EventDates'>
                    <Icon name='calendar' size='medium' />

                    <div className='EventHeader__EventDate'>
                        <Caption faded block>Start Date</Caption>
                        <Body number={2}>{!startAt ? 'Unlimited' : getDateTime(startAt, 'America/Los_Angeles')}</Body>
                    </div>
                    <Body number={2} className='spacing__left spacing__right'>-</Body>
                    <div className='EventHeader__EventDate'>
                        <Caption faded block>End Date</Caption>
                        <Body number={2}>{!endAt ? 'Unlimited' : getDateTime(endAt, 'America/Los_Angeles')}</Body>
                    </div>
                </div>
            </div>
            <div className='flex column align-flex-end space-between'>
                <div className='flex'>
                    {eventActions.map(action =>

                        <Tooltip
                            key={action.icon}
                            tooltipCopy={action.tooltip}
                            className={`EventAction EventAction--${action.icon}`}
                            disabled={action.disabled}
                            >

                            {action.onClick
                                ? <IconButton
                                        className='EventAction__Button'
                                        name={action.icon}
                                        onClick={action.onClick}
                                        disabled={action.disabled}
                                />
                                : <Icon name={action.icon} />}

                        </Tooltip>
                    )}
                </div>
                <div className='flex EventHeader__StatusTracker spacing__left'>
                    {eventStatusOrder.map((eventStatus, index) => {
                        const past = eventStatusOrder.indexOf(currentEventStatus) > index;
                        return (
                            <Status
                                type='list'
                                value={eventStatus.replace('_', ' ')} key={index}
                                active={currentEventStatus === eventStatus}
                                past={past} />
                        );
                    })}
                </div>
            </div>

        </Box>

    );
};

export default EventHeader;
