import { gql } from '@apollo/client';

export const GET_APP_CONFIG = gql`
	query getConfig {
		config: getConfig {
                  audienceInvite {
                        redirectUrl
                        subject
                  }
                  sentry {
                        baseUrl
                        redirectUri
                  }
                  felix {
                        baseUrl
                  }
                  genie {
                        baseUrl
                  }
                  imagick {
                        baseUrl
                  }
                  imageCdnUrl {
                        baseUrl
                  }
                  debut {
                        baseUrl
                  }
                  gusteau {
                        baseUrl
                  }
                  presentationTypes {
                        id
                        display
                  }
                  fileTypes {
                        id
                        display
                  }
                  videoFormats {
                        id
                        display
                  }
                  imageServeAsFormats {
                        id
                        display
                  }
                  imageTypes {
                        id
                        display 
                  }
                  audioKinds {
                        id
                        display
                  }
                  genieJobTypes {
                        id
                        display
                  }
                  rfc5646LanguageTags {
                        id
                        display
                  }
                  hdrMetadatas {
                        id
                        display
                        value
                  }
                  watermarkingModes {
                        id
                        display
                  }
                  contentWindows {
                        id
                        display
                  }
                  subtitleFormats {
                        id
                        display
                  }
                  audioFormats {
                        id
                        display
                  }
                  timeCodes {
                        id
                        display
                  }
                  atmosFormats {
                        id
                        display
                  }
                  encoder {
                        id
                        display
                  }
                  ACLs
                  ACLSubjects
                  ratings
                  entitlementReportingTags
                  entitlementGroupTypes
                  resolutions
                  watermarkTypes {
                        id
                        name
                  }
                  ndaTemplates {
                        id
                        name
                  }
                  eventApprovalSource
                  providers {
                        name
                        display
                  }
                  myIDEmailDomains
                  watermarkingType {
                        id
                        name
                        order
                  }
                  watermarkingLocation {
                        id
                        name
                        order
                  }
                  watermarkingPersistence {
                        id
                        name
                        order
                  }
                  eventTemplates {
                        id
                        name
                  }
                  entitlementTags
                  studios
                  featureFlags {
                        id
                        active
                  }
                  processingPlaybackSource {
                        id
                        name
                  }
                  dubbingCredit {
                        display
                        id
                  }
            }
	}
`;
