import { useContext } from 'react';
import PropTypes from 'prop-types';
import Checkbox from '../Checkbox';

import { FileProcessingContext } from '../../fileProcessing/FileProcessing';

import { fileProcessingConfig } from '../../../config/fileProcessingConfig';

const FileProcessingCheckbox = ({ getValue, row }) => {
    const { disabledFileTypes } = fileProcessingConfig;
    const { processingFiles, setProcessingFiles, fileData } = useContext(FileProcessingContext);
    const value = getValue();
    return (
        <Checkbox
            handleCheck={({ checked }) => setProcessingFiles(checked ? [...processingFiles, { ...fileData.find(data => data.id === value) }] : processingFiles.filter(job => job.id !== value))}
            disabled={disabledFileTypes.includes(row.original.type)}
            value={value}
            checked={processingFiles.some(job => job.id === value)}
        />
    );
};

export default FileProcessingCheckbox;

FileProcessingCheckbox.propTypes = {
    getValue: PropTypes.func,
    row: PropTypes.object
};
