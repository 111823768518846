import { useContext } from 'react';

import { DataContext } from '../../contexts/DataContext';

import Box from '../modules/Box';
import { Heading, Body } from '../modules/Typography';

import { FileContext } from '../pages/File';
import Status from '../modules/Status';
import ChipSetEditable from '../modules/ChipSetEditable';
import FileFields from './FileFields';

const FileProcessingData = props => {
    const { activeFileHistory, currentFileConfig, renderDefault } = useContext(FileContext);
    const { appConfig } = useContext(DataContext);
    const { jobInput, jobOutput, jobId } = activeFileHistory;

    const genieBase = appConfig.genie.baseUrl;

    const getValue = (id, section) => {
        if (id === 'offset') {
            return jobInput?.offset;
        } else if (section === 'input') {
            return activeFileHistory?.[id] || jobInput?.[id] || 'N/A';
        } else if (section === 'output') {
            return activeFileHistory?.[id] || jobOutput?.[id] || 'Not Found';
        }
    };

    const renderSection = (field, section) => {
        const { id, label, textField, type, parentField } = field;

        const value = !textField ? getValue(id, section) : getValue(textField.fieldId, section);

        const { parentId, parentValue } = parentField || {};
        if (parentField && getValue(parentId, section) !== parentValue) return;

        switch (type) {
        case 'tags':
            return (
                <div className='FileDetails--tags flex align-flex-start flex-start' key={id}>
                    <Body number={2} heavy fadedTwoThirds>{label}:</Body>
                    <ChipSetEditable chips={jobOutput?.outputTags || []} />
                </div>
            );
        case 'status':
            return (
                <div className='FileDetails--status flex center flex-start' key={id}>
                    <Body number={2} heavy fadedTwoThirds>{label}:</Body>
                    <Status value={value} />
                </div>
            );
        case 'genieLink':
            return (
                <div className='FileDetails--genieLink flex align-flex-start' key={id}>
                    <Body number={2} heavy fadedTwoThirds>{label}:</Body>
                    <a className='Typography Typography--body2 Typography--color-default link' href={`${genieBase}jobs/${jobId}`} target='_blank' rel='noreferrer'>{value}</a>
                </div>
            );
        default:
            return renderDefault(field, value);
        }
    };

    return (
        <>
            {
                currentFileConfig.processingData.map((section, index) => {
                    return (
                        <Box
                            key={Math.random()}
                            type='white'
                            borderType='none'
                            shadow
                            elevated={2}
                            padded={false}
                            className={`flex align-flex-start flex-start margin FileDetails FileDetails--${section.id}`}
                            style={{ height: 'auto' }}
                        >

                            <Heading number={5} capitalize>{section.title}</Heading>

                            {
                                section.details.map((value, i) =>
                                    <FileFields
                                        key={value.id}
                                        value={value}
                                        idx={i}
                                        renderSection={renderSection}
                                        section={section.id}
                                        getValue={getValue}
                                    />
                                )
                            }

                        </Box>
                    );
                })
            }
        </>
    );
};

export default FileProcessingData;
