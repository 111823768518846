import { useContext, useEffect } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import { GET_EVENT_CODES_BY_ID, EVENT_CREATE_CODES, EVENT_DELETE_CODES } from '../../queries';
import { EventContext } from '../pages/Event';
import LoadingOverlay from '../modules/LoadingOverlay';
import CodeBatches from '../code/CodeBatches';
import { SnackbarContext } from '../../contexts/SnackbarContextProvider';
import { DialogContext } from '../../contexts/DialogContextProvider';
import { SnackbarDialogContext } from '../../contexts/SnackbarDialogContextProvider';

const EventCodes = () => {
    const { event, setInitEvent, initEvent, handleRefetchEvent } = useContext(EventContext);
    const { successSnackbar, errorSnackbar, loadingSnackbar, closeSnackbar } = useContext(SnackbarContext);
    const { snackbarDialog } = useContext(SnackbarDialogContext);
    const { closeDialog } = useContext(DialogContext);
    const [getEventCodesById, { loading: loadingEventCodesById }] = useLazyQuery(GET_EVENT_CODES_BY_ID);
    const [addCodes] = useMutation(EVENT_CREATE_CODES);
    const [removeCodes] = useMutation(EVENT_DELETE_CODES);

    const eventCodes = initEvent.eventCodes ?? [];

    const handleCodes = async (updatedCodes) => {
        const currentEvent = await handleRefetchEvent();
        setInitEvent({ ...currentEvent, eventCodes: updatedCodes });
    };

    const handleCodeFetch = async () => {
        if (!initEvent.codeBatchCount) return;
        loadingSnackbar({ text: 'Fetching Event Codes' });
        try {
            const { data } = await getEventCodesById({ variables: { id: initEvent.id } });
            setInitEvent({ ...initEvent, eventCodes: [...(data.eventCodes ?? [])] });
            closeSnackbar();
        } catch (error) {
            if (error.name !== 'AbortError') {
                console.log(error);
                errorSnackbar({ text: 'Failed to fetch event codes' });
            }
        }
    };

    useEffect(() => {
        if (eventCodes.length) return;
        handleCodeFetch();
    }, [initEvent?.eventCodes]);

    const handleActionError = (error) => {
        snackbarDialog({
            snackbarText: 'Failed to Remove Event Code',
            dialogTitle: 'Remove Event Code Error Update Error',
            graphQLErrors: error?.graphQLErrors
        });
    };

    const cardActions = {
        handleRemove: async (code) => {
            const variables = {
                id: event.id,
                eventCodeIds: [code.id]
            };
            try {
                loadingSnackbar({ text: `Removing code batch ${code.description}` });
                const { data } = await removeCodes({ variables });
                if (data.error) {
                    handleActionError(data.error);
                } else {
                    const updatedCodes = eventCodes.filter(eventCode => eventCode.id !== code.id);
                    await handleCodes(updatedCodes);
                    successSnackbar({ text: `Successfully removed code batch ${code.description}` });
                }
            } catch (error) {
                console.log(error);
                handleActionError(error);
            }
            closeDialog();
        }
    };

    const updateEventCodes = async (newCode) => {
        const variables = {
            id: event.id,
            eventCodeIds: newCode.id
        };
        try {
            loadingSnackbar({ text: `Adding code batch ${newCode.description}` });
            const { data } = await addCodes({ variables });
            const updatedCodes = [...eventCodes, ...(data.createEventCodes ?? [])];
            await handleCodes(updatedCodes);
            successSnackbar({ text: `Successfully added code batch ${newCode.description}` });
        } catch (error) {
            console.log(error);
            errorSnackbar({ text: 'Error adding code bactch' });
        }
    };

    return (
        <div className='Event__Codes'>
            {(loadingEventCodesById) && <LoadingOverlay />}
            <CodeBatches
                codes={eventCodes}
                cardActions={cardActions}
                handleCodes={(newCodes) => updateEventCodes(newCodes)} />
        </div>
    );
};

export default EventCodes;
