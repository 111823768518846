import { useContext } from 'react';
import Checkbox from '../Checkbox';
import { EventsContext } from '../../pages/EventsDashboard';

const EventDefaultCheckbox = props => {
    const {
        setSelectedEvents,
        events,
        defaultChecked,
        setDefaultChecked
    } = useContext(EventsContext);

    const handleDefaultCheck = ({ checked }) => {
        setDefaultChecked(checked);
        setSelectedEvents(checked ? events : []);
    };

    return (
        <Checkbox
            handleCheck={handleDefaultCheck}
            checked={defaultChecked}
        />
    );
};

export default EventDefaultCheckbox;
