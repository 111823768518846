import { useContext, useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import PropTypes from 'prop-types';

import DialogFooter from '../modules/DialogFooter';

import { DialogContext } from '../../contexts/DialogContextProvider';
import { SnackbarContext } from '../../contexts/SnackbarContextProvider';

import { ADD_ASSOCIATIONS } from '../../queries';
import EpisodeSelect from '../modules/FormFields/EpisodeSelect';
import SeasonSelect from '../modules/FormFields/SeasonSelect';

import { addEpisodeConfig } from '../../config/createPresentationConfig';
import PresentationAutoPublish from './PresentationAutoPublish';

const PresentationAddEpisodes = ({ presentationData, setPresentationData }) => {
    const { closeDialog } = useContext(DialogContext);
    const { errorSnackbar, successSnackbar } = useContext(SnackbarContext);
    const [newAssociations, setNewAssociations] = useState({});
    const { episodeNumber, seasonNumber, episodeId } = newAssociations;
    const isNew = episodeId === 'createNew';

    const { requiredFields, requiredNewEpisode, requiredNewSeason } = addEpisodeConfig;

    const [disabled, setDisabled] = useState(false);

    const [addAssociations] = useMutation(ADD_ASSOCIATIONS);

    const handleUpdate = async (value, id, pickedPresentation) => {
        const update = { ...newAssociations, [id]: value };

        if (pickedPresentation) {
            if (id === 'episodeId') {
                update.episodeName = pickedPresentation.name;
                update.episodeNumber = pickedPresentation.episodeNumber;
            }
            if (id === 'seasonId') {
                update.seasonName = pickedPresentation.name;
                update.seasonNumber = pickedPresentation.seasonNumber;
            }
        }
        setNewAssociations(update);
    };

    useEffect(() => {
        const allRequired = requiredFields.every(field => !!newAssociations[field]) &&
        (newAssociations.episodeId === 'createNew' ? requiredNewEpisode.every(field => !!newAssociations[field]) : true) &&
        (newAssociations.seasonId === 'createNew' ? requiredNewSeason.every(field => !!newAssociations[field]) : true);
        setDisabled(!allRequired);
    }, [newAssociations]);

    useEffect(() => {
        if (!isNew) return;
        setNewAssociations({
            ...newAssociations,
            autoPublishToLive: false,
            autoPublishToPreview: true
        });
    }, [isNew]);

    const handleCancel = () => {
        setNewAssociations({});
        closeDialog();
    };

    const handleAddEpisode = async () => {
        try {
            const { data } = await addAssociations({
                variables: {
                    presentation: presentationData,
                    newAssociations: {
                        ...newAssociations,
                        episodeNumber: Number(episodeNumber),
                        seasonNumber: Number(seasonNumber)
                    },
                    getAssociations: true
                }
            });
            setPresentationData({ ...presentationData, associatedPresentations: { ...data.addAssociations.associatedPresentations }, history: data.addAssociations.history });
            successSnackbar({ text: 'Successfully added an episode' });
        } catch (err) {
            console.log(err);
            errorSnackbar({ text: 'There was an error adding episodes' });
        }
        setNewAssociations({});
        closeDialog();
    };

    return (
        <>
            <div className='padded'>
                <EpisodeSelect
                    episode={newAssociations}
                    seriesPresentation={presentationData}
                    allEpisodes={presentationData?.associatedPresentations?.associatedEpisodes}
                    handleChange={(value, id, pickedEpisode) => handleUpdate(value, id, pickedEpisode)}
                    setDisabled={setDisabled}
                    disabled={disabled}
                />
                <SeasonSelect
                    associatedPresentation={newAssociations}
                    handleChange={(value, id, pickedSeason) => handleUpdate(value, id, pickedSeason)}
                    setDisabled={setDisabled}
                    disabled={disabled}
                    seriesPresentation={presentationData}
                />
                {
                    isNew && <PresentationAutoPublish
                        presentation={newAssociations}
                        setPresentation={setNewAssociations}
                        createNewPresentation
                    />
                }
            </div>
            <DialogFooter
                disabled={disabled}
                handleCancel={handleCancel}
                confirmCancelText='Cancel'
                handleConfirm={handleAddEpisode}
                confirmText='Add Episode'
                setLoading
            />
        </>
    );
};

export default PresentationAddEpisodes;

PresentationAddEpisodes.protoTypes = {
    presentationData: PropTypes.object,
    setPresentationData: PropTypes.func
};
