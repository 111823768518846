import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import TextLink from '../../modules/TextLink';
import checkUserRole from '../../../hooks/checkUserRole';

const FileCell = ({ row, getValue }) => {
    const { id } = useParams();

    const validTypes = ['audio', 'subtitle', 'video', 'image'];
    const value = getValue();
    const fileId = row.original.id;
    const type = row.original.type;

    return (id && validTypes.includes(type) && checkUserRole('ROLE_CONTENT_INGEST'))
        ? <TextLink to={`/presentation/${id}/file/${fileId}`}>{value}</TextLink>
        : value;
};

export default FileCell;

FileCell.propTypes = {
    getValue: PropTypes.func,
    row: PropTypes.object
};
