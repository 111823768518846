import { createContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import Drawer from '../Drawer';
import IconButton from '../IconButton';
import TopAppBar from '../TopAppBar';
import { Heading } from '../Typography';
import FilterGroups from './FilterGroups';
import Button from '../Button';

import { filtersConfig } from '../../../config/filtersConfig';

export const FiltersContext = createContext();

const Filters = ({ open, handleClose, filterType, handleFilters, queryFilters, queryFilterConstraints }) => {
    const [filterGroups, setFilterGroups] = useState([]);
    const filterConfig = filtersConfig[filterType?.toLowerCase()];

    const handleInitFilters = (initFilters = {}) => {
        const initValues = [...filterConfig].map(data => {
            if (!data.filterIds) {
                return { ...data, filterValues: initFilters[data.id]?.split(',') ?? [] };
            }
            const currentVals = _.pick(initFilters, data.filterIds);
            return { ...data, filterValues: { ...data.filterValues, ...currentVals } };
        });

        setFilterGroups(initValues);
        return initValues;
    };

    useEffect(() => {
        if (!filterType || !filterConfig) return;
        handleInitFilters(queryFilters);
    }, [filterType, open]);

    const handleApplyFilters = async () => {
        await handleFilters(filterGroups);
        handleClose();
    };

    const handleClearFilters = async () => {
        const clearedFilters = handleInitFilters();
        await handleFilters(clearedFilters);
        handleClose();
    };

    return (
        <FiltersContext.Provider value={{
            filterGroups,
            setFilterGroups,
            queryFilterConstraints
        }}>
            <Drawer open={open} className='Filters--Wrapper'>
                <TopAppBar static className='flex padded Filters__Header'>
                    <Heading number={6}>Filter {filterType}</Heading>
                    <IconButton name='close' onClick={() => handleClose()} />
                </TopAppBar>
                <FilterGroups />
                <div className='Filters__Footer flex flex-end padded'>
                    <Button type='secondary' onClick={() => handleClearFilters()}>Clear</Button>
                    <Button type='primary' onClick={() => handleApplyFilters()} className='spacing__left'>Apply</Button>
                </div>
            </Drawer>
        </FiltersContext.Provider>
    );
};

export default Filters;

Filters.defaultProps = {
    open: 'false'
};
Filters.proptypes = {
    open: PropTypes.bool,
    handleClose: PropTypes.func,
    filterType: PropTypes.string,
    handleFilters: PropTypes.func,
    queryFilters: PropTypes.object,
    queryFilterConstraints: PropTypes.object
};
