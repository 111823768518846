/* eslint-disable indent */
import { useContext, useEffect } from 'react';
import { DataContext } from '../../../contexts/DataContext';
import { FileProcessingJobContext } from '../FileProcessingJob';

import Select from '../../modules/Select';
import { Caption } from '../../modules/Typography';
import TextField from '../../modules/TextField';
import Accordion from '../../modules/Accordion';
import Checkbox from '../../modules/Checkbox';
import Switch from '../../modules/Switch';
import Button from '../../modules/Button';
import TextFieldLabel from '../../modules/TextFieldLabel';
import { FeatureFlagContext } from '../../../contexts/FeatureFlagContext';
import { FileProcessingDialogContext } from '../FileProcessingDialog';

const JobFields = props => {
    const { appConfig } = useContext(DataContext);
    const { checkFeatureFlag } = useContext(FeatureFlagContext);
    const { isVpp } = useContext(FileProcessingDialogContext);
    const {
        videoJobType,
        setVideoJobType,
        currentFile,
        reprocessFile,
        setReprocessFile,
        jobConfig,
        setJobConfig,
        errors,
        setErrors,
        fileProcessingConfig,
        fileDefaults,
        filesToProcess,
        dolbyFiles,
        loadingProcessInfo
    } = useContext(FileProcessingJobContext);

    const { type, id: fileId } = currentFile;
    const { fields, processingFields, advancedFields, renderAdvanced } = fileProcessingConfig;
    const showAdvanced = !renderAdvanced?.some(x => jobConfig[x.configId] !== x.value);

    useEffect(() => {
        if (dolbyFiles.length >= 1 && jobConfig.fileFormat === 'uhd_dovi') {
            setJobConfig({ ...jobConfig, dolbyVisionXml: (isVpp) ? dolbyFiles[0].id : dolbyFiles[0].location });
        }
    }, [jobConfig.fileFormat]);

    const validate = (type, updatedConfig, value, allowZero = false, digits = 0) => {
        switch (type) {
            case 'number':
                return isNaN(value) || (digits > 0 && value?.toString().length !== digits);
            case 'time':
                return (allowZero && Number(value) === 0) ? false : !/^\d?\d:\d{2}:\d{2}:\d{2}$/.test(value);
            case 'hdrMetadata':
                return !/^G\(\d{4}\d?,\d{5}\)B\(\d{4},\d{4}\)R\(\d{5},\d{5}\)WP\(\d{5},\d{5}\)L\(\d{8},\d\d?\)$/.test(value);
            case 'checkClipmarkEnd':
                return isNaN(updatedConfig.clipmarkEnd) || updatedConfig.clipmarkEnd > 5000 || Number(updatedConfig.clipmarkThreshold) > Number(updatedConfig.clipmarkEnd);
            case 'checkClipmarkThreshold':
                return isNaN(updatedConfig.clipmarkThreshold) || updatedConfig.clipmarkThreshold > 1000 || Number(updatedConfig.clipmarkEnd) < Number(updatedConfig.clipmarkThreshold);
            case 'atmos':
                return (updatedConfig.jobType === 'shortform' || updatedConfig.jobType === 'proxisShortform') && updatedConfig.fileFormat === 'atmos';
            case 'dolby':
                return !dolbyFiles.length && updatedConfig.fileFormat === 'uhd_dovi';
            default:
                break;
        }
    };

    const checkForErrors = (config, field, value) => {
        const check = (validation) => {
            const error = validate(validation.type, config, value, validation.allowZero, validation.digits);
            const validationId = validation.configId || id;
            if (errors[validationId] !== error) setErrors((previous) => ({ ...previous, [validationId]: error }));
        };

        const { validation, id } = field;

        return Array.isArray(validation)
            ? validation.forEach(v => check(v))
            : check(validation);
    };

    const handleRelatedValues = (relatedValues, currentConfig) => (
        relatedValues.reduce((acc, relatedValue) => {
            const { changeValue, configId, value } = relatedValue;
            const currentValue = currentConfig[configId];
            return { ...acc, [configId]: (currentValue === changeValue || !changeValue) ? value : currentValue };
        }, { ...currentConfig })
    );

    const updateDefaults = async (jobTypeField) => {
        let current = { ...fileDefaults, jobType: jobConfig.jobType };
        if (current.jobType === 'proxisUnprotected' || current.jobType === 'proxisShortform') current = { ...current, ...current.proxis };
        if (jobTypeField?.relatedValues?.[jobConfig.jobType]) {
            current = await handleRelatedValues(jobTypeField.relatedValues?.[jobConfig.jobType], current);
        }
        setJobConfig(current);
    };

    useEffect(() => {
        const jobTypeField = fields.find(field => field.id === 'jobType');

        if (type === 'audio' && jobTypeField) checkForErrors(jobConfig, jobTypeField, jobConfig.jobType);

        if (type === 'video') {
            if (filesToProcess[0].id === fileId && jobConfig.jobType) {
                setVideoJobType(jobConfig.jobType);
            }
            if (fileDefaults) updateDefaults(jobTypeField);
        }
    }, [type, jobConfig.jobType]);

    const checkInt = (value) => {
        const testInt = /^\d+$/.test(value);
        return testInt ? parseInt(value) : value;
    };

    const handleConfigUpdate = async (value, key, field) => {
        const { validation, relatedValues, formatting } = field;
        let current = { ...jobConfig, [key]: formatting === 'number' ? checkInt(value) : value };
        if (relatedValues?.[value]) {
            current = await handleRelatedValues(relatedValues?.[value], current);
        }
        setJobConfig(current);

        if (validation) checkForErrors(current, field, value);
    };

    const renderDolby = (field) => {
        if (!dolbyFiles.length) return;
        return (
            dolbyFiles.length === 1
                ? <TextField
                        key={field.id} l
                        label='DolbyVisionXml'
                        disabled
                        defaultValue={dolbyFiles[0].name}
                />
                : <Select
                        key={field.id}
                        options={dolbyFiles.map(file => ({ text: file.name, value: file.location }))}
                        onChange={({ value }) => handleConfigUpdate(value, 'dolbyVisionXml', field.id)}
                        label='DolbyVisionXml'
                        selectedValue={jobConfig.dolbyVisionXml || ''}
                />
        );
    };

    const renderTextField = (field) => {
        const { id, label, errorMessage, formatting, disabled } = field;
        const value = formatting === 'number' ? jobConfig?.[id]?.toString() : jobConfig?.[id];

        return (
            <TextField
                key={id}
                label={label || id}
                onChange={(e) => handleConfigUpdate(e.target.value, id, field)}
                value={value || ''}
                error={errors[id] || false}
                errorMessage={errorMessage}
                disabled={disabled}
            />
        );
    };

    const renderTextFieldWithButton = (field) => {
        const { id, label, errorMessage, formatting, disabled, buttonFunction, buttonCopy } = field;
        const value = formatting === 'number' ? jobConfig?.[id]?.toString() : jobConfig?.[id];

        return (
            <div key={`${id}-div`} className='TextField--withButton'>
                <TextFieldLabel disabled={disabled} label={label} id={id} />
                <div className='flex spacing__bottom'>
                    <TextField
                        key={id}
                        onChange={(e) => handleConfigUpdate(e.target.value, id, field)}
                        value={value || ''}
                        error={errors[id] || false}
                        errorMessage={errorMessage}
                        disabled={disabled}
                        padded={false}
                />
                    <Button
                        type='primary'
                        text={buttonCopy}
                        onClick={() => handleConfigUpdate(buttonFunction(), id, field)}
                        className='spacing__left'
                    />
                </div>
            </div>
        );
    };

    const getSelectValue = (id, defaultVal) => {
        if (jobConfig.fileFormat === 'atmos' && id === 'jobType') {
            return jobConfig?.[id] || defaultVal || videoJobType;
        } else if (id === 'playbackSource') {
            return isVpp ? 'vpp' : 'debut';
        } else {
            return jobConfig?.[id] || defaultVal;
        }
    };

    const renderSelect = (field) => {
        const { appConfigData, filterSelectOptions, id, label, errorMessage, conditionalFields, defaultVal, disabled } = field;

        const options = appConfig[appConfigData].filter(val =>
            !filterSelectOptions?.find(filterSelectOption => {
                const checkConfig = filterSelectOption.values.includes(jobConfig[filterSelectOption.configId] || currentFile[filterSelectOption.configId]);
                const checkVpp = (filterSelectOption.configId === 'playbackSource' && isVpp);
                const hasValues = filterSelectOption.optionsToRemove.includes(val.id);
            return ((checkConfig || checkVpp) && hasValues);
            })
        ).map(option => ({
            text: id === 'language' ? option.id + ' | ' + option.display : (option.display ?? option.name),
            value: option.id
        }));

        const selectValue = getSelectValue(id, defaultVal);

        return (
            <div key={id} className={id}>
                <Select
                    label={label || id}
                    options={options}
                    onChange={({ value }) => handleConfigUpdate(value, id, field)}
                    selectedValue={selectValue}
                    searchable={id === 'language'}
                    disabled={appConfigData.length === 1 || loadingProcessInfo || disabled}
                    loading={loadingProcessInfo}
                />

                {errors[id] &&
                    <Caption error className='top-minus10'>{errorMessage}</Caption>}

                {(id === 'jobType' && !!videoJobType && jobConfig[id] !== videoJobType) &&
                    <Caption className='top-minus10'>*Job must be the same for all files to playback correctly</Caption>}

                {conditionalFields?.[selectValue] &&
                    conditionalFields[selectValue].map(field => renderField(field))}
            </div>
        );
    };

    const renderSwitch = (field) => {
        const { id, label, defaultVal } = field;
        const checked = jobConfig[id];
        if (checked === null) setJobConfig({ ...jobConfig, [id]: defaultVal });
        return <Switch label={label || id} key={id} id={id} checked={checked ?? defaultVal} className={id} onChange={({ target }) => handleConfigUpdate(target.checked, id, field)} />;
    };

    const renderField = (field) => {
        if (field.featureFlag && !checkFeatureFlag(field.featureFlag)) return;
        if (field.renderField && !field.renderField.some(render => render.values.includes(jobConfig[render.configId]))) return;

        switch (field.type) {
            case 'text':
                return renderTextField(field);
            case 'textWithButton':
                return renderTextFieldWithButton(field);
            case 'select':
                return renderSelect(field);
            case 'switch':
                return renderSwitch(field);
            case 'dolby':
                return renderDolby(field);
            case 'reprocess':
                return <Checkbox checked={reprocessFile} key='reprocess' label='Reprocess File' handleCheck={() => setReprocessFile(!reprocessFile)} />;
            default:
                break;
        }
    };

    return (
        <div className='spacing__top'>
            {fields.map(field => renderField(field))}
            {processingFields &&
                <div className='half-spacing__top half-spacing__bottom flex ProcessingFields'>
                    {processingFields.map(field => renderField(field))}
                </div>}
            {
                (advancedFields && showAdvanced) &&
                <Accordion title='Advanced' iconOpen='small-caret-right' iconClose='small-caret-down' type='advancedDetails'>
                    {advancedFields.map(field => renderField(field))}
                </Accordion>
            }
        </div>
    );
};

export default JobFields;
