/* eslint-disable indent */
import { useState, useContext } from 'react';
import { useMutation, useQuery } from '@apollo/client';

import { FileProcessingDialogContext } from './FileProcessingDialog';
import { SnackbarContext } from '../../contexts/SnackbarContextProvider';

import Select from '../modules/Select';
import Button from '../modules/Button';
import Accordion from '../modules/Accordion';
import { Body, Subtitle } from '../modules/Typography';
import DialogFooter from '../modules/DialogFooter';

import { ADD_FILE_TO_PRESENTATION, SEARCH_PRESENTATIONS } from '../../queries';
import CreatePresentation from '../modules/CreatePresentation';

import checkUserRole from '../../hooks/checkUserRole';
import { checkPlural } from '../../utils/checkPlural';

const FileProcessingPresentation = () => {
    const { setSelectedPresentation, processingFiles, setProcessingFiles } = useContext(FileProcessingDialogContext);
    const { errorSnackbar, successSnackbar } = useContext(SnackbarContext);

    const [toggleNewPresentation, setToggleNewPresentation] = useState(false);
    const [filesOpen, setFilesOpen] = useState(processingFiles.length <= 10);
    const [search, setSearch] = useState('');
    const [currentPresentation, setCurrentPresentation] = useState({});

    const accordionTitle = toggleNewPresentation ? 'Create new presentation' : 'Select a presentation';

    const [addFileToPresentation, { loading: loadingAddToPresentation }] = useMutation(ADD_FILE_TO_PRESENTATION);
    const variables = {
        search,
        orderBy: '',
        order: '',
        types: processingFiles.every(file => file.type === 'image') ? 'movie,promo,episode,public,series' : 'movie,promo,episode,public',
        limit: 15,
        offset: 0,
        page: 1
    };

    const { data: allPresentationData, loading: loadingPresentations } = useQuery(SEARCH_PRESENTATIONS, { variables });
    const { presentations } = allPresentationData?.allPresentations || {};
    const mapPresentations = presentations => presentations.map(item => {
        return ({
            text: item.name,
            value: item.id
        });
    }
    );

    const handleBulkAdd = async (presentation) => {
        for (const file of processingFiles) {
            const { id: fileId, name } = file;
            const variables = { id: presentation.id, fileId };
            try {
                await addFileToPresentation({ variables });
                successSnackbar({ text: `Successfully added files to presentation: ${name}` });
            } catch (error) {
                console.log(error);
                setProcessingFiles(processingFiles.filter(file => file.id !== fileId));
                errorSnackbar({ text: `There was an error adding file ${name} to presentation` });
            }
        }
        setSelectedPresentation(presentation);
    };

    const handlePresentationSelect = (id) => {
        const selected = presentations.find(presentation => presentation.id === id);
        setCurrentPresentation(selected);
    };

    return (

        <>
            <Accordion
                className='padded__left padded__right FileProcessing__Files--Accordion'
                initOpen={processingFiles.length <= 10}
                title={
                    <>
                        <Subtitle className='colorBase'>{accordionTitle}</Subtitle>
                        <span className='clickable'>{`Show Files (${processingFiles.length})`}</span>
                    </>
                }
                closeTitle={
                    <>
                        <Subtitle className='colorBase'>{accordionTitle}</Subtitle>
                        <span className='clickable'>Hide Files</span>
                    </>
                }
                handleToggleChange={(val) => setFilesOpen(val)}
                type='advancedDetails'>
                <div className='FileProcessing__Files--wrapper FileProcessing__Files--wrapper--solid padded'>
                    <div className='FileProcessing__Files FileProcessing__Files--list FileProcessing__Files--list--solid stretch-width'>
                        {
                            processingFiles.map((job) =>
                                <Body
                                    number={2}
                                    key={job.id}
                                    className='FileProcessing__Files--list--file flex'
                                >
                                    {job.name}
                                </Body>
                            )
                        }
                    </div>
                </div>
            </Accordion>
            {
                toggleNewPresentation
                    ? <CreatePresentation
                            handlePostCreate={(_, presentation) => handleBulkAdd(presentation)}
                            handleBack={() => setToggleNewPresentation(false)}
                            processing
                            processingFiles={processingFiles}
                            filesOpen={filesOpen}
                    />
                    : <>
                        <div className='flex column FileProcessing__Presentation' style={{ padding: '0 15px 15px', flexGrow: '1', width: '100%' }}>
                            <Select
                                className='FileProcessing__Presentation--select'
                                disabled={loadingPresentations}
                                loading={loadingPresentations}
                                options={loadingPresentations
                                    ? []
                                    : mapPresentations(presentations)}
                                searchable
                                handleSearchChange={(searchValue) => setSearch(searchValue)}
                                onChange={({ value }) => handlePresentationSelect(value)}
                                label='Presentation'
                                selectedValue={currentPresentation.id}
                                placeholder='Select a presentation...'
                            />
                            {
                                checkUserRole('ROLE_PRODUCER') &&
                                <Button onClick={() => setToggleNewPresentation(true)} size='small'>Create a new presentation</Button>
                            }
                        </div>
                        <DialogFooter
                            handleCancel={() => setProcessingFiles([])}
                            handleConfirm={() => handleBulkAdd(currentPresentation)}
                            confirmText={checkPlural('Process File', processingFiles)}
                            disabled={(!currentPresentation.id && !!processingFiles.length)}
                            loading={loadingAddToPresentation}
                        />
                    </>
            }
        </>
    );
};

export default FileProcessingPresentation;
