import { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { SnackbarContext } from '../../contexts/SnackbarContextProvider';
import FileUploadButton from './FileUploadButton';

const UploadDropzone = ({ acceptedFiles, handleFile, buttonCopy, children, className, disabled, multipleFiles, asperaWeb, isSeries }) => {
    const { warningSnackbar, errorSnackbar } = useContext(SnackbarContext);
    const [dropzoneActive, setDropzoneActive] = useState(false);

    const handleDrag = (e) => {
        e.preventDefault();
        if (disabled) return;
        setDropzoneActive(true);
    };

    const checkAsperaFileType = (files) => {
        let allowedFiles = /\.(mp4|mov|mxf|atmos.audio|atmos.metadata|atmos.dbmd|atmos|wav|vtt|tt|ttml|webvtt|xml|jpg|jpeg|png|pdf)$/i;
        if (isSeries) {
            allowedFiles = /\.(jpg|jpeg|png|pdf)$/i;
        }
        const validFiles = files.filter(file => allowedFiles.test(file.name));
        if (validFiles.length !== files.length || !validFiles.length) {
            return errorSnackbar({ text: `Invalid File. Accepted file types are ${acceptedFiles.map(type => ` ${type}`)}.` });
        }
        return validFiles;
    };

    const handleAsperaDrop = (dropEvent) => {
        const { files } = dropEvent.files.dataTransfer;
        const validFiles = checkAsperaFileType(files);
        !!validFiles.length && handleFile(validFiles);
    };

    useEffect(() => {
        if (!asperaWeb) return;
        if (asperaWeb) asperaWeb.setDragDropTargets('.UploadDropzone__Aspera', { drop: true, allowMultipleSelection: true, allowPropagation: true }, (e) => { handleAsperaDrop(e); });
    }, [asperaWeb]);

    const handleDrop = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setDropzoneActive(false);
        const files = [...e.dataTransfer.files];
        if (!files?.length) return;
        if (!validateFileExtensons(files, acceptedFiles)) {
            warningSnackbar({ text: 'Invalid file type' });
            return;
        };
        handleFile(multipleFiles ? files : files[0]);
    };

    const validateFileExtensons = (files, acceptedFiles) => {
        const allFilesNamesValid = files.every(file => {
            const fileNameExtension = file.name.split('.').pop().toLowerCase();
            const fileHasAcceptedExtension = acceptedFiles.indexOf(fileNameExtension) !== -1;
            return fileHasAcceptedExtension;
        });
        return allFilesNamesValid;
    };

    const handleDragEnd = (e) => {
        e.preventDefault();
        setDropzoneActive(false);
    };

    return (
        <div
            className={`UploadDropzone ${dropzoneActive ? 'UploadDropzone--active' : ''} ${className} ${asperaWeb ? 'UploadDropzone__Aspera' : ''}`}
            onDragOver={handleDrag}>
            {dropzoneActive && <div className='UploadDropzone__Mask' onDragLeave={handleDragEnd} onDrop={asperaWeb ? handleDragEnd : handleDrop} />}
            {
                buttonCopy &&
                    <div className='flex'>
                        <FileUploadButton
                            buttonCopy={buttonCopy}
                            handleFile={handleFile}
                            acceptedFiles={acceptedFiles}
                        />
                    </div>
            }

            <div className='UploadDropzone__Content'>
                {children}
            </div>
        </div>
    );
};

export default UploadDropzone;

UploadDropzone.defaultProps = {
    acceptedFiles: '.csv',
    className: '',
    multipleFiles: false,
    asperaDropzone: false
};

UploadDropzone.propTypes = {
    acceptedFiles: PropTypes.string,
    handleFile: PropTypes.func,
    children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    buttonCopy: PropTypes.string,
    className: PropTypes.string,
    disabled: PropTypes.bool,
    multipleFiles: PropTypes.bool,
    asperaDropzone: PropTypes.bool
};
