import PropTypes from 'prop-types';
import Icon from '../Icon';
export const getPublishStatusIcon = (value, id, version, lastPublishVersion) => {
    switch (value) {
    case 'published':
        return <Icon name='check-circle' color='success' />;
    case 'live_published':
        return <Icon name='check-circle' color='success' />;
    case 'preview_published':
        return <Icon name='check-circle' color='success' />;
    case 'unpublished':
        return <Icon name='stop' faded />;
    case 'live_unpublished':
        return <Icon name='stop' faded />;
    case 'preview_unpublished':
        return <Icon name='stop' faded />;
    case 'dirty':
        return (
            (!!version && version > lastPublishVersion?.[id]) || !version
                ? <Icon name='warning-round' color='warning' />
                : <Icon name='check-circle' color='success' />
        );
    case 'live_dirty':
        return (
            (!!version && version > lastPublishVersion?.[id]) || !version
                ? <Icon name='warning-round' color='warning' />
                : <Icon name='check-circle' color='success' />
        );
    case 'preview_dirty':
        return (
            (!!version && version > lastPublishVersion?.[id]) || !version
                ? <Icon name='warning-round' color='warning' />
                : <Icon name='check-circle' color='success' />
        );
    default:
        break;
    }
};
const PublishStatusCell = ({ getValue, row, column }) => {
    const { value } = getValue();
    const { id } = column;
    const { version, lastPublishVersion } = row.original;

    return getPublishStatusIcon(value, id, version, lastPublishVersion);
};

export default PublishStatusCell;

PublishStatusCell.propTypes = {
    getValue: PropTypes.func
};
