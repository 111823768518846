import { useContext, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useMutation, useLazyQuery } from '@apollo/client';
import _ from 'lodash';

import Table, { columnHelper } from '../modules/Table';
import IconButton from '../modules/IconButton';
import Button from '../modules/Button';
import TableCheckbox from '../modules/TableCell/TableCheckbox';
import TableCheckboxHeader from '../modules/TableCell/TableCheckboxHeader';
import SubRowToggle from '../modules/TableCell/SubRowToggle';
import PresentationDetailsCell from '../modules/TableCell/PresentationDetailsCell';
import DateTimeCell from '../modules/TableCell/DateTimeCell';
import EventContentSubRow from './EventContentSubRow';
import SearchDropdown from '../modules/SearchDropdown';
import EventContentEpisodes from './EventContentEpisodes';
import PopUpCard from '../modules/PopUpCard';

import checkUserRole from '../../hooks/checkUserRole';
import addNewEventContent from '../../hooks/event/addNewEventContent';

import { SEARCH_PRESENTATIONS, EVENT_REMOVE_CONTENT, GET_ASSOCIATIONS } from '../../queries';
import DashboardMenu from '../modules/DashboardMenu';
import { DialogContext } from '../../contexts/DialogContextProvider';
import { SnackbarContext } from '../../contexts/SnackbarContextProvider';
import { EventContext } from '../pages/Event';
import PresentationDeepLink from '../presentation/PresentationDeepLink';

const EventContentTable = props => {
    const {
        eventContent,
        event,
        setEvent,
        parentContent,
        createNew,
        handleNewContent,
        triggerNewContent
    } = props;

    const [removeEventContent] = useMutation(EVENT_REMOVE_CONTENT);
    const [getAssociatedPresentations] = useLazyQuery(GET_ASSOCIATIONS);

    const { makeDialog, closeDialog, deleteDialog } = useContext(DialogContext);
    const { setInitEvent, initEvent } = useContext(EventContext) ?? {};
    const { errorSnackbar, successSnackbar, warningSnackbar, loadingSnackbar, closeSnackbar } = useContext(SnackbarContext);
    const [episodeParent, setEpisodeParent] = useState(null);
    const [selectedContent, setSelectedContent] = useState([]);

    const { relatedContent } = parentContent ?? {};

    const initialData = parentContent
        ? [...(relatedContent ?? []), parentContent]
        : [...eventContent];

    const episodeDialog = (newContent) => {
        makeDialog({
            dialog: <EventContentEpisodes
                parentContent={newContent}
                handleCancel={() => closeDialog()}
                handleNewContent={handleNewContent}
                event={event}
                initialData={initialData}
            />,
            disableCloseOnClick: true,
            size: 'xl',
            className: 'EventContentEpisodesDialog manual-overflow'
        });
    };

    const renderSubComponent = (row, subRow, id) => (
        <EventContentSubRow
            row={row}
            subRow={subRow}
            id={id}
            createNew={createNew}
            event={initEvent ?? event}
            setEvent={(updatedEvent) => setInitEvent ? setInitEvent(updatedEvent) : setEvent(updatedEvent)}
            parentContent={parentContent}
        />
    );

    const getSelectedContentAssociations = async (newContent) => {
        const contentWithAssociations = { ...newContent };
        try {
            const { data, error } = await getAssociatedPresentations({ variables: { associations: newContent.associations } });
            return { contentWithAssociations: { ...contentWithAssociations, associatedPresentations: data?.getAssociations }, error };
        } catch (error) {
            console.log(error);
            return { contentWithAssociations, error };
        }
    };

    const handleSelectedContent = async (newContent) => {
        if (newContent.type === 'series' || newContent.type === 'season') {
            loadingSnackbar({ text: `Finding episodes assoicated with ${newContent.name}` });
            const associatedEpisodes = newContent?.associations?.episodePresentationIds || [];
            const episodes = associatedEpisodes?.filter(episodeId => !initialData?.some(data => data.presentationId === episodeId));
            if (!episodes.length) {
                return warningSnackbar({ text: 'Found no unique episodes that could be added to this event' });
            } else {
                const { contentWithAssociations, error } = await getSelectedContentAssociations(newContent);
                if (error) {
                    return errorSnackbar({ text: `Failed to find episodes assoicated with ${newContent.name}` });
                } else {
                    closeSnackbar();
                    return createNew ? setEpisodeParent(contentWithAssociations) : episodeDialog(contentWithAssociations);
                }
            }
        }

        loadingSnackbar({ text: 'Adding Event Content' });
        const currentContent = { ...newContent };
        if (newContent.type === 'episode') {
            const { contentWithAssociations } = await getSelectedContentAssociations(newContent);
            currentContent.associatedPresentations = contentWithAssociations.associatedPresentations;
        }
        const { updatedContent } = addNewEventContent(currentContent, parentContent ?? event);
        handleNewContent([updatedContent]);
        if (createNew) closeSnackbar();
    };

    const handleRemoveContent = async (content) => {
        const removeMessaging = parentContent
            ? {
                error: `Failed to remove Related Content from ${parentContent.name}`,
                success: `Successfully removed Related Content from ${parentContent.name}`
            }
            : {
                error: 'Error removing content',
                success: `Successfully removed ${content.length} piece${content.length > 1 ? 's' : ''} of content `
            };
        deleteDialog({
            type: 'Content',
            action: 'Remove',
            confirmAction: 'Removed',
            name: content.map((content) => content.name),
            size: 'medium',
            className: 'EventContent__Delete',
            handleCancel: () => {
                setSelectedContent([]);
                closeDialog();
            },
            deleteMutation: async () => {
                const variables = {
                    id: event.id,
                    data: content.map(c => c.id)
                };

                const { data } = await removeEventContent({ variables });
                if (data.error) {
                    errorSnackbar({ text: removeMessaging.error });
                } else {
                    successSnackbar({ text: removeMessaging.success });
                }
                setSelectedContent([]);
                closeDialog();
            }
        });
    };

    const handleNewRemoveContent = (content) => {
        const currentEvent = _.cloneDeep(event);
        if (parentContent) {
            const updatedRelatedContent = relatedContent.filter(related => !content.some(c => c.presentationId === related.presentationId));
            currentEvent.eventContent[currentEvent.eventContent.findIndex(c => c.presentationId === parentContent.presentationId)].relatedContent = updatedRelatedContent;
        } else {
            currentEvent.eventContent = currentEvent.eventContent.filter(ec => !content.some(c => ec.presentationId === c.presentationId));
        }
        setEvent(currentEvent);
    };

    const tableData = useMemo(() => eventContent.map(content => {
        return {
            ...content,
            startAt: content.startAt,
            endAt: content.endAt,
            subRows: [
                {
                    id: 'details',
                    renderSubComponent: (row, subRow, id) => renderSubComponent(row, subRow, id)
                },
                {
                    id: 'relatedContent',
                    label: `Related Content (${content.relatedContent?.length || 0})`,
                    renderSubComponent: (row, subRow, id) => renderSubComponent(row, subRow, id)
                }
            ]
        };
    }), [eventContent]);

    const columns = useMemo(
        () => [
            columnHelper.display({
                header: TableCheckboxHeader,
                id: 'checkbox',
                cell: TableCheckbox,
                enableSorting: false,
                size: 50,
                meta: {
                    className: 'CheckboxCell'
                }
            }),
            columnHelper.accessor('name', {
                header: 'content name',
                cell: PresentationDetailsCell,
                size: 250
            }),
            columnHelper.accessor('approvalSourceId', {
                header: 'Source Id',
                size: 125,
                meta: {
                    className: 'overflow--hidden'
                }
            }),
            columnHelper.accessor('tags', {
                header: 'Event Tags',
                size: 125,
                cell: ({ getValue, row }) => <PopUpCard chips={getValue()} cardTitle={row.original.name} listTitle='Event Tags' />,
                enableSorting: false,
                meta: {
                    className: 'overflow--visible'
                }
            }),
            columnHelper.accessor('seriesName', {
                header: 'series name',
                meta: {
                    className: 'flex-grow'
                },
                cell: ({ getValue, row }) => {
                    const { associatedSeries } = row?.original?.associatedPresentations || {};
                    return associatedSeries?.name ?? getValue() ?? 'N/A';
                }
            }),
            columnHelper.accessor('startAt', {
                header: 'Start Date',
                cell: DateTimeCell,
                size: 125
            }),
            columnHelper.accessor('endAt', {
                header: 'End Date',
                cell: DateTimeCell,
                size: 125
            }),
            columnHelper.accessor('details', {
                header: '',
                cell: SubRowToggle,
                size: 135
            }),
            columnHelper.accessor('relatedContent', {
                header: '',
                cell: SubRowToggle,
                size: 201
            }),
            columnHelper.display({
                id: 'deepLink',
                cell: ({ row }) => <PresentationDeepLink presentation={row.original} />,
                size: 50,
                meta: {
                    className: 'IconCell'
                }
            }),
            columnHelper.display({
                id: 'delete',
                cell: ({ row }) => <IconButton name='trash-can' onClick={() => createNew ? handleNewRemoveContent([row.original]) : handleRemoveContent([row.original])} />,
                size: 50,
                meta: {
                    className: 'IconCell'
                }
            })
        ], [event]);

    const initialState = {
        expanded: {},
        sorting: createNew
            ? null
            : [{
                id: 'startAt',
                desc: true
            }]
    };

    const menuData = {
        closeMenu: () => setSelectedContent([]),
        numberOfItems: selectedContent.length,
        actions: [
            {
                text: 'Delete',
                icon: 'trash-can',
                onClick: () => createNew ? handleNewRemoveContent(selectedContent) : handleRemoveContent(selectedContent)
            }
        ]
    };

    return (
        <div className={`${parentContent ? 'EventRelatedContent' : 'EventContent'} stretch-width`}>
            <div className='flex padded__bottom'>
                <SearchDropdown
                    className='flex-grow'
                    searchData={{
                        gqlQuery: SEARCH_PRESENTATIONS,
                        dataPath: 'allPresentations.presentations',
                        // TODO: custom to be added when backend can support
                        types: parentContent ? 'episode,promo,public,movie,season,series' : 'movie,episode,series,season',
                        addtionalVariables: {
                            fetchAssociations: false,
                            resetOnSelect: false
                        }
                    }}
                    placeholder={`Search for${parentContent ? ' related' : ''} content...`}
                    handleSearchResultClick={(newContent) => handleSelectedContent(newContent)}
                    filterBy='presentationId'
                    initialData={initialData}
                />
                {
                    (!parentContent && checkUserRole('ROLE_PRODUCER')) &&
                        <Button
                            icon='plus-circle'
                            className='EventContent__Create spacing__left'
                            onClick={() => triggerNewContent()}>Create New Presentation</Button>
                }
            </div>
            <Table
                className='EventContent__Table'
                data={tableData}
                columns={columns}
                setSelectedItems={setSelectedContent}
                selectedItems={selectedContent}
                renderSubComponent={renderSubComponent}
                initialState={initialState}
                columnVisibility={createNew
                    ? {
                        seriesName: false,
                        checkbox: false,
                        relatedContent: !parentContent,
                        deepLink: !parentContent
                    }
                    : {
                        relatedContent: !parentContent,
                        deepLink: !parentContent
                    }}
            />
            <DashboardMenu menuData={menuData} />
            {episodeParent &&
                <EventContentEpisodes
                    parentContent={episodeParent}
                    handleCancel={() => setEpisodeParent(null)}
                    handleNewContent={handleNewContent}
                    event={event}
                    initialData={initialData}
                />}
        </div>
    );
};

export default EventContentTable;

EventContentTable.defaultProps = {
    createNew: false
};

EventContentTable.propTypes = {
    eventContent: PropTypes.array,
    event: PropTypes.object,
    setEvent: PropTypes.func,
    parentContent: PropTypes.object,
    createNew: PropTypes.bool,
    handleNewContent: PropTypes.func,
    triggerNewContent: PropTypes.func
};
