import { gql } from '@apollo/client';
import { EVENT_EXPANDED, EVENT_SIMPLIFIED, EVENT_DETAILS } from '../fragments/event.js';

export const GET_EVENTS = gql`
    ${EVENT_SIMPLIFIED}
    query allEvents($search: String!, $orderBy: String, $order: String, $limit: Int, $offset: Int, $aclTypes: [String], $startAtFrom: Float, $startAtTo: Float, $endAtFrom: Float, $endAtTo: Float, $statuses: [String], $approvalSourceTypes: [String]) {
        allEvents(search: $search, orderBy: $orderBy, order: $order, limit: $limit, offset: $offset, aclTypes: $aclTypes, startAtFrom: $startAtFrom, startAtTo: $startAtTo, endAtFrom: $endAtFrom, endAtTo: $endAtTo, statuses: $statuses, approvalSourceTypes: $approvalSourceTypes) {
            events {
                ...EventSimplified
            }
            total
        }
    }
`;

export const GET_EVENT_BY_ID = gql`
    ${EVENT_EXPANDED}
    query eventById($id: ID!, $extended: Boolean) {
        event: eventById(id: $id, extended: $extended) {
            ...EventExpanded
        }
    }
`;

export const CREATE_EVENT = gql`
    ${EVENT_EXPANDED}
    mutation createEvent($createdEvent: CreateEventInput) {
        createEvent(createdEvent: $createdEvent) {
            ...EventExpanded
        }
    }
`;

export const COPY_EVENT = gql`
    ${EVENT_EXPANDED}
    mutation copyEvent($eventIds: [String], $content: Boolean, $audience: Boolean, $code: Boolean, $name: String) {
        copyEvent(eventIds: $eventIds, content: $content, audience: $audience, code: $code, name: $name) {
            ...EventExpanded
        }
    }
`;

export const DELETE_EVENT = gql`
    mutation deleteEvent($eventIds: [String]) {
        deleteEvent(eventIds: $eventIds) {
            message
            error
        }
    }
`;

export const CHECK_EVENT_NAME = gql`
    query checkEventName($name: String) {
        checkEventName(name: $name) {
            events {
                id
                name
            }
        }
    }
`;

export const UPDATE_EVENT = gql`
    ${EVENT_DETAILS}
    mutation updateEvent($id: ID, $data: EventInput) {
        event: updateEvent(id: $id, data: $data) {
            ...EventDetails
        }
    }
`;

export const PUBLISH_EVENT = gql`
    mutation publishEvent($id: ID) {
        publishEvent(id: $id) {
            message
            error
        }
    }
`;

export const UNPUBLISH_EVENT = gql`
    mutation unpublishEvent($id: ID) {
        unpublishEvent(id: $id) {
            message
            error
        }
    }
`;

export const PUBLISH_MANY_EVENTS = gql`
    mutation publishManyEvents($eventIds: [String]) {
        publishManyEvents(eventIds: $eventIds) {
            message
            error
        }
    }
`;

export const UNPUBLISH_MANY_EVENTS = gql`
    mutation unpublishManyEvents($eventIds: [String]) {
        unpublishManyEvents(eventIds: $eventIds) {
            message
            error
        }
    }
`;

export const CKECK_EVENT_PUBLISH_PERMISSIONS = gql`
    query checkEventPublishPermissions($id: ID) {
        checkEventPublishPermissions(id: $id) {
            message
            error
        }
    }
`;

export const GET_EVENT_TEMPLATE = gql`
    query getEventTemplate($id: ID) {
        getEventTemplate(id: $id) {
            types
            timezone
            tags
            maxDefinition
            playCount
            docusignIntegration
            watermarkType
            watermarkLocation
            watermarkPersistence
            plainModeEnabled
            allowOfflinePlayback
            approvalSourceType
            approvalSourceId
        }
    }
`;

export const DOES_EVENT_EXIST_BY_NAME = gql`
    query doesEventExistByName($name: String) {
        doesEventExistByName(name: $name) 
    }
`;

export const GET_ALL_EVENTS_FOR_SEARCH = gql`
    query getAllEventsForSearch {
        getAllEventsForSearch {
            id
            name
        }
    }
`;
