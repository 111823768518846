/* eslint-disable indent */
import { useContext } from 'react';
import PropTypes from 'prop-types';
import Checkbox from '../Checkbox';

import { PresentationsContext } from '../../pages/PresentationsDashboard';
import { EventAddContentContext } from '../../event/EventAddContent';
import PresentationsEpisodeToggle from './PresentationsEpisodeToggle';

const PresentationsCheckbox = ({ row, table }) => {
    const { selectedPresentations, setSelectedPresentations, allowSeries, currentPresentations } = useContext(PresentationsContext) ?? useContext(EventAddContentContext) ?? {};
    const value = row.original.id;
    const { type } = row.original;

    const handleCheckbox = ({ checked }) => {
        const associations = row?.original?.associations?.episodePresentationIds ?? [];
        setSelectedPresentations(
            checked
            ? [...selectedPresentations, { ...row.original }]
            : selectedPresentations.filter(presentation => presentation.id !== value).filter(presentation => !associations.some(association => association === presentation.id)));
    };

    return (
        <>
            {
                !allowSeries && type === 'series'
                    ? <PresentationsEpisodeToggle row={row} table={table} />
                    : <Checkbox
                            key={value}
                            handleCheck={handleCheckbox}
                            value={value}
                            checked={selectedPresentations.some(job => job?.id === value)}
                            disabled={!!currentPresentations && currentPresentations?.some(currentPresentation => currentPresentation.id === value || currentPresentation.presentationId === value)}
                    />

            }
        </>
    );
};

export default PresentationsCheckbox;

PresentationsCheckbox.propTypes = {
    getValue: PropTypes.func,
    row: PropTypes.object
};
