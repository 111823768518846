import { useContext } from 'react';
import PropTypes from 'prop-types';
import ChipSetEditable from './ChipSetEditable';
import ACLSelect from './ACLSelect';
import { hasACLAccess, mergeAcl } from '../../utils/acl';
import { DataContext } from '../../contexts/DataContext';
import TextFieldLabel from './TextFieldLabel';
import { UserContext } from '../../contexts/UserContextProvider';
import { Caption } from './Typography';
import Icon from './Icon';

const ACLChips = ({ aclTypes, acl, handleACLUpdate, label, className, hideError, required }) => {
    const { appConfig } = useContext(DataContext);
    const { user } = useContext(UserContext);
    const { ACLs } = appConfig;

    const userHasACLAccess = hasACLAccess(user, acl);

    const handleDeleteACL = (aclType) => {
        const acl = aclType.reduce((acc, curr) => {
            const newAcl = ACLs[curr];
            if (!newAcl) return acc;
            return mergeAcl(acc, newAcl);
        }, []);

        handleACLUpdate(aclType, acl);
    };

    return (
        <div className={`ChipSetSelect ACLChips stretch-width ${className} ${!userHasACLAccess && !!acl?.length ? 'ACLChips__AccessError' : ''}`}>
            <TextFieldLabel label={label} required={required} />
            <div className=' ACLChips__Fields flex column flex-end stretch-width'>
                <ChipSetEditable chips={aclTypes ?? []} allowRemove editSelect handleChips={handleDeleteACL} />
                <ACLSelect
                    onChange={(aclType, acl) => handleACLUpdate(aclType, acl)}
                    initACLs={aclTypes?.length ? aclTypes : []}
                    initAccess={acl?.length ? acl : []}
                    label={null}
                    aclChips
                    hideError />
            </div>
            {!userHasACLAccess && !hideError && !!aclTypes?.length &&
                <Caption className='ACLSelect__Caption flex flex-start align-flex-end'>
                    <Icon name='warning' color='warning' size='medium' />
                    <span>Your current profile doesn't have access to selected group access</span>
                </Caption>}
        </div>
    );
};

export default ACLChips;

ACLChips.defaultProps = {
    label: '',
    className: '',
    hideError: false,
    required: false
};

ACLChips.propTypes = {
    aclTypes: PropTypes.array,
    handleACLUpdate: PropTypes.func,
    label: PropTypes.string,
    className: PropTypes.string,
    acl: PropTypes.array,
    hideError: PropTypes.bool,
    required: PropTypes.bool
};
