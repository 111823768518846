/* eslint-disable indent */
import { createContext, useMemo, useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import { SnackbarContext } from '../../contexts/SnackbarContextProvider';
import { DialogContext } from '../../contexts/DialogContextProvider';
import { DataContext } from '../../contexts/DataContext';

import { columnHelper } from '../modules/Table';
import Status from '../modules/Status';
import FileProcessingDefaultCheckbox from '../modules/TableCell/FileProcessingDefaultCheckbox';
import FileProcessingCheckbox from '../modules/TableCell/FileProcessingCheckbox';
import DateCell from '../modules/TableCell/DateCell';
import FileCell from '../modules/TableCell/FileCell';
import PresentationCell from '../modules/TableCell/PresentationCell';
import FileProcessingDialog from './FileProcessingDialog';
import FileProcessingRename from './FileProcessingRename';
import Dashboard from '../modules/Dashboard';
import { Body } from '../modules/Typography';
import {
  UPDATE_PRESENTATION_FILE,
  DELETE_PRESENTATION_FILE,
  UPDATE_FILE
} from '../../queries';
import checkUserRole from '../../hooks/checkUserRole';

export const FileProcessingContext = createContext();

const FileProcessing = (props) => {
  const { appConfig } = useContext(DataContext);
  const { id: presentationId } = useParams();
  const columnVisibility = presentationId
    ? {
        presentations: false,
        createdBy: false,
        updatedAt: false,
        updatedBy: false,
        id: checkUserRole('ROLE_CONTENT_INGEST'),
        addedOn: true
      }
    : {
        addedOn: false,
        format: false
      };
  const {
    gqlQuery,
    fileProcessingData,
    refetch,
    triggerRefetch,
    setTriggerRefetch
  } = props;

  const { warningSnackbar, errorSnackbar, successSnackbar } =
    useContext(SnackbarContext);
  const { makeDialog, deleteDialog, confirmDialog, closeDialog } =
    useContext(DialogContext);
  const [updatePresentationFile] = useMutation(UPDATE_PRESENTATION_FILE);
  const [deletePresentationFile] = useMutation(DELETE_PRESENTATION_FILE);
  const [updateFile] = useMutation(UPDATE_FILE);

  const [fileData, setFileData] = useState([]);
  const [processingFiles, setProcessingFiles] = useState([]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [defaultChecked, setDefaultChecked] = useState(false);

  useEffect(() => {
    if (!processingFiles.length && dialogOpen) {
      setTriggerRefetch ? setTriggerRefetch(true) : refetch();
      closeDialog();
      setDialogOpen(false);
    }
  }, [processingFiles]);

  const tableData = fileData?.map((file) => {
    return {
      ...file,
      status: presentationId ? file.presentationStatus : file.status,
      format: file.lastProcessingHistoryJobInput?.fileFormat,
      language: file.details ? file.details?.language : file.lastProcessingHistoryJobInput?.language,
      preview: file.details?.key
        ? `${file.details?.key}.${file.details?.format}?r=${file.details.aspectRatioFraction}`
        : null
    };
  });

  const handleQuery = (data) => {
    setTriggerRefetch(false);
    setFileData(data?.allFiles?.files || []);
  };

  useEffect(() => {
    if (!processingFiles.length) {
      setDefaultChecked(false);
    }
  }, [processingFiles]);
  useEffect(() => {
    if (fileProcessingData) {
      setFileData(fileProcessingData);
    }
  }, [fileProcessingData]);

  const columns = useMemo(
    () => [
      columnHelper.accessor('id', {
        header: <FileProcessingDefaultCheckbox />,
        id: 'id',
        cell: FileProcessingCheckbox,
        enableSorting: false,
        size: 50,
        minSize: 50,
        maxSize: 50
      }),
      columnHelper.accessor('status', {
        cell: ({ getValue }) => <Status value={getValue()} />
      }),
      columnHelper.accessor('name', {
        header: 'Filename',
        cell: FileCell,
        size: 350,
        meta: {
          className: 'flex-grow-2'
        }
      }),
      columnHelper.accessor('type'),
      columnHelper.accessor('format', {
        header: 'Format',
        cell: ({ getValue }) => {
          const rawFormat = getValue();
          if (!rawFormat) return '-';
          const videoFormat = appConfig.videoFormats.find(videoFormat => videoFormat.id === rawFormat);
          return videoFormat ? videoFormat.display : rawFormat;
        }
      }),
      columnHelper.accessor('language', {
        cell: ({ getValue }) => {
          const language = getValue();
          if (!language) return '-';
          const currentLanguage = appConfig?.rfc5646LanguageTags?.find(rfc5646LanguageTag => rfc5646LanguageTag.id === language);
          return currentLanguage ? currentLanguage.id + ' | ' + currentLanguage.display : language;
        },
        meta: {
          className: 'flex-grow'
        }
      }),
      columnHelper.accessor('presentations', {
        cell: PresentationCell,
        enableSorting: false
      }),
      columnHelper.accessor('createdAt', {
        header: 'Uploaded',
        cell: DateCell
      }),
      columnHelper.accessor('createdBy', {
        header: 'Uploaded By'
      }),
      columnHelper.accessor('updatedAt', {
        header: 'Updated',
        cell: DateCell
      }),
      columnHelper.accessor('updatedBy', {
        header: 'Updated By'
      }),
      columnHelper.accessor('addedOn', {
        header: 'Added On',
        cell: DateCell
      })
    ],
    []
  );

  const handleTrigger = () => {
    if (
      processingFiles.some((file) => file.type === 'dolby') &&
      !processingFiles.some((file) => file.type === 'video')
    ) {
      warningSnackbar({ text: 'Dolby files require a video' });
    } else if (processingFiles.every((file) => file.type === 'unknown')) {
      warningSnackbar({ text: 'Cannot process file(s) with type "UNKNOWN"' });
      setProcessingFiles([]);
    } else {
      const currentFiles = processingFiles.filter((file) => {
        return file.type !== 'unknown';
      });
      if (processingFiles.some((file) => file.type === 'unknown')) {
        warningSnackbar({
          text: `Cannot process ${
            processingFiles.length - currentFiles.length
          }  file(s) with type "UNKNOWN"`
        });
        setProcessingFiles(currentFiles);
      }
      setDialogOpen(true);
      makeDialog({
        dialog: (
            <FileProcessingDialog
                key={Math.random()}
                size='medium'
                processingFiles={currentFiles}
                setProcessingFiles={setProcessingFiles}
                presentation={props.presentation}
          />
        ),
        disableCloseOnClick: true,
        size: 'xl',
        className:
          'FileProcessing FileProcessing__Presentation manual-overflow'
      });
    }
  };

  const deleteFiles = () => {
    const deletedFiles = processingFiles.filter((file) => {
      return file.presentationStatus === 'new';
    });
    if (deletedFiles.length) {
      if (deletedFiles < processingFiles) {
        warningSnackbar({
          text: `${
            processingFiles.length - deletedFiles.length
          } files cannot be removed. Files require a status of NEW.`
        });
        setProcessingFiles(deletedFiles);
      }
      setDialogOpen(true);
      deleteDialog({
        type: 'Files',
        action: 'Remove',
        confirmAction: 'Removed',
        name: deletedFiles.map((file) => file.name),
        size: 'medium',
        className: 'FileProcessing__Delete',
        handleConfirmationClose: () => setProcessingFiles([]),
        handleCancel: () => {
          setProcessingFiles([]);
        },
        deleteMutation: async () => {
          for (const file of deletedFiles) {
            const { id } = file;
            try {
              const variables = { id, presentationId };
              const { data } = await deletePresentationFile({ variables });
              file.result = data.deletePresentationFile.message;
            } catch (error) {
              console.log(error);
            }
          }
          await refetch();
          return deletedFiles;
        }
      });
    } else {
      errorSnackbar({ text: 'Files require a status of NEW to be removed' });
      setProcessingFiles([]);
    }
  };

  const handleRejected = async () => {
    for (const job of processingFiles) {
      const variables = {
        id: job.id,
        status: 'rejected'
      };
      try {
        if (!presentationId) {
          await updateFile({ variables });
        } else {
          variables.presentationId = presentationId;
          await updatePresentationFile({ variables });
        }
        successSnackbar({ text: 'Rejected file' });
      } catch (error) {
        console.log(error);
        errorSnackbar({ text: 'Error rejecting file' });
      }
    }
    setProcessingFiles([]);
  };

  const menuData = {
    closeMenu: () => setProcessingFiles([]),
    numberOfItems: processingFiles.length,
    actions: [
      {
        text: 'Process',
        icon: 'reload',
        onClick: handleTrigger
      },
      {
        text: 'Rename',
        icon: 'edit-page',
        onClick: () => {
          setDialogOpen(true);
          makeDialog({
            title: 'Update Filename',
            dialog: (
                <FileProcessingRename
                    key={Math.random()}
                    size='medium'
                    processingFiles={processingFiles}
                    setProcessingFiles={setProcessingFiles}
              />
            ),
            disableCloseOnClick: true,
            size: 'xl'
          });
        }
      },
      {
        text: 'Reject',
        icon: 'thumbs-down',
        onClick: () => {
          setDialogOpen(true);
          confirmDialog({
            title: `Reject ${processingFiles.length} file(s):`,
            text: (
                <div className='FileProcessing__Files--wrapper FileProcessing__Files--wrapper--solid padded'>
                    <ul className='FileProcessing__Files'>
                        {processingFiles.map((job) => (
                            <li key={job.id}>
                                <Body number={2}>{job.name}</Body>
                            </li>
                  ))}
                    </ul>
                </div>
            ),
            handleConfirm: handleRejected,
            handleCancel: () => setProcessingFiles([]),
            size: 'medium',
            className: 'FileProcessing__Reject'
          });
        }
      },
      {
        text: 'Remove',
        icon: 'trash-can',
        hide: !presentationId,
        onClick: deleteFiles
      }
    ]
  };

  const dashboardData = {
    hideHeader: !!presentationId,
    gqlQuery,
    handleQuery,
    tableData,
    tableSort: presentationId ? [{ id: 'createdAt', desc: true }] : null,
    columns,
    columnVisibility,
    triggerRefetch,
    menuData
  };

  return (
      <FileProcessingContext.Provider
          value={{
        processingFiles,
        setProcessingFiles,
        refetch: () => setTriggerRefetch(true),
        tableData,
        fileData,
        defaultChecked,
        setDefaultChecked
      }}
    >
          <Dashboard
              hasSideFilters
              dashboardData={dashboardData}
              type='Files'
              className={!presentationId ? 'FileProcessing' : 'PresentationFiles'}
      />
      </FileProcessingContext.Provider>
  );
};

export default FileProcessing;

FileProcessing.propTypes = {
  gqlQuery: PropTypes.object,
  fileProcessingData: PropTypes.array,
  refetch: PropTypes.func,
  triggerRefetch: PropTypes.bool,
  setTriggerRefetch: PropTypes.func
};
