import { useContext, useEffect, useState } from 'react';
import EventDetails from './EventDetails';
import { EventAddContentContext } from './EventAddContent';
import DialogFooter from '../modules/DialogFooter';

const EventAddContentDetails = props => {
    const { event, details, setDetails, setEditDetails } = useContext(EventAddContentContext);

    const [newDetails, setNewDetails] = useState({ ...details });

    const handleDetailsUpdate = async (value, field) => {
        const { type, id } = field;
        type === 'object' ? setNewDetails({ ...newDetails, ...value }) : setNewDetails({ ...newDetails, [id]: value });
    };

    useEffect(() => setNewDetails({ ...details }), [details]);

    const handleNewDetails = () => {
        setDetails({ ...newDetails });
        setEditDetails(false);
    };

    const handleCancel = () => {
        setNewDetails({ ...details });
        setEditDetails(false);
    };

    return (
        <div className='EventContent__Add--Details'>
            <EventDetails
                details={newDetails}
                setDetails={(updatedContent) => setNewDetails({ ...updatedContent })}
                event={event}
                detailsType='content'
                bulkEdit
                createNew
                parentDetails={newDetails}
                handleDetailsChange={(field, value) => handleDetailsUpdate(field, value)}
                handleDetailsBlur={(field, value) => handleDetailsUpdate(field, value)}
            />
            <DialogFooter
                handleConfirm={handleNewDetails}
                handleCancel={handleCancel}
            />
        </div>
    );
};

export default EventAddContentDetails;
