import { useContext, useState } from 'react';
import _ from 'lodash';
import { useMutation } from '@apollo/client';

import { EventContext } from '../pages/Event';
import { SnackbarContext } from '../../contexts/SnackbarContextProvider';
import { SnackbarDialogContext } from '../../contexts/SnackbarDialogContextProvider';

import EventDetails from './EventDetails';

import useUpdateEventContent from '../../hooks/event/useUpdateEventContent';

import { UPDATE_EVENT, UPDATE_EVENT_CONTENT } from '../../queries';
import EventRelatedContentV2 from './EventRelatedContentV2';

const EventContentSubRowV2 = ({ row, subrow, id, relatedContentParent }) => {
    const { event, initEvent, setInitEvent, handleRefetchEvent } = useContext(EventContext);
    const { id: eventId } = event;
    const { successSnackbar, loadingSnackbar, closeSnackbar } = useContext(SnackbarContext);
    const { snackbarDialog } = useContext(SnackbarDialogContext);

    const [handleEventUpdate] = useMutation(UPDATE_EVENT);
    const [updateEventContent] = useMutation(UPDATE_EVENT_CONTENT);

    const [contentErrors, setContentErrors] = useState({});
    const [contentDetails, setContentDetails] = useState({ ...row.original });
    const contentId = row?.original?.id;
    const contentIndex = event.eventContent.findIndex(eventPres => eventPres.id === contentId || eventPres.presentationId === contentId);
    const parentId = relatedContentParent?.id;
    const parentContentIndex = event.eventContent.findIndex(eventPres => eventPres.id === parentId || eventPres.presentationId === parentId);
    const relatedContentIndex = relatedContentParent?.relatedContent?.findIndex(content => content.id === contentId || content.presentationId === contentId);

    const validate = (value, field) => {
        if (value === null) return false;
        const { validation, allowZero = true } = field;
        switch (validation) {
        case 'number': {
            const number = Number(value);
            return !allowZero ? !number || value <= 0 : !number;
        }
        default:
            return false;
        }
    };

    const handleContentChange = (value, field) => {
        const currentPres = { ...contentDetails };
        const { validation, id } = field;
        currentPres[id] = value;
        setContentDetails(currentPres);
        if (validation) {
            const valid = validate(value, field);
            if (contentErrors[id] !== valid) setContentErrors({ ...contentErrors, [id]: valid });
        };
    };

    const handleDates = async (currentDetails) => {
        const { startAt, endAt } = currentDetails;
        const currentEvent = await handleRefetchEvent();

        if ((!!startAt && startAt < currentEvent.startAt) || !startAt) currentEvent.startAt = startAt;
        if ((!!endAt && !!currentEvent.endAt && endAt > currentEvent.endAt) || !endAt) currentEvent.endAt = endAt;
        if (!currentEvent.endAt) currentEvent.endAt = null;

        if (!_.isEqual(currentEvent, event)) {
            try {
                const variables = {
                    id: event.id,
                    data: {
                        ...currentEvent
                    }
                };
                await handleEventUpdate({ variables });
                return currentEvent;
            } catch (error) {
                console.log(error);
                closeSnackbar();
                snackbarDialog({
                    snackbarText: 'Error Updating Event Dates',
                    dialogTitle: 'Event Update Error',
                    graphQLErrors: error?.graphQLErrors
                });
                return false;
            }
        }
        return currentEvent;
    };

    const handleUpdatedContent = (updatedContent, updatedEvent) => {
        const currentEvent = updatedEvent ?? _.cloneDeep({ ...event });
        relatedContentParent
            ? currentEvent.eventContent[parentContentIndex].relatedContent[relatedContentIndex] = updatedContent
            : currentEvent.eventContent[contentIndex] = updatedContent;
        setInitEvent(currentEvent);
    };

    const handleContentUpdate = async (value, field) => {
        const initContent = relatedContentParent?.relatedContent?.[relatedContentIndex] ?? initEvent.eventContent[contentIndex];
        const { updatedDetails, updatedContent: relatedContent } = useUpdateEventContent(initContent, field, value);
        let updatedEvent;

        if ((field.id === 'eventDates') && !relatedContentParent) {
            loadingSnackbar({ text: `Updating ${field.label ?? field.id}` });
            const updatedDates = await handleDates(updatedDetails);
            if (!updatedDates) return;
            updatedEvent = updatedDates;
        }

        loadingSnackbar({ text: `Updating ${field.label ?? field.id}` });

        const data = relatedContentParent
            ? {
                ...updatedDetails
            }
            : {
                ...updatedDetails,
                relatedContent
            };

        try {
            const eventContentVariables = {
                id: eventId,
                contentId,
                data
            };

            await updateEventContent({ variables: eventContentVariables });
            const currentEvent = updatedEvent ?? await handleRefetchEvent();
            handleUpdatedContent(data, { ...currentEvent });
            successSnackbar({ text: `Successfully updated ${field.label}` });
        } catch (error) {
            console.log(error);
            closeSnackbar();
            snackbarDialog({
                snackbarText: `Error Updating ${field.label}`,
                dialogTitle: 'Event Details Error',
                graphQLErrors: error?.graphQLErrors
            });
        }
    };

    return (
        <>
            {
                {
                    details: <EventDetails
                        event={event}
                        details={contentDetails}
                        createNew={false}
                        setDetails={(updatedContent) => setContentDetails({ ...updatedContent })}
                        handleDetailsChange={(value, field) => handleContentChange(value, field)}
                        handleDetailsBlur={(value, field) => handleContentUpdate(value, field)}
                        errors={contentErrors}
                        detailsType='content'
                        parentDetails={relatedContentParent}
                        strictParentDates={!!relatedContentParent}
                    />,
                    relatedContent: <EventRelatedContentV2
                        relatedContentParent={row.original}
                    />
                }[id ?? row.id]
            }
        </>
    );
};

export default EventContentSubRowV2;
