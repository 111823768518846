import { useQuery } from '@apollo/client';
import PropTypes from 'prop-types';
import _ from 'lodash';
import TextField from '../TextField';
import Select from '../Select';
import { episodeSelect, createEpisode } from '../../../config/createPresentationConfig';

import { SEARCH_PRESENTATIONS } from '../../../queries';
import { useEffect, useState } from 'react';
import { isWholeNum } from '../../../utils/validation';

const EpisodeSelect = ({ createNew, handleChange, episode, allEpisodes, setDisabled, disabled }) => {
    const [disabledFields, setDisabledFields] = useState({});
    const [errors, setErrors] = useState({});
    const [search, setSearch] = useState('');

    const { episodeId, episodeNumber } = episode;

    const variables = {
        search,
        orderBy: '',
        order: '',
        types: 'episode',
        limit: 10,
        offset: 0,
        page: 1
    };

    const { data, loading } = useQuery(SEARCH_PRESENTATIONS, { variables });

    const episodes = data?.allPresentations?.presentations || [];
    const episodeOptions = episodes.map(episode => ({ text: episode.name, value: episode.id }));

    useEffect(() => setDisabled(!!_.size(errors) || disabled), [errors, disabled]);

    useEffect(() => {
        const currDisabled = { ...disabledFields };
        if (!episodeId || (episodeId && episodeId !== 'createNew')) {
            currDisabled.episodeName = true;
            currDisabled.episodeNumber = true;
        } else {
            delete currDisabled.episodeName;
            delete currDisabled.episodeNumber;
        }
        setDisabledFields(currDisabled);
    }, [episodeId]);

    useEffect(() => {
        if (!allEpisodes) return;
        const currErrors = { ...errors };
        allEpisodes.filter(ep => ep.episodeId === episodeId).length > 1 ? currErrors.episodeId = true : delete currErrors.episodeId;
        if (episodeId === 'createNew' || !episodeId) delete currErrors.episodeId;

        if (!episodeNumber) {
            errors.episodeNumber ? currErrors.episodeNumber = true : delete currErrors.episodeNumber;
        }

        if (!!episodeNumber && !currErrors.episodeNumber) {
            !isWholeNum(episodeNumber) || episodeNumber.length > 9 ? currErrors.episodeNumber = true : delete currErrors.episodeNumber;
        }

        setErrors(currErrors);
    }, [allEpisodes]);

    const handleUpdate = (value, field) => {
        let pickedEpisode;
        if (field.id === 'episodeId') {
            if (value !== 'createNew') {
                pickedEpisode = episodes.find(episode => episode.id === value);
            } else {
                pickedEpisode = { ...episode, episodeName: '', episodeNumber: '' };
            }
        }

        handleChange(!isWholeNum(value) ? value : Number(value), field.id, pickedEpisode);

        const currErrors = { ...errors };
        field.id === 'episodeNumber' && value == null ? currErrors.episodeNumber = true : delete currErrors.episodeNumber;
        setErrors(currErrors);
    };

    return (
        <div className='EpisodeSelect flex align-flex-start'>
            <Select
                key={episodeSelect.id}
                id={episodeSelect.id}
                options={[...episodeOptions]}
                onChange={({ value }) => handleUpdate(value, episodeSelect)}
                label={episodeSelect.label}
                selectedValue={episode[episodeSelect.id]}
                searchable
                handleSearchChange={(searchValue) => setSearch(searchValue)}
                className='EpisodeSelect--episodeId'
                loading={loading}
                error={errors[episodeSelect.id]}
                errorMessage='No duplicate episodes'
                createNew
                createNewRole='ROLE_PRODUCER'
                placeholder='Select or create a episode...'
            />
            {(createNew || episode.episodeId === 'createNew') &&
                createEpisode.map(field => {
                    const { id, label, errorMessage, maxLength, type, hideErrorStyles } = field;
                    return (
                        <TextField
                            key={id}
                            id={id}
                            type={type}
                            label={label || id}
                            className={`EpisodeSelect--${id}`}
                            maxLength={maxLength}
                            hideErrorStyles={hideErrorStyles}
                            onChange={({ target }) => handleUpdate(target.value, field)}
                            error={errors[id]}
                            errorMessage={errorMessage}
                            disabled={disabledFields[id]}
                            value={episode[id] ?? ''}
                        />
                    );
                })}
        </div>
    );
};

export default EpisodeSelect;

EpisodeSelect.defaultPropts = {
    createNew: false
};

EpisodeSelect.proptypes = {
    handleChange: PropTypes.func,
    episode: PropTypes.object,
    allEpisodes: PropTypes.array,
    setDisabled: PropTypes.func,
    disabled: PropTypes.bool,
    createNew: PropTypes.bool
};
