import { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import PropTypes from 'prop-types';
import EpisodeSelect from './FormFields/EpisodeSelect';
import SeasonSelect from './FormFields/SeasonSelect';
import IconButton from './IconButton';
import TextField from './TextField';
import { Body, Caption, Subtitle } from './Typography';
import { isWholeNum } from '../../utils/validation';
import PresentationAutoPublish from '../presentation/PresentationAutoPublish';

const AddEpisodeToSeries = ({ createdPresentation, setCreatedPresentation, setDisabled, disabled }) => {
    const addedEpisodes = createdPresentation.addedEpisodes || [];
    const newEpisodes = addedEpisodes.filter(addedEpisode => addedEpisode.episodeId === 'createNew');
    const [episodeCount, setEpisodeCount] = useState(1);

    useEffect(() => {
        const valid = addedEpisodes.every(episode => {
            const { episodeName, episodeNumber, episodeId, seasonNumber } = episode;
            return !!episodeName && isWholeNum(episodeNumber) && !!episodeId && isWholeNum(seasonNumber);
        });
        setDisabled(!valid);
    }, [addedEpisodes]);

    useEffect(() => {
        const updatedEpisodes = addedEpisodes.map(episode => {
            if (!isWholeNum(episode.seasonNumber)) return episode;
            const seasonName = `${createdPresentation.name} - Season ${episode.seasonNumber}`;
            return { ...episode, seasonName };
        });
        setCreatedPresentation({ ...createdPresentation, addedEpisodes: updatedEpisodes });
    }, [createdPresentation.name]);

    const handleEpisodeCount = () => {
        const updatedEpisodes = [...addedEpisodes];
        for (let i = 0; i < Number(episodeCount); i++) {
            updatedEpisodes.push({ id: uuidv4() });
        }
        setCreatedPresentation({ ...createdPresentation, addedEpisodes: updatedEpisodes });
        setEpisodeCount(1);
    };

    const handleRemove = (ep) => {
        const updatedEpisodes = addedEpisodes.filter(episode => episode.id !== ep.id);
        setCreatedPresentation({ ...createdPresentation, addedEpisodes: updatedEpisodes });
    };

    const updateEpisodes = (value, id, episode, pickedEpisode) => {
        const updatedEpisodes = [...addedEpisodes];
        const updates = pickedEpisode ? { [id]: value, episodeNumber: pickedEpisode.episodeNumber, episodeName: pickedEpisode.name } : { [id]: value };
        updatedEpisodes[updatedEpisodes.findIndex(ep => ep.id === episode.id)] = { ...episode, ...updates };
        setCreatedPresentation({ ...createdPresentation, addedEpisodes: updatedEpisodes });
    };

    return (
        <div className='AddEpisode'>
            <Subtitle number={4} fadedHalf block className='uppercase spacing__bottom'>Add Episode(s)</Subtitle>
            {
                addedEpisodes.map((episode) =>
                    <div key={episode.id} className='AddEpisode__Fields flex align-flex-start'>
                        <EpisodeSelect
                            createNew
                            episode={episode}
                            handleChange={(value, id, pickedEpisode) => updateEpisodes(value, id, episode, pickedEpisode)}
                            allEpisodes={addedEpisodes}
                            setDisabled={setDisabled}
                            disabled={disabled}
                        />
                        <SeasonSelect
                            createNew
                            associatedPresentation={episode}
                            handleChange={(value, id) => updateEpisodes(value, id, episode)}
                            createdPresentation={createdPresentation}
                            createdPresentations={createdPresentation.addedEpisodes}
                            setDisabled={setDisabled}
                            disabled={disabled}
                        />
                        <IconButton name='close-round' onClick={() => handleRemove(episode)} />
                    </div>
                )
            }
            {!!newEpisodes.length &&
                <PresentationAutoPublish
                    presentation={createdPresentation}
                    setPresentation={setCreatedPresentation}
                    isEpisode
                    createNewPresentation
                />}

            <div className='flex flex-start AddEpisode__Controls spacing__top'>
                <IconButton
                    disabled={!isWholeNum(episodeCount)}
                    name='plus-circle'
                    onClick={handleEpisodeCount} />
                <Body>Add</Body>
                <TextField
                    value={episodeCount}
                    onChange={({ target }) => setEpisodeCount(target.value)}
                />
                <Body>Episode(s)</Body>
            </div>
            {!isWholeNum(episodeCount) &&
                <Caption error padded block>Must be a valid number</Caption>}
        </div>
    );
};

export default AddEpisodeToSeries;

AddEpisodeToSeries.proptypes = {
    createdPresentation: PropTypes.object,
    setCreatedPresentation: PropTypes.func,
    setDisabled: PropTypes.func,
    disabled: PropTypes.bool
};
