import { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useLazyQuery } from '@apollo/client';
import { DateTime } from 'luxon';
import IconButton from '../modules/IconButton';
import { Heading, Body } from '../modules/Typography';
import ChipSet from '../modules/ChipSet';
import Button from '../modules/Button';
import { DialogContext } from '../../contexts/DialogContextProvider';
import download from '../../utils/download';

import { GET_BATCH_CODES } from '../../queries';
import Loading from '../modules/Loading';
import checkUserRole from '../../hooks/checkUserRole';
import { SnackbarContext } from '../../contexts/SnackbarContextProvider';
import { SnackbarDialogContext } from '../../contexts/SnackbarDialogContextProvider';

const CodeCard = ({ code, linkBatches, cardActions, createFlow }) => {
    const { deleteDialog, closeDialog } = useContext(DialogContext);
    const { errorSnackbar } = useContext(SnackbarContext);
    const { snackbarDialog } = useContext(SnackbarDialogContext);
    const { id, description, codeCount, createdAt, policyExpiration } = code;
    const [codeDates, setCodeDates] = useState({});
    const [loadingAction, setLoadingAction] = useState(false);

    const codeRole = checkUserRole('ROLE_CODE');

    const { handleRemove } = cardActions;

    const [getBatchCodes] = useLazyQuery(GET_BATCH_CODES, { variables: { id } });

    useEffect(() => {
        const createdAtDate = DateTime.fromMillis(createdAt).toFormat('LL/dd/y');
        const expirationUnix = createdAt + (policyExpiration * 1000);
        const expirationDate = DateTime.fromMillis(expirationUnix).toFormat('LL/dd/y');
        const currentDate = Date.now();
        const active = currentDate >= createdAt && currentDate <= expirationUnix;
        setCodeDates({
            duration: `${createdAtDate} - ${expirationDate}`,
            active
        });
    }, [policyExpiration]);

    const handleCodeRemove = () => {
        deleteDialog({
            type: 'Code',
            action: 'Remove',
            name: [description],
            size: 'medium',
            handleCancel: () => {
                closeDialog();
            },
            deleteMutation: async () => {
                await handleRemove(code);
                closeDialog();
            }
        });
    };

    const handleCodeDownload = async () => {
        setLoadingAction(true);
        try {
            const { data } = await getBatchCodes();
            const batchCodes = data?.getBatchCodes;
            if (!batchCodes || !batchCodes.length) {
                return errorSnackbar({ text: 'Error Downloading Codes' });
            }
            const codes = batchCodes.map(batch => batch.code);
            download(
                `${description}_${Date.now()}`,
                'text/csv;charset=utf-8',
                `codes\n${codes.join('\n')}`
            );
        } catch (error) {
            console.log(error);
            snackbarDialog({
                snackbarText: 'Error Downloading Codes',
                dialogTitle: 'Codes Download Error',
                graphQLErrors: error?.graphQLErrors
            });
        }

        setLoadingAction(false);
    };

    const getStatus = () => {
        if (!codeDates.active) return 'Expired';
        if (!!codeDates.active && !!code.enabled) return 'Active';
        return 'Deactivated';
    };

    return (
        <div className='CodeCard elevation-2 flex column'>
            <div className='CodeCard__Actions'>
                {/* Currently doesn't function */}
                {/* <IconButton name='email' onClick={() => console.log('email')} /> */}
                <IconButton name='download' onClick={() => handleCodeDownload()} disabled={!codeRole} />
                <IconButton name='trash-can' onClick={() => createFlow ? handleRemove(id) : handleCodeRemove()} disabled={!codeRole} />
            </div>
            {
                linkBatches
                    ? <Button type='text' onClick={() => console.log(id)}>{description}</Button>
                    : <Heading number={5}>{description}</Heading>
            }

            <div className='flex flex-start half-spacing__top half-spacing__bottom'>
                <Body>{getStatus()}</Body>
                <span className='block spacing'>&#8226;</span>
                <Body>{codeDates.duration}</Body>
            </div>
            <ChipSet chips={[`${codeCount} code${codeCount > 1 ? 's' : ''}`]} />
            {
                loadingAction &&
                <Loading />
            }
        </div>
    );
};

export default CodeCard;

CodeCard.defaultProps = {
    linkBatches: false,
    createFlow: false
};

CodeCard.proptypes = {
    code: PropTypes.object,
    linkBatches: PropTypes.bool,
    cardActions: PropTypes.object,
    createFlow: PropTypes.bool
};
