import { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useLazyQuery } from '@apollo/client';
import _ from 'lodash';

import Select from '../Select';

import { DataContext } from '../../../contexts/DataContext';
import { SnackbarContext } from '../../../contexts/SnackbarContextProvider';

import { GET_EVENT_TEMPLATE } from '../../../queries';

const EventTemplate = ({ event, setEvent }) => {
    const { appConfig } = useContext(DataContext);
    const { eventTemplates } = appConfig;
    const { loadingSnackbar, errorSnackbar, successSnackbar } = useContext(SnackbarContext);
    const [selectedTemplate, setSelectedTemplate] = useState('');
    const options = eventTemplates?.map(val => ({ text: val.name, value: val.id })) ?? [];

    const [getEventTemplate] = useLazyQuery(GET_EVENT_TEMPLATE);

    const [configValues, setConfigValues] = useState({});
    const matchesTemplate = Object.keys(configValues).every(x => event[x] === configValues[x]);

    const handleTemplateChange = async (value) => {
        loadingSnackbar({ text: 'Fetching Event Template Data' });
        setSelectedTemplate(value);
        try {
            const { data, errors } = await getEventTemplate({ variables: { id: value } });
            if (errors) return errorSnackbar({ text: 'Failed to fetch event template' });
            const currentEvent = _.cloneDeep({ ...event });
            Object.keys(configValues)?.forEach(key => {
                currentEvent[key]?.length && currentEvent[key]?.length > configValues[key]?.length
                    ? currentEvent[key] = currentEvent[key].filter(x => !configValues[key].some(initX => initX === x))
                    : currentEvent[key] = null;
            });
            const cleanData = _.omitBy((data?.getEventTemplate ?? {}), _.isNil);
            setConfigValues(cleanData);
            setEvent({ ...currentEvent, ...cleanData });
            successSnackbar({ text: 'Applied event template' });
        } catch (error) {
            console.log(error);
            errorSnackbar({ text: 'Failed to fetch event template' });
        }
    };

    useEffect(() => {
        if (!matchesTemplate) {
            setSelectedTemplate('');
            setConfigValues({});
        }
    }, [matchesTemplate]);

    return (
        <Select
            label='Event Template'
            options={[{ text: 'Custom', value: '' }, ...options]}
            selectedValue={selectedTemplate}
            onChange={({ value }) => handleTemplateChange(value)}
            disabled={!options.length}
        />
    );
};

export default EventTemplate;

EventTemplate.proptypes = {
    event: PropTypes.object,
    setEvent: PropTypes.func
};
