import { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import { SnackbarContext } from '../../contexts/SnackbarContextProvider';
import DialogFooter from '../modules/DialogFooter';
import { DialogContext } from '../../contexts/DialogContextProvider';
import { Subtitle } from '../modules/Typography';
import PresentationAutoPublishCheckbox from '../presentation/PresentationAutoPublishCheckbox';
import { publishingConfig } from '../../config/publishingConfig';
import { UNPUBLISH_PRESENTATION } from '../../queries';

const UnpublishDialog = ({ presentation, refetchPresentation }) => {
    const { name, type, documentId, doc } = presentation;
    const { status } = doc.publish;
    const { closeDialog } = useContext(DialogContext);
    const { errorSnackbar, successSnackbar } = useContext(SnackbarContext);
    const { environments, scopes } = publishingConfig[type];
    const [publishingEnvironments, setPublishingEnvironments] = useState(environments);

    useEffect(() => setPublishingEnvironments(publishingEnvironments.filter(env => status[env].value !== 'unpublished')), []);

    const [unpublishPresentation, { loading }] = useMutation(UNPUBLISH_PRESENTATION, {
        variables: {
            documentId,
            publishData: {
                type,
                environments: publishingEnvironments,
                scopes
            }
        }
    });

    const handleUnpublish = async () => {
        try {
            await unpublishPresentation();
            refetchPresentation();
            closeDialog();
            successSnackbar({ text: `Successfully unpublished ${name}` });
        } catch (err) {
            console.log(err);
            errorSnackbar({ text: `Falied to unpublish ${name}` });
        }
    };

    return (
        <>
            <div className='padded PublishingDialog'>
                <Subtitle number={1} block className='spacing__bottom'>Environments</Subtitle>
                <div className='flex flex-start'>
                    <PresentationAutoPublishCheckbox
                        value={publishingEnvironments}
                        disabled={{
                            live: status.live.value === 'unpublished',
                            preveiw: status.preview.value === 'unpublished'
                        }}
                        onChange={checked => setPublishingEnvironments(checked)}
                    />
                </div>
            </div>
            <DialogFooter
                handleCancel={closeDialog}
                handleConfirm={handleUnpublish}
                disabled={!publishingEnvironments.length}
                confirmText='Unpublish'
                confirmCancelText='Back'
                loading={loading}
            />
        </>
    );
};

UnpublishDialog.propTypes = {
    presentation: PropTypes.object,
    refetchPresentation: PropTypes.func
};

export default UnpublishDialog;
