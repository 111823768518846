export const fileConfig = {
    video: {
        header: {
            details: [
                {
                    title: 'Source File(s)',
                    id: 'sourceFiles',
                    fields: [
                        {
                            id: 'sourceFile',
                            label: 'Sourcefile',
                            textField: {
                                fieldId: 'location',
                                disabled: true,
                                type: 'textarea'
                            }
                        },
                        {
                            id: 'dolbyVisionXml',
                            label: 'Dolby',
                            parentField: {
                                parentId: 'fileFormat',
                                parentValue: 'uhd_dovi'
                            },
                            textField: {
                                fieldId: 'dolbyVisionXml',
                                disabled: true,
                                type: 'textarea'
                            }
                        },
                        {
                            id: 'docId',
                            label: 'Authoring ID'
                        },
                        {
                            id: 'createdBy',
                            label: 'Uploaded By'
                        },
                        {
                            id: 'createdAt',
                            label: 'Uploaded',
                            format: 'dateTime'
                        }
                    ]
                },
                {
                    title: 'Media Info',
                    id: 'mediaInfo',
                    columns: 2,
                    fields: [
                        {
                            id: 'resolution',
                            label: 'Resolution'
                        },
                        {
                            id: 'ColorSpace',
                            label: 'Colorspace'
                        },
                        {
                            id: 'framerate',
                            label: 'Framerate',
                            format: 'framerate'
                        },
                        {
                            id: 'Duration',
                            label: 'Duration',
                            format: 'time'
                        }
                    ]

                },
                {
                    id: 'MediaInfo',
                    label: 'Media Info',
                    type: 'json'
                }
            ]
        },
        processingData: [
            {
                id: 'input',
                title: 'Input',
                details: [
                    {
                        id: 'overview',
                        fields: [
                            {
                                id: 'createdBy',
                                label: 'Processed By'
                            },
                            {
                                id: 'createdAt',
                                label: 'Processed On',
                                format: 'dateTime'
                            }
                        ]
                    },
                    {
                        title: 'User Input',
                        id: 'userInput',
                        fields: [
                            {
                                id: 'jobType',
                                label: 'Job Type'
                            },
                            {
                                id: 'fileFormat',
                                label: 'Format'
                            },
                            {
                                id: 'language',
                                label: 'Language',
                                format: 'language'
                            }
                        ]
                    },
                    {
                        title: 'Advanced',
                        id: 'advanced',
                        columns: 3,
                        fields: [
                            {
                                id: 'chunkSize',
                                label: 'ChunkSize'
                            },
                            {
                                id: 'clipmarkStart',
                                label: 'ClipmarkStart'
                            },
                            {
                                id: 'uploadTimeStamp',
                                label: 'UploadTimeStamp'
                            },
                            {
                                id: 'audioKind',
                                label: 'AudioKind'
                            },
                            {
                                id: 'clipmarkEnd',
                                label: 'ClipmarkEnd'
                            },
                            {
                                id: 'watermarkingMode',
                                label: 'Watermarking'
                            },
                            {
                                id: 'clipmarkThreshold',
                                label: 'Threshold'
                            },
                            {
                                id: 'hdrMetadata',
                                label: 'HdrMetadata',
                                format: 'hdrMetadata',
                                parentField: {
                                    parentId: 'fileFormat',
                                    parentValue: 'uhd_hdr10'
                                }
                            }
                        ]
                    },
                    {
                        id: 'jobInput',
                        label: 'API Request',
                        type: 'json'
                    }
                ]
            },
            {
                id: 'output',
                title: 'Output',
                details: [
                    {
                        id: 'overview',
                        fields: [
                            {
                                id: 'workflowName',
                                label: 'Genie ID',
                                type: 'genieLink'
                            },
                            {
                                id: 'updatedAt',
                                label: 'Completed On',
                                format: 'dateTime'
                            }
                        ]
                    },
                    {
                        title: 'Genie Output',
                        id: 'genieOutput',
                        fields: [
                            {
                                id: 'jobStatus',
                                label: 'Status',
                                type: 'status'
                            },
                            {
                                label: 'Streaming URL',
                                textField: {
                                    fieldId: 'playlistUrl',
                                    disabled: false
                                }
                            },
                            {
                                id: 'outputTags',
                                label: 'Tags',
                                type: 'tags'
                            }
                        ]
                    },
                    {
                        id: 'jobOutput',
                        label: 'API Response',
                        type: 'json'
                    }
                ]
            }
        ]
    },
    audio: {
        header: {
            details: [
                {
                    id: 'overview',
                    fields: [
                        {
                            id: 'docId',
                            label: 'Authoring ID'
                        },
                        {
                            id: 'createdBy',
                            label: 'Uploaded By'
                        },
                        {
                            id: 'createdAt',
                            label: 'Uploaded',
                            format: 'dateTime'
                        }
                    ]
                },
                {
                    title: 'Media Info',
                    id: 'mediaInfo',
                    fields: [
                        {
                            id: 'Channels',
                            label: 'Channels'
                        },
                        {
                            id: 'language',
                            label: 'Language'
                        },
                        {
                            id: 'sourceFile',
                            label: 'Sourcefile',
                            textField: {
                                fieldId: 'location',
                                disabled: true,
                                type: 'textarea'
                            }
                        }
                    ]

                },
                {
                    id: 'MediaInfo',
                    label: 'Media Info',
                    type: 'json'
                }
            ]
        },
        processingData: [
            {
                id: 'input',
                title: 'Input',
                details: [
                    {
                        id: 'overview',
                        fields: [
                            {
                                id: 'createdBy',
                                label: 'Processed By'
                            },
                            {
                                id: 'createdAt',
                                label: 'Processed On',
                                format: 'dateTime'
                            }
                        ]
                    },
                    {
                        title: 'User Input',
                        id: 'userInput',
                        fields: [
                            {
                                id: 'jobType',
                                label: 'Job Type'
                            },
                            {
                                id: 'fileFormat',
                                label: 'Format'
                            },
                            {
                                id: 'language',
                                label: 'Language',
                                format: 'language'
                            }
                        ]
                    },
                    {
                        title: 'Advanced',
                        id: 'advanced',
                        columns: 3,
                        parentField: {
                            parentId: 'fileFormat',
                            parentValue: 'atmos'
                        },
                        fields: [
                            {
                                id: 'uploadTimeStamp',
                                label: 'UploadTimeStamp'
                            },
                            {
                                id: 'startTimecode',
                                label: 'Start Timecode'
                            },
                            {
                                id: 'end',
                                label: 'End'
                            },
                            {
                                id: 'offset',
                                label: 'Offset'
                            },
                            {
                                id: 'bitrate',
                                label: 'Bitrate'
                            },
                            {
                                id: 'sourceFormat',
                                label: 'SourceFormat'
                            },
                            {
                                id: 'audioKind',
                                label: 'AudioKind'
                            }
                        ]
                    },
                    {
                        id: 'jobInput',
                        label: 'API Request',
                        type: 'json'
                    }
                ]
            },
            {
                id: 'output',
                title: 'output',
                details: [
                    {
                        id: 'overview',
                        fields: [
                            {
                                id: 'workflowName',
                                label: 'Genie ID',
                                type: 'genieLink'
                            },
                            {
                                id: 'updatedAt',
                                label: 'Completed On',
                                format: 'dateTime'
                            }
                        ]
                    },
                    {
                        title: 'Genie Output',
                        id: 'genieOutput',
                        fields: [
                            {
                                id: 'jobStatus',
                                label: 'Status',
                                type: 'status'
                            },
                            {
                                label: 'Streaming URL',
                                textField: {
                                    fieldId: 'playlistUrl',
                                    disabled: false
                                }
                            }
                        ]
                    },
                    {
                        id: 'jobOutput',
                        label: 'API Response',
                        type: 'json'
                    }
                ]
            }
        ]
    },
    subtitle: {
        header: {
            details: [
                {
                    id: 'overview',
                    fields: [
                        {
                            id: 'docId',
                            label: 'Authoring ID'
                        },
                        {
                            id: 'createdBy',
                            label: 'Uploaded By'
                        },
                        {
                            id: 'createdAt',
                            label: 'Uploaded',
                            format: 'dateTime'
                        }
                    ]
                },
                {
                    title: 'Media Info',
                    id: 'mediaInfo',
                    fields: [
                        {
                            id: 'framerate',
                            label: 'Framerate',
                            format: 'framerate'
                        },
                        {
                            id: 'language',
                            label: 'Language'
                        },
                        {
                            id: 'timestamp',
                            label: 'Timestamp',
                            format: 'time'
                        },
                        {
                            id: 'sourceFile',
                            label: 'Sourcefile',
                            textField: {
                                fieldId: 'location',
                                disabled: true,
                                type: 'textarea'
                            }
                        }
                    ]

                },
                {
                    id: 'details',
                    label: 'Media Info',
                    type: 'json'
                }
            ]
        },
        processingData: [
            {
                id: 'input',
                title: 'Input',
                details: [
                    {
                        id: 'overview',
                        fields: [
                            {
                                id: 'createdBy',
                                label: 'Processed By'
                            },
                            {
                                id: 'createdAt',
                                label: 'Processed On',
                                format: 'dateTime'
                            }
                        ]
                    },
                    {
                        title: 'User Input',
                        id: 'userInput',
                        fields: [
                            {
                                id: 'jobType',
                                label: 'Job Type'
                            },
                            {
                                id: 'subtitleType',
                                label: 'Format'
                            },
                            {
                                id: 'language',
                                label: 'Language',
                                format: 'language'
                            }
                        ]
                    },
                    {
                        title: 'Advanced',
                        id: 'advanced',
                        fields: [
                            {
                                id: 'uploadTimeStamp',
                                label: 'UploadTimeStamp'
                            }
                        ]
                    },
                    {
                        id: 'jobInput',
                        label: 'API Request',
                        type: 'json'
                    }
                ]
            },
            {
                id: 'output',
                title: 'output',
                details: [
                    {
                        id: 'overview',
                        fields: [
                            {
                                id: 'workflowName',
                                label: 'Genie ID',
                                type: 'genieLink'
                            },
                            {
                                id: 'updatedAt',
                                label: 'Completed On',
                                format: 'dateTime'
                            }
                        ]
                    },
                    {
                        title: 'Genie Output',
                        id: 'genieOutput',
                        fields: [
                            {
                                id: 'jobStatus',
                                label: 'Status',
                                type: 'status'
                            },
                            {
                                id: 'timeStamp',
                                label: 'Timestamp'
                            },
                            {
                                label: 'Streaming URL',
                                textField: {
                                    fieldId: 'playlistUrl',
                                    disabled: false
                                }
                            }
                        ]
                    },
                    {
                        id: 'jobOutput',
                        label: 'API Response',
                        type: 'json'
                    }
                ]
            }
        ]
    },
    image: {
        header: {
            details: [
                {
                    id: 'imageHeader',
                    fields: [
                        {
                            id: 'image',
                            type: 'image'
                        },
                        {
                            id: 'sourceFile',
                            label: 'Sourcefile',
                            textField: {
                                fieldId: 'location',
                                disabled: true,
                                type: 'textarea'
                            }
                        }
                    ]
                },
                {
                    id: 'details',
                    label: 'Media Info',
                    type: 'json'
                }
            ]
        }
    }
};
