import Table, { columnHelper } from '../modules/Table';
import TableCheckbox from '../modules/TableCell/TableCheckbox';
import TableCheckboxHeader from '../modules/TableCell/TableCheckboxHeader';
import NotificationDetailsDialog from '../dialogs/event/NotificationDetailsDialog';
import PreviewEmailDialog from '../dialogs/event/PreviewEmailDialog';
import { useState } from 'react';
import DashboardMenu from '../modules/DashboardMenu';

const EventNotifications = () => {
    const [selected, setSelected] = useState([]);

    const testingData = [
        {
            id: '0',
            type: 'New Event Notification',
            dateTime: '05/01/2022; 11:00 PM PST',
            planned: '2',
            sent: '5',
            viewed: '11',
            opened: '0',
            errors: '1'
        }
    ];

    const columns = [
        columnHelper.accessor('id', {
            header: TableCheckboxHeader,
            enableSorting: false,
            size: 50,
            cell: TableCheckbox,
            meta: {
                className: 'padded flex-start'
            }
        }),
        columnHelper.accessor('type', {
            meta: {
                className: 'flex-grow flex-start'
            }
        }),
        columnHelper.accessor('dateTime', {
            header: 'Date & Time',
            meta: {
                className: 'flex-grow flex-start'
            }
        }),
        columnHelper.accessor('planned'),
        columnHelper.accessor('sent'),
        columnHelper.accessor('viewed'),
        columnHelper.accessor('opened'),
        columnHelper.accessor('errors')
    ];

    const dashboardActions = [

    ];

    console.log(dashboardActions);
    const menuData = {
        closeMenu: () => setSelected([]),
        numberOfItems: selected.length,
        actions: [
            {
                text: 'View Details',
                icon: '\ue9fe', // 'file-text' does not work as a ligature on Safari, use unicode
                dialog: <NotificationDetailsDialog />
            },
            {
                text: 'Preview Email',
                icon: 'email',
                dialog: <PreviewEmailDialog />
            }
        ]
    };

    return (
        <>
            <Table
                data={testingData}
                columns={columns}
                selectedItems={selected}
                setSelectedItems={setSelected}
            />
            <DashboardMenu menuData={menuData} />
        </>

    );
};

export default EventNotifications;
