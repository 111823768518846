import { useState, useMemo, useEffect } from 'react';

const useOnScreen = (ref, rootRef, data, options) => {
    const [isIntersecting, setIntersecting] = useState(false);

    const observer = useMemo(() => new IntersectionObserver(
        ([entry]) => setIntersecting(entry.isIntersecting), {
            root: rootRef.current,
            ...(options ?? {})
        }
    ), [ref, rootRef]);

    useEffect(() => {
        if (!ref.current || !rootRef.current || !data.length) return;
        observer.observe(ref.current);
        return () => observer.disconnect();
    }, [ref.current, rootRef.current, data]);

    return isIntersecting;
};

export default useOnScreen;
